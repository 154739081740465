import * as Rx from "rxjs";
import type { HpProfile, Modality } from "shared";
import { E } from "shared/base-prelude";
import { ApiMgr } from "../../../api.mgr";
import { createContextAndHook } from "../../../util";
import { BaseStateMgr } from "../base.statemgr";
import type { GenericChatCli } from "../chat.statemgr";
import type {
  GenericRtcChannelMgr,
  GenericRtcEngine,
  GenericRtcMgr,
} from "../rtc.statemgr";
import type { UserStateMgr } from "./user.statemgr";

export class HpStateMgr<
  StreamChatCli extends GenericChatCli,
  ChannelMgr extends GenericRtcChannelMgr,
  RtcEngine extends GenericRtcEngine<ChannelMgr>,
  RtcMgr extends GenericRtcMgr<RtcEngine, ChannelMgr>
> extends BaseStateMgr {
  profileMgr: HpProfileMgr;
  myUserId: string;

  constructor(
    readonly uMgr: UserStateMgr<StreamChatCli, ChannelMgr, RtcEngine, RtcMgr>,
    p: { myProfile: HpProfile; myUserId: string; apiMgr: ApiMgr }
  ) {
    super({ apiMgr: p.apiMgr });
    this.profileMgr = new HpProfileMgr({
      apiMgr: p.apiMgr,
      myProfile: p.myProfile,
    });
    this.myUserId = p.myUserId;
  }
}

export class HpProfileMgr extends BaseStateMgr {
  myProfile$: Rx.BehaviorSubject<HpProfile>;

  constructor(p: { apiMgr: ApiMgr; myProfile: HpProfile }) {
    super({ apiMgr: p.apiMgr });
    this.myProfile$ = new Rx.BehaviorSubject(p.myProfile);

    this.refreshMyProfile();
  }

  refreshMyProfile = () => {
    this.refreshMyProfileP().catch();
  };

  refreshMyProfileP = async () => {
    const r = await this.BE.fetchEndpointTE((Api) =>
      Api.hp.getMyProfile.query()
    )();
    console.log("REFRESH MY PROFILE! ", r);
    if (E.isRight(r)) {
      this.myProfile$.next(r.right);
    }

    return r;
  };

  saveOnboardProfile = async (p: { firstName: string; lastName: string }) => {
    this.myProfile$.next({
      ...this.myProfile$.value,
    });
    const saveResult = await this.BE.fetchEndpointTE((Api) =>
      Api.hp.setMyOnboardProfile.mutate(p)
    )();
    if (E.isRight(saveResult)) {
      this.refreshMyProfile();
    }
  };

  setMyProfile = (profile: HpProfile) => {
    this.myProfile$.next(profile);
  };

  addModality = (modality: Modality) => {
    const curProfile = this.myProfile$.value;
    this.myProfile$.next({
      ...curProfile,
      modalities: [...curProfile.modalities, modality],
    });
    this.BE.fetchEndpointTE((Api) =>
      Api.hp.settings.addModalityToMySkills.mutate({
        modality,
      })
    )().then((er) => {
      console.log("ER! ", er);
      this.refreshMyProfile();
    });
  };

  removeModality = (modality: Modality) => {
    const curProfile = this.myProfile$.value;
    this.myProfile$.next({
      ...curProfile,
      modalities: curProfile.modalities.filter((m) => m.slug !== modality.slug),
    });
    this.BE.fetchEndpointTE((Api) =>
      Api.hp.settings.removeModalityFromMySkills.mutate({
        modalitySlug: modality.slug,
      })
    )().then((er) => {
      console.log("ER! ", er);
      this.refreshMyProfile();
    });
  };
}

const [HpProfileMgrContext, useHpProfileMgr] =
  createContextAndHook<HpProfileMgr>();

export { HpProfileMgrContext, useHpProfileMgr };
