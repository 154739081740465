import {
  hasScreenShare,
  ParticipantView,
  type StreamVideoParticipant,
} from "@stream-io/video-react-sdk";
import {
  CustomParticipantViewUI,
  CustomVideoPlaceholder,
} from "@webapp/componentslive-session/live-session.components";
import React from "react";

interface OneOnOneParticipantViewProps {
  me: StreamVideoParticipant;
  otherParticipant: StreamVideoParticipant;
  aspectRatio?: "16/9" | "4/3";
}

export const OneOnOneParticipantView: React.FC<
  OneOnOneParticipantViewProps
> = ({ me, otherParticipant, aspectRatio }) => {
  return (
    <div
      className={`
    flex-1 
    ${aspectRatio === "16/9" ? "aspect-16/9" : ""}
    min-w-0 min-h-0 relative border-2 rounded-[22px] max-w-screen
    `}
    >
      <ParticipantView
        participant={otherParticipant}
        ParticipantViewUI={<CustomParticipantViewUI />}
        trackType={
          hasScreenShare(otherParticipant) ? "screenShareTrack" : "videoTrack"
        }
        VideoPlaceholder={CustomVideoPlaceholder}
      />
      <div className="absolute bottom-8 right-8 w-[200px] aspect-16/9 z-30 rounded-[22px] border-2 ">
        <ParticipantView
          participant={me}
          trackType={hasScreenShare(me) ? "screenShareTrack" : "videoTrack"}
          ParticipantViewUI={CustomParticipantViewUI}
          VideoPlaceholder={CustomVideoPlaceholder}
        />
      </div>
    </div>
  );
};
