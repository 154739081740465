import { PrimaryButton } from "@webapp/components/primitives/button";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";

export const HpOnboardChooseProfileMethodPage: React.FC = () => {
  const [enteredUrl, setEnteredUrl] = useState("");
  const nav = useNavigate();
  return (
    <div className="flex-1 p-8 flex flex-col items-center justify-center">
      <div className="p-8 flex flex-col gap-[22px]">
        <h1 className="text-[54px] font-medium text-vid-purple">
          Tell us about your practice
        </h1>
        <div className="flex justify-center self-stretch">
          <div className="flex flex-col gap-4 basis-[544px] grow-0 shrink">
            <input
              type="text"
              className="text-input"
              placeholder="Enter a URL (Psychology Today or your website)"
              value={enteredUrl}
              onChange={(e) =>
                setEnteredUrl((e.target as HTMLInputElement).value)
              }
            />
            <PrimaryButton
              title="Submit"
              disabled={enteredUrl.length === 0}
              onClick={() => {
                nav(
                  UROUTES.HP.ONBOARD_SET_PROFILE.buildPath(
                    {},
                    {
                      fromUrl: enteredUrl,
                    }
                  )
                );
              }}
            />
          </div>
        </div>
        <Link
          className="text-vid-purple font-medium self-center"
          to={UROUTES.HP.ONBOARD_SET_PROFILE.path}
        >
          Or enter manually
        </Link>
      </div>
    </div>
  );
};
