import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { BE } from "@webapp/backend";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { isLoadingState } from "frontend-shared/src/fetch";
import { type UApiOutput } from "frontend-shared/src/trpc-cli";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { useNavigate } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { useMe } from "../../../../u.webstatemgr";
import { CommunityPost } from "../components/community-post.fc";
import {
  CommunityCardContainer,
  CommunityEventCardContainer,
  MyProfileCard,
} from "../components/community.components";

export const CommunitiesLandingPage: React.FC = () => {
  const mgr = useHpState();
  const hpProfile = useObservableEagerState(mgr.myProfile$);
  const me = useMe();

  const rdLandingData = BE.useSuccessFetch(
    (Api) => Api.hp.communities.communitiesLandingHome.query(),
    []
  );

  if (isLoadingState(rdLandingData)) {
    return <FullContainerLoadingSpinner />;
  }

  const {
    communitiesIveJoined,
    suggestedCommunitiesToJoin,
    timelineFeedPosts,
    myUpcomingEvents,
  } = rdLandingData.data;

  return (
    <div className="flex-1 flex flex-col gap-8 p-8 static lg:relative max-w-full">
      <div className="flex-1 flex gap-16">
        <div className="flex flex-col gap-4 max-w-full lg:w-[714px]">
          <h4 className="text-xl font-medium">Communities</h4>
          <CommunitiesListSection
            communitiesIveJoined={communitiesIveJoined}
            suggestedCommunitiesToJoin={suggestedCommunitiesToJoin}
          />
          {myUpcomingEvents.length > 0 ? (
            <div className="flex flex-col gap-4 mt-4">
              <h4 className="text-xl font-medium">Upcoming events</h4>
              <div className="flex gap-2 items-center overflow-x-auto">
                {myUpcomingEvents.map((e) => (
                  <CommunityEventCardContainer
                    key={e.instanceId}
                    evtInstance={e}
                    showCommunityName={true}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div>No upcoming events</div>
          )}
          <div className="flex flex-col gap-4 mt-8">
            <h4 className="text-xl font-medium leading-6 ">Recent posts</h4>
            {timelineFeedPosts.map((post) => (
              <CommunityPost
                key={post.id}
                community={{
                  slug: post.communitySlug,
                  allMembers: [],
                }}
                post={post}
              />
            ))}
          </div>
        </div>
        <MyProfileCard
          name={me.name!}
          profilePhoto={ImageSrc.fromMbUrl(me.profilePhoto)}
          mode={{ _tag: "COMMUNITIES" }}
          bio={hpProfile.bio}
          modalities={hpProfile.modalities}
          editBioLink={""}
        />
      </div>
    </div>
  );
};

type CommunitiesIveJoined =
  UApiOutput["hp"]["communities"]["communitiesLandingHome"]["communitiesIveJoined"];
type SuggestedCommunitiesToJoin =
  UApiOutput["hp"]["communities"]["communitiesLandingHome"]["suggestedCommunitiesToJoin"];

const CommunitiesListSection: React.FC<{
  communitiesIveJoined: CommunitiesIveJoined;
  suggestedCommunitiesToJoin: SuggestedCommunitiesToJoin;
}> = ({ communitiesIveJoined, suggestedCommunitiesToJoin }) => {
  const nav = useNavigate();
  const hpState = useHpState();
  return (
    <div className="self-stretch flex justify-between gap-4 lg:min-w-0 overflow-x-auto">
      {communitiesIveJoined.map((c) => (
        <CommunityCardContainer
          key={c.slug}
          headerBackgroundType={{ _tag: "FIXED", color: c.color }}
          innerCircleComponent={
            <div className="w-10 h-10 lg:w-[100px] lg:h-[100px] bg-vid-black-200 rounded-full" />
          }
          title={c.name}
          content={
            <div className="flex flex-col gap-2">
              <div></div>
              <div className="text-vid-black-400">members</div>
            </div>
          }
          button={{
            title: "View",
            onClick: () => {
              nav(
                UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
                  {
                    community: c.slug,
                  }
                )
              );
            },
          }}
        />
      ))}
      {suggestedCommunitiesToJoin.map((c) => (
        <CommunityCardContainer
          key={c.slug}
          headerBackgroundType={{
            _tag: "FIXED",
            color: c.color,
          }}
          innerCircleComponent={
            <div className="w-10 h-10 lg:w-[100px] lg:h-[100px] bg-vid-black-200 rounded-full" />
          }
          title={c.name}
          content={
            <div className="flex flex-col gap-2">
              <div></div>
              <div className="text-vid-black-400">members</div>
            </div>
          }
          button={{
            title: "Join",
            onClick: () => {
              nav(
                UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
                  {
                    community: c.slug,
                  }
                )
              );
            },
          }}
        />
      ))}

      <CommunityCardContainer
        key={"create-community"}
        headerBackgroundType={{
          _tag: "FIXED",
          color: "#000000",
        }}
        innerCircleComponent={
          <div className="w-10 h-10 lg:w-[100px] lg:h-[100px] bg-vid-black-200 rounded-full" />
        }
        title={"Create a community"}
        content={
          <div className="flex flex-col gap-2">
            <div></div>
            <div className="text-vid-black-400">members</div>
          </div>
        }
        button={{
          title: "Create",
          onClick: () => {
            hpState.dashboardState.openRightNav({
              _tag: "CREATE_COMMUNITY",
            });
          },
        }}
      />
    </div>
  );
};
