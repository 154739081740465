import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { BE } from "@webapp/backend";
import { CommunityTabMessenger } from "@webapp/components/chat/group-chat";
import { useTaskEither } from "frontend-shared/src/api.mgr";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { RD } from "shared/base-prelude";
import { useMe } from "../../../u.webstatemgr";

export const CommunityTabLayout: React.FC = () => {
  return (
    <div className="flex-1 flex flex-col static lg:relative">
      <Outlet />
      <CommunityTabMessengerBottomBar />
    </div>
  );
};

const CommunityTabMessengerBottomBar: React.FC = () => {
  const hpState = useHpState();
  const me = useMe();
  const rdTotalUnreadMessagesCount = useTaskEither(
    BE.fetchEndpointTE((Api) =>
      Api.hp.communities.getUnreadMessagesCount.query()
    )
  );

  const [isMinimized, setIsMinimized] = React.useState(true);

  useEffect(() => {
    if (
      RD.isSuccess(rdTotalUnreadMessagesCount) &&
      rdTotalUnreadMessagesCount.value > 0
    ) {
      setIsMinimized(false);
    }
  }, [rdTotalUnreadMessagesCount]);

  return (
    <div
      className={`
        hidden fixed bottom-0 right-24 z-20  w-[400px] rounded-lg 
        lg:flex flex-col
        overflow-y-auto
        border
        ${isMinimized ? "max-h-[80px]" : "min-h-[400px] max-h-[80%]"}
          `}
      style={{
        boxShadow: "0px 8px 30px 0px rgba(0, 0, 0, 0.3)",
      }}
    >
      <CommunityTabMessenger
        myProfile={{
          id: me.id!,
          name: me.name!,
          profilePhoto: me.profilePhoto!,
        }}
        onMinimizeClick={() => setIsMinimized((i) => !i)}
        isMinimized={isMinimized}
        chatClient={hpState.uMgr.chatStateMgr.chatCli}
      />
    </div>
  );
};
