import { pipe } from "fp-ts/lib/function";
import { O, RD } from "shared/base-prelude";
import {
  OneIndexedMonth,
  toZeroIndexedMonth,
  type CalendarEventInstanceIsh,
  type CalendarEventsByDay,
  type DayOfYear,
} from "shared/types/calendar.types";
import { CalendarUtils } from "shared/utils/calendar.utils";
import { FullContainerLoadingSpinner } from "../../loading";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

interface CalendarMonthViewProps<T> {
  rdDaysWithEventsForMonth: RD.RemoteData<any, CalendarEventsByDay<T>>;
  dayInFocus: DayOfYear;
  renderEvent: (event: T) => React.ReactNode;
  onDayClick: (day: Date) => void;
}

export const CalendarMonthView = <T extends CalendarEventInstanceIsh>({
  rdDaysWithEventsForMonth,
  dayInFocus,
  renderEvent,
  onDayClick,
}: CalendarMonthViewProps<T>) => {
  return pipe(
    rdDaysWithEventsForMonth,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      (daysWithEventsForMonth) => (
        <CalendarMonthViewLoaded
          daysWithEventsForMonth={daysWithEventsForMonth}
          dayInFocus={dayInFocus}
          renderEvent={renderEvent}
          onDayClick={onDayClick}
        />
      )
    )
  );
};

type LoadedProps<T> = Omit<
  CalendarMonthViewProps<T>,
  "rdDaysWithEventsForMonth"
> & {
  daysWithEventsForMonth: CalendarEventsByDay<T>;
};

export const CalendarMonthViewLoaded = <T extends CalendarEventInstanceIsh>({
  daysWithEventsForMonth,
  dayInFocus,
  renderEvent,
  onDayClick,
}: LoadedProps<T>) => {
  const curDoy = CalendarUtils.doyToDate(dayInFocus);
  const curMonth = OneIndexedMonth(curDoy.getMonth() + 1);
  const curYear = curDoy.getFullYear();

  return (
    <div
      key={`${curMonth}-${curYear}`}
      className="flex-1 flex flex-col border rounded-xl my-4 h-full"
    >
      <div className="grid grid-cols-7 border rounded-xl">
        {daysOfWeek.map((day, idx) => (
          <div
            key={idx}
            className="h-fit py-2 font-sans text-center font-medium inline-flex justify-center bg-gray-100"
          >
            {day}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 rounded-xl">
        {CalendarUtils.getDaysForMonth({
          monthIdx: toZeroIndexedMonth({ oiMonthIdx: curMonth }),
          year: curYear,
        })
          .map(CalendarUtils.asDOY)
          .map((doy) => {
            const mbEventDay = daysWithEventsForMonth.find(
              (calEvent) => calEvent.doy.day === doy.day
            );

            return (
              <div
                key={doy.day}
                className="border border-gray-200 flex flex-col justify-start items-center p-1 h-42 aspect-square overflow-hidden"
                onClick={() => onDayClick(CalendarUtils.doyToDate(doy))}
              >
                <div
                  className={`${
                    doy.day === dayInFocus.day
                      ? "bg-vid-purple text-white p-1 rounded-full"
                      : ""
                  } text-xs text-center font-semibold w-fit h-fit`}
                >
                  {doy.day}
                </div>
                {mbEventDay && (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="flex flex-col w-full"
                  >
                    {mbEventDay.events
                      .sort(
                        (a, b) => a.startTime.getTime() - b.startTime.getTime()
                      )
                      .map((appt) => (
                        <div key={appt.instanceId}>{renderEvent(appt)}</div>
                      ))}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};
