import * as Rx from "rxjs";
import type { BaseSessionStateModule } from "shared/session-state/session-state.types";
import type { RemoteSessionStateSyncMgr } from "../../../../../firestore-live-session.mgmt";
import type { BaseStageViewStateMgr } from "../../../../../types/video-state.types";

export interface BaseSessionToolStateMgrParams {
  remoteStateMgr: RemoteSessionStateSyncMgr<
    BaseSessionStateModule.FirebaseEncodedState,
    BaseSessionStateModule.State
  >;
  stageViewStateMgr: BaseStageViewStateMgr<any, any>;
}

export class BaseSessionToolStateMgr {
  baseSessionState$: Rx.Observable<BaseSessionStateModule.State>;
  remoteStateMgr: RemoteSessionStateSyncMgr<
    BaseSessionStateModule.FirebaseEncodedState,
    BaseSessionStateModule.State
  >;
  stageViewStateMgr: BaseStageViewStateMgr<any, any>;

  constructor(params: BaseSessionToolStateMgrParams) {
    this.baseSessionState$ = params.remoteStateMgr.decodedRemoteState$;
    this.remoteStateMgr = params.remoteStateMgr;
    this.stageViewStateMgr = params.stageViewStateMgr;
  }
}
