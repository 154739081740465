import type { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import { apiMgr } from "@webapp/backend";
import { FullScreenLoadingSpinner } from "@webapp/loading";
import { WebRtcMgr } from "@webapp/mgrs/web-rtc.mgr";
import { Match, Option } from "effect";
import { signOut } from "firebase/auth";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import {
  PubSubNotificationsMgmt,
  PubSubNotificationsMgmtContext,
} from "frontend-shared/src/mgrs/pubsub-notifications.mgmt";
import {
  AuthStateMgr,
  type AuthState,
} from "frontend-shared/src/mgrs/state-mgrs/auth/auth.statemgr";
import { useSetupUStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/user.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import type { SimpleUserWithProfilePhoto } from "shared";
import type { UserPrimaryRole } from "shared/db";
import { UROUTES } from "shared/routes/u.routes";
import { StreamChat } from "stream-chat";
import { UWebStateMgr, UWebStateMgrContext } from "./u.webstatemgr";

export const ULayout: React.FC = () => {
  const authStateMgr = useOnce(() => new AuthStateMgr({ apiMgr: apiMgr }));
  const rdAuthState = useObservableEagerState(authStateMgr.authState$);

  useEffect(() => {
    authStateMgr.runCheckAuthState();
  }, []);

  console.log("U LAYOUT! ", rdAuthState);

  return Match.value(rdAuthState).pipe(
    Match.tag("RemoteInitial", () => <FullScreenLoadingSpinner />),
    Match.tag("RemotePending", () => <FullScreenLoadingSpinner />),
    Match.tag("RemoteFailure", () => <div>Error</div>), // TODO
    Match.tag("RemoteSuccess", (authState) => (
      <ULoadedView authState={authState.value} />
    )),
    Match.exhaustive
  );
};

export const ULoadedView: React.FC<{
  authState: AuthState;
}> = ({ authState }) => {
  const firebaseJs = useFirebaseJs();
  useEffect(() => {
    if (authState._tag === "UNAUTHORIZED") {
      signOut(firebaseJs.auth).finally(() => {
        window.location.href = "/";
      });
    }
  }, [authState]);

  console.log("ULoadedView! ", authState);

  return Match.value(authState).pipe(
    Match.tag("UNAUTHORIZED", () => <div></div>),
    Match.tag("AUTHORIZED", ({ mbPrimaryRole, me }) => (
      <AuthorizedView mbPrimaryRole={mbPrimaryRole} me={me} />
    )),
    Match.exhaustive
  );
};

const AuthorizedView: React.FC<{
  mbPrimaryRole: UserPrimaryRole | null;
  me: SimpleUserWithProfilePhoto;
}> = ({ mbPrimaryRole, me }) => {
  const location = useLocation();
  const nav = useNavigate();
  const firebaseJs = useFirebaseJs();
  const pubSubMgr = useOnce(() => new PubSubNotificationsMgmt(firebaseJs));

  const mbUStateMgr = useSetupUStateMgr<
    StreamChat,
    Call,
    StreamVideoClient,
    WebRtcMgr,
    UWebStateMgr
  >(
    apiMgr,
    firebaseJs,
    me,
    (p) => StreamChat.getInstance(p.apiKey),
    (p) => new UWebStateMgr(p)
  );

  console.log("MB U STATE MGR! ", mbUStateMgr);

  useEffect(() => {
    if (location.pathname === UROUTES.path) {
      Match.value(mbPrimaryRole).pipe(
        Match.when("client", () => {
          nav(UROUTES.CP.DASHBOARD.HOME.path);
        }),
        Match.when("hp", () => {
          nav(UROUTES.HP.MY.DASHBOARD.CLIENTS.path);
        }),
        Match.when(null, () => {
          nav(UROUTES.HP.MY.DASHBOARD.CLIENTS.path);
        }),
        Match.exhaustive
      );
    }
  }, [mbPrimaryRole, location.pathname]);

  if (Option.isNone(mbUStateMgr)) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <UWebStateMgrContext.Provider value={mbUStateMgr.value}>
      <PubSubNotificationsMgmtContext.Provider value={pubSubMgr}>
        <Outlet />
      </PubSubNotificationsMgmtContext.Provider>
    </UWebStateMgrContext.Provider>
  );
};
