import type { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import type { WebRtcMgr } from "@webapp/mgrs/web-rtc.mgr";
import type { UserStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/user.statemgr";
import React from "react";
import { E } from "shared/base-prelude";
import { StreamChat } from "stream-chat";
import { BE } from "../../../backend";
import { DashboardStateMgr } from "../../../components/dashboard/dashboard.state";
import { Rx } from "../../../prelude";
import type { CpDashboardRightNav } from "./right-nav";
export type CpSimpleProfile = {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  profilePhoto: string | null;
  worksWithClients: boolean | null;
};

export class CpState {
  myUserId: string;
  dashboardMgr = new DashboardStateMgr<CpDashboardRightNav>();
  myProfile$: Rx.BehaviorSubject<CpSimpleProfile>;

  constructor(
    readonly uMgr: UserStateMgr<StreamChat, Call, StreamVideoClient, WebRtcMgr>,
    p: { myProfile: CpSimpleProfile }
  ) {
    this.myUserId = p.myProfile.id;
    this.myProfile$ = new Rx.BehaviorSubject(p.myProfile);
  }

  refreshMyProfile = () => {
    BE.fetchEndpointTE((Api) => Api.cp.getMyProfile.query())().then((er) => {
      if (E.isRight(er)) {
        this.myProfile$.next({
          id: er.right.id,
          firstName: er.right.first_name,
          lastName: er.right.last_name,
          name: er.right.name,
          email: er.right.email,
          profilePhoto: er.right.profilePhoto,
          worksWithClients: er.right.works_with_clients,
        });
      }
    });
  };

  updateName = (p: { firstName?: string; lastName?: string }) => {
    const firstName = p.firstName ?? this.myProfile$.value.firstName;
    const lastName = p.lastName ?? this.myProfile$.value.lastName;
    this.myProfile$.next({
      ...this.myProfile$.value,
      firstName,
      lastName,
    });

    BE.fetchEndpointTE((Api) =>
      Api.cp.updateProfile.mutate({
        firstName,
        lastName,
      })
    )().then((er) => {
      console.log("UPDATE PROFILE RESULT! ", er);
      this.refreshMyProfile();
    });
  };
}

export const CpStateContext = React.createContext<CpState | null>(null);

export function useCpState() {
  return React.useContext(CpStateContext)!;
}
