import * as Sentry from "@sentry/react";
import { BE } from "@webapp/backend";
import { FullScreenLoadingSpinner } from "@webapp/loading";
import { pipe } from "fp-ts/function";
import { useTaskEither } from "frontend-shared/src/api.mgr";
import React, { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { HpProfile } from "shared";
import { RD } from "shared/base-prelude";
import { useMe, useUMgr } from "../u.webstatemgr";
import { HpStateContext, HpWebStateMgr } from "./hp.webstatemgr";

export const HpLayout: React.FC = () => {
  const rdMyProfile = useTaskEither(
    BE.fetchEndpointTE((Api) => Api.hp.getMyProfile.query()),
    []
  );
  const me = useMe();

  useEffect(() => {
    if (RD.isSuccess(rdMyProfile)) {
      console.log("MY PROFILE! ", rdMyProfile.value);
      const { id, email } = me;
      Sentry.setUser({ id, email });
    }
    if (RD.isFailure(rdMyProfile)) {
      console.log(rdMyProfile);
      window.location.href = "/";
    }
  }, [rdMyProfile]);

  return pipe(
    rdMyProfile,
    RD.fold(
      () => <></>,
      () => <FullScreenLoadingSpinner />,
      (e) => <div>{JSON.stringify(e)}</div>,
      (myProfile) => <MyLayoutContent myProfile={myProfile} />
    )
  );
};

const MyLayoutContent: React.FC<{ myProfile: HpProfile }> = ({ myProfile }) => {
  const uMgr = useUMgr();
  const state = useMemo(
    () => new HpWebStateMgr(uMgr, { myProfile, myUserId: uMgr.myUserId }),
    [uMgr, myProfile]
  );

  return (
    <HpStateContext.Provider value={state}>
      <Outlet />
    </HpStateContext.Provider>
  );
};
