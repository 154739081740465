import { useCall } from "@stream-io/video-react-sdk";
import { apiMgr } from "@webapp/backend";
import { WebkitAudioPlayer } from "@webapp/mgrs/web-audio-player";
import {
  HpPrivateSessionRoomCallStateMgr,
  HpPrivateSessionRoomCallStateMgrContext,
} from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/hp-room-call.statemgr";
import {
  usePrivateSessionCallStateMgr,
  usePrivateSessionStateMgr,
} from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/remote-state-and-call.statemgrs";
import { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";

export const HpPrivateSessionCallLayout: React.FC = () => {
  const psSessionStateMgr = usePrivateSessionStateMgr();
  const callStateMgr = usePrivateSessionCallStateMgr();
  const call = useCall()!;

  const hpRoomCallStateMgr = useMemo(
    () =>
      new HpPrivateSessionRoomCallStateMgr({
        apiMgr: apiMgr,
        callId: callStateMgr.callId,
        sessionMgr: psSessionStateMgr,
        callStateMgr: callStateMgr,
        remoteParticipants$: call.state.remoteParticipants$,
        audioPlayer: new WebkitAudioPlayer(() => {}),
      }),
    [callStateMgr.callId, psSessionStateMgr]
  );

  useEffect(() => {
    return () => {
      hpRoomCallStateMgr.cleanup();
    };
  }, [hpRoomCallStateMgr]);

  return (
    <HpPrivateSessionRoomCallStateMgrContext.Provider
      value={hpRoomCallStateMgr}
    >
      <Outlet />
    </HpPrivateSessionRoomCallStateMgrContext.Provider>
  );
};
