import { ReviewFullPrivateSession } from "@webapp/componentssession-review/components/session-review.components";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { UROUTES } from "shared/routes/u.routes";

export const PrivateSessionReviewPage: React.FC = () => {
  const { privateSessionId } = useTypedParams(
    UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.REVIEW
  );
  return (
    <div className="flex-1 flex flex-col">
      <ReviewFullPrivateSession sessionId={privateSessionId} hideTabs={[]} />
    </div>
  );
};
