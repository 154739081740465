import { useRtcMgr } from "@pages/u/u.webstatemgr";
import {
  BackgroundFiltersProvider,
  StreamCall,
  StreamVideo,
  useCallStateHooks,
  type Call,
} from "@stream-io/video-react-sdk";
import { apiMgr } from "@webapp/backend";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { WebkitAudioPlayer } from "@webapp/mgrs/web-audio-player";
import { SuccessOnlyViewO } from "@webapp/utils/utils";
import { Effect, Option } from "effect";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import {
  GroupSessionRoomCallStateMgrContext,
  useGroupSessionRoomCallStateMgr,
  useSetupGroupSessionRoomCallStateMgr,
} from "frontend-shared/src/mgrs/state-mgrs/sessions/group-sessions/group-session.statemgrs";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { UROUTES } from "shared/routes/u.routes";

export const HpGroupSessionCallLayout: React.FC<{}> = () => {
  const { callId } = useTypedParams(
    UROUTES.GROUP_SESSIONS.GROUP_SESSION_ID.CALLS.CALL_ID
  );

  const rtcMgr = useRtcMgr();

  useEffect(() => {
    Effect.runPromise(
      rtcMgr.lazyJoinChannel({
        channelType: "group_session",
        channelId: callId,
      })
    ).then((_) => {
      console.log("JOINED CALL! ", callId);
    });

    return () => {
      console.log("LEAVING CALL ON DISMOUNT! ", callId);
      Effect.runPromise(rtcMgr.handleDismountInCall).then((_) => {
        console.log("LEFT CALL! ", callId);
      });
    };
  }, []);

  const mbChannelMgr = useObservableEagerState(rtcMgr.mbCurrentChannelMgr$);

  // const mbFinishedSetup = useSetupGroupSessionRoomCallStateMgr({
  //   groupSessionId,
  //   callId,
  //   firebaseJsMgr,
  //   apiMgr,
  //   audioPlayer,
  // });

  return (
    <SuccessOnlyViewO
      mbV={mbChannelMgr}
      loadedView={(channelMgr) => <LoadedView channelMgr={channelMgr} />}
    />
  );
};

const LoadedView: React.FC<{ channelMgr: Call }> = ({ channelMgr }) => {
  const { callId, groupSessionId } = useTypedParams(
    UROUTES.GROUP_SESSIONS.GROUP_SESSION_ID.CALLS.CALL_ID
  );
  const firebaseJsMgr = useFirebaseJs();
  const audioPlayer = useOnce(() => new WebkitAudioPlayer(() => {}));

  const mbFinishedSetup = useSetupGroupSessionRoomCallStateMgr({
    groupSessionId,
    channelMgr,
    firebaseJsMgr,
    apiMgr,
    audioPlayer,
  });

  const rtcMgr = useRtcMgr();

  return Option.match(mbFinishedSetup, {
    onNone: () => <FullContainerLoadingSpinner />,
    onSome: ({ gsRoomCallStateMgr }) => (
      <GroupSessionRoomCallStateMgrContext.Provider value={gsRoomCallStateMgr}>
        <StreamVideo client={rtcMgr.client}>
          <StreamCall call={channelMgr}>
            <BackgroundFiltersProvider>
              <Content />
            </BackgroundFiltersProvider>
          </StreamCall>
        </StreamVideo>
      </GroupSessionRoomCallStateMgrContext.Provider>
    ),
  });
};

const Content: React.FC = () => {
  const gsmgr = useGroupSessionRoomCallStateMgr();
  const { useScreenShareState } = useCallStateHooks();
  const { status: screenshareStatus } = useScreenShareState();

  useEffect(() => {
    gsmgr.screenshareState$.next(screenshareStatus);
  }, [screenshareStatus]);

  return <Outlet />;
};
