import { apiMgr } from "@webapp/backend";
import { AvatarCircle } from "@webapp/components/avatar.tc";
import { MentionableTextarea } from "@webapp/componentsprimitives/textarea";
import { LoadingSpinner } from "@webapp/loading";
import { RxO } from "@webapp/prelude";
import { formatDistanceToNowStrict } from "date-fns";
import { Option } from "effect";
import {
  CommunityPostStateMgr,
  type ReplyStateMgr,
} from "frontend-shared/src/mgrs/state-mgrs/community.statemgr";
import { useKeyOfObservableAsState, useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import * as React from "react";
import { Link } from "react-router-dom";
import { RD } from "shared/base-prelude";
import { UROUTES } from "shared/routes/u.routes";
import type {
  CommunityPostReplyInfo,
  CommunityPostWithReactionsDto,
} from "shared/schemas/community/community.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { AvailableForPracticeTag } from "./community.components";

type Poster = {
  id: string;
  name: string;
  profilePhoto: string | null;
  isAvailableForPractice: boolean;
};

export const CommunityPost: React.FC<{
  community: { slug: string; allMembers: { id: string; name: string }[] };
  post: CommunityPostWithReactionsDto;
}> = ({ community, post }) => {
  const postSam = useOnce(
    () =>
      new CommunityPostStateMgr({
        community,
        post,
        apiMgr: apiMgr,
        onSuccessReply: (p: { reply: CommunityPostReplyInfo }) => {
          console.log("Successfully replied to post! ", p);
        },
      })
  );
  const { author, mainText, media } = post;

  const reactions$ = useOnce(() =>
    postSam.localPostState$.pipe(RxO.map((p) => p.reactions))
  );
  const numLikes = useKeyOfObservableAsState(reactions$, "likes", 0);
  const isLikedByMe = useKeyOfObservableAsState(
    reactions$,
    "haveILikedPost",
    false
  );
  const replies = useKeyOfObservableAsState(reactions$, "replies", []);
  const myReplyState = useObservableEagerState(postSam.myReplyState$);
  const [isLikeDisabled, setIsLikeDisabled] = React.useState(false);
  return (
    <div className="flex flex-col max-w-[800px] lg:border lg:border-vid-black-200 rounded-lg lg:p-4">
      {/* <button
        onClick={() => {
          postSam.hardDeletePost();
        }}
        className="w-[400px] h-[600px] bg-red-500"
      >
        Delete
      </button> */}
      {/* <button
        onClick={() => {
          BE.fetchEndpointTE((Api) =>
            Api.hp.community.deletePost.mutate({
              communitySlug: communitySlug!,
              postId: post.id,
            })
          )().then((er) => {
            console.log("Result of deleting post! ", er);
          });
        }}
      >
        Delete
      </button> */}
      <PosterInfoSection postedAt={post.postedAt} poster={author} />
      {/* <div className="flex gap-5 justify-between px-6 py-4 w-full text-sm font-medium leading-4 whitespace-nowrap bg-white rounded-xl border border-solid border-[color:var(--Vidalify-Black-200,#DBDAEC)] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="my-auto w-6 h-6" />
      </div> */}
      <div className="px-1 py-2 lg:py-8 lg:px-4 text-sm lg:text-base">
        {mainText}
      </div>
      {media ? (
        <div className="relative w-full h-[300px]">
          {media.mediaType === "video" ? (
            <video
              src={media.mainUrl}
              className="absolute inset-0 w-full h-full object-contain lg:object-cover"
              controls
              loop
              playsInline
            />
          ) : (
            <img
              src={media.mainUrl}
              className="absolute inset-0 w-full h-full object-contain lg:object-cover"
            />
          )}
        </div>
      ) : (
        <div className="justify-center items-center px-16 h-16 w-full text-7xl font-medium text-center text-gray-900 whitespace-nowrap bg-zinc-300 leading-[96px] max-md:px-5 max-md:py-10 max-md:max-w-full max-md:text-4xl"></div>
      )}
      <div className="flex gap-5 justify-between p-3 lg:p-6 w-full text-sm font-medium leading-4 whitespace-nowrap bg-white border border-[color:var(--Vidalify-Black-200,#DBDAEC)] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="self-stretch">
          {numLikes > 0 && (
            <div className="flex gap-3 justify-between text-gray-900">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6e009f647cc2c68c63e731310e044ad885937197095639739574fdec9df478ca?"
                className="w-6 aspect-square"
              />
              <div className="justify-center px-px py-1 my-auto aspect-[0.94]">
                {numLikes}
              </div>
            </div>
          )}
        </div>
        <div className="flex gap-5 justify-between text-neutral-400">
          {!isLikedByMe ? (
            <button
              className="flex gap-2 justify-between"
              // disabled={isLikeDisabled}
              onClick={() => {
                postSam.likePost("like");
                setIsLikeDisabled(true);
                setTimeout(() => {
                  setIsLikeDisabled(false);
                }, 2000);
              }}
            >
              <UnlikedHeartSvg />
              <div className="hidden lg:flex justify-center px-px py-1 my-auto lg:aspect-[1.53]">
                Like
              </div>
            </button>
          ) : (
            <button
              disabled={isLikeDisabled}
              onClick={() => {
                postSam.likePost("unlike");
                setIsLikeDisabled(true);
                setTimeout(() => {
                  setIsLikeDisabled(false);
                }, 2000);
              }}
            >
              <LikedHeartSvg />
            </button>
          )}
          <div
            className="flex gap-2 justify-between cursor-pointer"
            onClick={() => {
              postSam.onCommentReplyButtonClick();
            }}
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/dd92debdbd1ee3fc4e91c304661aa4208cbb663e6423b216aaf4fb351bc7cab3?"
              className="w-6 aspect-square"
            />
            <div className="hidden lg:flex justify-center px-px py-1 my-auto aspect-[3.71]">
              Comment
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 pt-4">
        <div className="flex flex-col gap-6">
          {Option.isSome(myReplyState) && (
            <div>
              <PostCommentView replyStateMgr={myReplyState.value} />
            </div>
          )}
          {[...replies].reverse().map((r) => (
            <ReplyItem key={r.id} reply={r} />
          ))}
        </div>
      </div>
    </div>
  );
};

const PostCommentView: React.FC<{
  replyStateMgr: ReplyStateMgr;
}> = ({ replyStateMgr }) => {
  const inputValue = useObservableEagerState(replyStateMgr.myReplyText$);
  const rdSubmitResult = useObservableEagerState(replyStateMgr.rdSubmitResult$);
  const isInputtable = RD.isInitial(rdSubmitResult);

  return (
    <div className="flex gap-4 items-center max-md:flex-wrap relative">
      <AvatarCircle mbProfilePhoto={null} size={40} />
      <div className="flex-1 h-14 relative">
        <div className="absolute inset-0 bg-gray rounded-lg flex items-center">
          <MentionableTextarea
            value={inputValue}
            onChange={(v) => replyStateMgr.onInputChange(v)}
            data={replyStateMgr.p.allCommunityMembers.map((m) => ({
              id: m.id,
              display: m.name,
            }))}
            readOnly={!isInputtable}
            style={{
              padding: "4px 48px 0 12px",
              border: "4px solid #3b82f6",
              borderRadius: "8px",
            }}
          />
        </div>
        <div className="absolute bottom-0 right-0 top-0 flex justify-center items-center mt-2">
          <button
            className={`text-sm lg:text-base w-16 font-medium mb-2 ${
              !isInputtable
                ? "opacity-50 pointer-events-none"
                : "cursor-pointer text-vid-purple"
            }`}
            onClick={() => {
              replyStateMgr.submitReply();
            }}
          >
            Reply
          </button>
        </div>
      </div>
      {!isInputtable && (
        <div
          className={`
        absolute inset-0 flex justify-center items-center
        bg-gradient-to-r from-slate-200 via-slate-100 to-slate-200 bg-[length:200%_100%] animate-pulse-gradient 
        rounded-[500px] 
        opacity-80
        `}
        >
          {/* <LoadingSpinner /> */}
        </div>
      )}
    </div>
  );
};

const ReplyItem: React.FC<{ reply: CommunityPostReplyInfo }> = ({ reply }) => {
  return (
    <div className="flex gap-4 items-start lg:items-center">
      <AvatarCircle
        mbProfilePhoto={
          reply.author.profilePhoto
            ? ImageSrc.fromURL(reply.author.profilePhoto)
            : null
        }
        size={40}
      />
      <div className="flex-1 bg-slate-50 rounded-lg p-2 lg:p-4 text-sm lg:text-base">
        <MentionableTextarea
          value={reply.content}
          onChange={() => {}}
          data={[]}
          readOnly={true}
        />
      </div>
    </div>
  );
};

const PosterInfoSection: React.FC<{ postedAt: Date; poster: Poster }> = ({
  postedAt,
  poster,
}) => {
  return (
    <Link
      to={UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.ABOUT.buildPath(
        { userId: poster.id }
      )}
      className="flex gap-4 justify-between cursor-pointer"
    >
      {poster.profilePhoto && (
        <img
          src={poster.profilePhoto}
          className="w-10 h-10 lg:h-14 lg:w-14 aspect-square rounded-full object-cover bg-gray-100"
        />
      )}
      <div className="flex flex-col flex-1 my-auto">
        <div className="flex flex-col lg:flex-row gap-0 lg:gap-2 text-gray-900">
          <div className="font-semibold grow text-sm lg:text-base">
            {poster.name}
          </div>
          <div className="hidden lg:block">•</div>
          <div className="text-neutral-400 text-xs lg:text-base">
            {formatDistanceToNowStrict(postedAt, { addSuffix: false })
              .replace(/ minutes?/, "m")
              .replace(/ hours?/, "h")
              .replace(/ days?/, "d")
              .replace(/about /, "")
              .replace(/^1 day ago$/, "Yesterday")}
          </div>
        </div>
        {poster.isAvailableForPractice && <AvailableForPracticeTag />}
      </div>
    </Link>
  );
};

const UnlikedHeartSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        stroke="#1D1626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.62 20.81c-.34.12-.9.12-1.24 0C8.48 19.82 2 15.69 2 8.69 2 5.6 4.49 3.1 7.56 3.1c1.82 0 3.43.88 4.44 2.24a5.53 5.53 0 014.44-2.24C19.51 3.1 22 5.6 22 8.69c0 7-6.48 11.13-9.38 12.12z"
      ></path>
    </svg>
  );
};

const LikedHeartSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#690DFF"
        stroke="#690DFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.62 20.81c-.34.12-.9.12-1.24 0C8.48 19.82 2 15.69 2 8.69 2 5.6 4.49 3.1 7.56 3.1c1.82 0 3.43.88 4.44 2.24a5.53 5.53 0 014.44-2.24C19.51 3.1 22 5.6 22 8.69c0 7-6.48 11.13-9.38 12.12z"
      ></path>
    </svg>
  );
};
