import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { BE } from "@webapp/backend";
import { PrivateChatFC } from "@webapp/components/chat/private-chat";
import { useObservableEagerState } from "observable-hooks";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { UROUTES } from "shared/routes/u.routes";

export const HpDashboardClientMessagesPage: React.FC = () => {
  const cpId = useTypedParams(
    UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID.CHAT
  ).clientId;
  const hpState = useHpState();
  const myProfile = useObservableEagerState(hpState.myProfile$);

  return (
    <div className="flex-1 flex flex-col p-8">
      <PrivateChatFC
        otherUserId={cpId}
        chatStateMgr={hpState.uMgr.chatStateMgr}
        setupChatEff={(p: { otherUserId: string }) =>
          BE.fetchEndpointEff((Api) =>
            Api.hp.clients.client.startPrivateChatWithCp.mutate({
              clientId: p.otherUserId,
            })
          )
        }
      />
    </div>
  );
};
