import * as S from "@effect/schema/Schema";
import { apiMgr, BE } from "@webapp/backend";
import { FullScreenLoadingSpinner } from "@webapp/loading";
import { Effect, Match, Schedule } from "effect";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import {
  PrivateSessionStateMgr,
  PrivateSessionStateMgrContext,
} from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/remote-state-and-call.statemgrs";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  useTypedParams,
  useTypedSearchParams,
} from "react-router-typesafe-routes/dom";
import { UROUTES } from "shared/routes/u.routes";
import { SessionCallNextActionForUser } from "shared/schemas/session.schemas";
import type { UserEnteredPrivateSessionResult } from "shared/schemas/session/private-session.schemas";

export const PrivateSessionLayoutPage: React.FC = ({}) => {
  const { privateSessionId } = useTypedParams(
    UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.ENTRY
  );

  const userEnteredResult = BE.useSuccessFetch(
    (Api) =>
      Api.u.session.registerUserEnteredSession.mutate({
        sessionId: privateSessionId,
      }),
    [privateSessionId]
  );

  console.log("USER ENTERED RESULT! ", userEnteredResult);

  return Match.value(userEnteredResult).pipe(
    Match.tag("LoadingState", () => <FullScreenLoadingSpinner />),
    Match.tag("SuccessState", ({ data }) => {
      return <LoadedView result={data} />;
    }),
    Match.exhaustive
  );
};

const LoadedView: React.FC<{
  result: UserEnteredPrivateSessionResult;
}> = ({ result }) => {
  const nav = useNavigate();
  const location = useLocation();
  const firebaseJsMgr = useFirebaseJs();
  const psStateMgr = useMemo(() => {
    return new PrivateSessionStateMgr(
      result.privateSessionInfo,
      firebaseJsMgr,
      apiMgr
    );
  }, [result]);

  useEffect(() => {
    console.log("RESULT! ", result);
    const nextAction = S.decodeUnknownSync(SessionCallNextActionForUser.Action)(
      result.stateAndNextAction.nextAction
    );
    const mbStartOrJoinAction = nextAction.mbStartOrJoinAction;

    if (mbStartOrJoinAction) {
      if (mbStartOrJoinAction._tag === "Start") {
        const { channelId } = mbStartOrJoinAction;

        nav(
          UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.CALLS.CALL_ID.HP.WAITING_ROOM.buildPath(
            {
              callId: channelId,
              privateSessionId: result.privateSessionInfo.id,
            }
          )
        );
      }
    }
  }, []);

  useEffect(() => {
    console.log("RESULT! ", result);
    const nextAction = S.decodeUnknownSync(SessionCallNextActionForUser.Action)(
      result.stateAndNextAction.nextAction
    );
    const mbEnterRoomAction = nextAction.mbEnterRoomAction;
    console.log("NEXT ACTION! ", nextAction, mbEnterRoomAction);
    if (mbEnterRoomAction && location.pathname.includes("entry")) {
      const roomParams = {
        callId: mbEnterRoomAction.call.callInfo.callId,
        privateSessionId: result.privateSessionInfo.id,
      };
      Match.value(mbEnterRoomAction.room).pipe(
        Match.when("main", () => {
          Match.value(result.stateAndNextAction.myRole).pipe(
            Match.when("hp", () =>
              nav(
                UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.CALLS.CALL_ID.HP.MAIN_ROOM.buildPath(
                  roomParams
                )
              )
            ),
            Match.when("client", () => {
              nav(
                UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.CALLS.CALL_ID.CP.MAIN_ROOM.buildPath(
                  roomParams
                )
              );
            }),
            Match.exhaustive
          );
        }),
        Match.when("waiting", () => {
          Match.value(result.stateAndNextAction.myRole).pipe(
            Match.when("hp", () => {
              nav(
                UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.CALLS.CALL_ID.HP.WAITING_ROOM.buildPath(
                  {
                    callId: mbEnterRoomAction.call.callInfo.callId,
                    privateSessionId: result.privateSessionInfo.id,
                  }
                )
              );
            }),
            Match.when("client", () => {
              nav(
                UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.CALLS.CALL_ID.CP.WAITING_ROOM.buildPath(
                  roomParams
                )
              );
            }),
            Match.exhaustive
          );
        })
      );
    }
  }, [result]);

  return (
    <PrivateSessionStateMgrContext.Provider value={psStateMgr}>
      <Outlet />
    </PrivateSessionStateMgrContext.Provider>
  );
};
