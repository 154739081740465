import { Disclosure } from "@headlessui/react";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { pipe } from "fp-ts/lib/function";
import type { PrivateSessionReviewRdMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/review.statemgr";
import { useObservableEagerState } from "observable-hooks";
import { useState } from "react";
import { IoChevronUpCircleOutline } from "react-icons/io5";
import { O, RD } from "shared/base-prelude";
import type { ChronologicalSummary } from "shared/schemas/session/review/review.schemas";
import { PrimaryButton } from "../../../primitives/button";

export const ReviewTools: React.FC<{
  reviewMgr: PrivateSessionReviewRdMgr;
}> = ({ reviewMgr }) => {
  const rdChronologicalSummary = useObservableEagerState(
    reviewMgr.chronSummaryFS.value$
  );
  const [isViewingChronSummary, setIsViewingChronSummary] = useState(false);
  return (
    <div>
      {/* <PrimaryButton title="View Full Review" onClick={onViewFullReview} /> */}

      <PrimaryButton
        title="View chronological summary"
        onClick={() => {
          reviewMgr.chronSummaryFS.fetchAndSet();
          setIsViewingChronSummary(true);
        }}
      />
      {isViewingChronSummary &&
        pipe(
          rdChronologicalSummary,
          RD.toOption,
          O.fold(
            () => <FullContainerLoadingSpinner />,
            (chronSummary) => (
              <ChronologicalSummaryView chronSummary={chronSummary} />
            )
          )
        )}
    </div>
  );
};

const ChronologicalSummaryView: React.FC<{
  chronSummary: ChronologicalSummary;
}> = ({ chronSummary }) => {
  return (
    <div className="w-full max-w-md p-2 mx-auto bg-white rounded-2xl">
      {chronSummary.fullSummary.map((item, index) => (
        <Disclosure as="div" key={index} className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-purple-900 bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>
                  {item.title} ({item.startMinute}-{item.endMinute} min)
                </span>
                <IoChevronUpCircleOutline
                  className={`${
                    open ? "transform rotate-180" : ""
                  } w-5 h-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                <p>{item.summary}</p>
                {/* {item.isTherapeutic !== undefined && (
                  <p className="mt-2 font-semibold">
                    {item.isTherapeutic
                      ? "Therapeutic Content"
                      : "Non-therapeutic Content"}
                  </p>
                )} */}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};
