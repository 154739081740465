import * as S from "@effect/schema/Schema";
import { addMinutes, differenceInMinutes } from "date-fns";
import { DateTime, Effect, Match, Option } from "effect";
import type { Selectable } from "kysely";
import {
  INTERVAL_SECONDS_TO_START_NEW_RECORDING,
  RUN_NEW_LAMBDA_SECONDS_INTERVAL,
  STARTABLE_MINUTES_WINDOW_FOR_SESSION,
} from "shared/constants";
import type { Session } from "shared/db";
import {
  CallCredentials,
  CallInfo,
  type KNOWN_USER_CALL_ROLE,
} from "shared/schemas/call.schemas";
import { SavedTranscript } from "shared/schemas/session/review/transcript.schemas";
import { epipe } from "../../base-prelude";
import { PrivateSessionEventInstance } from "../../types/calendar.types";
import type { PastPrivateSessionDTO } from "../../types/session/session.types";
import {
  SimpleUserWithProfilePhoto,
  type SimpleUser,
} from "../../types/user.types";
import {
  SessionCallNextActionForUser,
  SessionCallStatus,
  SessionTimeInfo,
  SessionTimeLeftInfo,
} from "../session.schemas";
import { SavedChronologicalSummary } from "./review/review.schemas";

type PrivateSessionUserRole = "hp" | "client";

export class PrivateSessionInfo extends S.Class<PrivateSessionInfo>(
  "PrivateSessionInfo"
)({
  id: S.String,
  hp: SimpleUserWithProfilePhoto,
  client: SimpleUserWithProfilePhoto,
  startedAt: S.Option(S.Date),
  endedAt: S.Option(S.Date),
  scheduledStartTime: S.Date,
  estimatedDurationInMinutes: S.Option(S.Number),
  inviteLink: S.String,
  audioOnly: S.Boolean,
  lastPingedAt: S.Option(S.Date),
  lastStartedNewRecordingAt: S.Option(S.Date),
  lastInvokedLambdaAt: S.Option(S.Date),
  clientFirstEnteredAt: S.Option(S.Date),

  minutesBeforeEndReminder: S.Option(S.Number),
  displayedTimeLeftNotifAt: S.Option(S.Date),
}) {
  get isInStartableWindow() {
    return PrivateSessionInfo.isNowInStartableWindow({
      startsAt: this.scheduledStartTime,
      windowInMinutes: STARTABLE_MINUTES_WINDOW_FOR_SESSION,
    });
  }

  get assumedStartedAt(): Date {
    if (Option.isNone(this.startedAt)) {
      throw new Error("Session does not have startedAt when expected");
    }
    return this.startedAt.value;
  }

  get assumedDurationInMinutes(): number {
    return this.estimatedDurationInMinutes.pipe(Option.getOrElse(() => 60));
  }

  get assumedEndsAt(): DateTime.Utc {
    return DateTime.unsafeFromDate(this.assumedStartedAt).pipe(
      DateTime.add({ minutes: this.assumedDurationInMinutes })
    );
  }

  get assumedEndsAtWindow(): {
    min: DateTime.Utc;
    max: DateTime.Utc;
  } {
    return {
      min: DateTime.subtract(this.assumedEndsAt, { minutes: 15 }),
      max: DateTime.add(this.assumedEndsAt, { minutes: 30 }),
    };
  }

  asMbPastSession = (p: {
    relativeToNow: DateTime.Utc;
  }): Option.Option<PastPrivateSessionDTO> => {
    console.log("AS MAYBE PAST SESSION! ", this.hp, this.lastPingedAt);

    if (Option.isNone(this.clientFirstEnteredAt)) {
      return Option.none();
    }

    if (Option.isNone(this.lastPingedAt)) {
      return Option.none();
    }

    if (Option.isSome(this.endedAt)) {
      return Option.some({
        ...this,
        startedAt: this.assumedStartedAt,
        endedAt: this.endedAt.value,
      });
    }

    const lastPingedAt = DateTime.unsafeFromDate(this.lastPingedAt.value);

    // If the session was less than 3 minutes long, do not consider it a full session
    const startedAt = DateTime.unsafeFromDate(this.assumedStartedAt);
    console.log("STARTED AT! ", startedAt);
    console.log("ESTIMATED ENDS AT! ", this.assumedEndsAt);
    if (DateTime.distance(startedAt, lastPingedAt) < 1000 * 60 * 3) {
      return Option.none();
    }

    const endsAtWindow = this.assumedEndsAtWindow;
    console.log("ENDS AT WINDOW! ", endsAtWindow);
    console.log("LAST PINGED AT! ", lastPingedAt);

    const isNowMuchAfterMaxEndedAt = p.relativeToNow.pipe(
      DateTime.greaterThan(endsAtWindow.max)
    );
    console.log("IS NOW MUCH AFTER MAX ENDED AT! ", isNowMuchAfterMaxEndedAt);
    if (isNowMuchAfterMaxEndedAt) {
      return Option.some({
        ...this,
        startedAt: this.assumedStartedAt,
        endedAt: this.assumedEndsAt,
      });
    }

    const wasBeforeMaxEndedAtWindow = lastPingedAt.pipe(
      DateTime.lessThanOrEqualTo(endsAtWindow.max)
    );
    console.log("WAS BEFORE MAX ENDED AT WINDOW! ", wasBeforeMaxEndedAtWindow);
    const wasAfterMinEndedAtWindow = lastPingedAt.pipe(
      DateTime.greaterThanOrEqualTo(endsAtWindow.min)
    );
    console.log("WAS AFTER MIN ENDED AT WINDOW! ", wasAfterMinEndedAtWindow);

    if (wasBeforeMaxEndedAtWindow && wasAfterMinEndedAtWindow) {
      return Option.some({
        ...this,
        startedAt: this.assumedStartedAt,
        endedAt: this.lastPingedAt.value,
      } satisfies PastPrivateSessionDTO);
    }

    return Option.none();
  };

  hpOrClientRole = (p: { userId: string }): PrivateSessionUserRole => {
    if (p.userId === this.hp.id) {
      return "hp";
    }
    return "client";
  };

  knownUserCallRole = (p: { userId: string }): KNOWN_USER_CALL_ROLE => {
    return Match.value(this.hpOrClientRole(p)).pipe(
      Match.when("hp", () => "admin" as const),
      Match.when("client", () => "user" as const),
      Match.exhaustive
    );
  };

  unsafeGetTimeInfo = (): Effect.Effect<SessionTimeInfo> =>
    Effect.gen(this, function* () {
      const mbTimeLeftInfo = yield* this.deriveTimeLeftInfo;

      const timeInfo = yield* SessionTimeInfo.from({
        mbTimeLeftInfo,
        sessionLike: {
          startedAt: this.startedAt,
        },
      });

      return timeInfo;
    });

  deriveTimeLeftInfo: Effect.Effect<Option.Option<SessionTimeLeftInfo>> =
    Effect.gen(this, function* () {
      const asAllSome = Option.all([
        this.startedAt,
        this.estimatedDurationInMinutes,
      ]);

      if (Option.isNone(asAllSome)) {
        return Option.none();
      }

      const [startedAt, estimatedDurationInMinutes] = asAllSome.value;

      const now = yield* DateTime.now;

      return Option.some(
        SessionTimeLeftInfo.from({
          startedAt: DateTime.unsafeFromDate(startedAt),
          estimatedDurationInMinutes: estimatedDurationInMinutes,
          mbConfiguredMinutesUntilEndReminder: this.minutesBeforeEndReminder,
          mbDisplayedTimeLeftNotifAt: this.displayedTimeLeftNotifAt,
          now,
        })
      );
    });

  mbTimeSinceLastInvokedLambda: Effect.Effect<Option.Option<number>> =
    DateTime.now.pipe(
      Effect.map((now) => {
        return this.lastInvokedLambdaAt.pipe(
          Option.map((lastInvokedLambdaAt) =>
            now.pipe(
              DateTime.distance(DateTime.unsafeMake(lastInvokedLambdaAt))
            )
          )
        );
      })
    );

  getShouldInvokeLambda: Effect.Effect<boolean> =
    this.mbTimeSinceLastInvokedLambda.pipe(
      Effect.map((mbSecondsSinceLastInvokedLambda) => {
        console.log(
          "MB SECONDS SINCE LAST INVOKED LAMBDA! ",
          mbSecondsSinceLastInvokedLambda
        );
        return Option.match(mbSecondsSinceLastInvokedLambda, {
          onNone: () => true,
          onSome: (secondsSinceLastInvokedLambda) =>
            secondsSinceLastInvokedLambda > RUN_NEW_LAMBDA_SECONDS_INTERVAL,
        });
      })
    );

  isSessionInEndableWindow = () =>
    Effect.gen(this, function* () {
      if (Option.isNone(this.startedAt)) {
        return Effect.dieMessage("Session not started when checking to end");
      }
      const timeInfo = yield* this.unsafeGetTimeInfo();

      return timeInfo.isLikelyNearEndBasedOnTime; // TODO: make this more sophisticated
    });

  isNowInIntervalToStartNewRecording: Effect.Effect<boolean> =
    DateTime.now.pipe(
      Effect.map((now) => {
        const mbSecondsSinceLastRecordingStarted =
          this.lastStartedNewRecordingAt.pipe(
            Option.map((lastStartedAt) =>
              now.pipe(DateTime.distance(DateTime.unsafeMake(lastStartedAt)))
            )
          );

        console.log(
          "MB SECONDS SINCE LAST RECORDING STARTED! ",
          mbSecondsSinceLastRecordingStarted
        );

        return Option.match(mbSecondsSinceLastRecordingStarted, {
          onNone: () => true,
          onSome: (secondsSinceLastRecordingStarted) =>
            secondsSinceLastRecordingStarted >
            INTERVAL_SECONDS_TO_START_NEW_RECORDING,
        });
      })
    );

  static isNowInStartableWindow = (p: {
    startsAt: Date;
    windowInMinutes: number;
  }): boolean =>
    Effect.runSync(
      DateTime.now.pipe(
        Effect.map((now) => {
          const startsAt = DateTime.unsafeFromDate(p.startsAt);
          const nMinutesAfter = DateTime.add(startsAt, {
            minutes: p.windowInMinutes,
          });
          const nMinutesBefore = DateTime.subtract(startsAt, {
            minutes: p.windowInMinutes,
          });
          return now.pipe(
            DateTime.between({
              minimum: nMinutesBefore,
              maximum: nMinutesAfter,
            })
          );
        })
      )
    );

  static fromEntityLike = (p: {
    entity: Selectable<Session> & {
      hp: SimpleUser & { profilePhotoUploadedAt: Date | null };
      client: SimpleUser & { profilePhotoUploadedAt: Date | null };
    };
    inviteLink: string;
    getProfilePhoto: (p: {
      userId: string;
    }) => Effect.Effect<Option.Option<string>>;
  }): Effect.Effect<PrivateSessionInfo> =>
    Effect.gen(function* () {
      const { entity, inviteLink, getProfilePhoto } = p;
      const mbCpProfilePhotoEff = getProfilePhoto({
        userId: entity.client.id,
      });

      const mbHpProfilePhotoEff = getProfilePhoto({
        userId: entity.hp.id,
      });

      const [mbHpProfilePhoto, mbCpProfilePhoto] = yield* Effect.all(
        [mbHpProfilePhotoEff, mbCpProfilePhotoEff],
        { concurrency: "unbounded" }
      );

      const {
        last_invoked_lambda_at,
        minutes_before_end_reminder,
        displayed_time_left_notif_at,
      } = entity;

      const hp = new SimpleUserWithProfilePhoto(
        {
          ...entity.hp,
          profilePhoto: Option.getOrNull(mbHpProfilePhoto),
        },
        { disableValidation: true }
      );

      const client = new SimpleUserWithProfilePhoto(
        {
          ...entity.client,
          profilePhoto: Option.getOrNull(mbCpProfilePhoto),
        },
        { disableValidation: true }
      );

      return new PrivateSessionInfo(
        {
          id: entity.id,
          hp,
          client,
          endedAt: Option.fromNullable(entity.ended_at),
          startedAt: Option.fromNullable(entity.started_at),
          scheduledStartTime: new Date(),
          inviteLink,
          audioOnly: entity.audio_only,
          lastStartedNewRecordingAt: Option.fromNullable(
            entity.last_started_new_recording_at
          ),
          clientFirstEnteredAt: Option.fromNullable(entity.client_joined_at),
          lastInvokedLambdaAt: Option.fromNullable(last_invoked_lambda_at),
          lastPingedAt: Option.fromNullable(entity.last_pinged_at),
          minutesBeforeEndReminder: Option.fromNullable(
            minutes_before_end_reminder
          ),
          displayedTimeLeftNotifAt: Option.fromNullable(
            displayed_time_left_notif_at
          ),
          estimatedDurationInMinutes: entity.intended_duration_seconds
            ? Option.some(Math.floor(entity.intended_duration_seconds / 60))
            : Option.none(),
        },
        { disableValidation: true }
      );
    });
}

const AllReviewInfo = S.Struct({
  mbTranscript: S.Option(SavedTranscript),
  notes: S.NullOr(S.Any),
  mbChronologicalSummary: S.NullOr(SavedChronologicalSummary),
});

export class PrivateSessionWithReviewInfo extends PrivateSessionInfo.extend<PrivateSessionWithReviewInfo>(
  "PrivateSessionWithReviewInfo"
)({
  review: AllReviewInfo,
}) {
  static fromEntityLike = (p: {
    entity: Selectable<Session> & {
      hp: SimpleUser & { profilePhotoUploadedAt: Date | null };
      client: SimpleUser & { profilePhotoUploadedAt: Date | null };
    };
    inviteLink: string;
    getProfilePhoto: (p: {
      userId: string;
    }) => Effect.Effect<Option.Option<string>>;
  }): Effect.Effect<PrivateSessionWithReviewInfo> => {
    return Effect.gen(function* () {
      const info = yield* PrivateSessionInfo.fromEntityLike(p);
      return PrivateSessionWithReviewInfo.make(
        {
          ...info,
          review: {
            mbTranscript: Option.none(),
            notes: null,
            mbChronologicalSummary: epipe(
              Option.fromNullable(p.entity.chronological_summary),
              Option.map((cs) => {
                console.log("CS! ", cs, typeof cs);
                return SavedChronologicalSummary.fromString(JSON.stringify(cs));
              }),
              Option.getOrNull
            ),
          },
        },
        { disableValidation: true }
      );
    });
  };
}

export class PrivateSessionUser extends S.Class<PrivateSessionUser>(
  "PrivateSessionUser"
)({
  id: S.String,
  role: S.Union(S.Literal("host"), S.Literal("participant")),
}) {
  static from = (p: {
    privateSessionInfo: PrivateSessionInfo;
    myUserId: string;
  }) => {
    if (p.myUserId === p.privateSessionInfo.hp.id) {
      return PrivateSessionUser.make({
        id: p.myUserId,
        role: "host",
      });
    }
    return PrivateSessionUser.make({
      id: p.myUserId,
      role: "participant",
    });
  };
}

export class PrivateSessionStateAndUserNextAction extends S.Class<PrivateSessionStateAndUserNextAction>(
  "PrivateSessionStateAndUserNextAction"
)({
  privateSessionInfo: PrivateSessionInfo,
  nextAction: SessionCallNextActionForUser.Action,
  status: SessionCallStatus.Status,
  myRole: S.Union(S.Literal("hp"), S.Literal("client")),
}) {
  static from = (p: {
    privateSessionInfo: PrivateSessionInfo;
    mbCurrentCall: Option.Option<{
      callInfo: CallInfo;
      callCredentials: CallCredentials;
    }>;
    forUser: { id: string; isHp: boolean };
  }): PrivateSessionStateAndUserNextAction => {
    const psUserWithRole = PrivateSessionUser.from({
      privateSessionInfo: p.privateSessionInfo,
      myUserId: p.forUser.id,
    });

    const status = SessionCallStatus.Status.from({
      sessionInfo: p.privateSessionInfo,
      mbCurrentCall: p.mbCurrentCall,
    });

    const isUserHost = psUserWithRole.role === "host";
    const nextAction = SessionCallNextActionForUser.Action.from({
      state: status,
      channelId: p.privateSessionInfo.id,
      isUserHost,
    });

    return PrivateSessionStateAndUserNextAction.make({
      privateSessionInfo: p.privateSessionInfo,
      nextAction,
      status,
      myRole: p.forUser.isHp ? "hp" : "client",
    });
  };
}

export class UserEnteredPrivateSessionResult extends S.Class<UserEnteredPrivateSessionResult>(
  "UserEnteredPrivateSessionResult"
)({
  privateSessionInfo: PrivateSessionInfo,
  stateAndNextAction: PrivateSessionStateAndUserNextAction,
  performedActions: S.NullOr(
    S.Array(S.Union(S.Literal("started_new_call"), S.Literal("joined_call")))
  ),
}) {}

export const StartASessionFormDataSchema = S.Struct({
  clientUserId: S.NullOr(S.String),
  durationInMinutes: S.NullOr(S.Number),
  minutesBeforeEndReminder: S.NullOr(S.Number),
  sendInviteEmail: S.Boolean,
  audioOnly: S.UndefinedOr(S.Boolean),
});

export type StartASessionFormData = typeof StartASessionFormDataSchema.Type;

export class RegisterCloseCallClickNextAction extends S.Class<RegisterCloseCallClickNextAction>(
  "RegisterCloseCallClickNextAction"
)({
  action: S.Union(
    S.TaggedStruct("LEAVE", { nextRoute: S.String }),
    S.TaggedStruct("GO_TO_ROUTE", { nextRoute: S.String })
  ),
}) {
  static asLeave = (p: { nextRoute: string }) => {
    return RegisterCloseCallClickNextAction.make({
      action: {
        _tag: "LEAVE",
        nextRoute: p.nextRoute,
      },
    });
  };

  static asGoToRoute = (p: { nextRoute: string }) => {
    return RegisterCloseCallClickNextAction.make({
      action: {
        _tag: "GO_TO_ROUTE",
        nextRoute: p.nextRoute,
      },
    });
  };
}

export class RegisterCloseCallClickResult extends S.Class<RegisterCloseCallClickResult>(
  "RegisterCloseCallClickResult"
)({
  privateSessionInfo: PrivateSessionInfo,
  nextAction: RegisterCloseCallClickNextAction,
}) {
  static withLeaveAsNextAction = (p: {
    privateSessionInfo: PrivateSessionInfo;
    nextRoute: string;
  }): RegisterCloseCallClickResult => {
    return RegisterCloseCallClickResult.make({
      privateSessionInfo: p.privateSessionInfo,
      nextAction: RegisterCloseCallClickNextAction.asLeave({
        nextRoute: p.nextRoute,
      }),
    });
  };

  static withGoToRouteAsNextAction = (p: {
    privateSessionInfo: PrivateSessionInfo;
    nextRoute: string;
  }): RegisterCloseCallClickResult => {
    return RegisterCloseCallClickResult.make({
      privateSessionInfo: p.privateSessionInfo,
      nextAction: RegisterCloseCallClickNextAction.asGoToRoute({
        nextRoute: p.nextRoute,
      }),
    });
  };
}

export class PrivateSessionAppointment extends S.Class<PrivateSessionAppointment>(
  "PrivateSessionAppointment"
)({
  instance: PrivateSessionEventInstance,
  client: SimpleUserWithProfilePhoto,
}) {
  get durationInMinutes(): number {
    return differenceInMinutes(this.instance.endTime, this.instance.startTime);
  }

  get priceCents(): number | null {
    return this.instance.privateSessionInfo.priceCents;
  }

  withUpdatedInstance = (p: {
    instance: PrivateSessionEventInstance;
  }): PrivateSessionAppointment => {
    return PrivateSessionAppointment.make(
      {
        ...this,
        instance: p.instance,
      },
      { disableValidation: true }
    );
  };

  withUpdatedStartTime = (p: {
    startTime: Date;
  }): PrivateSessionAppointment => {
    return this.withUpdatedInstance({
      instance: {
        ...this.instance,
        startTime: p.startTime,
      },
    });
  };

  withUpdatedDurationInMinutes = (p: {
    durationInMinutes: number;
  }): PrivateSessionAppointment => {
    return this.withUpdatedInstance({
      instance: {
        ...this.instance,
        endTime: addMinutes(this.instance.startTime, p.durationInMinutes),
      },
    });
  };

  withUpdatedPriceCents = (p: {
    priceCents: number | null;
  }): PrivateSessionAppointment => {
    return this.withUpdatedInstance({
      instance: {
        ...this.instance,
        privateSessionInfo: {
          ...this.instance.privateSessionInfo,
          priceCents: p.priceCents,
        },
      },
    });
  };
}
