import { AvatarCircle } from "@webapp/componentsavatar.tc";
import { format } from "date-fns";
import { Option } from "effect";
import { usePubSubNotificationsMgmt } from "frontend-shared/src/mgrs/pubsub-notifications.mgmt";
import type { UApiOutput } from "frontend-shared/src/trpc-cli";
import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import type { UserWithProfilePhoto } from "shared";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { BE } from "../../../backend";
import { ListContainer } from "../../../components/list.components";
import { PrimaryButton } from "../../../components/primitives/button";
import { FullContainerLoadingSpinner } from "../../../loading";

type HomepageData = UApiOutput["cp"]["getHomepageData"];

export const CpDashboardHomePage: React.FC = () => {
  const mbLoadedMe = BE.useSuccessFetchO(
    (Api) => Api.cp.getMyProfile.query(),
    []
  );
  const mbLoadedHomepageData = BE.useSuccessFetchO(
    (Api) => Api.cp.getHomepageData.query(),
    []
  );
  const [queryParams, _] = useSearchParams();
  const acceptHpRequest = queryParams.get("acceptHpRequest");

  useEffect(() => {
    if (acceptHpRequest) {
      BE.fetchEndpointTE((Api) =>
        Api.cp.acceptHpRequest.mutate({
          requestId: acceptHpRequest,
          doesAccept: true,
        })
      )().then((r) => {
        console.log("RESULT OF ACCEPTING HP REQUEST", r);
        // window.location.href = CP_ROUTES.DASHBOARD.HOME.path;
      });
    }
  }, [acceptHpRequest]);

  const allMbData = Option.all([mbLoadedHomepageData, mbLoadedMe]);

  if (Option.isNone(allMbData)) {
    return <FullContainerLoadingSpinner />;
  }

  const [homepageData, me] = allMbData.value;

  return (
    <div className="flex flex-col p-8 h-screen">
      <div className="flex flex-col gap-12">
        <div className="flex gap-4">
          <h4 className="text-lg font-semibold text-vid-black-900">{`Welcome ${me.first_name}`}</h4>
        </div>
        {homepageData.hpRequests.length > 0 && (
          <HpRequestsList hpRequests={homepageData.hpRequests} me={me} />
        )}
        {homepageData.liveAndOngoingSessions.length > 0 && (
          <LiveAndOngoingSessionsList
            liveAndOngoingSessions={homepageData.liveAndOngoingSessions}
          />
        )}
        {homepageData.upcomingSessions.length > 0 && (
          <div className="mt-4 flex flex-col">
            <ListContainer
              title="Upcoming Sessions"
              rows={homepageData.upcomingSessions.map((s) => (
                <div className="flex gap-4">
                  <div>{s.host.name}</div>
                  <div>{format(s.startTime, "MMM dd yyyy")}</div>
                </div>
              ))}
              emptyMessage="You have no upcoming sessions scheduled"
            />
          </div>
        )}
        <div className="flex flex-col gap-4">
          <ListContainer
            title="My Therapists"
            rows={homepageData.myPractitioners.map((t) => (
              <Link
                to={UROUTES.CP.DASHBOARD.HPS.HP_ID.DETAILS.buildPath({
                  hpId: t.id,
                })}
                key={t.id}
                className="cursor-pointer py-1 flex items-center gap-2"
              >
                <AvatarCircle
                  size={50}
                  mbProfilePhoto={ImageSrc.fromMbUrl(t.profilePhoto)}
                />
                {`${t.name}`}
              </Link>
            ))}
          />
        </div>

        {homepageData.pastSessions.length > 0 && (
          <div>
            <ListContainer
              title="Past Sessions"
              rows={homepageData.pastSessions.slice(0, 5).map((ps) => (
                <Link
                  to={UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.REVIEW.buildPath(
                    {
                      privateSessionId: ps.id,
                    }
                  )}
                >
                  <div className="flex-1 flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <AvatarCircle
                        size={50}
                        mbProfilePhoto={ImageSrc.fromMbUrl(ps.hp.profilePhoto)}
                      />
                      <div className="">{`${ps.hp.name}`}</div>
                      <div className="">
                        {format(new Date(ps.startedAt), "MMM dd yyyy")}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const HpRequestsList: React.FC<{
  hpRequests: HomepageData["hpRequests"];
  me: UserWithProfilePhoto;
}> = ({ hpRequests, me }) => {
  const pubSubNotifsMgmt = usePubSubNotificationsMgmt();
  return (
    <ListContainer
      title="Requests"
      rows={hpRequests.map((request) => (
        <div className="flex-1 flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <AvatarCircle
              size={50}
              mbProfilePhoto={ImageSrc.fromMbUrl(request.hp.profilePhoto)}
            />
            <h4 className="font-bold">{`${request.hp.name}`}</h4>
          </div>
          <div>
            <PrimaryButton
              title="Accept"
              onClick={() => {
                BE.fetchEndpointTE((Api) =>
                  Api.cp.acceptHpRequest.mutate({
                    requestId: request.requestId,
                    doesAccept: true,
                  })
                )().then((er) => {
                  console.log("RESPONDING TO REQUEST", er);
                  pubSubNotifsMgmt.notifyCpAcceptedHpRequest({
                    hpId: request.hp.id,
                    cpName: `${me.first_name} ${me.last_name}`,
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                });
              }}
            />
          </div>
        </div>
      ))}
    />
  );
};

const LiveAndOngoingSessionsList: React.FC<{
  liveAndOngoingSessions: HomepageData["liveAndOngoingSessions"];
}> = ({ liveAndOngoingSessions }) => {
  const nav = useNavigate();
  return (
    <ListContainer
      title="Live now"
      rows={liveAndOngoingSessions
        // .filter((s) => s.state === "LIVE")
        .map(({ sessionInfo, state }) => (
          <div className="flex-1 flex justify-between items-center">
            <div className="flex items-center gap-2">
              <AvatarCircle
                size={50}
                mbProfilePhoto={ImageSrc.fromMbUrl(sessionInfo.hp.profilePhoto)}
              />
              <div>{sessionInfo.hp.name}</div>
              <div>{state}</div>
            </div>
            <div>
              <PrimaryButton
                title="Join session"
                onClick={() => {
                  nav(
                    UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.ENTRY.buildPath(
                      {
                        privateSessionId: sessionInfo.id,
                      }
                    )
                  );
                }}
              />
            </div>
          </div>
        ))}
    />
  );
};
