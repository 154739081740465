import { RadioGroup } from "@headlessui/react";
import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { FormComponents } from "@webapp/components/primitives/form.primitives";
import { Rx } from "@webapp/prelude";
import { format } from "date-fns";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { Link } from "react-router-dom";
import type { EventInstanceId } from "shared/types/calendar.types";
import { SimpleClient } from "../right-drawer-action/right-nav";
import { AvatarCircle } from "@webapp/componentsavatar.tc";
import { ImageSrc } from "shared/types/miscellaneous.types";

export namespace ClientsPageComponents {
  export type NewSessionConfig = {
    client: SimpleClient | null;
    durationInMinutes: number | null;
    minutesBeforeEndReminder: number | null;
    sendInviteEmail: boolean;
    audioOnly: boolean;
  };
  export const DurationRadioGroup: React.FC<{
    sessionConfig$: Rx.BehaviorSubject<NewSessionConfig>;
  }> = ({ sessionConfig$ }) => {
    const sessionConfig = useObservableEagerState(sessionConfig$);

    return (
      <RadioGroup
        value={sessionConfig.durationInMinutes}
        onChange={(v) => {
          sessionConfig$.next({ ...sessionConfig, durationInMinutes: v });
        }}
        className={`flex flex-col gap-4`}
      >
        <RadioGroup.Label className="flex flex-col">
          <div className={`font-bold text-lg`}>Duration</div>
          <div className="italic">
            This will only be used to assist in sending notifications. It will
            not close the session.
          </div>
        </RadioGroup.Label>
        <div className="flex gap-4">
          <RadioGroup.Option value={90}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="90 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={60}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="60 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={30}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="30 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={10}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="10 mins" />
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
    );
  };

  export const SetTimeWarningRadioGroup: React.FC<{
    sessionConfig$: Rx.BehaviorSubject<NewSessionConfig>;
  }> = ({ sessionConfig$ }) => {
    const sessionConfig = useObservableEagerState(sessionConfig$);

    return (
      <RadioGroup
        value={sessionConfig.minutesBeforeEndReminder}
        onChange={(v) => {
          sessionConfig$.next({
            ...sessionConfig,
            minutesBeforeEndReminder: v,
          });
        }}
        className={`flex flex-col gap-4`}
      >
        <RadioGroup.Label className="flex flex-col">
          <div className={`font-bold text-lg`}>Ending session reminder</div>
        </RadioGroup.Label>
        <div className="flex gap-4">
          <RadioGroup.Option value={5}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="5 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={10}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="10 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={15}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="15 mins" />
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={20}>
            {({ checked }) => (
              <FormComponents.RadioBtn checked={checked} text="20 mins" />
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
    );
  };
}

export const ClientsListContainer: React.FC<{
  title: string;
  rows: (() => React.ReactNode)[];
}> = ({ title, rows }) => {
  return (
    <div className="flex flex-col border rounded-[12px] rounded-[12px]">
      <div
        className={`bg-vid-black-100 py-4 px-6 
            "rounded-tr-[12px] rounded-tl-[12px]"
        `}
      >
        <h3 className="font-sans font-semibold text-sm text-vid-black-900">
          {title}
        </h3>
      </div>
      <div className="flex flex-col">
        {rows.map((row, key) => (
          <div
            key={key}
            className="border-b border-vid-black-200 h-[140px] flex items-center px-2 py-4 lg:px-7 lg:py-8"
          >
            {row()}
          </div>
        ))}
      </div>
    </div>
  );
};

export const ClientsListRowItemContainer: React.FC<{
  name: string;
  profilePhotoUrl: string | null;
  linkTo: string;
  appt?: { date: Date; id: EventInstanceId };
  children: React.ReactNode;
  title: string;
  sessionType?: string | null;
}> = ({
  name,
  profilePhotoUrl,
  appt,
  linkTo,
  children,
  title,
  sessionType,
}) => {
  return (
    <div className="flex-1 lg:flex flex-col lg:flex-row items-center">
      <div className="flex gap-2 w-full items-center lg:justify-between">
        <div className="flex items-center w-full gap-2">
          <AvatarCircle
            size={70}
            mbProfilePhoto={ImageSrc.fromMbUrl(profilePhotoUrl)}
          />
          <div className="flex flex-col items-center lg:justify-between w-full lg:w-fit gap-1">
            <div className="text-sm lg:text-xl font-sans font-semibold lg:font-light leading-7">
              {name}
            </div>
            {title != "" && <ScheduledTimePill appt={appt} title={title} />}
          </div>
        </div>
        {sessionType && (
          <div className="bg-vid-black-100 hover:cursor-pointer rounded-full w-fit h-fit px-[22px] py-3 flex items-center self-center mr-12">
            <img src="/therapy.svg" width={24} height={24} />
            <p className="ml-2 font-sans font-light text-sm">{sessionType}</p>
          </div>
        )}
      </div>
      <div className="flex-1 flex items-center">{children}</div>
      <Link
        to={linkTo}
        className="text-btn-purple w-fit ml-12 h-full hidden lg:flex justify-end items-center"
      >
        <img src="/arrow-right-circle.svg" />
      </Link>
    </div>
  );
};

export const ScheduledTimePill: React.FC<{
  title: string;
  appt?: { date: Date; id: EventInstanceId };
}> = ({ title, appt }) => {
  const hpState = useHpState();
  return (
    <div
      onClick={() => {
        if (appt?.id) {
          hpState.dashboardState.openRightNav({
            _tag: "VIEW_APPOINTMENT",
            appointmentId: appt?.id,
          });
        }
      }}
      className={`bg-vid-black-100 hover:cursor-pointer rounded-full w-fit px-[22px] py-3 flex items-center justify-between
    `}
    >
      <img src="/schedule.svg" width={16} height={16} />
      <span className="uppercase text-xs font-sans font-semibold ml-2">
        {appt?.date ? format(appt?.date, "MMM d | h:mm a") : title}
      </span>
    </div>
  );
};
