import { useUMgr } from "@pages/u/u.webstatemgr";
import {
  ParticipantView,
  useCall,
  useCallStateHooks,
  type StreamVideoParticipant,
} from "@stream-io/video-react-sdk";
import { BE } from "@webapp/backend";
import { PrivateChatFC } from "@webapp/components/chat/private-chat";
import {
  CustomParticipantViewUI,
  CustomVideoPlaceholder,
  LiveSessionComponents,
} from "@webapp/components/live-session/live-session.components";
import { MainRoomContainer } from "@webapp/components/live-session/main-room/main-room-container";
import { EmdrToolsPanel } from "@webapp/components/live-session/right-nav/therapy-tools/emdr/emdr-tools";
import { MeditationToolsPanel } from "@webapp/components/live-session/right-nav/therapy-tools/meditation.tools";
import { ReviewTools } from "@webapp/components/live-session/right-nav/therapy-tools/review-tools";
import { OneOnOneParticipantView } from "@webapp/componentslive-session/main-room/participant-layouts";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { Match } from "effect";
import { useCpPrivateSessionRoomCallStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/cp-room-call.statemgr";
import type { UApiOutput } from "frontend-shared/src/trpc-cli";
import type {
  PrivateSessionSpecialContent,
  PrivateSessionSpecificStageViewState,
} from "frontend-shared/src/types/video-state.types";
import React, { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";
import type { PrivateSessionStateModule } from "shared/session-state/session-state.types";

type PingResult = UApiOutput["hp"]["sessions"]["liveSession"]["registerPing"];

export const CpPrivateSessionMainRoomPage: React.FC = () => {
  const nav = useNavigate();
  const uMgr = useUMgr();
  const mgr = useCpPrivateSessionRoomCallStateMgr();
  const { useLocalParticipant } = useCallStateHooks();
  const me = useLocalParticipant();
  const call = useCall();
  // const stageViewState = useObservableEagerState(mgr.stageViewState$);

  console.log(
    "RENDERING CP PRIVATE SESSION MAIN ROOM! ",
    call?.state.callingState
  );

  useEffect(() => {
    console.log(
      "CALL STATE IN CP PRIVATE SESSSION! ",
      call?.state.callingState
    );
  }, [call?.state.callingState]);

  if (!me) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <MainRoomContainer<
      PingResult,
      PrivateSessionSpecificStageViewState<StreamVideoParticipant>,
      PrivateSessionSpecialContent,
      PrivateSessionStateModule.FirebaseEncodedState,
      PrivateSessionStateModule.State
    >
      mainRoomStateMgr={mgr.mainRoomStateMgr}
      stageRenders={{
        sessionSpecificStageView: (sv) =>
          Match.value(sv).pipe(
            Match.tag(
              "SELF_SMALL_OTHER_USER_LARGE",
              ({ remoteParticipant }) => (
                <OneOnOneParticipantView
                  otherParticipant={remoteParticipant}
                  me={me}
                />
              )
            ),
            Match.exhaustive
          ),
        bottomOfTopPreview: () => (
          <div className="flex-1 flex flex-col relative">
            <ParticipantView
              participant={me}
              trackType={
                mgr.mainRoomStateMgr.hasScreenShare(me)
                  ? "screenShareTrack"
                  : "videoTrack"
              }
              ParticipantViewUI={CustomParticipantViewUI}
              VideoPlaceholder={CustomVideoPlaceholder}
            />
          </div>
        ),
        sessionSpecificContentView: (sc) => <div>{JSON.stringify(sc)}</div>,
        meOnlyVideoView: () => (
          <ParticipantView
            participant={me}
            trackType={
              mgr.mainRoomStateMgr.hasScreenShare(me)
                ? "screenShareTrack"
                : "videoTrack"
            }
            ParticipantViewUI={CustomParticipantViewUI}
            VideoPlaceholder={CustomVideoPlaceholder}
          />
        ),
      }}
      settings={{
        shareableLink: mgr.sessionStateMgr.privateSession.inviteLink,
      }}
      leftControls={[
        // <RecordSectionButton />,
        <LiveSessionComponents.BottomLeftButtons.NotesButton
          onNotesClick={() => {
            mgr.mainRoomStateMgr.openPanel({
              state: "THERAPY_TOOLS",
              initialViewState: { _tag: "TOOL", tool: "notes" },
            });
          }}
        />,
        // <LiveSessionComponents.BottomLeftButtons.BookmarkButton
        //   onBookmarkClick={() => {
        //     mgr.notesMgr.saveBookmark({}).then(() => {
        //       mgr.notesMgr.notesAndBookmarks.runFetchAndSet();
        //       mgr.mainRoomStateMgr.openPanel({
        //         state: "THERAPY_TOOLS",
        //         initialViewState: { _tag: "TOOL", tool: "notes" },
        //       });
        //     });
        //   }}
        // />,
      ]}
      knownTools={[
        // {
        //   tool: "notes",
        //   component: (
        //     <NoteToolsPanel
        //       notes$={mgr.notesMgr.notes$}
        //       bookmarks={{
        //         rdBookmarks$: mgr.notesMgr.rdBookmarks$,
        //       }}
        //       bookends={{
        //         rdRecordedBookends$:
        //           mgr.notesMgr.recordSectionMgr.rdRecordedBookends$,
        //         onBookendLabelChange: ({ bookendId, newLabel }) => {
        //           mgr.notesMgr.recordSectionMgr.setBookendLabel({
        //             bookendId,
        //             label: newLabel,
        //           });
        //         },
        //       }}
        //     />
        //   ),
        // },
        {
          tool: "emdr",
          component: (
            <EmdrToolsPanel emdrMgr={mgr.mainRoomStateMgr.tools.emdrMgr} />
          ),
        },
        {
          tool: "meditation",
          component: (
            <MeditationToolsPanel
              meditationMgr={mgr.mainRoomStateMgr.tools.meditationMgr}
            />
          ),
        },
        {
          tool: "review",
          component: <ReviewTools reviewMgr={mgr.reviewMgr} />,
        },
        {
          tool: "chat",
          component: (
            <PrivateChatFC
              otherUserId={mgr.sessionStateMgr.privateSession.hp.id}
              chatStateMgr={uMgr.chatStateMgr}
              setupChatEff={() =>
                BE.fetchEndpointEff((api) =>
                  api.cp.liveSession.startChatChannel.mutate({
                    sessionId: mgr.sessionStateMgr.privateSession.id,
                  })
                )
              }
            />
          ),
        },
      ]}
      topBarLeft={{
        memberProfilePhotos: [],
      }}
      rdClosedCallResult$={mgr.callStateMgr.submitCallClosedResult$}
      onCloseCallButtonClick={() => {
        console.log("LEAVING CALL! ", call?.state.callingState);
        mgr.callStateMgr.onCloseCallClick({
          onNextAction: (nextAction) => {
            return Match.value(nextAction.action).pipe(
              Match.tag("GO_TO_ROUTE", ({ nextRoute }) => {
                nav(nextRoute);
              }),
              Match.tag("LEAVE", ({ nextRoute }) => {
                call!.leave().then((_) => {
                  nav(nextRoute);
                });
              }),
              Match.exhaustive
            );
          },
        });
      }}
    />
  );
};

// const StageView: React.FC<{
//   stageViewState: PrivateSessionStageViewState;
// }> = ({ stageViewState }) => {
//   return Match.value(stageViewState).pipe(
//     Match.tag("UNKNOWN", () => <div>UNKNOWN</div>),
//     Match.tag("SELF_ONLY_VIDEO", () => <div>SELF_ONLY_VIDEO</div>),
//     Match.tag("PRIVATE_SESSION", () => <div>PRIVATE_SESSION</div>),
//     Match.tag("CONTENT_VIEW", ({ viewState }) => (
//       <div>{JSON.stringify(viewState)}</div>
//     )),
//     Match.tag("GROUP_SESSION", () => <div>GROUP_SESSION</div>),
//     Match.exhaustive
//   );
// };

// const RecordSectionButton: React.FC = () => {
//   const mgr = useCpPrivateSessionRoomCallStateMgr();
//   const isRecordingSectionId$ = useKeyOfObservable(
//     mgr.sessionStateMgr.firestoreMgr.remoteStateSyncMgr.decodedRemoteState$,
//     "isRecordingSectionId"
//   );

//   const isRecordingSection$ = useMemo(
//     () => isRecordingSectionId$.pipe(RxO.map((isr) => isr !== null)),
//     []
//   );
//   return (
//     <LiveSessionComponents.BottomLeftButtons.RecordSectionButton
//       isRecording$={isRecordingSection$}
//       onStartClick={() => {
//         mgr.notesMgr.recordSectionMgr.startSectionRecording();
//       }}
//       onStopClick={() => {
//         mgr.notesMgr.recordSectionMgr.stopSectionRecording().then(() => {
//           mgr.notesMgr.recordSectionMgr.fetchAndSetRecordedBookends().catch();
//           mgr.mainRoomStateMgr.openPanel({
//             state: "THERAPY_TOOLS",
//             initialViewState: { _tag: "TOOL", tool: "notes" },
//           });
//         });
//       }}
//     />
//   );
// };
