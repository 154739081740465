import { CpDashboardCalendarPage } from "@pages/cp/dashboard/calendar.page";
import { RouteObject } from "react-router-dom";
import { CPROUTES } from "shared/routes/cp.routes";
import { CpLayout } from "../pages/cp/cp.layout";
import { CpDashboardLayout } from "../pages/cp/dashboard/dashboard.layout";
import { CpDashboardHomePage } from "../pages/cp/dashboard/home.page";
import { CpHpDetailsPage } from "../pages/cp/dashboard/hps/[hpid]/details.page";
import { CpSettingsPage } from "../pages/cp/dashboard/settings.page";
import { CpSessionReviewPage } from "../pages/cp/sessions/[sessionId]/review.page";

console.log(
  "CPROUTES! ",
  CPROUTES.DASHBOARD.ROOT.relativePath,
  CPROUTES.DASHBOARD.CHILDREN.HOME.relativePath
);

export const cpRoutes: RouteObject = {
  path: "",
  element: <CpLayout />,
  children: [
    {
      path: CPROUTES.SESSIONS.SESSION_ID.REVIEW.relativePath,
      element: <CpSessionReviewPage />,
    },
    {
      path: CPROUTES.ROOT.DASHBOARD.relativePath,
      element: <CpDashboardLayout />,
      children: [
        {
          path: CPROUTES.DASHBOARD.CHILDREN.HOME.relativePath,
          element: <CpDashboardHomePage />,
        },
        {
          path: CPROUTES.DASHBOARD.CHILDREN.CALENDAR.relativePath,
          element: <CpDashboardCalendarPage />,
        },
        {
          path: CPROUTES.DASHBOARD.CHILDREN.SETTINGS.relativePath,
          element: <CpSettingsPage />,
        },
        {
          children: [
            {
              path: CPROUTES.DASHBOARD.CHILDREN.HPS.HP_ID.DETAILS.relativePath,
              element: <CpHpDetailsPage />,
            },
          ],
        },
      ],
    },
  ],
};
