import * as S from "@effect/schema/Schema";
import { DateTime, Effect, Option } from "effect";
import { STARTABLE_MINUTES_WINDOW_FOR_SESSION } from "shared/constants";
import { EventTemplateId } from "../types/calendar.types";
import { CallCredentials, CallInfo } from "./call.schemas";
import {
  SessionCallNextActionForUser,
  SessionCallStatus,
} from "./session.schemas";

export class GroupSessionInfo extends S.Class<GroupSessionInfo>(
  "GroupSessionInfo"
)({
  id: S.String,
  host: S.Struct({ id: S.String }),
  endedAt: S.Option(S.Date),
  mbEvent: S.Option(
    S.Struct({
      community: S.Struct({ slug: S.String, name: S.String }),
      startTime: S.Date,
      eventTemplateId: EventTemplateId,
    })
  ),
  inviteLink: S.String,
}) {
  get mbStartsAt() {
    return this.mbEvent.pipe(Option.map((e) => e.startTime));
  }

  get isInStartableWindow() {
    return Option.match(this.mbStartsAt, {
      onNone: () => true,
      onSome: (startsAt) =>
        GroupSessionInfo.isNowInStartableWindow({
          startsAt,
          windowInMinutes: STARTABLE_MINUTES_WINDOW_FOR_SESSION,
        }),
    });
  }
  static isNowInStartableWindow = (p: {
    startsAt: Date;
    windowInMinutes: number;
  }): boolean =>
    Effect.runSync(
      DateTime.now.pipe(
        Effect.map((now) => {
          const startsAt = DateTime.unsafeFromDate(p.startsAt);
          const nMinutesAfter = DateTime.add(startsAt, {
            minutes: p.windowInMinutes,
          });
          const nMinutesBefore = DateTime.subtract(startsAt, {
            minutes: p.windowInMinutes,
          });
          return now.pipe(
            DateTime.between({
              minimum: nMinutesBefore,
              maximum: nMinutesAfter,
            })
          );
        })
      )
    );
}

export class GroupSessionUser extends S.Class<GroupSessionUser>(
  "GroupSessionUser"
)({
  id: S.String,
  role: S.Union(S.Literal("host"), S.Literal("participant")),
}) {
  static from = (p: {
    groupSessionInfo: GroupSessionInfo;
    myUserId: string;
  }) => {
    if (p.myUserId === p.groupSessionInfo.host.id) {
      return GroupSessionUser.make(
        {
          id: p.myUserId,
          role: "host",
        },
        { disableValidation: true }
      );
    }
    return GroupSessionUser.make(
      {
        id: p.myUserId,
        role: "participant",
      },
      { disableValidation: true }
    );
  };
}

export class GroupSessionStateAndUserNextAction extends S.Class<GroupSessionStateAndUserNextAction>(
  "GroupSessionStateAndUserNextAction"
)({
  groupSessionInfo: GroupSessionInfo,
  nextAction: SessionCallNextActionForUser.Action,
  status: SessionCallStatus.Status,
}) {
  static from = (p: {
    groupSessionInfo: GroupSessionInfo;
    mbCurrentCall: Option.Option<{
      callInfo: CallInfo;
      callCredentials: CallCredentials;
    }>;
    forUser: { id: string };
  }) => {
    const gsUserWithRole = GroupSessionUser.from({
      groupSessionInfo: p.groupSessionInfo,
      myUserId: p.forUser.id,
    });
    console.log("GS USER WITH ROLE! ", gsUserWithRole);
    const status = SessionCallStatus.Status.from({
      sessionInfo: p.groupSessionInfo,
      mbCurrentCall: p.mbCurrentCall,
    });
    console.log("STATUS!!!!!!!!! ", status);
    const isUserHost = gsUserWithRole.role === "host";
    const nextAction = SessionCallNextActionForUser.Action.from({
      state: status,
      channelId: p.groupSessionInfo.id,
      isUserHost,
    });
    return GroupSessionStateAndUserNextAction.make({
      groupSessionInfo: p.groupSessionInfo,
      nextAction,
      status,
    });
  };
}

export class UserEnteredGroupSessionResult extends S.Class<UserEnteredGroupSessionResult>(
  "UserEnteredGroupSessionResult"
)({
  groupSessionInfo: GroupSessionInfo,
  stateAndNextAction: GroupSessionStateAndUserNextAction,
  performedActions: S.NullOr(
    S.Array(S.Union(S.Literal("started_new_call"), S.Literal("joined_call")))
  ),
}) {}
