import { Switch } from "@headlessui/react";
import { UROUTES } from "shared/routes/u.routes";
import { useAuthedFetchTE } from "frontend-shared/src/api.mgr";
import { useObservableEagerState } from "observable-hooks";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { E, O, RD } from "shared/base-prelude";
import { BE } from "../../../backend";
import { TextInput } from "../../../components/form/form-inputs.fc";
import { PrimaryButton } from "../../../components/primitives/button";
import { useCpState } from "./cpdashboard.state";

// class SettingsStateMgr {
//   worksWithClients$ = new Rx.BehaviorSubject<boolean>(false);

//   constructor() {
//     this.worksWithClients$.subscribe((b) => {
//       BE.fetchEndpointTE((Api) =>
//         Api.u.setWorksWithClients.mutate({ worksWithClients: b })
//       )().then((er) => {
//         console.log("SET WORK WITH CLIENTS RESULT! ", er);
//       });
//     });
//   }

//   setWorksWithClients(b: boolean) {
//     this.worksWithClients$.next(b);
//   }
// }

// const [SettingsStateMgrProvider, useSettingsStateMgr] =
//   createContextAndHook<SettingsStateMgr>();

export const CpSettingsPage: React.FC<{}> = ({}) => {
  const cpState = useCpState();
  const myProfile = useObservableEagerState(cpState.myProfile$);
  return (
    <div className="flex flex-col gap-4 p-8">
      <div className="p-8 border rounded-2xl">
        <UploadProfilePhoto />
      </div>
      <div className="p-8 border rounded-2xl max-w-[300px]">
        <UpdateProfile />
      </div>
      <div className="p-8 border rounded-2xl">
        <WorksWithClientsSwitch
          worksWithClients={myProfile.worksWithClients}
          onChange={(b) => {
            BE.fetchEndpointTE((Api) =>
              Api.u.setWorksWithClients.mutate({ worksWithClients: b })
            )().then((er) => {
              console.log("SET WORK WITH CLIENTS RESULT! ", er);
              cpState.refreshMyProfile();
            });
          }}
        />
        {myProfile.worksWithClients && (
          <Link
            to={UROUTES.HP.MY.DASHBOARD.CLIENTS.path}
            className="text-blue-500 "
          >
            Go to therapist/coaching portal
          </Link>
        )}
      </div>
      <div className="p-8 border rounded-2xl">
        <RequestATherapistSection />
      </div>
    </div>
  );
};

const RequestATherapistSection: React.FC<{}> = ({}) => {
  const [email, setEmail] = useState("");
  return (
    <form
      className="p-8 border rounded-2xl"
      onSubmit={(e) => {
        e.preventDefault();
        console.log("REQUESTING A THERAPIST! ", email);
        BE.fetchEndpointTE((Api) =>
          Api.cp.requestATherapist.mutate({ hpEmail: email })
        )().then((er) => {
          console.log("REQUEST A THERAPIST RESULT! ", er);
        });
      }}
    >
      <h4 className="text-lg font-medium">Request a therapist</h4>
      <TextInput label="Email" value={email} onChange={setEmail} />
      <PrimaryButton title="Request" />
    </form>
  );
};

const UpdateProfile: React.FC<{}> = ({}) => {
  const cpState = useCpState();
  const myProfile = useObservableEagerState(cpState.myProfile$);
  const [firstName, setFirstName] = useState(myProfile.firstName);
  const [lastName, setLastName] = useState(myProfile.lastName);
  return (
    <div className="flex flex-col gap-4">
      <TextInput
        label="First Name"
        value={firstName}
        onChange={(firstName) => setFirstName(firstName)}
      />
      <TextInput
        label="Last Name"
        value={lastName}
        onChange={(lastName) => setLastName(lastName)}
      />
      <div className="text-sm text-gray-500">{myProfile.email}</div>
      <PrimaryButton
        title="Update Profile"
        onClick={() => {
          console.log("UPDATING PROFILE! ", firstName, lastName);
          cpState.updateName({ firstName, lastName });
        }}
      />
    </div>
  );
};

const UploadProfilePhoto: React.FC<{}> = ({}) => {
  const [mbProfilePhoto, setMbProfilePhoto] = React.useState<O.Option<Blob>>(
    O.none
  );

  const rdUploadUrl = useAuthedFetchTE(
    (Api) => Api.cp.getProfilePhotoUploadUrl.query(),
    []
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        {O.isSome(mbProfilePhoto) && (
          <img
            src={URL.createObjectURL(mbProfilePhoto.value)}
            className="w-32 h-32 rounded-full"
          />
        )}
        <label htmlFor="profilePhoto">Profile Photo </label>
        <input
          type="file"
          id="profilePhoto"
          onChange={(e) => {
            const file = e.target.files?.[0];

            if (file && RD.isSuccess(rdUploadUrl)) {
              const uploadPhoto = async () => {
                const uploadResult = await fetch(
                  rdUploadUrl.value.profilePhotoUploadUrl,
                  {
                    method: "PUT",
                    body: file,
                  }
                );

                if (uploadResult.ok) {
                  console.log("UPLOAD RESULT! ", uploadResult);
                  console.log("REGISTERING IT UPLOADED! ");
                  const itUploadedResult = await BE.fetchEndpointTE((Api) =>
                    Api.cp.setProfilePhotoUploadedAt.mutate()
                  )();
                  console.log("IT UPLOADED RESULT! ", itUploadedResult);

                  const downloadUrl = await BE.fetchEndpointTE((Api) =>
                    Api.cp.getProfilePhotoDownloadUrl.query()
                  )();

                  if (E.isRight(downloadUrl)) {
                    const downloadResult = await fetch(
                      downloadUrl.right.profilePhotoDownloadUrl,
                      {
                        method: "GET",
                      }
                    );

                    if (downloadResult.ok) {
                      const blob = await downloadResult.blob();
                      setMbProfilePhoto(O.some(blob));
                    }
                  }
                }
              };

              uploadPhoto().then();
            }
          }}
        />
      </div>
    </div>
  );
};

const WorksWithClientsSwitch: React.FC<{
  worksWithClients: boolean | null;
  onChange: (b: boolean) => void;
}> = ({ worksWithClients, onChange }) => {
  const [enabled, setEnabled] = useState(worksWithClients ?? false);

  return (
    <div className="p-4">
      <h4 className="text-lg font-medium">Do you also work with clients?</h4>
      <Switch
        checked={enabled}
        onChange={(b) => {
          setEnabled(b);
          onChange(b);
        }}
        className={`${worksWithClients ? "bg-blue-600" : "bg-gray-200"}
          relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
      >
        <span
          className={`${worksWithClients ? "translate-x-6" : "translate-x-1"}
            inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
        />
      </Switch>
    </div>
  );
};
