import {
  SfuModels,
  type StreamVideoParticipant,
} from "@stream-io/video-react-sdk";
import { Effect, Equal, Option } from "effect";
import {
  FirestoreSessionStateMgmt,
  type RemoteSessionStateSyncMgr,
} from "frontend-shared/src/firestore-live-session.mgmt";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { type TherapyToolMenuViewState } from "shared";
import { O, erp } from "shared/base-prelude";
import { RUN_PING_SECONDS_INTERVAL } from "shared/constants";
import {
  StageClockDisplay,
  type SessionTimeLeftInfo,
  type SpecialClockInfo,
} from "shared/schemas/session.schemas";
import { BaseSessionStateModule } from "shared/session-state/session-state.types";
import type { EmdrState } from "shared/session-state/shared-session-content-state.types";
import type { BaseStageViewStateMgr } from "../../../types/video-state.types";
import type { AbstractAudioPlayer } from "../../media-player.statemgr";
import { MeditationRemoteStateMgr } from "./tools/meditation.statemgr";
import { AudioMediaStateMgr } from "./tools/shared/audio-media.statemgr";
import { EmdrSyncStateMgr } from "./tools/shared/emdr.statemgr";

export type OpenRightPanelViewState =
  | {
      state: "THERAPY_TOOLS";
      initialViewState?: TherapyToolMenuViewState;
    }
  | { state: "SETTINGS" }
  | { state: "NEXT_SCHEDULE_REMINDER" };

export type TopPreviewContent<Extra> =
  | { _tag: "Meditation"; videoSrc?: string; minutes: number; seconds: number }
  | {
      _tag: "Review";
      sessionType:
        | { _tag: "PRIVATE"; sessionId: string }
        | { _tag: "GROUP"; groupSessionId: string };
    }
  | {
      _tag: "EMDR_BALL";
      mode: EmdrState["mode"];
      ballFrequency: number;
    }
  | { _tag: "Extra"; extraState: Extra };

export interface SpecialClockData {
  secondsLeft: number;
  description: "meditation" | "warningTimeLeft";
  message?: string;
}

export type BaseRegisterPingRes = {
  mbTimeLeftInfo: Option.Option<SessionTimeLeftInfo>;
};

class PingMgr<RegisterPingRes extends BaseRegisterPingRes> {
  pingInterval: Timer | null = null;
  pingResult$ = new Rx.ReplaySubject<RegisterPingRes>();
  sendPing: () => void;

  constructor(p: {
    sendPingEff: Effect.Effect<RegisterPingRes, unknown, never>;
  }) {
    this.sendPing = () => {
      erp(p.sendPingEff).then((pr) => {
        console.log("PING RESULT! ", pr);
        this.pingResult$.next(pr);
      });
    };

    this.pingInterval = setInterval(() => {
      console.log("SENDING PING!");
      this.sendPing();
    }, 1000 * RUN_PING_SECONDS_INTERVAL);
  }

  cleanup() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
  }
}

export class MainRoomStateMgr<
  RegisterPingRes extends BaseRegisterPingRes,
  SessionSpecificStageViewState,
  SessionSpecificContent,
  RemoteSessionState extends BaseSessionStateModule.FirebaseEncodedState,
  SessionState extends BaseSessionStateModule.State
> {
  rightNav$ = new Rx.BehaviorSubject<O.Option<OpenRightPanelViewState>>(O.none);
  isLeftNavToolsOpen$ = new Rx.BehaviorSubject<boolean>(true);
  stageViewMgr: BaseStageViewStateMgr<
    SessionSpecificStageViewState,
    SessionSpecificContent
  >;

  firestoreMgr: FirestoreSessionStateMgmt<RemoteSessionState, SessionState>;

  pingMgr: PingMgr<RegisterPingRes>;
  mbTimeLeftInfo$: Rx.Observable<Option.Option<SessionTimeLeftInfo>>;
  clockDisplay$: Rx.Observable<StageClockDisplay>;

  tools: {
    meditationMgr: MeditationRemoteStateMgr;
    emdrMgr: EmdrSyncStateMgr;
    audioMediaMgr: AudioMediaStateMgr;
  };

  audioPlayer: AbstractAudioPlayer;

  constructor(p: {
    stageViewMgr: BaseStageViewStateMgr<
      SessionSpecificStageViewState,
      SessionSpecificContent
    >;
    sendPingEff: Effect.Effect<RegisterPingRes>;
    fsSessionStateMgr: FirestoreSessionStateMgmt<
      RemoteSessionState,
      SessionState
    >;
    audioPlayer: AbstractAudioPlayer;
  }) {
    this.stageViewMgr = p.stageViewMgr;
    this.audioPlayer = p.audioPlayer;
    this.firestoreMgr = p.fsSessionStateMgr;
    this.pingMgr = new PingMgr({
      sendPingEff: p.sendPingEff,
    });

    const baseRemoteStateSyncMgr = p.fsSessionStateMgr
      .remoteStateSyncMgr as unknown as RemoteSessionStateSyncMgr<
      BaseSessionStateModule.FirebaseEncodedState,
      BaseSessionStateModule.State
    >;
    this.tools = {
      audioMediaMgr: new AudioMediaStateMgr(
        {
          remoteStateMgr: baseRemoteStateSyncMgr,
          stageViewStateMgr: this.stageViewMgr,
        },
        p.audioPlayer
      ),
      meditationMgr: new MeditationRemoteStateMgr(
        {
          remoteStateMgr: baseRemoteStateSyncMgr,
          stageViewStateMgr: this.stageViewMgr,
        },
        p.audioPlayer
      ),
      emdrMgr: new EmdrSyncStateMgr({
        remoteStateMgr: baseRemoteStateSyncMgr,
        stageViewStateMgr: this.stageViewMgr,
      }),
    };

    this.mbTimeLeftInfo$ = this.pingMgr.pingResult$.pipe(
      RxO.map((pr) => pr.mbTimeLeftInfo)
    );

    this.clockDisplay$ = Rx.combineLatest([
      // this.pingMgr.pingResult$.pipe(RxO.startWith(null)),
      this.mbTimeLeftInfo$,
      this.tools.meditationMgr.showClock$.pipe(
        RxO.startWith(Option.none() as Option.Option<number>)
      ),
    ]).pipe(
      RxO.map(([mbTimeLeftInfo, mbShowMeditationClock]) => {
        const mbSpecialClock = mbShowMeditationClock.pipe(
          Option.map(
            (secondsLeft) =>
              ({
                secondsLeft,
                description: "meditation",
                message: null,
              } satisfies SpecialClockInfo)
          )
        );
        return StageClockDisplay.from({
          mbSpecialClock,
          mbTimeLeftInfo,
        });
      }),
      RxO.distinctUntilChanged((a, b) => Equal.equals(a, b))
    );
  }

  cleanup() {
    this.pingMgr.cleanup();
  }

  hasScreenShare = (p: StreamVideoParticipant) =>
    p.publishedTracks.includes(SfuModels.TrackType.SCREEN_SHARE);

  closePanel() {
    this.rightNav$.next(O.none);
  }

  openPanel(rightNav: OpenRightPanelViewState) {
    this.rightNav$.next(O.some(rightNav));
  }

  toggleLeftToolsNav() {
    this.isLeftNavToolsOpen$.next(!this.isLeftNavToolsOpen$.value);
  }
}
