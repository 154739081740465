import { Equal, Match, Option } from "effect";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { PrivateSessionStateModule } from "shared/session-state/session-state.types";
import { ContentViewStateModule } from "shared/session-state/shared-session-content-state.types";
import { type RemoteSessionStateSyncMgr } from "../../../../firestore-live-session.mgmt";
import {
  BaseStageViewStateMgr,
  type BaseStageViewStatMode,
  type PrivateSessionSpecificStageViewState,
  type StageContentViewMode,
} from "../../../../types/video-state.types";
import type { GenericChannelParticipantI } from "shared/schemas/call.schemas";

type PrivateSessionSpecificContent = {};

type PrivateSessionStageViewState<
  TParticipant extends GenericChannelParticipantI
> = BaseStageViewStatMode<
  PrivateSessionSpecificStageViewState<TParticipant>,
  PrivateSessionSpecificContent
>;

export class PrivateSessionStageViewStateMgr<
  TParticipant extends GenericChannelParticipantI
> implements
    BaseStageViewStateMgr<
      PrivateSessionSpecificStageViewState<TParticipant>,
      PrivateSessionSpecificContent
    >
{
  stageViewState$: Rx.Observable<PrivateSessionStageViewState<TParticipant>>;
  localStateContentViewMode$ = new Rx.BehaviorSubject<
    Option.Option<StageContentViewMode>
  >(Option.none());

  setContentViewMode(viewMode: ContentViewStateModule.ContentStageViewMode) {
    return Match.value(this.myRole).pipe(
      Match.when("HOST", () => {
        return this.remoteSyncMgr.updateRemoteState((curState) => {
          return new PrivateSessionStateModule.State({
            ...curState,
            hostContentViewMode: viewMode,
          });
        });
      }),
      Match.when("PARTICIPANT", () => {
        return this.remoteSyncMgr.updateRemoteState((curState) => {
          return new PrivateSessionStateModule.State({
            ...curState,
            participantsContentViewMode: viewMode,
          });
        });
      }),
      Match.exhaustive
    );
  }

  closeTopPreview() {
    console.log("CLOSING TOP PREVIEW! ");
    this.remoteSyncMgr.runUpdateHostContentViewMode(null);
  }

  maximizeTopPreview() {
    this.remoteSyncMgr.runUpdateHostContentViewMode("FULL_SCREEN");
  }

  minimizeTopPreview() {
    this.remoteSyncMgr.runUpdateHostContentViewMode("TOP_PREVIEW");
  }

  constructor(
    readonly remoteParticipants$: Rx.Observable<TParticipant[]>,
    readonly remoteSyncMgr: RemoteSessionStateSyncMgr<
      PrivateSessionStateModule.FirebaseEncodedState,
      PrivateSessionStateModule.State
    >,
    readonly myRole: "HOST" | "PARTICIPANT"
  ) {
    this.localStateContentViewMode$.subscribe((cv) => {
      console.log("CONTENT VIEW! ", cv);
    });

    const remoteState$ = remoteSyncMgr.decodedRemoteState$;

    this.stageViewState$ = Rx.combineLatest([
      remoteParticipants$,
      remoteState$,
    ]).pipe(
      RxO.map(([remoteParticipants, remoteState]) => {
        return this.determineStageViewState({
          remoteParticipants,
          remoteState,
        });
      }),
      RxO.startWith({
        _tag: "UNKNOWN",
      } as PrivateSessionStageViewState<TParticipant>)
    );
  }

  private determineStageViewState = (p: {
    remoteParticipants: TParticipant[];
    remoteState: PrivateSessionStateModule.State;
  }): PrivateSessionStageViewState<TParticipant> => {
    const { remoteParticipants, remoteState } = p;
    const mbContent = Option.fromNullable(remoteState.content?.current);
    const hostContentViewMode = Option.fromNullable(
      remoteState.hostContentViewMode
    );
    if (Option.isSome(hostContentViewMode) && Option.isSome(mbContent)) {
      return {
        _tag: "CONTENT_VIEW",
        viewMode: hostContentViewMode.value,
        content: mbContent.value,
      };
    }

    if (remoteParticipants.length === 0) {
      return {
        _tag: "PARTICIPANTS_VIEW",
        viewMode: { _tag: "SELF_ONLY_VIDEO" },
      };
    } else {
      return {
        _tag: "PARTICIPANTS_VIEW",
        viewMode: {
          _tag: "SESSION_SPECIFIC",
          viewMode: {
            _tag: "SELF_SMALL_OTHER_USER_LARGE",
            remoteParticipant: remoteParticipants[0],
          },
        },
      };
    }
  };
}
