import { useObservableEagerState, useObservableState } from "observable-hooks";
import { useMemo } from "react";
import { IoArrowDownCircle, IoCloseCircle } from "react-icons/io5";
import { Modality } from "shared";
import { BE } from "../backend";
import { RD, Rx, RxO } from "../prelude";
import { createContextAndHook } from "frontend-shared/src/util";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

type ViewState = "DROPPED_DOWN" | "SELECTED" | "LOADING";

class StateActions {
  rdAllModalities$ = new Rx.BehaviorSubject<RD.RemoteData<any, Modality[]>>(
    RD.initial
  );
  isDroppedDown$ = new Rx.BehaviorSubject<boolean>(false);
  viewState$: Rx.Observable<ViewState>;

  constructor(
    readonly onSelect: (modality: Modality) => void,
    readonly onUnselect: (modality: Modality) => void,
    readonly myModalities$: Rx.BehaviorSubject<Modality[]>
  ) {
    this.viewState$ = Rx.combineLatest([
      this.rdAllModalities$,
      this.isDroppedDown$,
    ]).pipe(
      RxO.map(([rd, isDroppedDown]) => {
        if (RD.isSuccess(rd)) {
          return isDroppedDown ? "DROPPED_DOWN" : "SELECTED";
        }

        return "LOADING";
      })
    );
    this.fetchAndSetAllModalities();
  }

  fetchAndSetAllModalities() {
    this.rdAllModalities$.next(RD.pending);
    BE.publicTE(() => BE.PublicApi.listTherapyTypes.query())().then((er) => {
      this.rdAllModalities$.next(RD.fromEither(er));
    });
  }

  onSelectableModalityClick = (modality: Modality) => {
    this.myModalities$.next([...this.myModalities$.value, modality]);

    this.onSelect(modality);
  };

  unselectModality = (modality: Modality) => {
    this.myModalities$.next(
      this.myModalities$.value.filter((m) => m.slug !== modality.slug)
    );

    this.onUnselect(modality);
  };
}

const [StateActionsContext, useStateActions] =
  createContextAndHook<StateActions>();

export const ModalitiesOfferedSelector: React.FC<{
  myModalities$: Rx.BehaviorSubject<Modality[]>;
  onSelect: (modality: Modality) => void;
  onUnselect: (modality: Modality) => void;
}> = ({ myModalities$, onSelect, onUnselect }) => {
  const saMgr = useMemo(
    () => new StateActions(onSelect, onUnselect, myModalities$),
    []
  );

  return (
    <StateActionsContext.Provider value={saMgr}>
      <Menu as="div" className="relative w-full">
        {({ open }) => (
          <>
            <Menu.Button className="w-full">
              <SelectedView />
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute z-10 w-full mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <DroppedDownView />
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </StateActionsContext.Provider>
  );
};

const SelectedView: React.FC = () => {
  const saMgr = useStateActions();
  const myModalities = useObservableEagerState(saMgr.myModalities$);

  return (
    <div className="border border-vid-black-200 rounded-[12px] pl-8 py-4 flex justify-between">
      <div className="flex-1 flex flex-wrap gap-4">
        {myModalities.length === 0 ? (
          <h4>Select modalities you offer</h4>
        ) : (
          myModalities.map((m) => (
            <SelectedModalityPill key={m.slug} modality={m} />
          ))
        )}
      </div>
      <div className="flex justify-end items-center px-6 cursor-pointer">
        <IoArrowDownCircle size={24} />
      </div>
    </div>
  );
};

const SelectedModalityPill: React.FC<{ modality: Modality }> = ({
  modality,
}) => {
  const saMgr = useStateActions();
  return (
    <div
      className="bg-gray-200 rounded-md px-2 py-1 cursor-pointer flex items-center gap-2"
      onClick={() => {
        saMgr.unselectModality(modality);
      }}
    >
      <IoCloseCircle size={14} />
      <span>{modality.name}</span>
    </div>
  );
};

const DroppedDownView: React.FC = () => {
  const saMgr = useStateActions();
  const selectedModalities = useObservableEagerState(saMgr.myModalities$);
  const rdAllModalities = useObservableEagerState(saMgr.rdAllModalities$);

  if (!RD.isSuccess(rdAllModalities)) {
    return null;
  }

  return (
    <div className="text-input flex flex-col gap-2 p-4">
      {rdAllModalities.value
        .filter(
          (m) => !selectedModalities.map((sm) => sm.slug).includes(m.slug)
        )
        .map((m) => (
          <Menu.Item key={m.slug}>
            {({ active }) => (
              <div
                className={`cursor-pointer ${active ? "bg-gray-100" : ""}`}
                onClick={() => {
                  saMgr.onSelectableModalityClick(m);
                }}
              >
                {m.name}
              </div>
            )}
          </Menu.Item>
        ))}
    </div>
  );
};
