import {
  publicTrpc,
  trpcQueryToTE,
  uTrpc,
  type FetchEsque,
  type TrpcFetchError,
} from "./trpc-cli";

import { type CaptureContext } from "@sentry/types";
import { TRPCClientError, type CreateTRPCClient } from "@trpc/client";
import type { TRPC_ERROR_CODE_KEY } from "@trpc/server/unstable-core-do-not-import";
import type { RootAuthedRouter } from "backend/src/Routers/Root.Router";
import {
  Cause,
  Console,
  Data,
  Effect,
  Either,
  Match,
  Option,
  Schedule,
} from "effect";
import { UnknownException } from "effect/Cause";
import { pipe } from "fp-ts/function";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect } from "react";
import {
  capDelay,
  exponentialBackoff,
  limitRetries,
  Monoid,
  type RetryPolicy,
} from "retry-ts";
import { retrying } from "retry-ts/Task";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import {
  E,
  effectToTaskEither,
  erte,
  RD,
  T,
  taskEitherToEff,
  TE,
} from "shared/base-prelude";
import {
  FetchSuccessStateMgr,
  FetchWithHandleErrorStateMgr,
  type NoErrorFetchState,
} from "./fetch";
import { createContextAndHook, useOnce } from "./util";

export type AuthedApi = ReturnType<ApiMgr["Api"]>;

class NoTokenError extends Data.TaggedError("NoTokenError")<{
  error: { message: string };
}> {}

export type AuthedFetchError = TrpcFetchError | NoTokenError;

export type DefaultError = AuthedFetchError;
const policy = capDelay(
  2000,
  Monoid.concat(exponentialBackoff(200), limitRetries(5))
);

export class FetchExceptionError extends Data.TaggedError(
  "FetchExceptionError"
)<{
  error: { message: string; cause?: Cause.Cause<unknown> };
}> {}

const FETCH_RETRY_POLICY = Schedule.fixed("100 millis").pipe(
  Schedule.compose(Schedule.recurs(5))
);

export class ApiMgr {
  constructor(
    readonly apiUrl: string,
    readonly getFirebaseToken: Effect.Effect<
      Option.Option<string>,
      UnknownException
    >,
    readonly fetch: FetchEsque,
    readonly actions: {
      readonly reportError: (e: {
        message: string;
        extra?: CaptureContext;
      }) => void;
      readonly appActionAfterDefect: (errorMessage: string) => void;
      readonly onResolved: () => void;
      readonly onFailToReachServerError: () => void;
    }
  ) {
    console.log("THIS API URL! ", this.apiUrl);
  }

  Api = (jwt: string): CreateTRPCClient<RootAuthedRouter> =>
    uTrpc({ API_URL: `${this.apiUrl}/trpc`, jwt, fetch: this.fetch });

  PublicApi = () =>
    publicTrpc({ API_URL: `${this.apiUrl}/trpc`, fetch: this.fetch });

  getFirebaseTokenEff = (): Effect.Effect<Option.Option<string>> =>
    this.getFirebaseToken.pipe(
      Effect.catchAll((error) => {
        this.actions.reportError({
          message: "Error getting firebase token",
          extra: { level: "error", extra: { error: JSON.stringify(error) } },
        });
        return Effect.die(
          new UnknownException(error, "Error getting firebase token")
        );
      })
    );

  getTokenThenFetch = <V>(
    authedTE: (fbToken: string) => TE.TaskEither<TrpcFetchError, V>
  ): Effect.Effect<V, AuthedFetchError, never> =>
    Effect.gen(this, function* () {
      const mbToken = yield* this.getFirebaseTokenEff();
      if (Option.isNone(mbToken)) {
        return yield* Effect.fail(
          new NoTokenError({ error: { message: "No auth token" } })
        );
      }
      const r = yield* taskEitherToEff(authedTE(mbToken.value));
      return r;
    });

  getTokenThenFetchTE = <V>(
    authedTE: (fbToken: string) => TE.TaskEither<TrpcFetchError, V>
  ): TE.TaskEither<DefaultError, V> => erte(this.getTokenThenFetch(authedTE));

  fetchEndpointTE = <V>(
    trpcquery: (api: AuthedApi) => Promise<V>,
    allowedErrorCodes?: TRPC_ERROR_CODE_KEY[]
  ): TE.TaskEither<DefaultError, V> => {
    return this.authedTE(
      (fbToken) => trpcquery(this.Api(fbToken)),
      allowedErrorCodes
    );
  };

  fetchEndpointEff = <V>(
    trpcquery: (api: AuthedApi) => Promise<V>
  ): Effect.Effect<V, AuthedFetchError, never> => {
    return this.getTokenThenFetch((fbToken) =>
      trpcQueryToTE<V>(() => trpcquery(this.Api(fbToken)))
    );
  };

  runFetchEndpointWithHandleError = <V, E>(
    trpcquery: (api: AuthedApi) => Promise<Either.Either<V, E>>,
    p: {
      onSuccess: (v: V) => void;
      onError: (e: E) => void;
    }
  ): void => {
    Effect.runPromise(
      this.fetchEndpointWithHandleError(trpcquery).pipe(Effect.either)
    ).then((ev) => {
      return Either.match(ev, {
        onLeft: (e) => p.onError(e),
        onRight: (v) => p.onSuccess(v),
      });
    });
  };

  fetchEndpointWithHandleErrorP = <V, E>(
    trpcquery: (api: AuthedApi) => Promise<Either.Either<V, E>>
  ): Promise<Either.Either<V, E>> =>
    Effect.runPromise(
      this.fetchEndpointWithHandleError(trpcquery).pipe(Effect.either)
    );

  fetchEndpointWithHandleError = <V, E>(
    trpcquery: (api: AuthedApi) => Promise<Either.Either<V, E>>
  ): Effect.Effect<V, E> =>
    Effect.gen(this, function* () {
      const token = yield* this.getFirebaseTokenEff().pipe(
        Effect.map(Option.getOrElse(() => ""))
      );

      const queryEff: Effect.Effect<
        Either.Either<V, E>,
        string
      > = Effect.tryPromise({
        try: () => trpcquery(this.Api(token)),
        catch: (err) => {
          if (err instanceof TRPCClientError) {
            return err.message;
          } else {
            return "An unknown error occured";
          }
        },
      });

      const withRetry = queryEff.pipe(
        Effect.tap((r) => Effect.log("RETRYING ", r)),
        Effect.retry(FETCH_RETRY_POLICY)
      );

      const withHandleDefectAndRetry = withRetry.pipe(
        Effect.catchAll((cause) =>
          Effect.die(cause).pipe(
            Effect.tap((_) => {
              this.actions.reportError({
                message: "Error fetching endpoint",
                extra: { level: "error", extra: { cause } },
              });
              this.actions.appActionAfterDefect?.(cause);
              return Console.log("FETCH EXCEPTION ", cause);
            })
          )
        ),
        Effect.flatMap((ev) =>
          Either.match(ev, {
            onLeft: (e) => Effect.fail(e),
            onRight: (v) => Effect.succeed(v),
          })
        )
      );

      return yield* withHandleDefectAndRetry;
    });

  fetchSuccessOnlyEndpointP = <V>(
    trpcquery: (api: AuthedApi) => Promise<V>
  ): Promise<V> => Effect.runPromise(this.fetchSuccessOnlyEndpoint(trpcquery));

  fetchSuccessOnlyEndpoint = <V>(
    trpcquery: (api: AuthedApi) => Promise<V>
  ): Effect.Effect<V> =>
    Effect.gen(this, function* () {
      const token = yield* this.getFirebaseTokenEff().pipe(
        Effect.map(Option.getOrElse(() => ""))
      );

      const queryEff: Effect.Effect<V, string> = Effect.tryPromise({
        try: () => trpcquery(this.Api(token)),
        catch: (err) => {
          if (err instanceof TRPCClientError) {
            return err.message;
          } else {
            return "An unknown error occured";
          }
        },
      });

      const withRetry = queryEff.pipe(Effect.retry(FETCH_RETRY_POLICY));

      const withHandleDefectAndRetry = withRetry.pipe(
        Effect.catchAll((cause) => {
          this.actions.reportError({
            message: "Error fetching endpoint",
            extra: { level: "error", extra: { cause } },
          });
          this.actions.appActionAfterDefect?.(cause);
          console.log("FETCH EXCEPTION ", cause);
          return Effect.die(cause);
        })
      );

      return yield* withHandleDefectAndRetry;
    });

  private authedTE = <V>(
    trpcquery: (jwt: string) => Promise<V>,
    allowedErrorCodes?: TRPC_ERROR_CODE_KEY[]
  ): TE.TaskEither<DefaultError, V> => {
    const baseTE = this.getTokenThenFetchTE((fbToken) =>
      trpcQueryToTE<V>(() => trpcquery(fbToken))
    );

    return pipe(
      retrying(
        policy,
        (_) => baseTE,
        (er) => {
          return E.isLeft(er) && er.left._tag === "FailedToReachServer";
        }
      ),
      T.chain((er) => {
        if (E.isLeft(er)) {
          const mbSentryError = mbFetchErrorForSentry(
            er.left,
            allowedErrorCodes
          );

          if (Option.isSome(mbSentryError)) {
            this.actions.reportError(mbSentryError.value);
          }

          if (er.left._tag === "FailedToReachServer") {
            this.actions.onFailToReachServerError();
          }
        }

        return T.of(er);
      })
    );
  };

  publicTE<V>(trpcquery: () => Promise<V>): TE.TaskEither<TrpcFetchError, V> {
    return trpcQueryToTE<V>(trpcquery);
  }

  useFetchSuccessStateMgr<V>(
    trpcquery: (api: AuthedApi) => Promise<V>,
    options: {
      autoFetchAndSetState: boolean;
    }
  ) {
    const mgr = useOnce(
      () => new FetchSuccessStateMgr(this, trpcquery, options)
    );
    return mgr;
  }

  useFetchWithErrorStateMgr<V, E extends Error>(
    trpcquery: (api: AuthedApi) => Promise<Either.Either<V, E>>,
    options: {
      autoFetchAndSetState: boolean;
    }
  ) {
    const mgr = useOnce(
      () => new FetchWithHandleErrorStateMgr(this, trpcquery, options)
    );
    return mgr;
  }

  useSuccessFetch<V>(
    trpcquery: (api: AuthedApi) => Promise<V>,
    deps: any[]
  ): NoErrorFetchState<V> {
    const mgr = this.useFetchSuccessStateMgr<V>(trpcquery, {
      autoFetchAndSetState: false,
    });

    useEffect(() => {
      console.log("FETCHING AND SETTING STATE! ", deps);
      mgr.fetchAndSetState(false);
    }, deps);

    const fetchState$ = useOnce(() =>
      mgr.fetchState$.pipe(
        RxO.distinctUntilChanged((a, b) => {
          if (a._tag === "LoadingState" && b._tag === "LoadingState") {
            return true;
          } else {
            return false;
          }
        })
      )
    );

    const fetchState = useObservableEagerState(fetchState$);

    console.log("FETCH STATE SHOULD BE DISTINCT! ", fetchState);

    return fetchState;
  }

  useSuccessFetchO<V>(
    trpcquery: (api: AuthedApi) => Promise<V>,
    deps: any[]
  ): Option.Option<V> {
    const fetchState = this.useSuccessFetch<V>(trpcquery, deps);

    console.log("FETCH STATE IN USE SUCCESS FETCH O! ", fetchState);

    if (fetchState._tag === "SuccessState") {
      return Option.some(fetchState.data);
    }

    return Option.none();
  }
}

class FetchErrorForSentry extends Data.TaggedError("FetchErrorForSentry")<{
  message: string;
  extra: CaptureContext;
}> {}

function mbFetchErrorForSentry(
  error: AuthedFetchError,
  allowedErrorCodes?: TRPC_ERROR_CODE_KEY[]
): Option.Option<FetchErrorForSentry> {
  return Match.value(error).pipe(
    Match.tag("ErrorFromServer", (err) => {
      if (
        allowedErrorCodes &&
        allowedErrorCodes.includes(err.error.data!.code)
      ) {
        return Option.none<FetchErrorForSentry>();
      } else {
        return Option.some(
          new FetchErrorForSentry({
            message: err.error.message,
            extra: { level: "error" } as CaptureContext,
          })
        );
      }
    }),
    Match.tag("FailedToReachServer", (e) => {
      return Option.some(
        new FetchErrorForSentry({
          message: "Failed to reach server",
          extra: {
            level: "error",
            extra: { error: JSON.stringify(e) },
          } as CaptureContext,
        })
      );
    }),
    Match.tag("NoTokenError", (fberror) => {
      return Option.some(
        new FetchErrorForSentry({
          message: fberror.message,
          extra: { level: "warning" },
        })
      );
    }),
    Match.exhaustive
  );
}

export function useTaskEither<E, V = unknown>(
  te: TE.TaskEither<E, V>,
  deps?: any[],
  onError?: (e: E) => void
) {
  const rdValue$ = useTaskEither$({ baseTe: te, deps, onError });
  const rdValue = useObservableEagerState(rdValue$);

  return rdValue;
}

export function useRunEffect<V, E>(
  eff: Effect.Effect<V, E, never>,
  deps?: any[],
  onError?: (e: E) => void
) {
  return useTaskEither(effectToTaskEither(eff), deps, onError);
}

export function useTaskEither$<E, V = unknown>(p: {
  baseTe: TE.TaskEither<E, V>;
  deps?: any[];
  onError?: (e: E) => void;
  withRetry?: {
    policy: RetryPolicy;
    isComplete: (e: E.Either<E, V>) => boolean;
  };
}) {
  const { baseTe, deps, onError, withRetry } = p;
  const rdValue$ = React.useMemo(
    () => new Rx.BehaviorSubject<RD.RemoteData<E, V>>(RD.initial),
    []
  );

  useEffect(() => {
    rdValue$.next(RD.pending);

    const te = withRetry
      ? retrying(
          withRetry.policy,
          (status) =>
            pipe(
              baseTe,
              TE.chainFirst((r) =>
                TE.fromIO(() => {
                  console.log("retrying", status, r);
                  return rdValue$.next(RD.success(r));
                })
              )
            ),
          withRetry.isComplete
        )
      : baseTe;
    te().then((er) => {
      rdValue$.next(RD.fromEither(er));

      if (E.isLeft(er) && onError) {
        onError(er.left);
      }
    });
  }, deps ?? []);

  return rdValue$;
}

export const [ApiMgrContext, useApiMgr] = createContextAndHook<ApiMgr>();

export function useAuthedFetchTE<V>(
  trpcQueryP: (api: AuthedApi) => Promise<V>,
  deps: any[]
) {
  const apiMgr = useApiMgr();

  const rdV = useTaskEither(
    apiMgr.fetchEndpointTE<V>((Api) => trpcQueryP(Api)),
    deps
  );

  return rdV;
}

export function useAuthedFetch<V>(
  trpcQueryP: (api: AuthedApi) => Promise<V>,
  deps: any[]
) {
  const apiMgr = useApiMgr();

  const rdV = useRunEffect(
    apiMgr.fetchEndpointEff<V>((Api) => trpcQueryP(Api)),
    deps
  );

  return rdV;
}
