import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { BE } from "@webapp/backend";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { O, RD } from "@webapp/prelude";
import { format } from "date-fns";
import { pipe } from "fp-ts/function";
import { UApiOutput } from "frontend-shared/src/trpc-cli";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { UROUTES } from "shared/routes/u.routes";

type ClientInfoResp = UApiOutput["hp"]["clients"]["client"]["getClient"];

export const HpDashboardClientInfoPage: React.FC = () => {
  const hpState = useHpState();
  const { clientId } = useTypedParams(
    UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID
  );
  const [rdClient, setRdClient] = useState<RD.RemoteData<any, ClientInfoResp>>(
    RD.initial
  );

  useEffect(() => {
    if (clientId === undefined) return;
    setRdClient(RD.pending);
    BE.fetchEndpointTE((Api) =>
      Api.hp.clients.client.getClient.query({ clientId })
    )().then((er) => {
      setRdClient(RD.fromEither(er));
    });
  }, []);

  return (
    <div className="w-full flex flex-col p-8">
      {pipe(
        rdClient,
        RD.toOption,
        O.fold(
          () => <FullContainerLoadingSpinner />,
          ({ client, pastSessions }) => (
            <div className="flex flex-col gap-8">
              <h4 className="font-bold text-2xl text-btn-purple">
                {client.name}
              </h4>
              <h4 className="font-bold text-2xl text-btn-purple">
                {client.email}
              </h4>
              <button
                className="w-[200px] ml-4 bg-vid-purple text-center text-white p-3 mb-4 rounded-2xl"
                onClick={() => {
                  hpState.dashboardState.openRightNav({
                    _tag: "START_CLIENT_SESSION",
                    client,
                  });
                }}
              >
                Begin session
              </button>
              {/* {lastSessionReview && (
                <div className="flex flex-col gap-8">
                  <h1 className="font-bold text-xl mt-8">
                    Last Session Review
                  </h1>
                  <ReviewPageContent
                    sessionType={{
                      _tag: "PRIVATE",
                      sessionId: lastSessionReview.,
                    }}
                  />
                </div>
              )} */}
              <h1 className="font-bold text-xl mt-8">Past Sessions</h1>
              {pastSessions.map((ps) => (
                <Link
                  to={`/hp/sessions/${ps.id}/review`}
                  className="w-64 p-8 border-2 shadow-md bg-white rounded-md flex flex-col gap-8 cursor-pointer"
                >
                  <div>{ps.id}</div>
                  <div className="flex flex-col gap-4">
                    <div>{`Started: ${format(
                      new Date(ps.startedAt),
                      "MMM do hh:mm"
                    )}`}</div>
                    {ps.endedAt && (
                      <div>{`Ended: ${format(
                        new Date(ps.endedAt),
                        "MMM do hh:mm"
                      )}`}</div>
                    )}
                  </div>
                  {/* <video src={recording} controls className="w-[40%]" /> */}
                </Link>
              ))}
            </div>
          )
        )
      )}
    </div>
  );
};
