import { addSeconds } from "date-fns";
import { Effect, Match } from "effect";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import {
  ContentViewStateModule,
  EmdrStateModule,
} from "shared/session-state/shared-session-content-state.types";
import { isNonNull } from "shared/util";
import { distinctUntilChangedEquals } from "../../../../../util";
import {
  BaseSessionToolStateMgr,
  type BaseSessionToolStateMgrParams,
} from "./base-session-tool.statemgr";

export type EmdrMediaButtonState = "PLAY" | "PREPARING" | "PAUSE" | "STOP";
type EmdrState = EmdrStateModule.State;

export class EmdrSyncStateMgr extends BaseSessionToolStateMgr {
  emdrState$: Rx.Observable<EmdrStateModule.State | null>;
  mediaButtonState$: Rx.Observable<EmdrMediaButtonState | null>;
  // mediaMgr: MediaPlayerMgr;
  mediaPlayInterval: number | null = null;

  constructor(readonly baseParams: BaseSessionToolStateMgrParams) {
    super(baseParams);

    this.emdrState$ = this.baseSessionState$.pipe(
      RxO.map((ss) => ss.content?.current),
      RxO.filter(isNonNull),
      RxO.filter((cs) => cs._tag === "EMDR"),
      RxO.map((cs): EmdrState => cs.state),
      distinctUntilChangedEquals()
    );
    this.mediaButtonState$ = this.emdrState$.pipe(
      RxO.map(EmdrSyncStateMgr.asMediaButtonState),
      distinctUntilChangedEquals()
    );

    this.emdrState$.pipe(RxO.filter(isNonNull)).subscribe((emdrState) => {
      if (emdrState.mode._tag === "PREPARING") {
        const now = new Date();
        const showTime = new Date(emdrState.mode.showBouncingBallAt).getTime();
        const currentTime = now.getTime();
        const timeUntilShow = showTime - currentTime;
        console.log("TIME UNTIL SHOW! ", timeUntilShow);

        Rx.timer(timeUntilShow).subscribe(() => {
          console.log("TIME! ");
          this.runUpdateEmdrRemoteState({
            mode: new EmdrStateModule.PlayingState(),
          });
        });
      }
    });

    // this.showBouncingBallState$.subscribe((bs) => {
    //   console.log("SHOULD PLAY BASED ON STATE?! ", bs.state);
    //   if (this.mediaPlayInterval) {
    //     console.log("CLEARING AND STOPPING! ");
    //     clearInterval(this.mediaPlayInterval);
    //     this.mediaPlayInterval = null;
    //     this.mediaMgr.mediaTrack?.stop();
    //   }
    //   match(bs.state)
    //     .with("SHOW", () => {
    //       console.log("BS FREQUENCY! ", bs.frequency);
    //       this.mediaPlayInterval = setInterval(() => {
    //         console.log("PS! PLAYING! ");
    //         this.mediaMgr.mediaTrack?.play();
    //         setTimeout(() => {
    //           console.log("PS! STOPPING!");
    //           this.mediaMgr.mediaTrack?.stop();
    //         }, 300);
    //       }, (1000 * 4.5) / bs.frequency);
    //     })
    //     .otherwise(() => {
    //       this.mediaMgr.mediaTrack?.stop();
    //       if (this.mediaPlayInterval) {
    //         clearInterval(this.mediaPlayInterval);
    //         this.mediaPlayInterval = null;
    //       }
    //     });
    // });
  }

  static asMediaButtonState(
    emdrState: EmdrState | null
  ): EmdrMediaButtonState | null {
    if (!emdrState) {
      return "PLAY";
    }
    return Match.value(emdrState.mode).pipe(
      Match.tag("OFF", () => "PLAY" as EmdrMediaButtonState),
      Match.tag("PREPARING", () => "PREPARING" as EmdrMediaButtonState),
      Match.tag("PLAYING", () => "PAUSE" as EmdrMediaButtonState),
      Match.tag("PAUSED", () => "PLAY" as EmdrMediaButtonState),
      Match.tag("COMPLETE", () => "PLAY" as EmdrMediaButtonState),
      Match.exhaustive
    );
  }

  hideBouncingBall$ = new Rx.BehaviorSubject<boolean>(false);

  runUpdateEmdrRemoteState(updates: Partial<EmdrState>) {
    Effect.runPromise(this.updateEmdrRemoteStateEff(updates)).then();
  }

  updateEmdrRemoteStateEff(updates: Partial<EmdrState>) {
    const eff = this.remoteStateMgr
      .updateBaseContentState(({ current }) => {
        if (current?._tag !== "EMDR") {
          return ContentViewStateModule.StateContentDisplay.State.asEmdrDisplay(
            EmdrStateModule.defaultEmdrState
          );
        }

        const curEmdrState = current.state;
        const baseEmdrState = curEmdrState ?? EmdrStateModule.defaultEmdrState;
        const newEmdrState = new EmdrStateModule.State({
          ...baseEmdrState,
          ...updates,
        });

        if (newEmdrState.mode._tag === "OFF") {
          return ContentViewStateModule.StateContentDisplay.State.turnOff();
        }

        return ContentViewStateModule.StateContentDisplay.State.asEmdrDisplay(
          newEmdrState
        );
      })
      .pipe(
        Effect.flatMap((_) =>
          this.stageViewStateMgr.setContentViewMode("TOP_PREVIEW")
        )
      );

    return eff;
  }

  setStatePreparing() {
    this.runUpdateEmdrRemoteState({
      mode: new EmdrStateModule.PreparingState({
        showBouncingBallAt: addSeconds(new Date(), 2).toISOString(),
      }),
    });
  }

  setStatePaused() {
    this.runUpdateEmdrRemoteState({ mode: new EmdrStateModule.PausedState() });
  }

  setStateOff() {
    this.runUpdateEmdrRemoteState({ mode: new EmdrStateModule.OffState() });
  }

  hideBouncingBall(shouldHide: boolean) {
    this.hideBouncingBall$.next(shouldHide);
  }

  setBouncingBallFrequency(frequency: number) {
    this.runUpdateEmdrRemoteState({ ballFrequency: frequency });
  }
}
