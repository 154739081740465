import { Data } from "effect";
import * as Rx from "rxjs";
import type { SimpleUserWithProfilePhoto } from "shared";
import { E, RD } from "shared/base-prelude";
import type { UserPrimaryRole } from "shared/db";
import { BaseStateMgr } from "../base.statemgr";

export type AuthState = Data.TaggedEnum<{
  AUTHORIZED: {
    mbPrimaryRole: UserPrimaryRole | null;
    me: SimpleUserWithProfilePhoto;
  };
  UNAUTHORIZED: {};
}>;

const { AUTHORIZED, UNAUTHORIZED } = Data.taggedEnum<AuthState>();

export class AuthStateMgr extends BaseStateMgr {
  authState$ = new Rx.BehaviorSubject<RD.RemoteData<unknown, AuthState>>(
    RD.initial
  );

  runCheckAuthState() {
    this.authState$.next(RD.pending);
    this.BE.fetchEndpointTE((Api) => Api.u.me.getMe.query())().then((er) => {
      if (E.isRight(er)) {
        const me = er.right.me;
        this.authState$.next(
          RD.success(AUTHORIZED({ me, mbPrimaryRole: "hp" }))
        );
      } else {
        this.authState$.next(RD.success(UNAUTHORIZED()));
      }
    });
  }
}
