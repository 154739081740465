import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { BE } from "@webapp/backend";
import { TextInput } from "@webapp/components/form/form-inputs.fc";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "@webapp/components/primitives/button";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { format } from "date-fns";
import { Match } from "effect";
import { pipe } from "fp-ts/function";
import { useAuthedFetchTE } from "frontend-shared/src/api.mgr";
import React, { useMemo } from "react";
import { IoTrash } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { E, O, RD, TE } from "shared/base-prelude";
import { UROUTES } from "shared/routes/u.routes";
import { type StartOrJoinChatResponse } from "shared/types/chat.types";
import type { FullCommunityEventInstanceData } from "shared/types/community-event.types";
import * as stream from "stream-chat";
import {
  Channel,
  ChannelHeader,
  ChannelList,
  type ChannelPreviewUIComponentProps,
  Chat,
  type DefaultStreamChatGenerics,
  MessageInput,
  MessageList,
  Thread,
  Window,
  useTranslationContext,
} from "stream-chat-react";

export const CommunityEventPage: React.FC = () => {
  const hpState = useHpState();
  const nav = useNavigate();
  const params = useTypedParams(
    UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS
      .EVENT_INSTANCE_ID
  );

  const rdEventInfo = useAuthedFetchTE(
    (Api) =>
      Api.hp.communityEventInstance.getInstanceInfo.query({
        communitySlug: params.community,
        eventInstanceId: params.eventInstanceId,
      }),
    []
  );

  return pipe(
    rdEventInfo,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      (eventInfo) => (
        <div className="flex flex-col gap-4 border-4 rounded-lg p-4">
          <div className="flex justify-between">
            <h4 className="text-2xl font-bold">{eventInfo.title}</h4>
            <div className="flex items-center gap-2">
              <button
                className="px-4 py-2 rounded-md"
                onClick={() => {
                  hpState.dashboardState.openRightNav({
                    _tag: "CUSTOM",
                    topView: <div>{`Invite to ${eventInfo.title}`}</div>,
                    content: (
                      <InviteSomeoneToEventRightNav
                        event={eventInfo}
                        communitySlug={params.community}
                      />
                    ),
                  });
                }}
              >
                <div className="flex items-center gap-2 border rounded-md px-4 py-2">
                  <span>Invite someone</span>
                </div>
              </button>
              <button
                className=" px-4 py-2 rounded-md"
                onClick={() => {
                  BE.fetchEndpointTE((Api) =>
                    Api.hp.communityEventInstance.hardDeleteEventInstance.mutate(
                      {
                        eventInstanceId: eventInfo.instanceId,
                        communitySlug: params.community,
                      }
                    )
                  )().then((er) => {
                    console.log("ER! ", er);
                    if (E.isRight(er)) {
                      nav(
                        UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.ALL.buildPath(
                          {
                            community: params.community,
                          }
                        )
                      );
                      setTimeout(() => {
                        hpState.dashboardState.showBottomToast({
                          msg: "Successfully deleted event!",
                          reload: false,
                        });
                      }, 1000);
                    }
                  });
                }}
              >
                <IoTrash />
              </button>
            </div>
          </div>
          <p>{eventInfo.description}</p>
          <p>{`Occurs at ${format(eventInfo.startTime, "P p")} ${
            eventInfo.recurrenceRule !== null &&
            eventInfo.recurrenceRule.frequency
          }`}</p>
          {eventInfo.myStatus === undefined && (
            <div className="flex gap-4">
              <div className="w-[100px]">
                <PrimaryButton
                  title="Attend"
                  onClick={() => {
                    BE.fetchEndpointTE((Api) =>
                      Api.hp.communityEvent.setStatusForAllEventInstances.mutate(
                        {
                          communitySlug: params.community,
                          eventTemplateId: eventInfo.eventTemplateId,
                          status: "attending",
                        }
                      )
                    )().then((er) => {
                      if (E.isRight(er)) {
                        hpState.dashboardState.showBottomToast({
                          msg: "Successfuly set status!",
                          reload: true,
                        });
                      }
                    });
                  }}
                />
              </div>
              <div className="w-[100px]">
                <InversePrimaryButton
                  title="Interested"
                  onClick={() => {
                    BE.fetchEndpointTE((Api) =>
                      Api.hp.communityEvent.setStatusForAllEventInstances.mutate(
                        {
                          communitySlug: params.community,
                          eventTemplateId: eventInfo.eventTemplateId,
                          status: "maybe",
                        }
                      )
                    )().then((er) => {
                      if (E.isRight(er)) {
                        hpState.dashboardState.showBottomToast({
                          msg: "Successfuly set status!",
                          reload: true,
                        });
                      }
                    });
                  }}
                />
              </div>
            </div>
          )}
          {eventInfo.myStatus && <p>{`My status: ${eventInfo.myStatus}`}</p>}
        </div>
      )
    )
  );
};

const InviteSomeoneToEventRightNav: React.FC<{
  event: FullCommunityEventInstanceData;
  communitySlug: string;
}> = ({ event, communitySlug }) => {
  const hpState = useHpState();
  const [email, setEmail] = React.useState("");
  const [rdSubmitResult, setRdSubmitResult] = React.useState<
    RD.RemoteData<any, any>
  >(RD.initial);

  return (
    <div className="flex flex-col p-8">
      <form
        className="flex flex-col gap-4"
        onSubmit={(e) => {
          e.preventDefault();
          console.log("EMAIL! ", email);
          setRdSubmitResult(RD.pending);
          BE.fetchSuccessOnlyEndpointP((Api) =>
            Api.hp.communityEvent.inviteToEvent.mutate({
              communitySlug,
              eventTemplateId: event.eventTemplateId,
              email,
            })
          ).then((r) => {
            setRdSubmitResult(RD.success(r));

            hpState.dashboardState.showBottomToast({
              msg: "Invite link sent",
              reload: true,
            });

            setTimeout(() => {
              hpState.dashboardState.closeRightNav();
            }, 1000);
          });
        }}
      >
        <TextInput value={email} onChange={(e) => setEmail(e)} label="Email" />
        {pipe(
          rdSubmitResult,
          RD.fold(
            () => <PrimaryButton type="submit" title="Invite" />,
            () => <div>Submitting...</div>,
            (er) => <div>{er}</div>,
            () => <div>Invited!</div>
          )
        )}
      </form>
    </div>
  );
};

interface Props {
  setupChatTE: () => TE.TaskEither<any, StartOrJoinChatResponse>;
  myProfile: { id: string; name: string };
}

type ChannelViewState =
  | { _tag: "LIST" }
  | { _tag: "CHANNEL"; channel: stream.Channel };

export const GroupChatFC: React.FC<Props> = ({ myProfile }) => {
  const [viewState, setViewState] = React.useState<ChannelViewState>({
    _tag: "LIST",
  });
  const [rdSetup, setChannel] = React.useState<
    RD.RemoteData<
      any,
      {
        cli: stream.StreamChat<stream.DefaultGenerics>;
        channel: stream.Channel;
      }
    >
  >(RD.initial);

  React.useEffect(() => {
    // async function setup() {
    //   const startChatRes = await setupChatTE()();
    //   if (E.isLeft(startChatRes)) {
    //     return RD.failure(startChatRes.left);
    //   }
    //   const cli = stream.StreamChat.getInstance(startChatRes.right.apiKey);
    //   const channel = startChatRes.right.channel.id
    //     ? cli.getChannelById(
    //         startChatRes.right.channel.type.id,
    //         startChatRes.right.channel.id,
    //         { members: startChatRes.right.allMemberIds }
    //       )
    //     : cli.getChannelByMembers(startChatRes.right.channel.type.id, {
    //         members: startChatRes.right.allMemberIds,
    //       });
    //   setChannel(RD.success({ cli, channel }));
    // }
    // setup().catch();
  }, []);

  if (!RD.isSuccess(rdSetup)) {
    return <div>Loading...</div>;
  }

  const onChannelListItemClick = (channel: stream.Channel) => {
    setViewState({ _tag: "CHANNEL", channel });
  };

  return (
    <Chat client={rdSetup.value.cli}>
      {Match.value(viewState).pipe(
        Match.tag("LIST", () => (
          <ChannelList
            filters={{ members: { $in: [myProfile.id] } }}
            Preview={(props) => (
              <CustomChannelPreview
                {...props}
                onChannelListItemClick={onChannelListItemClick}
              />
            )}
          />
        )),
        Match.tag("CHANNEL", (res) => (
          <Channel channel={res.channel}>
            <Window>
              <ChannelHeader />
              <MessageList />
              <MessageInput />
            </Window>
            <Thread />
          </Channel>
        )),
        Match.exhaustive
      )}
    </Chat>
  );
};

const CustomChannelPreview: React.FC<
  ChannelPreviewUIComponentProps<DefaultStreamChatGenerics> & {
    onChannelListItemClick: (channel: stream.Channel) => void;
  }
> = (props) => {
  const {
    channel,
    activeChannel,
    displayImage,
    displayTitle,
    latestMessage,
    setActiveChannel,
    onChannelListItemClick,
  } = props;
  const latestMessageAt = channel.state.last_message_at;
  const isSelected = channel.id === activeChannel?.id;
  const { userLanguage } = useTranslationContext();

  const timestamp = useMemo(() => {
    if (!latestMessageAt) {
      return "";
    }
    const formatter = new Intl.DateTimeFormat(userLanguage, {
      timeStyle: "short",
    });
    return formatter.format(latestMessageAt);
  }, [latestMessageAt, userLanguage]);

  const handleClick = () => {
    console.log("HANDLING CLICK! ", channel);
    setActiveChannel?.(channel);
    onChannelListItemClick(channel);
  };

  return (
    <button
      className={`channel-preview ${
        isSelected ? "channel-preview_selected" : ""
      } border w-full p-4 cursor-pointer`}
      // disabled={isSelected}
      onClick={handleClick}
    >
      <img className="channel-preview__avatar" src={displayImage} alt="" />
      <div className="channel-preview__main">
        <div className="channel-preview__header">
          {displayTitle}
          <time
            dateTime={latestMessageAt?.toISOString()}
            className="channel-preview__timestamp"
          >
            {timestamp}
          </time>
        </div>
        <div className="channel-preview__message">{latestMessage}</div>
      </div>
    </button>
  );
};
