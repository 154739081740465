import { FullScreenLoadingSpinner } from "@webapp/loading";
import { signOut } from "firebase/auth";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { BE } from "../../../backend";
import { DashboardLayout } from "../../../components/dashboard/dashboard-layout.fc";
import { useCpState } from "./cpdashboard.state";
import { DashboardRightDrawer } from "./right-nav";

export const CpDashboardLayout: React.FC<{}> = () => {
  const firebaseJs = useFirebaseJs();
  const cpState = useCpState();
  const location = useLocation();
  const myProfile = useObservableEagerState(cpState.myProfile$);
  const chatCli = cpState.uMgr.chatStateMgr.chatCli;

  useEffect(() => {
    BE.fetchEndpointTE((Api) => Api.cp.setLastViewedPortalAt.mutate())().then();
  }, []);

  if (!chatCli) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <DashboardLayout
      stateMgr={cpState.dashboardMgr}
      matchViewForRightNav={(rightNav) => {
        return { view: <DashboardRightDrawer rightNav={rightNav} /> };
      }}
      leftMenu={{
        onSignout: () => {
          signOut(firebaseJs.auth).then(() => {
            window.location.href = "/";
          });
        },
        onLogoClick: () => {
          window.location.href = UROUTES.CP.DASHBOARD.HOME.path;
        },
        topLinks: [
          {
            name: "Home",
            to: UROUTES.CP.DASHBOARD.HOME.path,
          },
          {
            name: "Calendar",
            to: UROUTES.CP.DASHBOARD.CALENDAR.path,
          },
          {
            name: "Settings",
            to: UROUTES.CP.DASHBOARD.SETTINGS.path,
          },
        ],
      }}
      navbar={{
        chatStateMgr: cpState.uMgr.chatStateMgr,
        currentDashboardPage: getCurrentDashboardPageFromUrl(location.pathname),
        middleSection: <></>,
        hamburger: {
          links: [],
          onSignout: () => {
            signOut(firebaseJs.auth).then(() => {
              window.location.href = "/";
            });
          },
        },
        profileButton: {
          to: UROUTES.CP.DASHBOARD.SETTINGS.path,
          profilePhoto: myProfile.profilePhoto
            ? ImageSrc.fromURL(myProfile.profilePhoto)
            : null,
        },
      }}
      mainContent={<Outlet />}
    />
  );
};

function getCurrentDashboardPageFromUrl(path: string): string {
  /*
  /cp/dashboard/settings => Settings
  /cp/dashboard/home => Home
  /cp/dashboard/portal/1 => Portal
  */

  const segments = path.split("/");
  const lastSeg = segments[segments.length - 1]; // Returns the last segment
  return lastSeg.charAt(0).toUpperCase() + lastSeg.slice(1);
}
