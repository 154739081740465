import { pipe } from "fp-ts/function";
import { useObservableState } from "observable-hooks";
import {
  Bookend,
  NotesAndBookmarks,
  SavedBookmark,
} from "shared/types/session/session.types";
import { O, RD, Rx } from "../../prelude";

export const BookmarksCard: React.FC<{
  rdNotesAndBookmarks$: Rx.Observable<RD.RemoteData<any, NotesAndBookmarks>>;
}> = ({ rdNotesAndBookmarks$ }) => {
  const rdNotesAndBookmarks = useObservableState(
    rdNotesAndBookmarks$,
    RD.initial
  );

  return pipe(
    rdNotesAndBookmarks,
    RD.toOption,
    O.fold(
      () => <div>...</div>,
      ({ bookmarks, bookends }) => {
        if (bookmarks.length === 0 && bookends.length === 0) {
          return (
            <div className="flex flex-col justify-center items-center h-[300px] gap-4 border rounded-lg p-4">
              No bookmarks saved for this session.
            </div>
          );
        }

        return (
          <div className="flex flex-col gap-4 border rounded-lg p-4">
            <div className="flex flex-col gap-2">
              {bookmarks.map((bookmark) => (
                <SavedBookmarkItem bookmark={bookmark} />
              ))}
            </div>
            <div className="flex flex-col gap-2">
              {bookends.map((bookend) => (
                <div>{bookend.label}</div>
              ))}
            </div>
          </div>
        );
      }
    )
  );
};

export const SavedBookmarkItem: React.FC<{ bookmark: SavedBookmark }> = ({
  bookmark,
}) => {
  const color = bookmark.color ?? "gray";
  return (
    <div className="flex gap-2 items-center">
      <BookmarkIcon color={color} />
      <div>{bookmark.label}</div>
      <div className="">{`(${Math.floor(
        bookmark.bookmarkedSecondsIntoSession / 60
      )
        .toString()
        .padStart(2, "0")}:${(bookmark.bookmarkedSecondsIntoSession % 60)
        .toString()
        .padStart(2, "0")})`}</div>
    </div>
  );
};

export const BookmarkIcon: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M14.0168 2.16602H5.98351C4.20851 2.16602 2.76685 3.61602 2.76685 5.38268V17.1244C2.76685 18.6243 3.84185 19.2577 5.15851 18.5327L9.22518 16.2744C9.65851 16.0327 10.3585 16.0327 10.7835 16.2744L14.8502 18.5327C16.1668 19.266 17.2418 18.6327 17.2418 17.1244V5.38268C17.2335 3.61602 15.7918 2.16602 14.0168 2.16602Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RecordedSectionItem: React.FC<{ recordedSection: Bookend }> = ({
  recordedSection,
}) => {
  // const color = recordedSection.color ?? "gray";
  const color = "red";
  return (
    <div className="flex gap-2 items-center cursor-pointer">
      <BookmarkIcon color={color} />
      <div>{`Recorded Section`}</div>
      <div>{`(${Math.floor(recordedSection.secondsIntoStart / 60)})m`}</div>
      {/* <div className="">{`(${recordedSection.book}:${recordedSection.bookmarkedInto.secondsInto})`}</div> */}
    </div>
  );
};

export const BookendItem: React.FC<{
  bookend: Bookend;
  onLabelChange: (bk: Bookend, label: string) => void;
}> = ({ bookend, onLabelChange }) => {
  return (
    <div className="flex flex-col gap-4 border p-4 rounded-lg">
      <h4>{`Minute: ${Math.floor(bookend.secondsIntoStart / 60)}`}</h4>
      <div className="flex gap-4 items-center ">
        <div
          key={bookend.label}
          className={`bg-${bookend.color}-400 w-2 h-2 rounded-full`}
        />
        <input
          type="text"
          className="text-input "
          placeholder={bookend.label ?? ""}
          onChange={(e) => {
            onLabelChange(bookend, e.target.value);
          }}
        />
      </div>
    </div>
  );
};
