import { Effect, pipe } from "effect";
import { type UserCredential } from "firebase/auth";
import type { Selectable } from "kysely";
import * as Rx from "rxjs";
import { erte, O, RD, taskEitherToEff, type TE } from "shared/base-prelude";
import type { Users } from "shared/db";
import type { ApiMgr } from "../../../api.mgr";
import {
  isAlreadyCreatedError,
  type FirebaseAuthError,
  type FirebaseAuthErrorCode,
  type FirebaseJsMgr,
} from "../../../firebase";
import type { TrpcFetchError } from "../../../trpc-cli";
import { createContextAndHook } from "../../../util";
import { BaseFormStateMgr, BaseStateMgr } from "../base.statemgr";

export class OnboardHpRegisterFlowStateMgr extends BaseStateMgr {
  emailPasswordMgr: EmailPasswordRegisterWithFallbackLoginFormStateMgr;
  onboardProfileMgr: ManuallyEnterOnboardProfileFormStateMgr;

  constructor(p: { apiMgr: ApiMgr; firebaseJsMgr: FirebaseJsMgr }) {
    super({ apiMgr: p.apiMgr });
    this.emailPasswordMgr =
      new EmailPasswordRegisterWithFallbackLoginFormStateMgr({
        apiMgr: p.apiMgr,
        firebaseJsMgr: p.firebaseJsMgr,
        mbInitialData: O.none,
      });
    this.onboardProfileMgr = new ManuallyEnterOnboardProfileFormStateMgr({
      apiMgr: p.apiMgr,
      mbInitialData: O.none,
    });
  }
}

export const [
  OnboardHpRegisterFlowStateMgrContext,
  useOnboardHpRegisterFlowStateMgr,
] = createContextAndHook<OnboardHpRegisterFlowStateMgr>();

type RegistrationError =
  | { _tag: "FirebaseCreateUserError"; error: FirebaseAuthError }
  | { _tag: "REGISTER_VIA_API_ERROR"; error: TrpcFetchError };

type SigninError = { _tag: "FirebaseSigninError"; error: FirebaseAuthError };

type RegisterOrSigninError =
  | { _tag: "REGISTRATION_ERROR"; error: RegistrationError }
  | { _tag: "AUTO_ATTEMPTED_LOGIN_ERROR"; error: SigninError };

type SuccessClick =
  | { _tag: "NEW_ACCOUNT_CREATED"; user: Selectable<Users> }
  | { _tag: "SIGNED_IN_TO_EXISTING_ACCOUNT"; user: UserCredential };

class BaseEmailPasswordMgr<
  FormData,
  SubmitResult,
  SubmitError
> extends BaseFormStateMgr<FormData, SubmitResult, SubmitError> {
  rdSendPasswordResetResponse$ = new Rx.BehaviorSubject<
    RD.RemoteData<{ code?: FirebaseAuthErrorCode }, void>
  >(RD.initial);

  firebaseJsMgr: FirebaseJsMgr;

  constructor(p: {
    apiMgr: ApiMgr;
    firebaseJsMgr: FirebaseJsMgr;
    mbInitialData: O.Option<FormData>;
    defaultData: FormData;
    onSubmit: (formData: FormData) => TE.TaskEither<SubmitError, SubmitResult>;
  }) {
    super({
      apiMgr: p.apiMgr,
      mbInitialData: p.mbInitialData,
      defaultData: p.defaultData,
      onSubmit: p.onSubmit,
    });
    this.firebaseJsMgr = p.firebaseJsMgr;
  }

  sendPasswordResetEmail = (p: {
    firebaseJs: FirebaseJsMgr;
    email: string;
  }): void => {
    this.rdSendPasswordResetResponse$.next(RD.pending);
    erte(p.firebaseJs.sendPasswordResetEmailEff(p.email))().then((er) => {
      this.rdSendPasswordResetResponse$.next(RD.fromEither(er));
    });
  };
}

export class EmailPasswordRegisterWithFallbackLoginFormStateMgr extends BaseEmailPasswordMgr<
  RegisterForm,
  SuccessClick,
  RegisterOrSigninError
> {
  constructor(p: {
    apiMgr: ApiMgr;
    mbInitialData: O.Option<RegisterForm>;
    firebaseJsMgr: FirebaseJsMgr;
  }) {
    super({
      apiMgr: p.apiMgr,
      firebaseJsMgr: p.firebaseJsMgr,
      mbInitialData: p.mbInitialData,
      defaultData: {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
      },
      onSubmit: (formData) => {
        return erte(this.performRegisterOrLoginEff(formData));
      },
    });
  }

  private registerViaApiEff(p: {
    email: string;
    firebaseUid: string;
  }): Effect.Effect<
    Selectable<Users>,
    { _tag: "REGISTER_VIA_API_ERROR"; error: TrpcFetchError },
    never
  > {
    console.log("registerViaApiEff", { ...p, primaryRole: "hp" });
    return pipe(
      taskEitherToEff(
        this.BE.publicTE(() =>
          this.BE.PublicApi().auth.register.mutate({ ...p, primaryRole: "hp" })
        )
      ),
      Effect.mapError((e) => ({ _tag: "REGISTER_VIA_API_ERROR", error: e }))
    );
  }

  performRegisterOrLoginEff = (
    data: RegisterForm
  ): Effect.Effect<SuccessClick, RegisterOrSigninError, never> => {
    return pipe(
      this.firebaseJsMgr.createUserWithEmailAndPasswordEff(data),
      Effect.matchEffect({
        onFailure: (e) => {
          if (isAlreadyCreatedError(e)) {
            return pipe(
              this.firebaseJsMgr.signinWithEmailPasswordEff(data),
              Effect.tap((r) => console.log("Succesfully logged in!", r)),
              Effect.map((r) => {
                return { _tag: "SIGNED_IN_TO_EXISTING_ACCOUNT", user: r };
              }),
              Effect.mapError((e) => ({
                _tag: "AUTO_ATTEMPTED_LOGIN_ERROR",
                error: { _tag: "FirebaseSigninError", error: e },
              }))
            ) as Effect.Effect<SuccessClick, RegisterOrSigninError, never>;
          }
          return Effect.fail({
            _tag: "REGISTRATION_ERROR",
            error: { _tag: "FirebaseCreateUserError", error: e },
          });
        },
        onSuccess: (r) => {
          return pipe(
            this.registerViaApiEff({
              email: data.email,
              firebaseUid: r.user.uid,
            }),
            Effect.mapError((e) => ({
              _tag: "REGISTRATION_ERROR",
              error: e,
            })),
            Effect.map((r) => ({ _tag: "NEW_ACCOUNT_CREATED", user: r }))
          ) as Effect.Effect<SuccessClick, RegisterOrSigninError, never>;
        },
      })
    );
  };
}

interface ManualOnboardProfileFormData {
  firstName: string;
  lastName: string;
  profilePhotoB64?: string | null;
}

export class ManuallyEnterOnboardProfileFormStateMgr extends BaseFormStateMgr<
  ManualOnboardProfileFormData,
  void
> {
  constructor(p: {
    apiMgr: ApiMgr;
    mbInitialData: O.Option<ManualOnboardProfileFormData>;
  }) {
    super({
      apiMgr: p.apiMgr,
      mbInitialData: p.mbInitialData,
      defaultData: {
        firstName: "",
        lastName: "",
        profilePhotoB64: undefined,
      },
      onSubmit: (formData) => {
        return this.BE.fetchEndpointTE((Api) =>
          Api.hp.setMyOnboardProfile.mutate({
            firstName: formData.firstName,
            lastName: formData.lastName,
          })
        );
      },
    });
  }
}

export interface EmailPasswordForm {
  email: string;
  password: string;
}

type RegisterForm = EmailPasswordForm & {
  firstName: string;
  lastName: string;
};

type PerformLoginError =
  | { _tag: "FirebaseSigninError"; error: FirebaseAuthError }
  | { _tag: "LOGIN_VIA_API_ERROR"; error: TrpcFetchError };

export class LoginEmailPasswordFormStateMgr extends BaseEmailPasswordMgr<
  EmailPasswordForm,
  any,
  PerformLoginError
> {
  constructor(p: {
    apiMgr: ApiMgr;
    firebaseJsMgr: FirebaseJsMgr;
    mbInitialData: O.Option<EmailPasswordForm>;
  }) {
    super({
      apiMgr: p.apiMgr,
      firebaseJsMgr: p.firebaseJsMgr,
      mbInitialData: O.none,
      defaultData: { email: "", password: "" },
      onSubmit: (formData) => {
        return erte(this.performLoginEff(p.firebaseJsMgr)(formData));
      },
    });
  }

  private performLoginEff =
    (firebaseJs: FirebaseJsMgr) =>
    (
      formData: EmailPasswordForm
    ): Effect.Effect<any, PerformLoginError, never> =>
      pipe(
        this.signinToFirebaseEff(firebaseJs)(formData),
        Effect.flatMap((r) => {
          return this.loginViaApiEff({ firebaseUserId: r.user.uid });
        })
      );

  private loginViaApiEff = (p: {
    firebaseUserId: string;
  }): Effect.Effect<any, PerformLoginError, never> => {
    return pipe(
      taskEitherToEff(
        this.BE.publicTE(() =>
          this.BE.PublicApi().auth.login.mutate({
            firebaseUserId: p.firebaseUserId,
          })
        )
      ),
      Effect.mapError((e) => ({ _tag: "LOGIN_VIA_API_ERROR", error: e }))
    );
  };

  private signinToFirebaseEff =
    (firebaseJs: FirebaseJsMgr) =>
    (
      formData: EmailPasswordForm
    ): Effect.Effect<any, PerformLoginError, never> => {
      return pipe(
        firebaseJs.signinWithEmailPasswordEff(formData),
        Effect.mapError((e) => ({ _tag: "FirebaseSigninError", error: e }))
      );
    };
}

export const [
  LoginEmailPasswordFormStateMgrContext,
  useLoginEmailPasswordFormStateMgr,
] = createContextAndHook<LoginEmailPasswordFormStateMgr>();
