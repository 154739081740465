import { apiMgr, BE } from "@webapp/backend";
import { ProfileImageSelector } from "@webapp/components/avatar.tc";
import { ModalitiesOfferedSelector } from "@webapp/components/modalities-selector.fc";
import { PrimaryButton } from "@webapp/components/primitives/button";
import { LoadingSpinner } from "@webapp/loading";
import { Rx, RxO } from "@webapp/prelude";
import { convertObservableToBehaviorSubject } from "@webapp/utils/utils";
import { Effect, Either, Option } from "effect";
import { StandardFormStateMgr } from "frontend-shared/src/mgrs/state-mgrs/base.statemgr";
import { type PublicApiOutput } from "frontend-shared/src/trpc-cli";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState, useObservableState } from "observable-hooks";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { RemoteData } from "shared/remote-data";
import { UROUTES } from "shared/routes/u.routes";
import { BaseUserInputData } from "shared/types/user.types";
import { useHpState } from "../hp.webstatemgr";

type ImportInfoResp = PublicApiOutput["getProfileFromOtherSite"]["info"];

type OnboardProfileInputState =
  | { _tag: "ENTER_URL"; url: string }
  | { _tag: "FILL_OUT" };

class OnboardProfileFormStateMgr extends StandardFormStateMgr<
  BaseUserInputData,
  void,
  string
> {
  readonly inputSource$ = new Rx.BehaviorSubject<OnboardProfileInputState>({
    _tag: "ENTER_URL",
    url: "",
  });

  onSubmit = (formData: BaseUserInputData) => {
    const { profilePhotoRemotePtr } = formData;
    return BE.fetchEndpointWithHandleError((Api) =>
      Api.u.me.setBaseProfile.mutate({
        firstName: formData.firstName,
        lastName: formData.lastName,
        profilePhotoRemotePtr: profilePhotoRemotePtr
          ? {
              publicId: profilePhotoRemotePtr.publicId,
              assetId: profilePhotoRemotePtr.assetId,
            }
          : null,
      })
    );
  };

  setUrl(url: string) {
    this.inputSource$.next({ _tag: "ENTER_URL", url });
  }

  setFillOut() {
    this.inputSource$.next({ _tag: "FILL_OUT" });
  }
}

export const HpOnboardSetProfilePage: React.FC = () => {
  // const [{ fromUrl, redirectTo }] = useTypedSearchParams(
  //   UROUTES.HP.ONBOARD_SET_PROFILE
  // );
  // const [rdExistingProfile, setRdExistingProfile] = useState<
  //   RD.RemoteData<any, O.Option<ImportInfoResp>>
  // >(RD.initial);

  return <ImportFetchCompletedView />;

  // return pipe(
  //   rdExistingProfile,
  //   RD.toOption,
  //   O.fold(
  //     () => <FullContainerLoadingSpinner message={"Importing data..."} />,
  //     (mbProfile) => <ImportFetchCompletedView mbProfile={mbProfile} />
  //   )
  // );
};

const ImportFetchCompletedView: React.FC<{}> = () => {
  const [{ redirectTo }] = useTypedSearchParams(UROUTES.HP.ONBOARD_SET_PROFILE);

  const formMgr = useOnce(
    () =>
      new OnboardProfileFormStateMgr({
        apiMgr: apiMgr,
        mbInitialData: Option.none(),
        defaultData: BaseUserInputData.default,
      })
  );
  const firstName = formMgr.useFormValue("firstName");
  const lastName = formMgr.useFormValue("lastName");

  const rdSubmitResult = useObservableEagerState(formMgr.submitResult$);

  const hpState = useHpState();
  const mbProfilePhoto = useObservableState(
    hpState.uMgr.profilePhoto$,
    Option.none()
  );
  const myModalities$ = useMemo(
    () =>
      convertObservableToBehaviorSubject(
        hpState.myProfile$.pipe(RxO.map((mp) => mp.modalities)),
        []
      ),
    []
  );
  const nav = useNavigate();

  return (
    <div className="flex-1 flex flex-col gap-12 justify-center items-center">
      {/* <h2 className="onboard-header">Tell us about your practice</h2> */}
      <form
        className="flex flex-col gap-4 items-center max-w-[500px]"
        onSubmit={(e) => {
          e.preventDefault();

          Effect.runPromise(formMgr.submitEff).then((er) => {
            if (Either.isRight(er)) {
              hpState.uMgr.runRefreshMe();
              if (redirectTo) {
                window.location.href = decodeURIComponent(redirectTo);
              } else {
                nav(UROUTES.HP.MY.DASHBOARD.CLIENTS.path);
              }
            }
          });
        }}
      >
        <ProfileImageSelector
          currentMbProfile={Option.getOrNull(mbProfilePhoto)}
          onImageSelectedAndTransformed={(imgInfo) => {
            formMgr.setFormValue("profilePhotoRemotePtr", imgInfo);
          }}
        />
        <div className="flex gap-4">
          <input
            name="firstName"
            placeholder="First Name"
            className="text-input"
            value={firstName}
            onChange={(e) => {
              formMgr.setFormValue("firstName", e.target.value);
            }}
            tabIndex={1}
          />
          <input
            name="lastName"
            placeholder="Last Name"
            className="text-input"
            value={lastName}
            onChange={(e) => {
              formMgr.setFormValue("lastName", e.target.value);
            }}
            tabIndex={2}
          />
        </div>
        <div className="self-stretch flex">
          <ModalitiesOfferedSelector
            myModalities$={myModalities$}
            onUnselect={(modality) => {
              hpState.removeModality(modality);
            }}
            onSelect={(modality) => {
              hpState.addModality(modality);
            }}
          />
        </div>
        {RemoteData.match(rdSubmitResult, {
          onInitial: () => <div></div>,
          onLoading: () => <LoadingSpinner />,
          onSuccess: () => <div></div>,
          onFailure: (reason) => (
            <div className="text-red-500">Failure: {reason}</div>
          ),
        })}
        <PrimaryButton type="submit" title="Next" />
      </form>
    </div>
  );
};
