import { Effect, Either } from "effect";
import { pipe } from "fp-ts/lib/function";
import * as Rx from "rxjs";
import type { SimplestUserWithProfilePhoto } from "shared";
import { O, TE } from "shared/base-prelude";
import type { CallWithCredentials } from "shared/schemas/call.schemas";
import type {
  PrivateSessionInfo,
  StartASessionFormData,
} from "shared/schemas/session/private-session.schemas";
import type { ApiMgr } from "../../api.mgr";
import type { FirebaseJsMgr } from "../../firebase";
import { FirestorePrivateSessionStateMgmt } from "../../firestore-live-session.mgmt";
import type { UApiOutput } from "../../trpc-cli";
import { BaseFormStateMgr } from "./base.statemgr";

export type StartASessionSelectClientType =
  | { _tag: "EXISTING"; client: SimplestUserWithProfilePhoto }
  | { _tag: "SELECT"; myClients: SimplestUserWithProfilePhoto[] };

type SubmitResult = UApiOutput["u"]["privateSession"]["startAdhocSession"];

export class StartASessionFormStateMgr extends BaseFormStateMgr<
  StartASessionFormData,
  { info: PrivateSessionInfo },
  string
> {
  selectedClient$: Rx.BehaviorSubject<SimplestUserWithProfilePhoto | null>;
  constructor(p: {
    apiMgr: ApiMgr;
    firebaseMgr: FirebaseJsMgr;
    forClient: StartASessionSelectClientType;
    onSuccessSubmit: (sessionId: string) => void;
  }) {
    super({
      apiMgr: p.apiMgr,
      mbInitialData: O.none,
      defaultData: {
        clientUserId: null,
        durationInMinutes: 60,
        minutesBeforeEndReminder: 10,
        sendInviteEmail: true,
        audioOnly: false,
      },
      onSubmit: (formData) => {
        return pipe(
          startSessionTE(p.apiMgr, p.firebaseMgr, formData),
          TE.chainFirst((r) =>
            TE.fromIO(() => {
              p.onSuccessSubmit(r.info.id);
            })
          )
        );
      },
    });
    this.selectedClient$ =
      new Rx.BehaviorSubject<SimplestUserWithProfilePhoto | null>(
        p.forClient._tag === "EXISTING" ? p.forClient.client : null
      );

    this.selectedClient$.subscribe((client) => {
      if (client) {
        this.setFormValue("clientUserId", client.id);
      }
    });
  }
}

function startSessionTE(
  BE: ApiMgr,
  firebaseJs: FirebaseJsMgr,
  sessionConfig: StartASessionFormData
): TE.TaskEither<string, { info: PrivateSessionInfo }> {
  console.log("SUBMITTING ", sessionConfig);

  const startSessionEff: Effect.Effect<SubmitResult> =
    BE.fetchEndpointWithHandleError((Api) =>
      Api.u.privateSession.startAdhocSession.mutate({
        formData: sessionConfig,
      })
    ).pipe(
      Effect.either,
      Effect.flatMap((r) => {
        if (Either.isRight(r)) {
          const firestoreMgmt = FirestorePrivateSessionStateMgmt(
            firebaseJs,
            r.right.info.id
          );
          return firestoreMgmt
            .createSessionStateDocEff()
            .pipe(Effect.map(() => Either.right(r.right) as SubmitResult));
        } else {
          return Effect.succeed(Either.left(r.left)) satisfies Effect.Effect<
            Either.Either<CallWithCredentials, string>
          >;
        }
      })
    );

  const te: TE.TaskEither<string, { info: PrivateSessionInfo }> = () =>
    Effect.runPromise(startSessionEff);
  return te;
}
