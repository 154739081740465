import { BE } from "@webapp/backend";
import { O } from "@webapp/prelude";
import { pipe } from "fp-ts/function";
import { CalendarStateMgr } from "frontend-shared/src/mgrs/state-mgrs/calendar.statemgr";
import { useOnce } from "frontend-shared/src/util";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { Arr } from "shared/base-prelude";
import { UROUTES } from "shared/routes/u.routes";
import {
  EventInstanceId,
  type PrivateSessionEventInstance,
} from "shared/types/calendar.types";
import { ViewAppointmentRequestForm } from "src/resources/request-private-session-apt/request-private-session-appt.form";
import { FullCalendar } from "../../../../../components/calendar/full-calendar.fc";
import { useHpState } from "../../hp.webstatemgr";

export const HpDashboardCalendarPage: React.FC = () => {
  const queryParams = useTypedSearchParams(UROUTES.HP.MY.DASHBOARD.CALENDAR);
  const hpState = useHpState();
  const [_, setSearchParams] = useSearchParams();
  const stateMgr = useOnce(
    () =>
      new CalendarStateMgr<PrivateSessionEventInstance>({
        fetchApptsTE: BE.fetchEndpointTE((Api) =>
          Api.hp.calendar.listMyAppointmentsForNearbyMonths.query({})
        ),
      })
  );

  useEffect(() => {
    if (queryParams.length > 0) {
      const [{ action, clientId, clientName, requestApptId }] = queryParams;
      console.log("queryParams", action);

      if (requestApptId) {
        hpState.dashboardState.openRightNav({
          _tag: "CUSTOM",
          topView: <div></div>,
          content: (
            <ViewAppointmentRequestForm
              onSuccess={() => {
                hpState.dashboardState.closeRightNav();
                hpState.dashboardState.showBottomToast({
                  msg: "Appointment scheduled!",
                  reload: true,
                });
                setSearchParams({});
              }}
              requestId={requestApptId}
            />
          ),
        });
        return;
      }

      switch (action) {
        case "new-appointment":
          const client = pipe(
            [clientId, clientName],
            Arr.map(O.fromNullable),
            Arr.sequence(O.option),
            O.map(([clientId, clientName]) => ({
              id: clientId,
              name: clientName,
            })),
            O.toUndefined
          );
          hpState.dashboardState.openRightNav({
            _tag: "NEW_APPOINTMENT",
            client,
            requestApptId,
          });
          break;
        case "view-appointment":
          console.log("view-appointment", requestApptId);
          hpState.dashboardState.openRightNav({
            _tag: "VIEW_APPOINTMENT",
            appointmentId: EventInstanceId.make(requestApptId!),
          });
          break;
        default:
          break;
      }
    }
  }, [queryParams]);

  return (
    <FullCalendar<PrivateSessionEventInstance>
      stateMgr={stateMgr}
      fetchOnMount
      renderEventInMonth={(appt) => (
        <div
          className="bg-vid-black-100 px-2 py-2 flex gap-2 mb-1 rounded-2xl flex justify-center items-center cursor-pointer"
          onClick={() => {
            hpState.dashboardState.openRightNav({
              _tag: "VIEW_APPOINTMENT",
              appointmentId: appt.instanceId,
            });
          }}
        >
          <div className="text-sm">{appt.privateSessionInfo.client.name}</div>
        </div>
      )}
      onDayClick={(day) => {
        console.log(day);
      }}
      newApptButton={{
        onClick: () => {
          hpState.dashboardState.openRightNav({
            _tag: "NEW_APPOINTMENT",
            client: undefined,
            requestApptId: undefined,
          });
        },
        title: "New appointment",
      }}
    />
  );
};
