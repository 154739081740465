import { HpGroupSessionCallLayout } from "@pages/u/group-sessions/[group-session-id]/calls/[callId]/layout";
import { HpGroupSessionMainRoomPage } from "@pages/u/group-sessions/[group-session-id]/calls/[callId]/main-room/main-room.page";
import { HpGroupSessionWaitingRoomPage } from "@pages/u/group-sessions/[group-session-id]/calls/[callId]/waiting-room.page";
import { GroupSessionEntryPage } from "@pages/u/group-sessions/[group-session-id]/entry.page";
import { GroupSessionLayoutPage } from "@pages/u/group-sessions/[group-session-id]/layout";
import { HpLayout } from "@pages/u/hp/hp.layout";
import { PrivateSessionReviewLayout } from "@pages/u/private-sessions/[sessionId]/review/layout";
import { PrivateSessionReviewPage } from "@pages/u/private-sessions/[sessionId]/review/review.page";
import { CpPrivateSessionCallLayout } from "@pages/u/private-sessions/[sessionId]/rtv/calls/[callId]/cp/layout";
import { CpPrivateSessionMainRoomPage } from "@pages/u/private-sessions/[sessionId]/rtv/calls/[callId]/cp/main-room.page";
import { CpPrivateSessionWaitingRoomPage } from "@pages/u/private-sessions/[sessionId]/rtv/calls/[callId]/cp/waiting-room.page";
import { HpPrivateSessionCallLayout } from "@pages/u/private-sessions/[sessionId]/rtv/calls/[callId]/hp/layout";
import { HpPrivateSessionMainRoomPage } from "@pages/u/private-sessions/[sessionId]/rtv/calls/[callId]/hp/main-room.page";
import { HpPrivateSessionWaitingRoomPage } from "@pages/u/private-sessions/[sessionId]/rtv/calls/[callId]/hp/waiting-room.page";
import { PrivateSessionCallLayout } from "@pages/u/private-sessions/[sessionId]/rtv/calls/[callId]/layout";
import { PrivateSessionEntryPage } from "@pages/u/private-sessions/[sessionId]/rtv/entry.page";
import { PrivateSessionLayoutPage } from "@pages/u/private-sessions/[sessionId]/rtv/layout";
import { ULayout } from "@pages/u/u.layout";
import { type RouteObject } from "react-router-dom";
import { HPROUTES } from "shared/routes/routes";
import {
  UGROUP_SESSIONS_ROUTES,
  UPRIVATE_SESSIONS_ROUTES,
  UROUTES,
} from "shared/routes/u.routes";
import { cpRoutes } from "./cp-route-views";
import { hpRoutes } from "./hp-route-views";

console.log("UROUTES", UROUTES.path);
console.log("HPROUTES", HPROUTES.path);

export const uRouteViews: RouteObject = {
  path: UROUTES.path,
  element: <ULayout />,
  children: [
    {
      element: <HpLayout />,
      children: hpRoutes,
    },
    cpRoutes,
    {
      element: <GroupSessionLayoutPage />,
      children: [
        {
          path: UGROUP_SESSIONS_ROUTES.GROUP_SESSION_ID.ENTRY.relativePath,
          element: <GroupSessionEntryPage />,
        },
        {
          element: <HpGroupSessionCallLayout />,
          children: [
            {
              path: UGROUP_SESSIONS_ROUTES.GROUP_SESSION_ID.CALLS.CALL_ID
                .WAITING_ROOM.relativePath,
              element: <HpGroupSessionWaitingRoomPage />,
            },
            {
              path: UGROUP_SESSIONS_ROUTES.GROUP_SESSION_ID.CALLS.CALL_ID
                .MAIN_ROOM.relativePath,
              element: <HpGroupSessionMainRoomPage />,
            },
          ],
        },
      ],
    },
    {
      element: <PrivateSessionReviewLayout />,
      children: [
        {
          path: UPRIVATE_SESSIONS_ROUTES.PRIVATE_SESSION_ID.REVIEW.relativePath,
          element: <PrivateSessionReviewPage />,
        },
      ],
    },
    {
      element: <PrivateSessionLayoutPage />,
      children: [
        {
          path: UPRIVATE_SESSIONS_ROUTES.PRIVATE_SESSION_ID.RTV.ENTRY
            .relativePath,
          element: <PrivateSessionEntryPage />,
        },
        {
          element: <PrivateSessionCallLayout />,
          children: [
            {
              element: <HpPrivateSessionCallLayout />,
              children: [
                {
                  path: UPRIVATE_SESSIONS_ROUTES.PRIVATE_SESSION_ID.RTV.CALLS
                    .CALL_ID.HP.WAITING_ROOM.relativePath,
                  element: <HpPrivateSessionWaitingRoomPage />,
                },
                {
                  path: UPRIVATE_SESSIONS_ROUTES.PRIVATE_SESSION_ID.RTV.CALLS
                    .CALL_ID.HP.MAIN_ROOM.relativePath,
                  element: <HpPrivateSessionMainRoomPage />,
                },
              ],
            },
            {
              element: <CpPrivateSessionCallLayout />,
              children: [
                {
                  path: UPRIVATE_SESSIONS_ROUTES.PRIVATE_SESSION_ID.RTV.CALLS
                    .CALL_ID.CP.WAITING_ROOM.relativePath,
                  element: <CpPrivateSessionWaitingRoomPage />,
                },
                {
                  path: UPRIVATE_SESSIONS_ROUTES.PRIVATE_SESSION_ID.RTV.CALLS
                    .CALL_ID.CP.MAIN_ROOM.relativePath,
                  element: <CpPrivateSessionMainRoomPage />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
