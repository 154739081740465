import * as Rx from "rxjs";
import type {
  BaseNewPrivateSessionFormSubmit,
  NewCpPrivateSessionFormSubmit,
  NewHpPrivateSessionFormSubmit,
  SimplestUserWithProfilePhoto,
  SimpleUserWithProfilePhoto,
} from "shared";
import { E, O } from "shared/base-prelude";
import type { ApiMgr } from "../../api.mgr";
import { BaseFormStateMgr } from "./base.statemgr";

const defaultForm: BaseNewPrivateSessionFormSubmit = {
  sessionType: "initial",
  durationInMinutes: 60,
  priceCents: null,
  startsAt: null,
  therapyTypeSlug: null,
  paymentMethod: "vidalify",
};

const defaultHpForm: NewHpPrivateSessionFormSubmit = {
  ...defaultForm,
  client: null,
};

export class HpSchedulePrivateSessionFormStateMgr extends BaseFormStateMgr<
  NewHpPrivateSessionFormSubmit,
  any,
  unknown
> {
  clientsList$ = new Rx.BehaviorSubject<SimplestUserWithProfilePhoto[]>([]);
  constructor(p: {
    defaultPrefilledData: Partial<NewHpPrivateSessionFormSubmit>;
    mbClient: { id: string; name: string } | undefined;
    apiMgr: ApiMgr;
    requestApptId: string | undefined;
    initialDate: Date | undefined;
  }) {
    const initialForm: O.Option<NewHpPrivateSessionFormSubmit> = O.some({
      ...defaultForm,
      ...p.defaultPrefilledData,
      client: p.mbClient ?? null,
      startsAt: p.initialDate ?? null,
    });
    super({
      ...p,
      mbInitialData: initialForm,
      apiMgr: p.apiMgr,
      defaultData: {
        ...defaultHpForm,
        client: p.mbClient ?? null,
      },
      onSubmit: (formData) =>
        this.BE.fetchEndpointTE((Api) =>
          Api.hp.calendar.scheduleAppointment.mutate({
            ...formData,
          })
        ),
    });

    this.fetchAndSetClientsList();
  }

  fetchAndSetClientsList() {
    this.BE.fetchEndpointTE((Api) =>
      Api.hp.clients.getAllClients.query()
    )().then((er) => {
      if (E.isRight(er)) {
        this.clientsList$.next([...er.right]);
      }
    });
  }
}

export class CpSchedulePrivateSessionFormStateMgr extends BaseFormStateMgr<
  NewCpPrivateSessionFormSubmit,
  any,
  unknown
> {
  hpList$ = new Rx.BehaviorSubject<SimpleUserWithProfilePhoto[]>([]);
  constructor(p: {
    defaultPrefilledData: Partial<NewCpPrivateSessionFormSubmit>;
    mbHp?: { id: string; name: string };
    apiMgr: ApiMgr;
    requestApptId?: string;
    initialDate?: Date;
  }) {
    const initialForm: O.Option<NewCpPrivateSessionFormSubmit> = O.some({
      ...defaultForm,
      ...p.defaultPrefilledData,
      hp: p.mbHp ?? null,
      startsAt: p.initialDate ?? null,
    });
    super({
      ...p,
      mbInitialData: initialForm,
      apiMgr: p.apiMgr,
      defaultData: {
        ...defaultHpForm,
        hp: p.mbHp ?? null,
      },
      onSubmit: (formData) =>
        this.BE.fetchEndpointTE((Api) =>
          Api.cp.schedulePrivateSession.mutate({
            ...formData,
          })
        ),
    });

    this.fetchAndSetHpList();
  }

  fetchAndSetHpList() {
    this.BE.fetchEndpointTE((Api) => Api.cp.getMyTherapists.query())().then(
      (er) => {
        if (E.isRight(er)) {
          this.hpList$.next([...er.right]);
        }
      }
    );
  }
}
