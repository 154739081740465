import { FullContainerLoadingSpinner } from "@webapp/loading";
import { Effect } from "effect";
import type { ChatStateMgr } from "frontend-shared/src/mgrs/state-mgrs/chat.statemgr";
import * as React from "react";
import { E, RD } from "shared/base-prelude";
import type { StartOrJoinChatResponse } from "shared/types/chat.types";
import * as stream from "stream-chat";
import { StreamChat } from "stream-chat";
import {
  Channel,
  ChannelHeader,
  ChannelList,
  Chat,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";

interface Props {
  setupChatEff: (p: {
    otherUserId: string;
  }) => Effect.Effect<StartOrJoinChatResponse, any>;
  chatStateMgr: ChatStateMgr<StreamChat>;
  otherUserId: string;
}

export const PrivateChatFC: React.FC<Props> = ({
  otherUserId,
  chatStateMgr,
  setupChatEff,
}) => {
  const [rdSetup, setChannel] = React.useState<
    RD.RemoteData<
      any,
      {
        cli: stream.StreamChat<stream.DefaultGenerics>;
        channel: stream.Channel;
      }
    >
  >(RD.initial);

  React.useEffect(() => {
    async function setup() {
      const startChatRes = await Effect.runPromise(
        setupChatEff({ otherUserId }).pipe(Effect.either)
      );

      if (E.isLeft(startChatRes)) {
        return RD.failure(startChatRes.left);
      }

      console.log("startChatRes! ", startChatRes);

      const cli = chatStateMgr.chatCli;

      const chatRes = startChatRes.right;

      const channel = cli.channel(chatRes.channel.type, chatRes.channel.id, {
        members: chatRes.allMemberIds,
      });

      // await channel.addMembers(
      //   chatRes.allMemberIds.map((id) => ({ user_id: id }))
      // );

      // const cs = await cli.queryChannels({
      //   type: chatRes.channel.type.id,
      //   id: chatRes.channel.id,
      // });

      // const channel = cs[0];

      const c = await channel.watch();

      console.log("CHANNEL! ", cli.user, channel.initialized);

      setChannel(RD.success({ cli, channel }));
    }
    setup().catch();
  }, []);

  if (!RD.isSuccess(rdSetup)) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <Chat client={rdSetup.value.cli}>
      {/* <ChannelList /> */}
      <Channel channel={rdSetup.value.channel}>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};
