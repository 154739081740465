import { route, string } from "react-router-typesafe-routes/dom";
import { HPROUTES } from "shared/routes/hp/hp.routes";
import { CPROUTES } from "./cp.routes";

export const USESSIONS_ROUTES = route("sessions/:sessionId", {
  params: { sessionId: string().defined() },
});

export const UGROUP_SESSIONS_ROUTES = route(
  "group-sessions",
  {},
  {
    GROUP_SESSION_ID: route(
      ":groupSessionId",
      { params: { groupSessionId: string().defined() } },
      {
        ENTRY: route("entry"),
        CALLS: route(
          "calls",
          {},
          {
            CALL_ID: route(
              ":callId",
              {
                params: { callId: string().defined() },
              },
              {
                MAIN_ROOM: route("main-room"),
                WAITING_ROOM: route("waiting-room"),
              }
            ),
          }
        ),
      }
    ),
  }
);

export const UPRIVATE_SESSIONS_ROUTES = route(
  "private-sessions",
  {},
  {
    PRIVATE_SESSION_ID: route(
      ":privateSessionId",
      {
        params: { privateSessionId: string().defined() },
      },
      {
        REVIEW: route("review"),
        RTV: route(
          "rtv",
          { searchParams: { startNew: string() } },
          {
            ENTRY: route("entry"),
            CALLS: route(
              "calls",
              {},
              {
                CALL_ID: route(
                  ":callId",
                  {
                    params: { callId: string().defined() },
                  },
                  {
                    HP: route(
                      "hp",
                      {},
                      {
                        MAIN_ROOM: route("main-room"),
                        WAITING_ROOM: route("waiting-room"),
                      }
                    ),
                    CP: route(
                      "cp",
                      {},
                      {
                        MAIN_ROOM: route("main-room"),
                        WAITING_ROOM: route("waiting-room"),
                      }
                    ),
                  }
                ),
              }
            ),
          }
        ),
      }
    ),
  }
);

export const UROUTES = route(
  "u",
  {},
  {
    CP: CPROUTES.ROOT,
    HP: HPROUTES,
    SESSIONS: USESSIONS_ROUTES,
    GROUP_SESSIONS: UGROUP_SESSIONS_ROUTES,
    PRIVATE_SESSIONS: UPRIVATE_SESSIONS_ROUTES,
  }
);
