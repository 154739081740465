import * as S from "@effect/schema/Schema";
import type { Option } from "effect";
import queryString from "query-string";
import { EventInstanceId, EventTemplateId } from "./calendar.types";

export namespace ScreenActions {
  export namespace CommunityScreen {
    export const ActionSchema = S.Struct({
      viewEventInstance: S.optional(
        S.Struct({ eventInstanceId: EventInstanceId })
      ),
      viewEventTemplate: S.optional(
        S.Struct({ eventTemplateId: EventTemplateId })
      ),
      autoJoin: S.optional(S.Union(S.Literal("true"), S.Literal("false"))),
    });

    type Action = S.Schema.Type<typeof ActionSchema>;

    export const decodeActionFromString = (action: string): Action => {
      return S.decodeUnknownSync(ActionSchema)(JSON.parse(action));
    };
  }

  export namespace CalendarScreen {
    export const ViewApptSchema = S.Struct({
      _tag: S.Literal("ViewAppt"),
      apptId: S.String,
    });
    export type ViewAppt = S.Schema.Type<typeof ViewApptSchema>;

    export const ActionSchema = S.Union(ViewApptSchema);
    export type Action = S.Schema.Type<typeof ActionSchema>;
  }

  export namespace AnonRedirect {
    export const ToHpCalendarScreenSchema = S.Struct({
      _tag: S.Literal("TO_HP_PRIVATE_CALENDAR"),
      apptId: S.String,
    });

    export const ToSessionScreenSchema = S.Struct({
      _tag: S.Literal("TO_SESSION"),
      sessionId: S.String,
    });

    export const ToGenericHpDashboardScreenSchema = S.Struct({
      _tag: S.Literal("TO_GENERIC_HP_DASHBOARD"),
      screen: S.Union(S.Literal("clients"), S.Literal("calendar")),
    });

    export const ToGenericCpDashboardScreenSchema = S.Struct({
      _tag: S.Literal("TO_GENERIC_CP_DASHBOARD"),
      screen: S.Union(S.Literal("home"), S.Literal("calendar")),
    });

    export const ToCpHomeScreenSchema = S.Struct({
      _tag: S.Literal("TO_CP_HOME"),
      acceptHpRequest: S.optional(S.String),
    });

    export const ToGroupSessionScreenSchema = S.Struct({
      _tag: S.Literal("TO_GROUP_SESSION"),
      groupSessionId: S.String,
    });

    export const ToCommunityScreenSchema = S.Struct({
      _tag: S.Literal("TO_COMMUNITY_SCREEN"),
      communitySlug: S.String,
      action: CommunityScreen.ActionSchema,
    });

    export const ToCommunityEventInstanceViewSchema = S.Struct({
      _tag: S.Literal("TO_COMMUNITY_EVENT_INSTANCE_VIEW"),
      communitySlug: S.String,
      eventInstanceId: EventInstanceId,
    });

    export const ToPrivateSessionMainRoomScreenSchema = S.Struct({
      _tag: S.Literal("TO_PRIVATE_SESSION_MAIN_ROOM"),
      roleView: S.Union(S.Literal("host"), S.Literal("client")),
      privateSessionId: S.String,
    });

    export const ActionSchema = S.Union(
      ToHpCalendarScreenSchema,
      ToSessionScreenSchema,
      ToGenericHpDashboardScreenSchema,
      ToGenericCpDashboardScreenSchema,
      ToGroupSessionScreenSchema,
      ToPrivateSessionMainRoomScreenSchema,
      ToCpHomeScreenSchema,
      ToCommunityScreenSchema,
      ToCommunityEventInstanceViewSchema
    );
    export type Action = S.Schema.Type<typeof ActionSchema>;

    export const decodeActionFromString = (action: string): Action => {
      return S.decodeUnknownSync(ActionSchema)(JSON.parse(action));
    };

    export const decodeFromURLSearchParams = (
      urlSearchParams: URLSearchParams
    ): Option.Option<Action> => {
      const parsed = queryString.parse(urlSearchParams.toString());
      return S.decodeUnknownOption(ActionSchema)(parsed);
    };
  }

  const ActionSchema = S.Union(
    AnonRedirect.ActionSchema,
    CalendarScreen.ActionSchema
  );
  export type Action = S.Schema.Type<typeof ActionSchema>;
}

export function asActionQueryParam(action: ScreenActions.Action): {
  action: string;
} {
  return { action: JSON.stringify(action) };
}

export function asActionQueryParamStr(action: ScreenActions.Action): string {
  return queryString.stringify(asActionQueryParam(action));
}
