import { Menu, Transition } from "@headlessui/react";
import {
  ParticipantView,
  useCall,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { MainRoomContainer } from "@webapp/components/live-session/main-room/main-room-container";
import { MeditationToolsPanel } from "@webapp/components/live-session/right-nav/therapy-tools/meditation.tools";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { useGroupSessionRoomCallStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/group-sessions/group-session.statemgrs";
import { useObservableEagerState } from "observable-hooks";
import { Fragment } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";
import { MainRoomGroupSpeakerViewLayout } from "./layouts/speaker-view";
import { Match } from "effect";
import type {
  GroupSessionSpecialContent,
  GroupSessionSpecificStageViewState,
} from "frontend-shared/src/types/video-state.types";
import type { GroupSessionStateModule } from "shared/session-state/session-state.types";
import { OneOnOneParticipantView } from "@webapp/componentslive-session/main-room/participant-layouts";
import {
  CustomParticipantViewUI,
  CustomVideoPlaceholder,
} from "@webapp/componentslive-session/live-session.components";

export const HpGroupSessionMainRoomPage: React.FC = () => {
  const gss = useGroupSessionRoomCallStateMgr();
  const nav = useNavigate();
  const { useLocalParticipant, useRemoteParticipants } = useCallStateHooks();
  const remoteParticipants = useRemoteParticipants();
  const me = useLocalParticipant();
  const call = useCall();

  const shareableLink = `${window.location}`;

  if (!me) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-4">
      <h4 className="text-white">Group Session</h4>
      <MainRoomContainer<
        any,
        GroupSessionSpecificStageViewState,
        GroupSessionSpecialContent,
        GroupSessionStateModule.FirebaseEncodedState,
        GroupSessionStateModule.State
      >
        mainRoomStateMgr={gss.mainRoomMgr}
        stageRenders={{
          sessionSpecificStageView: (state) => {
            const layoutView = Match.value(state).pipe(
              Match.tag("SELF_SMALL_OTHER_USER_LARGE", () => (
                <OneOnOneParticipantView
                  me={me}
                  otherParticipant={remoteParticipants[0]}
                />
              )),
              Match.tag("GALLERY", () => <MainRoomGroupSpeakerViewLayout />),
              Match.exhaustive
            );
            return layoutView;
          },
          sessionSpecificContentView: () => {
            return <></>;
          },
          meOnlyVideoView: () => (
            <div className="flex-1 flex flex-col relative">
              <ParticipantView
                participant={me}
                trackType="videoTrack"
                ParticipantViewUI={CustomParticipantViewUI}
                VideoPlaceholder={CustomVideoPlaceholder}
              />
            </div>
          ),
          bottomOfTopPreview: () => (
            <div className="flex-1 flex flex-col relative">
              <ParticipantView
                participant={me}
                trackType="videoTrack"
                ParticipantViewUI={CustomParticipantViewUI}
                VideoPlaceholder={CustomVideoPlaceholder}
              />
            </div>
          ),
        }}
        topBarLeft={{
          memberProfilePhotos: remoteParticipants
            .concat(me)
            .map((p) => p.image),
        }}
        leftControls={[]}
        settings={{
          shareableLink,
        }}
        knownTools={[
          {
            tool: "meditation",
            component: (
              <MeditationToolsPanel
                meditationMgr={gss.mainRoomMgr.tools.meditationMgr}
              />
            ),
          },
        ]}
        // rightNavDisplay={(rightPanelViewState) =>
        //   match(rightPanelViewState)
        //     .with({ state: "THERAPY_TOOLS" }, () => {
        //       return <TherapyToolMenuContainer knownTools={[]} />;
        //     })
        //     .otherwise(() => <></>)
        // }
        // stageContent={
        //   <div className="flex-1 flex flex-col justify-center min-h-0 relative">
        //     {match(currentLayout)
        //       .with({ _tag: "PAGINATED_GRID" }, () => <PaginatedGridView />)
        //       .with({ _tag: "SPEAKER" }, () => <MainRoomGroupSpeakerViewLayout />)
        //       .exhaustive()}
        //     <div className="absolute top-4 right-8">
        //       <ChangeViewLayoutButton />
        //     </div>
        //   </div>
        // }
        rdClosedCallResult$={gss.submitCallClosedResult$}
        onCloseCallButtonClick={() => {
          gss.cleanup();
          call?.leave().then((_) => {
            nav(UROUTES.HP.MY.DASHBOARD.CLIENTS.path);
          });
        }}
      />
    </div>
  );
};

const ChangeViewLayoutButton: React.FC<{}> = ({}) => {
  const gss = useGroupSessionRoomCallStateMgr();
  const currentLayout = useObservableEagerState(gss.selectedLayout$);
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="border py-1 px-2 rounded-lg text-white">
          Change View
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => {
                    gss.selectedLayout$.next({ _tag: "PAGINATED_GRID" });
                  }}
                >
                  {currentLayout._tag === "PAGINATED_GRID" && (
                    <span className="mr-2">✔</span>
                  )}
                  Gallery view
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => {
                    gss.selectedLayout$.next({ _tag: "SPEAKER" });
                  }}
                >
                  {currentLayout._tag === "SPEAKER" && (
                    <span className="mr-2">✔</span>
                  )}
                  Speaker view
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

// const SettingsButton: React.FC = () => {
//   const { useScreenShareState } = useCallStateHooks();
//   const { status: screenshareStatus, screenShare } = useScreenShareState();
//   return (
//     <Menu as="div" className="relative inline-block text-left">
//       <Menu.Button>
//         <ControlPanelComponents.RoundButtonContainer
//           onClick={() => {}}
//           bgColor="white"
//         >
//           <SettingsIcon />
//         </ControlPanelComponents.RoundButtonContainer>
//       </Menu.Button>
//       <Transition
//         as={Fragment}
//         enter="transition ease-out duration-100"
//         enterFrom="transform opacity-0 scale-95"
//         enterTo="transform opacity-100 scale-100"
//         leave="transition ease-in duration-75"
//         leaveFrom="transform opacity-100 scale-100"
//         leaveTo="transform opacity-0 scale-95"
//       >
//         <Menu.Items className="absolute right-0 bottom-16 w-56 origin-bottom-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
//           <div className="px-1 py-1 ">
//             <Menu.Item>
//               {({ active }) => (
//                 <button
//                   className={`${
//                     active ? "bg-violet-500 text-white" : "text-gray-900"
//                   } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
//                   onClick={() => {
//                     screenShare.toggle();
//                   }}
//                 >
//                   {screenshareStatus === "enabled"
//                     ? "Stop Sharing"
//                     : "Share Screen"}
//                 </button>
//               )}
//             </Menu.Item>
//           </div>
//         </Menu.Items>
//       </Transition>
//     </Menu>
//   );
// };
