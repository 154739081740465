import { HpDashbaordRightNav } from "@hppages/dashboard/right-drawer-action/right-nav";
import type { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import { apiMgr, BE } from "@webapp/backend";
import { DashboardStateMgr } from "@webapp/components/dashboard/dashboard.state";
import type { WebRtcMgr } from "@webapp/mgrs/web-rtc.mgr";
import { Rx } from "@webapp/prelude";
import { HpStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/hp.statemgr";
import { UserStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/user.statemgr";
import React from "react";
import type { HpProfile, Modality } from "shared";
import { E, O } from "shared/base-prelude";
import { ErrorTracking } from "src/error-tracking";
import { StreamChat } from "stream-chat";
import type { UWebStateMgr } from "../u.webstatemgr";

// class HpProfileMgr {
//   myProfile$: Rx.BehaviorSubject<HpProfile>;

//   constructor(
//     readonly baseMgr: UserStateMgr<StreamChat, Call, WebRtcMgr>,
//     p: { myProfile: HpProfile }
//   ) {
//     this.myProfile$ = new Rx.BehaviorSubject(p.myProfile);

//     const savedProfilePhoto$ = this.baseMgr.me$.pipe(
//       RxO.map((p) => Option.fromNullable(ImageSrc.fromMbUrl(p.profilePhoto)))
//     );

//     savedProfilePhoto$.subscribe((p) => {
//       console.log("SAVED PROFILE PHOTO! ", p);
//     });
//   }

//   refreshMyProfile = () => {
//     Effect.runPromise(this.refreshMyProfileEff).catch();
//   };

//   refreshMyProfileEff = Effect.gen(this, function* () {
//     const r = yield* BE.fetchSuccessOnlyEndpoint((Api) =>
//       Api.hp.getMyProfile.query()
//     );
//     this.myProfile$.next(r);

//     return r;
//   });

//   saveOnboardProfile = (p: { firstName: string; lastName: string }) =>
//     Effect.gen(this, function* () {
//       yield* this.baseMgr.saveMyProfile({
//         firstName: p.firstName,
//         lastName: p.lastName,
//       });
//       this.myProfile$.next({
//         ...this.myProfile$.value,
//       });
//       const saveResult = yield* BE.fetchSuccessOnlyEndpoint((Api) =>
//         Api.hp.setMyOnboardProfile.mutate(p)
//       );
//       console.log("SAVE RESULT! ", saveResult);
//       this.refreshMyProfile();
//     });

//   setMyProfile = (profile: HpProfile) => {
//     this.myProfile$.next(profile);
//   };

//   addModality = (modality: Modality) => {
//     const curProfile = this.myProfile$.value;
//     this.myProfile$.next({
//       ...curProfile,
//       modalities: [...curProfile.modalities, modality],
//     });
//     BE.fetchEndpointTE((Api) =>
//       Api.hp.settings.addModalityToMySkills.mutate({
//         modality,
//       })
//     )().then((er) => {
//       console.log("ER! ", er);
//       this.refreshMyProfile();
//     });
//   };

//   removeModality = (modality: Modality) => {
//     const curProfile = this.myProfile$.value;
//     this.myProfile$.next({
//       ...curProfile,
//       modalities: curProfile.modalities.filter((m) => m.slug !== modality.slug),
//     });
//     BE.fetchEndpointTE((Api) =>
//       Api.hp.settings.removeModalityFromMySkills.mutate({
//         modalitySlug: modality.slug,
//       })
//     )().then((er) => {
//       console.log("ER! ", er);
//       this.refreshMyProfile();
//     });
//   };
// }

export class HpWebStateMgr extends HpStateMgr<
  StreamChat,
  Call,
  StreamVideoClient,
  WebRtcMgr
> {
  dashboardState = new DashboardStateMgr<HpDashbaordRightNav>();
  myUserId: string;
  myProfile$: Rx.BehaviorSubject<HpProfile>;

  constructor(
    readonly uMgr: UWebStateMgr,
    p: { myProfile: HpProfile; myUserId: string }
  ) {
    super(uMgr, {
      myProfile: p.myProfile,
      myUserId: p.myUserId,
      apiMgr: apiMgr,
    });
    this.myUserId = uMgr.me$.value.id;
    this.myProfile$ = this.profileMgr.myProfile$;
  }

  runRefreshMyProfile = () => {
    this.profileMgr.refreshMyProfileP().catch();
  };

  checkIfIsInLiveSession = async () => {
    const isInSessionRes = await BE.fetchEndpointTE((Api) =>
      Api.hp.sessions.getIsCurrentlyInSession.query()
    )();

    if (E.isLeft(isInSessionRes)) {
      ErrorTracking.reportError({
        message: "Error checking if user is in live session",
        extra: { tags: { rawError: JSON.stringify(isInSessionRes.left) } },
      });
      return O.none;
    }
    return O.fromNullable(isInSessionRes.right);
  };

  setMyProfile = (profile: HpProfile) => {
    this.profileMgr.setMyProfile(profile);
  };

  updateMyProfile = (partialProfile: Partial<HpProfile>) => {
    this.myProfile$.next({
      ...this.myProfile$.value,
      ...partialProfile,
    });
  };

  addModality = (modality: Modality) => {
    this.profileMgr.addModality(modality);
  };

  removeModality = (modality: Modality) => {
    this.profileMgr.removeModality(modality);
  };
}

export const HpStateContext = React.createContext<HpWebStateMgr | null>(null);

export function useHpState() {
  return React.useContext(HpStateContext)!;
}
