import { useHpState } from "@pages/u/hp/hp.webstatemgr.js";
import { useUMgr } from "@pages/u/u.webstatemgr.js";
import { BE } from "@webapp/backend";
import { ModalitiesOfferedSelector } from "@webapp/componentsmodalities-selector.fc.js";
import { Rx, RxO } from "@webapp/prelude";
import {
  convertObservableToBehaviorSubject,
  useScrollToAnchor,
} from "@webapp/utils/utils";
import { Effect, Match } from "effect";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useTypedHash } from "react-router-typesafe-routes/dom";
import { UROUTES } from "shared/routes/u.routes";
import { SettingsContainer } from "../components/settings.components.js";
import { HpSettingsEditProfileForm } from "./edit-profile.form.js";

export const HpDashboardSettingsAccountPage: React.FC<{}> = ({}) => {
  useScrollToAnchor();

  return (
    <div className="flex-1 flex flex-col gap-4">
      <div className="flex gap-8">
        <Sidebar />
        <div className="flex flex-col gap-4 w-[50%]">
          <PracticeInformationView />
          {/* <VidalifySubscriptionView /> */}
          <ModalitiesView />
          <ExtraView />
          <DangerZoneView />
          {/* <InviteFriendsView /> */}
        </div>
      </div>
    </div>
  );
};

const Sidebar: React.FC = () => {
  return (
    <div className="sticky top-0 h-[400px] w-[256px] py-6 flex flex-col gap-4">
      <div>
        <SidebarTab tab={"practice-info"} />
      </div>
      {/* <div>
        <button onClick={() => handleClick("vidalify-subscription")}>
          Vidalify Subscription
        </button>
      </div> */}
      <div>
        <SidebarTab tab="modalities" />
      </div>
      <div>
        <SidebarTab tab="extra" />
      </div>
      <div>
        <SidebarTab tab="invite-friends" />
      </div>
    </div>
  );
};

type SideTabHashes =
  | "practice-info"
  | "modalities"
  | "extra"
  | "invite-friends";

function titleForTab(tab: SideTabHashes): string {
  return Match.value(tab).pipe(
    Match.when("practice-info", () => "Practice Information"),
    Match.when("modalities", () => "Modalities"),
    Match.when("extra", () => "Extra"),
    Match.when("invite-friends", () => "Invite Friends"),
    Match.exhaustive
  );
}

const SidebarTab: React.FC<{ tab: SideTabHashes }> = ({ tab }) => {
  const curHash = useTypedHash(UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT);

  return (
    <Link
      to={`${UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.buildPath(
        {},
        undefined,
        tab
      )}`}
      className="flex items-center gap-2"
    >
      <div
        className={`rounded-lg pr-4 pl-4 py-2 font-sans ${
          curHash !== undefined && curHash === tab
            ? "bg-vid-black-100"
            : "bg-white"
        }`}
      >
        {titleForTab(tab)}
      </div>
    </Link>
  );
};

const PracticeInformationView: React.FC = () => {
  return (
    <SettingsContainer id="practice-info" title="Practice Information">
      <HpSettingsEditProfileForm />
    </SettingsContainer>
  );
};

const ModalitiesView: React.FC = () => {
  const dashboardState = useHpState();
  const myModalities$ = useMemo(
    () =>
      convertObservableToBehaviorSubject(
        dashboardState.myProfile$.pipe(RxO.map((p) => p.modalities)),
        dashboardState.myProfile$.value.modalities
      ),
    []
  );
  return (
    <SettingsContainer id="modalities" title="Modalities">
      <ModalitiesOfferedSelector
        myModalities$={myModalities$}
        onSelect={(m) => {
          dashboardState.addModality(m);
        }}
        onUnselect={(m) => {
          dashboardState.removeModality(m);
        }}
      />
    </SettingsContainer>
  );
};

const ExtraView: React.FC = () => {
  return (
    <SettingsContainer id="extra" title="Extra">
      <div className="flex flex-col gap-4">
        <h4>I am also a client</h4>
        <Link to={UROUTES.CP.DASHBOARD.HOME.path} className="text-blue-500">
          Go to client portal
        </Link>
      </div>
    </SettingsContainer>
  );
};

const DangerZoneView: React.FC = () => {
  const uMgr = useUMgr();
  return (
    <SettingsContainer id="danger-zone" title="Danger Zone">
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 items-center justify-between">
          <p>Fully delete your account</p>
          <button
            className="bg-red-100 text-white p-4 rounded-md w-[200px]"
            onClick={() => {
              Effect.runPromise(
                BE.fetchSuccessOnlyEndpoint((Api) =>
                  Api.u.me.deleteMyAccount.mutate()
                ).pipe(Effect.flatMap((_) => uMgr.signOut))
              ).then((_) => {
                window.location.href = "/";
              });
            }}
          >
            Delete Account
          </button>
        </div>
      </div>
    </SettingsContainer>
  );
};

// const InviteFriendsView: React.FC = () => {
//   const hpState = useHpState();
//   return (
//     <SettingsContainer id="invite-friends" title="Invite Friends">
//       <PrimaryButton
//         title="Invite someone you know"
//         onClick={() => {
//           hpState.dashboardState.openRightNav({
//             _tag: "CUSTOM",
//             topView: (
//               <div className="text-center text-2xl">
//                 Invite someone you know
//               </div>
//             ),
//             content: <InviteFriendRightNav />,
//           });
//         }}
//       />
//     </SettingsContainer>
//   );
// };

class InviteFriendFormStateMgr {
  email$: Rx.BehaviorSubject<string> = new Rx.BehaviorSubject("");
  worksWithClients$: Rx.BehaviorSubject<boolean> = new Rx.BehaviorSubject(
    false
  );
}

// const InviteFriendRightNav: React.FC = () => {
//   const hpState = useHpState();
//   const formMgr = useOnce(() => new InviteFriendFormStateMgr());
//   const email = useObservableEagerState(formMgr.email$);
//   const worksWithClients = useObservableEagerState(formMgr.worksWithClients$);
//   return (
//     <form
//       className="flex flex-col gap-4 p-4"
//       onSubmit={(e) => {
//         e.preventDefault();
//         console.log(email, worksWithClients);

//         BE.fetchEndpointTE((Api) =>
//           Api.u.inviteAFriend.mutate({
//             email,
//             worksWithClients,
//           })
//         )().then((er) => {
//           if (E.isRight(er)) {
//             hpState.dashboardState.showBottomToast({
//               msg: "Invite sent",
//             });
//             hpState.dashboardState.closeRightNav();
//           }
//         });
//       }}
//     >
//       <div className="flex flex-col gap-2">
//         <label htmlFor="email" className="text-sm font-medium text-gray-700">
//           Email
//         </label>
//         <input
//           type="email"
//           id="email"
//           className="border border-gray-300 rounded-md p-2"
//           value={email}
//           onChange={(e) => formMgr.email$.next(e.target.value)}
//         />
//       </div>
//       <div className="flex items-center gap-2">
//         <input
//           type="checkbox"
//           id="worksWithClients"
//           className="h-4 w-4 text-blue-600 border-gray-300 rounded"
//           checked={worksWithClients}
//           onChange={(e) => formMgr.worksWithClients$.next(e.target.checked)}
//         />
//         <label
//           htmlFor="worksWithClients"
//           className="text-sm font-medium text-gray-700"
//         >
//           Works with clients
//         </label>
//       </div>
//       <PrimaryButton title="Send Invite" type="submit" />
//     </form>
//   );
// };
