import * as Rx from "rxjs";
import { E, erp, RD } from "shared/base-prelude";
import type { PrivateSessionInfo } from "shared/schemas/session/private-session.schemas";
import type { PrivateSessionStateModule } from "shared/session-state/session-state.types";
import type {
  Bookend,
  SavedBookmark,
} from "shared/types/session/session.types";
import type { ApiMgr } from "../../../../api.mgr";
import type { RemoteSessionStateSyncMgr } from "../../../../firestore-live-session.mgmt";
import { FetchSuccessAtom } from "../../../../util";
import { BaseStateMgr } from "../../base.statemgr";

export class NotesMgr extends BaseStateMgr {
  notes$ = new Rx.BehaviorSubject<string>("");

  recordSectionMgr: RecordSectionMgr;
  rdBookmarks$ = new Rx.BehaviorSubject<RD.RemoteData<any, SavedBookmark[]>>(
    RD.initial
  );

  notesAndBookmarks = new FetchSuccessAtom({
    endpt: (Api) =>
      Api.hp.sessions.session.getNotesAndBookmarks.query({
        sessionId: this.session.id,
      }),
    apiMgr: this.BE,
  });

  constructor(
    readonly remoteStateSyncMgr: RemoteSessionStateSyncMgr<
      PrivateSessionStateModule.FirebaseEncodedState,
      PrivateSessionStateModule.State
    >,
    readonly session: PrivateSessionInfo,
    readonly apiMgr: ApiMgr
  ) {
    super({ apiMgr });
    this.recordSectionMgr = new RecordSectionMgr(
      this.remoteStateSyncMgr,
      this.session,
      this.apiMgr
    );

    this.recordSectionMgr.fetchAndSetRecordedBookends().catch();
    this.notesAndBookmarks.runFetchAndSet();
  }

  async saveBookmark(p: { label?: string }) {
    return this.BE.fetchEndpointTE((Api) =>
      Api.hp.sessions.liveSession.bookmarkMoment.mutate({
        sessionId: this.session.id,
        bookmark: {
          label: p.label,
        },
      })
    )();
  }
}

class RecordSectionMgr extends BaseStateMgr {
  rdRecordedBookends$ = new Rx.BehaviorSubject<RD.RemoteData<any, Bookend[]>>(
    RD.initial
  );

  constructor(
    readonly remoteStateSyncMgr: RemoteSessionStateSyncMgr<
      PrivateSessionStateModule.FirebaseEncodedState,
      PrivateSessionStateModule.State
    >,
    readonly session: PrivateSessionInfo,
    readonly apiMgr: ApiMgr
  ) {
    super({ apiMgr });
  }

  async fetchAndSetRecordedBookends() {
    this.rdRecordedBookends$.next(RD.pending);
    const er = await this.BE.fetchEndpointTE((Api) =>
      Api.hp.sessions.session.getRecordedBookends.query({
        sessionId: this.session.id,
      })
    )();
    this.rdRecordedBookends$.next(RD.fromEither(er));
  }

  startSectionRecording = () => {
    this.BE.fetchEndpointTE((Api) =>
      Api.hp.sessions.liveSession.startBookendRecording.mutate({
        sessionId: this.session.id,
      })
    )().then((er) => {
      console.log("RESULT OF BOOKENDING AT! ", er);
      if (E.isRight(er)) {
        this.remoteStateSyncMgr.runUpdateRemoteState((_) => ({
          isRecordingSectionId: er.right.id,
        }));
      }
    });
  };

  stopSectionRecording = async () => {
    const sessionState = await erp(
      this.remoteStateSyncMgr.getCurrentRemoteState()
    );

    const isRecordingSectionId = sessionState.isRecordingSectionId!;

    const stopRes = await this.BE.fetchEndpointTE((Api) =>
      Api.hp.sessions.liveSession.stopBookendRecording.mutate({
        sessionId: this.session.id,
        id: isRecordingSectionId,
      })
    )();

    console.log("STOP RES! ", stopRes);

    // await this.remoteStateMgr.fetchAndSetRecordedBookends().catch();

    this.remoteStateSyncMgr.runUpdateRemoteState((_) => ({
      isRecordingSectionId: null,
    }));
  };

  async setBookendLabel(p: { bookendId: string; label: string }) {
    return this.BE.fetchEndpointTE((Api) =>
      Api.hp.sessions.session.setBookendLabel.mutate({
        ...p,
        sessionId: this.session.id,
      })
    )();
  }
}
