import type { StreamVideoParticipant } from "@stream-io/video-react-sdk";
import { Option } from "effect";
import type { ApiMgr } from "frontend-shared/src/api.mgr";
import type { AbstractAudioPlayer } from "frontend-shared/src/mgrs/media-player.statemgr";
import { BaseStateMgr } from "frontend-shared/src/mgrs/state-mgrs/base.statemgr";
import { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import type {
  PrivateSessionCallStateMgr,
  PrivateSessionStateMgr,
} from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/remote-state-and-call.statemgrs";
import { PrivateSessionReviewRdMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/review.statemgr";
import { PrivateSessionStageViewStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/stageview.statemgr";
import type { UApiOutput } from "frontend-shared/src/trpc-cli";
import { type PrivateSessionSpecificStageViewState } from "frontend-shared/src/types/video-state.types";
import * as Rx from "rxjs";
import { PrivateSessionStateModule } from "shared/session-state/session-state.types";
import { createContextAndHook } from "../../../../util";

export type CpPrivateSessionPingResult =
  UApiOutput["cp"]["liveSession"]["registerPing"];

type PrivateSessionSpecificContent = {};

export class CpPrivateSessionRoomCallStateMgr extends BaseStateMgr {
  sessionStateMgr: PrivateSessionStateMgr;
  callStateMgr: PrivateSessionCallStateMgr;
  mainRoomStateMgr: MainRoomStateMgr<
    CpPrivateSessionPingResult,
    PrivateSessionSpecificStageViewState<StreamVideoParticipant>,
    PrivateSessionSpecificContent,
    PrivateSessionStateModule.FirebaseEncodedState,
    PrivateSessionStateModule.State
  >;

  // notesMgr: NotesMgr;
  reviewMgr: PrivateSessionReviewRdMgr;

  constructor(p: {
    sessionMgr: PrivateSessionStateMgr;
    callStateMgr: PrivateSessionCallStateMgr;
    remoteParticipants$: Rx.Observable<StreamVideoParticipant[]>;
    apiMgr: ApiMgr;
    audioPlayer: AbstractAudioPlayer;
  }) {
    super({ apiMgr: p.apiMgr });
    this.sessionStateMgr = p.sessionMgr;
    this.callStateMgr = p.callStateMgr;
    const stageViewMgr = new PrivateSessionStageViewStateMgr(
      p.remoteParticipants$,
      this.sessionStateMgr.firestoreMgr.remoteStateSyncMgr,
      "PARTICIPANT"
    );
    this.mainRoomStateMgr = new MainRoomStateMgr<
      CpPrivateSessionPingResult,
      PrivateSessionSpecificStageViewState<StreamVideoParticipant>,
      PrivateSessionSpecificContent,
      PrivateSessionStateModule.FirebaseEncodedState,
      PrivateSessionStateModule.State
    >({
      stageViewMgr,
      sendPingEff: this.BE.fetchSuccessOnlyEndpoint((Api) =>
        Api.cp.liveSession.registerPing.mutate({
          sessionId: this.sessionStateMgr.privateSession.id,
        })
      ),
      fsSessionStateMgr: this.sessionStateMgr.firestoreMgr,
      audioPlayer: p.audioPlayer,
    });

    // this.notesMgr = new NotesMgr(
    //   this.sessionStateMgr.firestoreMgr.remoteStateSyncMgr,
    //   this.sessionStateMgr.privateSession,
    //   p.apiMgr
    // );
    this.reviewMgr = new PrivateSessionReviewRdMgr(
      { sessionId: this.sessionStateMgr.privateSession.id },
      p.apiMgr
    );

    this.mainRoomStateMgr.pingMgr.sendPing();

    this.mainRoomStateMgr.pingMgr.pingResult$.subscribe((pr) => {
      this.showNextScheduleReminderIfNecessary(pr);
    });
  }

  cleanup() {
    this.mainRoomStateMgr.cleanup();
  }

  showNextScheduleReminderIfNecessary(pr: CpPrivateSessionPingResult) {
    const { mbTimeLeftInfo } = pr;

    if (Option.isNone(mbTimeLeftInfo)) {
      return;
    }

    const timeLeftSeconds = mbTimeLeftInfo.value.timeLeftSeconds;

    if (timeLeftSeconds < 60 * 5) {
      this.sessionStateMgr.firestoreMgr.remoteStateSyncMgr.runUpdateRemoteState(
        (sessionState) =>
          !sessionState.showedNextScheduleReminderAt
            ? {
                isShowingNextScheduleReminder: true,
                showedNextScheduleReminderAt: new Date().toISOString(),
              }
            : {}
      );
    }
  }
}

export const [
  CpPrivateSessionRoomCallStateMgrContext,
  useCpPrivateSessionRoomCallStateMgr,
] = createContextAndHook<CpPrivateSessionRoomCallStateMgr>();
