import { format } from "date-fns";
import { Match, Option } from "effect";
import { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import { useObservableState } from "observable-hooks";
import React from "react";
import { StageClockDisplay } from "shared/schemas/session.schemas";
import { AvatarCircles } from "../../../avatar.tc";

interface StageTopNavProps {
  topBarLeft: {
    memberProfilePhotos: (string | null)[];
    totalMembers?: number;
  };

  mainRoomStateMgr: MainRoomStateMgr<any, any, any, any, any>;
}

export const StageTopNav: React.FC<StageTopNavProps> = ({
  topBarLeft,
  mainRoomStateMgr,
}) => {
  return (
    <div className="basis-[55px] grow-0 shrink-0 flex justify-between">
      <div className="flex-1 min-w-0 flex">
        <div className="border rounded-full flex items-center gap-2 text-white px-4">
          <AvatarCircles sources={topBarLeft.memberProfilePhotos} size={40} />
          {topBarLeft.totalMembers ? (
            <div
              style={{
                transform: `translateX(-10px)`,
              }}
            >
              {topBarLeft.totalMembers}
            </div>
          ) : (
            <div className="text-white">{format(new Date(), "MMM do")}</div>
          )}
        </div>
      </div>
      <ClocksSection mainRoomStateMgr={mainRoomStateMgr} />
    </div>
  );
};

const ClocksSection: React.FC<{
  mainRoomStateMgr: MainRoomStateMgr<any, any, any, any, any>;
}> = ({ mainRoomStateMgr }) => {
  const clockDisplay = useObservableState(
    mainRoomStateMgr.clockDisplay$,
    StageClockDisplay.default
  );
  const { mbSpecialClock, regularClock } = clockDisplay;
  return (
    <div className="flex gap-4">
      {Option.isSome(mbSpecialClock) && (
        <CountdownClock
          timeLeftSeconds={mbSpecialClock.value.secondsLeft}
          message={mbSpecialClock.value.message ?? undefined}
          onClick={() => {
            if (mbSpecialClock.value.description === "meditation") {
              mainRoomStateMgr.openPanel({
                state: "THERAPY_TOOLS",
                initialViewState: {
                  _tag: "TOOL",
                  tool: "meditation",
                },
              });
            }
          }}
          backgroundColor="vid-purple"
          showSeconds={true}
        />
      )}
      {Match.value(regularClock).pipe(
        Match.tag("ENDING_SOON_REMINDER", ({ message }) => (
          <CountdownClock
            timeLeftSeconds={null}
            message={message}
            onClick={() => {}}
            backgroundColor="vid-purple"
          />
        )),
        Match.tag("REGULAR", ({ minutesLeft }) => (
          <CountdownClock
            timeLeftSeconds={minutesLeft ? minutesLeft * 60 : null}
            onClick={() => {}}
          />
        )),
        Match.exhaustive
      )}
    </div>
  );
};

const CountdownClock: React.FC<{
  timeLeftSeconds: number | null;
  onClick?: () => void;
  backgroundColor?: string;
  showSeconds?: boolean;
  message?: string;
}> = ({ timeLeftSeconds, onClick, backgroundColor, showSeconds, message }) => {
  return (
    <div
      onClick={onClick}
      className={`border px-5 py-2 rounded-full text-white flex items-center gap-4 text-base cursor-pointer ${
        backgroundColor ? `bg-${backgroundColor}` : ""
      }`}
    >
      <img src="/alarm.svg" alt="Alarm icon" width={20} height={20} />
      {message ? (
        <div>{message}</div>
      ) : (
        timeLeftSeconds !== null &&
        (showSeconds
          ? format(new Date(timeLeftSeconds * 1000), "m:ss")
          : `${Math.floor(timeLeftSeconds / 60)}`)
      )}
    </div>
  );
};
