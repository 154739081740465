import { Effect, Either } from "effect";
import * as Rx from "rxjs";
import { isDoNothing } from "shared/types/response.types";
import type { ApiMgr, AuthedApi } from "./api.mgr";
import { BaseStateMgr } from "./mgrs/state-mgrs/base.statemgr";

export interface LoadingState {
  _tag: "LoadingState";
}

interface FailureState<E extends Error> {
  _tag: "FailureState";
  error: E;
}
interface SuccessState<V> {
  _tag: "SuccessState";
  data: V;
}

export function isLoadingState(
  state: FetchState<any, any>
): state is LoadingState {
  return state._tag === "LoadingState";
}

export function isFailureState<E extends Error>(
  state: FetchState<any, E>
): state is FailureState<E> {
  return state._tag === "FailureState";
}

export type NoErrorFetchState<V> = LoadingState | SuccessState<V>;

export type FetchState<V, E extends Error = never> =
  | NoErrorFetchState<V>
  | FailureState<E>;

export class FetchWithHandleErrorStateMgr<
  V,
  E extends Error
> extends BaseStateMgr {
  fetchState$ = new Rx.BehaviorSubject<FetchState<V, E>>({
    _tag: "LoadingState",
  });

  constructor(
    apiMgr: ApiMgr,
    readonly trpcquery: (api: AuthedApi) => Promise<Either.Either<V, E>>,
    options: {
      autoFetchAndSetState: boolean;
    }
  ) {
    super({ apiMgr });

    if (options.autoFetchAndSetState) {
      this.fetchAndSetState();
    }
  }

  fetchAndSetState() {
    this.fetchState$.next({ _tag: "LoadingState" });

    Effect.runPromise(
      this.BE.fetchEndpointWithHandleError(this.trpcquery).pipe(Effect.either)
    ).then((er) => {
      Either.match(er, {
        onLeft: (e) => {
          this.fetchState$.next({
            _tag: "FailureState",
            error: e,
          });
        },
        onRight: (r) => {
          this.fetchState$.next({
            _tag: "SuccessState",
            data: r,
          });
        },
      });
    });
  }
}

export class FetchSuccessStateMgr<V> extends BaseStateMgr {
  fetchState$ = new Rx.BehaviorSubject<NoErrorFetchState<V>>({
    _tag: "LoadingState",
  });

  constructor(
    apiMgr: ApiMgr,
    readonly trpcquery: (api: AuthedApi) => Promise<V>,
    options: {
      autoFetchAndSetState: boolean;
    }
  ) {
    super({ apiMgr });

    if (options.autoFetchAndSetState) {
      this.fetchAndSetState();
    }
  }

  fetchAndSetState(setLoading?: boolean) {
    if (setLoading) {
      this.fetchState$.next({ _tag: "LoadingState" });
    }

    Effect.runPromise(this.BE.fetchSuccessOnlyEndpoint(this.trpcquery)).then(
      (r) => {
        if (isDoNothing(r)) {
          return;
        }
        this.fetchState$.next({
          _tag: "SuccessState",
          data: r,
        });
      }
    );
  }
}
