import { Effect, Option } from "effect";
import { pipe } from "fp-ts/lib/function";
import {
  createContextAndHook,
  FetchSuccessAtom,
} from "frontend-shared/src/util";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { RD, TE } from "shared/base-prelude";
import {
  CommunityPostReactions,
  CommunityPostWithReactionsDto,
  type CommunityInfo,
  type CommunityPostReplyInfo,
} from "shared/schemas/community/community.schemas";
import type { NearbyCalendarMonths } from "shared/types/calendar.types";
import type { FullCommunityEventInstanceData } from "shared/types/community-event.types";
import { CalendarUtils } from "shared/utils/calendar.utils";
import type { ApiMgr } from "../../api.mgr";
import { BaseStateMgr } from "./base.statemgr";
import { CalendarStateMgr } from "./calendar.statemgr";

export type CommunityPageTab = "Discussion" | "Learning" | "Members" | "Events";

const ALL_KNOWN_COLORS = [
  "purple",
  "green",
  "yellow",
  "red",
  "lightBlue",
  "orange",
];
type KnownColor = (typeof ALL_KNOWN_COLORS)[number];

export class CommunityStateMgr extends BaseStateMgr {
  community$: Rx.BehaviorSubject<CommunityInfo>;
  communitySlug: string;

  recentPosts = new FetchSuccessAtom({
    endpt: (Api) =>
      Api.hp.community.listRecentPosts.query({
        communitySlug: this.community$.value.slug,
      }),
    apiMgr: this.BE,
  });

  allEvents = new FetchSuccessAtom({
    endpt: (Api) =>
      Api.hp.community.getAllRelevantEvents.query({
        communitySlug: this.community$.value.slug,
        moy: CalendarUtils.getCurrentMOY(),
      }),
    apiMgr: this.BE,
  });

  allMembers = new FetchSuccessAtom({
    endpt: (Api) =>
      Api.hp.community.listAllMembers.query({
        communitySlug: this.community$.value.slug,
      }),
    apiMgr: this.BE,
  });

  myCommunityProfile = new FetchSuccessAtom({
    endpt: (Api) =>
      Api.hp.community.getMyCommunityProfile.query({
        communitySlug: this.community$.value.slug,
      }),
    apiMgr: this.BE,
  });

  isJoiningOrUnjoining$ = new Rx.BehaviorSubject<boolean>(false);

  calendarMgr: CalendarStateMgr<FullCommunityEventInstanceData>;

  constructor(community: CommunityInfo, apiMgr: ApiMgr) {
    super({ apiMgr });
    this.community$ = new Rx.BehaviorSubject<CommunityInfo>(community);
    this.communitySlug = community.slug;

    const rdAppts$ = new Rx.BehaviorSubject<
      RD.RemoteData<
        unknown,
        NearbyCalendarMonths<FullCommunityEventInstanceData>
      >
    >(RD.initial);

    this.allEvents.value$.subscribe((rae) => {
      console.log("RAE", rae);
      rdAppts$.next(
        pipe(
          rae,
          RD.map((ae) => ae.nearbyMonthEvents)
        )
      );
    });

    this.calendarMgr = new CalendarStateMgr({
      fetchApptsTE: pipe(
        apiMgr.fetchEndpointTE((Api) =>
          Api.hp.community.getAllRelevantEvents.query({
            communitySlug: this.community$.value.slug,
            moy: CalendarUtils.getCurrentMOY(),
          })
        ),
        TE.map((r) => r.nearbyMonthEvents)
      ),
      rdAppts$,
    });

    this.runFetchAndSetAll();
  }

  runFetchAndSetAll() {
    Effect.runPromise(this.fetchAndSetAll).catch((err) => {
      console.error("FETCH AND SET ALL ERROR! ", err);
    });
  }

  fetchAndSetAll = Effect.all(
    [
      this.recentPosts.fetchAndSetEff(),
      this.allMembers.fetchAndSetEff(),
      this.myCommunityProfile.fetchAndSetEff(),
      this.allEvents.fetchAndSetEff(),
    ],
    { concurrency: "unbounded" }
  );

  fetchAndSetCommunity = this.BE.fetchSuccessOnlyEndpoint((Api) =>
    Api.hp.community.getCommunity.query({
      communitySlug: this.communitySlug,
    })
  ).pipe(Effect.tap((c) => this.community$.next(c)));

  joinCommunity(onSuccess: () => void) {
    this.isJoiningOrUnjoining$.next(true);
    this.BE.fetchSuccessOnlyEndpointP((Api) =>
      Api.hp.community.joinCommunity.mutate({
        communitySlug: this.community$.value.slug,
      })
    ).then((r) => {
      console.log("JOIN COMMUNITY RESULT: ", r);
      this.isJoiningOrUnjoining$.next(false);
      this.runReloadAll();
      onSuccess();
    });
  }

  unjoinCommunity(onSuccess: () => void) {
    this.isJoiningOrUnjoining$.next(true);
    this.BE.fetchSuccessOnlyEndpointP((Api) =>
      Api.hp.community.unjoinCommunity.mutate({
        communitySlug: this.community$.value.slug,
      })
    ).then(() => {
      this.isJoiningOrUnjoining$.next(false);
      this.runReloadAll();
      onSuccess();
    });
  }

  hardDeleteCommunity(onSuccess: () => void) {
    this.BE.fetchSuccessOnlyEndpointP((Api) =>
      Api.hp.community.hardDeleteCommunity.mutate({
        communitySlug: this.community$.value.slug,
      })
    ).then(() => {
      onSuccess();
    });
  }

  reloadAll = Effect.all([this.fetchAndSetAll, this.fetchAndSetCommunity], {
    concurrency: "unbounded",
  });

  runReloadAll() {
    Effect.runPromise(this.reloadAll).catch((err) => {
      console.error("RELOAD ALL ERROR! ", err);
    });
  }

  setIsAvailableForPractice(v: boolean) {
    this.BE.fetchEndpointTE((Api) =>
      Api.hp.community.setIsAvailableForPractice.mutate({
        communitySlug: this.community$.value.slug,
        isAvailable: v,
      })
    )().then((er) => {
      console.log("RESULT OF MAKING AVAILBLE! ", er);
    });
  }

  static randomColor(idx: number) {
    return CommunityStateMgr.communityColorByName(
      ALL_KNOWN_COLORS[idx % ALL_KNOWN_COLORS.length]
    );
  }

  static communityColorByName(colorName: string) {
    switch (colorName as KnownColor) {
      case "purple":
        return "#690DFF";
      case "green":
        return "#23CE24";
      case "yellow":
        return "#FFCF26";
      case "red":
        return "#FFA8A4";
      case "lightBlue":
        return "#53CEDF";
      case "orange":
        return "#FF891D";
      default:
        return "#690DFF";
    }
  }
}

export const [CommunitySamContext, useCommunitySam] =
  createContextAndHook<CommunityStateMgr>("CommunitySam");

export class CommunityPostStateMgr extends BaseStateMgr {
  localPostState$: Rx.BehaviorSubject<CommunityPostWithReactionsDto>;
  myReplyState$: Rx.BehaviorSubject<Option.Option<ReplyStateMgr>>;
  constructor(
    readonly p: {
      community: { slug: string; allMembers: { id: string; name: string }[] };
      post: CommunityPostWithReactionsDto;
      apiMgr: ApiMgr;
      onSuccessReply: (p: { reply: CommunityPostReplyInfo }) => void;
    }
  ) {
    super({ apiMgr: p.apiMgr });
    this.localPostState$ =
      new Rx.BehaviorSubject<CommunityPostWithReactionsDto>(
        CommunityPostWithReactionsDto.make(
          {
            ...p.post,
            reactions: CommunityPostReactions.make(p.post.reactions, {
              disableValidation: true,
            }),
          },
          { disableValidation: true }
        )
      );
    this.myReplyState$ = new Rx.BehaviorSubject<Option.Option<ReplyStateMgr>>(
      Option.none()
    );
  }

  private mkNewReplyStateMgr = () => {
    return new ReplyStateMgr({
      communitySlug: this.p.community.slug,
      allCommunityMembers: this.p.community.allMembers,
      postId: this.p.post.id,
      apiMgr: this.BE,
      onSuccessReply: (r) => {
        this.runFetchAndSetLocalState(() => {
          this.myReplyState$.next(Option.none());
        });
        this.p.onSuccessReply(r);
      },
    });
  };

  onCommentReplyButtonClick = () => {
    this.myReplyState$.next(Option.some(this.mkNewReplyStateMgr()));
  };

  runFetchAndSetLocalState = (onSuccess: () => void) => {
    Effect.runPromise(this.fetchAndSetLocalStateEff).then(() => {
      onSuccess();
    });
  };

  fetchAndSetLocalStateEff = Effect.gen(this, function* () {
    const post = yield* this.BE.fetchSuccessOnlyEndpoint((Api) =>
      Api.hp.community.listRecentPosts.query({
        communitySlug: this.p.community.slug,
      })
    ).pipe(
      Effect.map((rps) => rps.posts.find((p) => p.id === this.p.post.id)!)
    );
    this.localPostState$.next(post);
  });

  setLocalLikesState(action: "like" | "unlike") {
    this.localPostState$.next(
      this.localPostState$.value.withNewLike(action === "like")
    );
  }

  likePost(action: "like" | "unlike") {
    this.setLocalLikesState(action);
    this.BE.fetchSuccessOnlyEndpointP((Api) =>
      Api.hp.community.toggleLikePost.mutate({
        communitySlug: this.p.community.slug,
        postId: this.p.post.id,
        isLiked: action === "like" ? true : false,
      })
    ).then((_) => {
      // this.setLocalLikesState(action);
    });
  }

  hardDeletePost() {
    this.BE.fetchSuccessOnlyEndpointP((Api) =>
      Api.hp.community.hardDeletePost.mutate({
        communitySlug: this.p.community.slug,
        postId: this.p.post.id,
      })
    ).then((_) => {
      // this.setLocalLikesState(action);
    });
  }
}

export class ReplyStateMgr extends BaseStateMgr {
  myReplyText$ = new Rx.BehaviorSubject<string>("");
  rdSubmitResult$ = new Rx.BehaviorSubject<RD.RemoteData<any, any>>(RD.initial);

  atMentionQuery$ = new Rx.BehaviorSubject<Option.Option<string>>(
    Option.none()
  );

  mbMatchingMentions$ = this.atMentionQuery$.pipe(
    RxO.map((mbq) => {
      return mbq.pipe(
        Option.map((q) => {
          if (q.length < 2) {
            return this.p.allCommunityMembers.slice(0, 5);
          }
          return this.p.allCommunityMembers
            .filter((m) => m.name.toLowerCase().includes(q.toLowerCase()))
            .slice(0, 5);
        })
      );
    })
  );

  constructor(
    readonly p: {
      communitySlug: string;
      allCommunityMembers: { id: string; name: string }[];
      postId: string;
      apiMgr: ApiMgr;
      onSuccessReply: (p: { reply: CommunityPostReplyInfo }) => void;
    }
  ) {
    super({ apiMgr: p.apiMgr });
  }

  onInputChange(v: string) {
    this.myReplyText$.next(v);

    const lastWord = v.split(" ").pop();
    if (lastWord && lastWord.startsWith("@")) {
      this.atMentionQuery$.next(Option.some(lastWord.slice(1)));
    } else {
      this.atMentionQuery$.next(Option.none());
    }
  }

  onAddMention(mid: string) {
    const matchingMember = this.p.allCommunityMembers.find(
      (mm) => mm.id === mid
    );
    console.log("MATCHING MEMBER! ", matchingMember);
    this.atMentionQuery$.next(Option.none());
  }

  submitReply() {
    if (this.myReplyText$.value.trim().length < 2) {
      return;
    }
    this.rdSubmitResult$.next(RD.pending);
    this.BE.fetchSuccessOnlyEndpointP((Api) =>
      Api.hp.community.replyToPost.mutate({
        communitySlug: this.p.communitySlug,
        postId: this.p.postId,
        reply: this.myReplyText$.value,
      })
    ).then((r) => {
      this.p.onSuccessReply(r);
      this.rdSubmitResult$.next(RD.success(r));
    });
  }
}

export const [CommunityPostSamContext, useCommunityPostSam] =
  createContextAndHook<CommunityPostStateMgr>("CommunityPostSam");
