import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  getDate,
  getMonth,
  getYear,
  startOfMonth,
  subMonths,
} from "date-fns";
import * as Arr from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import * as NE from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";
import * as Rec from "fp-ts/Record";
import {
  OneIndexedMonth,
  ZeroIndexedMonth,
  type DayOfYear,
  type MonthOfYear,
  type NearbyCalendarMonths,
} from "../types/calendar.types";

export namespace CalendarUtils {
  export function getFirstAndLastDaysOfMonth(moy: MonthOfYear): {
    firstDay: Date;
    lastDay: Date;
  } {
    const randomDay = randomDayOfMonth({
      monthIdx: ZeroIndexedMonth(moy.oiMonthIdx - 1),
      year: moy.year,
    });
    const firstDay = startOfMonth(randomDay);
    const lastDay = endOfMonth(randomDay);
    return { firstDay, lastDay };
  }

  export function randomDayOfMonth(p: {
    monthIdx: ZeroIndexedMonth;
    year: number;
  }) {
    const daysForMonth = getDaysForMonth(p);
    const randomDayOfMonth =
      daysForMonth[Math.floor(Math.random() * daysForMonth.length)];
    return randomDayOfMonth;
  }

  export function getCurrentMOY(): MonthOfYear {
    return {
      oiMonthIdx: OneIndexedMonth(getMonth(new Date()) + 1),
      year: getYear(new Date()),
    };
  }

  export function getDaysForMonth(p: {
    monthIdx: ZeroIndexedMonth;
    year?: number;
  }): Date[] {
    const year = p.year ?? new Date().getFullYear();
    const month = startOfMonth(new Date(year, p.monthIdx));
    return eachDayOfInterval({
      start: month,
      end: endOfMonth(month),
    });
  }

  export function doyToDate(doy: DayOfYear): Date {
    return new Date(doy.year, doy.oneIndexedMonth - 1, doy.day);
  }

  export function asDOY(d: Date): DayOfYear {
    const month = getMonth(d);
    const year = getYear(d);
    const day = getDate(d);
    return { year, oneIndexedMonth: OneIndexedMonth(month + 1), day };
  }

  export function getNearbyMonths(moy: MonthOfYear): {
    thisMonth: MonthOfYear;
    prevMonth: MonthOfYear;
    nextMonth: MonthOfYear;
  } {
    const { oiMonthIdx, year } = moy;
    const thisMonth = { oiMonthIdx, year };
    const dayOfThisMonth = startOfMonth(new Date(year, oiMonthIdx - 1));
    const dayOfNextMonth = addMonths(dayOfThisMonth, 1);
    const dayOfPrevMonth = subMonths(dayOfThisMonth, 1);
    const nextYear = getYear(dayOfNextMonth);
    const prevYear = getYear(dayOfPrevMonth);
    const nextMonth = {
      oiMonthIdx: OneIndexedMonth(dayOfNextMonth.getMonth() + 1),
      year: nextYear,
    };
    const prevMonth = {
      oiMonthIdx: OneIndexedMonth(dayOfPrevMonth.getMonth() + 1),
      year: prevYear,
    };
    return { thisMonth, prevMonth, nextMonth };
  }

  export function groupEventsByMonth<T extends { startTime: Date }>(p: {
    events: T[];
  }): { moy: MonthOfYear; events: T[] }[] {
    return pipe(
      p.events,
      NE.fromArray,
      O.fold(
        () => [],
        (events) =>
          pipe(
            events,
            NE.groupBy((event) => {
              const { year, oneIndexedMonth } = asDOY(event.startTime);
              return `${year}-${oneIndexedMonth}`;
            }),
            Rec.map((events) => events as T[]),
            Rec.toArray,
            Arr.map(([key, events]) => {
              const [year, month] = key.split("-").map(Number);
              return {
                moy: { year, oiMonthIdx: month as OneIndexedMonth },
                events,
              };
            })
          )
      )
    );
  }

  export function groupEventsByDay<T extends { startTime: Date }>(p: {
    events: T[];
  }): { doy: DayOfYear; events: T[] }[] {
    return pipe(
      p.events,
      NE.fromArray,
      O.fold(
        () => [],
        (events) =>
          pipe(
            events,
            NE.groupBy((event) => {
              const doy = asDOY(event.startTime);
              return `${doy.year}-${doy.oneIndexedMonth}-${doy.day}`;
            }),
            Rec.map((events) => events as T[]),
            Rec.toArray,
            Arr.map(([key, events]) => {
              const [year, oim, day] = key.split("-").map(Number);
              const oneIndexedMonth = OneIndexedMonth(oim);
              return {
                doy: { year, oneIndexedMonth, day },
                events,
              };
            })
          )
      )
    );
  }

  export function asNearbyCalendarMonths<T extends { startTime: Date }>(p: {
    moy: MonthOfYear;
    events: T[];
  }): NearbyCalendarMonths<T> {
    const nearbyMonths = getNearbyMonths(p.moy);
    const groupedByMonth = groupEventsByMonth({ events: p.events }).map((r) => {
      const eventsByDayForMonth = groupEventsByDay({ events: r.events });
      return {
        moy: r.moy,
        eventsByDay: eventsByDayForMonth,
      };
    });

    const thisMonth = groupedByMonth
      .filter((r) => r.moy.oiMonthIdx === p.moy.oiMonthIdx)
      .flatMap((r) => r.eventsByDay);
    const nextMonth = groupedByMonth
      .filter((r) => r.moy.oiMonthIdx === nearbyMonths.nextMonth.oiMonthIdx)
      .flatMap((r) => r.eventsByDay);
    const prevMonth = groupedByMonth
      .filter((r) => r.moy.oiMonthIdx === nearbyMonths.prevMonth.oiMonthIdx)
      .flatMap((r) => r.eventsByDay);

    return {
      thisMonth,
      nextMonth,
      prevMonth,
    };
  }
  {
  }
}

interface BasicCalendarEventInfo {
  title: string;
  start: Date;
  end: Date;

  description?: string;
}

export class CalendarLinks {
  static getGoogleCalendarLink = (p: BasicCalendarEventInfo) => {
    const startDate = encodeURIComponent(
      p.start.toISOString().replace(/-|:|\.\d\d\d/g, "")
    );
    const endDate = encodeURIComponent(
      p.end.toISOString().replace(/-|:|\.\d\d\d/g, "")
    );
    const title = encodeURIComponent(p.title);
    const description = p.description ? encodeURIComponent(p.description) : "";

    const baseUrl = "https://www.google.com/calendar/render";
    const action = "TEMPLATE";

    const queryParams = [
      `action=${action}`,
      `text=${title}`,
      `dates=${startDate}/${endDate}`,
      `details=${description}`,
    ].join("&");

    return `${baseUrl}?${queryParams}`;
  };
}
