import { ImageSrc, type SimpleUserDTO } from "shared/types/miscellaneous.types";
import { AvatarCircle } from "./avatar.tc";
import { AppButton } from "./primitives/button";

export const InfoHeader: React.FC<{
  title?: string;
  rightHeader?: React.ReactNode;
}> = ({ title, rightHeader }) => {
  return (
    <div
      className={`
      bg-vid-black-100 py-4 px-6
        rounded-tr-[12px] rounded-tl-[12px]
        flex justify-between items-center
    `}
    >
      <h3 className="font-sans font-semibold text-sm text-vid-black-900">
        {title}
      </h3>
      {rightHeader}
    </div>
  );
};

export const ListContainer: React.FC<{
  rows: React.ReactNode[];
  title?: string;
  emptyMessage?: string;
  rightHeader?: React.ReactNode;
}> = ({ title, rows, emptyMessage, rightHeader }) => {
  return (
    <div className="flex flex-col border rounded-[12px] rounded-[12px]">
      <InfoHeader title={title} rightHeader={rightHeader} />
      <div className="flex flex-col">
        {rows.length === 0 ? (
          <div className="text-vid-black-900 font-medium text-center p-12">
            {emptyMessage ?? "Empty"}
          </div>
        ) : (
          rows.map((row, key) => (
            <div
              key={key}
              className="border-b border-vid-black-200 h-[140px] flex items-center px-2 py-4 lg:px-7 lg:py-8"
            >
              {row}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

/*
 Common List Rows
*/
const UserWithActionRow: React.FC<{
  user: SimpleUserDTO;
  action?: {
    title: string;
    onClick: () => void;
    invertedStyle?: boolean;
    maxWidthPixels?: number;
  };
}> = ({ user, action }) => {
  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex items-center gap-4">
        <AvatarCircle
          mbProfilePhoto={user.image ? ImageSrc.fromURL(user.image) : null}
          size={72}
        />
        <div>{user.name}</div>
      </div>
      {action && (
        <AppButton
          onClick={action.onClick}
          title={action.title}
          isInvertedStyle={action.invertedStyle}
          maxWidthPixels={action.maxWidthPixels}
        />
      )}
    </div>
  );
};

export const CommonListRows = {
  UserWithActionRow,
};
