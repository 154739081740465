import { apiMgr, BE } from "@webapp/backend";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { Match } from "effect";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import {
  GroupSessionRemoteStateMgr,
  GroupSessionStateMgrContext,
} from "frontend-shared/src/mgrs/state-mgrs/sessions/group-sessions/group-session.statemgrs";
import { useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { UROUTES } from "shared/routes/u.routes";
import type { UserEnteredGroupSessionResult } from "shared/schemas/group-session.schemas";

export const GroupSessionLayoutPage: React.FC = ({}) => {
  const nav = useNavigate();
  const { groupSessionId } = useTypedParams(
    UROUTES.GROUP_SESSIONS.GROUP_SESSION_ID.ENTRY
  );

  const userEnteredResult = BE.useSuccessFetch(
    (Api) =>
      Api.u.groupSession.registerUserEnteredGroupSession.mutate({
        groupSessionId,
      }),
    [groupSessionId]
  );

  useEffect(() => {
    if (userEnteredResult._tag === "SuccessState") {
      const action =
        userEnteredResult.data.stateAndNextAction.nextAction.action;

      Match.value(action).pipe(
        Match.tag("EnterRoom", ({ call }) => {
          console.log("ENTER ROOM! ", call);

          nav(
            UROUTES.GROUP_SESSIONS.GROUP_SESSION_ID.CALLS.CALL_ID.WAITING_ROOM.buildPath(
              {
                callId: call.callInfo.callId,
                groupSessionId,
              }
            )
          );
        }),
        Match.orElse(() => {
          //TODO
        })
      );
    }
  }, [userEnteredResult]);

  return Match.value(userEnteredResult).pipe(
    Match.tag("LoadingState", () => <FullContainerLoadingSpinner />),
    Match.tag("SuccessState", ({ data }) => {
      return <LoadedView result={data} />;
    }),
    Match.exhaustive
  );
};

const LoadedView: React.FC<{
  result: UserEnteredGroupSessionResult;
}> = ({ result }) => {
  const firebaseJsMgr = useFirebaseJs();

  const groupSessionStateMgr = useMemo(
    () =>
      new GroupSessionRemoteStateMgr(
        result.groupSessionInfo,
        firebaseJsMgr,
        apiMgr
      ),
    [result.groupSessionInfo, firebaseJsMgr, apiMgr]
  );

  return (
    <GroupSessionStateMgrContext.Provider value={groupSessionStateMgr}>
      <Outlet />
    </GroupSessionStateMgrContext.Provider>
  );
};
