import { type RouteObject } from "react-router-dom";
import { ANON_ROUTES, ROUTES } from "shared/routes/routes";
import { IndexLayout } from "../index.layout";
import { AnonConfirmEmailInviteRedirectPage } from "../pages/anon/confirm-email-invite/confirm-email-redirect.page";
import { AnonConfirmEmailViewOurWorkPage } from "../pages/anon/confirm-email-invite/view-our-work.page";
import { CpOnboardCreateAccountPage } from "../pages/cp-onboard/create-account.page";
import { LoginPage } from "../pages/login.page";
import { onboardRoutes } from "./onboard.routes";
import { uRouteViews } from "./u-route-views";
import { OnboardLayout } from "@pages/onboard/onboard.layout";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <IndexLayout />,
    children: [
      {
        element: <OnboardLayout />,
        children: [
          {
            path: ROUTES.LOGIN.path,
            element: <LoginPage />,
          },
        ],
      },
      onboardRoutes,
      uRouteViews,
      {
        path: "onboard-cp/create-account",
        element: <CpOnboardCreateAccountPage />,
      },
      {
        children: [
          {
            children: [
              {
                path: ANON_ROUTES.CONFIRM_EMAIL_INVITE.path,
                element: <AnonConfirmEmailInviteRedirectPage />,
              },
              {
                path: "view-our-work",
                element: <AnonConfirmEmailViewOurWorkPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];

console.log("ROUTES", routes);
