import { Menu, Switch } from "@headlessui/react";
import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { BE } from "@webapp/backend";
import { FullCalendar } from "@webapp/componentscalendar/full-calendar.fc";
import { CalendarMenuInput } from "@webapp/componentsform/calendar.input";
import { DurationPicker } from "@webapp/componentsform/durationpicker.input";
import {
  TextAreaInput$,
  TextInput$,
} from "@webapp/componentsform/form-inputs.fc";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { Rx } from "@webapp/prelude";
import { pipe } from "fp-ts/function";
import { type DefaultError } from "frontend-shared/src/api.mgr";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import { useCommunitySam } from "frontend-shared/src/mgrs/state-mgrs/community.statemgr";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { E, O, RD } from "shared/base-prelude";
import { UROUTES } from "shared/routes/u.routes";
import {
  EventInstanceId,
  type RecurrenceFrequencyChoice,
} from "shared/types/calendar.types";
import type { FullCommunityEventInstanceData } from "shared/types/community-event.types";
import { CommunityEventCardContainer } from "../../components/community.components";

export const CommunityEventsTabLayout: React.FC = () => {
  return (
    <div className="flex flex-col gap-8">
      <Outlet />
    </div>
  );
};

export const CommunityEventsTabAll: React.FC = () => {
  const hpState = useHpState();
  const sam = useCommunitySam();
  const [{ showEventRightNav, showEventInstanceRightNav }] =
    useTypedSearchParams(
      UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.ALL
    );

  useEffect(() => {
    if (showEventRightNav !== undefined) {
      hpState.dashboardState.openRightNav({
        _tag: "VIEW_COMMUNITY_EVENT",
        eventTemplateId: showEventRightNav,
        communitySlug: sam.communitySlug,
      });
    }

    if (showEventInstanceRightNav !== undefined) {
      hpState.dashboardState.openRightNav({
        _tag: "VIEW_COMMUNITY_EVENT_INSTANCE",
        eventInstanceId: EventInstanceId.make(showEventInstanceRightNav),
        communitySlug: sam.communitySlug,
      });
    }
  }, [showEventRightNav, showEventInstanceRightNav]);

  const rdAllEvents = useObservableEagerState(sam.allEvents.value$);

  useEffect(() => {
    sam.allEvents.runFetchAndSet();
  }, []);

  return pipe(
    rdAllEvents,
    RD.toOption,
    O.fold(
      () => (
        <div className="w-full min-h-[500px]">
          <FullContainerLoadingSpinner />
        </div>
      ),
      (res) => (
        <div className="flex flex-col gap-8 rounded-lg min-h-[500px]">
          <div className="flex flex-col gap-4">
            <div className="flex max-w-full overflow-x-auto gap-4">
              {res.upcomingEvents.map((evt) => (
                <CommunityEventCardContainer
                  key={evt.instanceId}
                  evtInstance={evt}
                />
              ))}
            </div>
          </div>
          <FullCalendar<FullCommunityEventInstanceData>
            stateMgr={sam.calendarMgr}
            fetchOnMount={false}
            renderEventInMonth={(evt) => (
              <div
                className="bg-vid-black-100 rounded-2xl flex justify-center items-center text-black px-1 py-2 self-stretch cursor-pointer overflow-hidden whitespace-nowrap font-sans"
                onClick={(e) => {
                  e.stopPropagation();
                  hpState.dashboardState.openRightNav({
                    _tag: "VIEW_COMMUNITY_EVENT_INSTANCE",
                    eventInstanceId: evt.instanceId,
                    communitySlug: sam.communitySlug,
                  });
                }}
              >
                <div className="text-center text-xs truncate overflow-hidden">
                  {evt.title}
                </div>
              </div>
            )}
            onDayClick={(_) => {
              hpState.dashboardState.openRightNav({
                _tag: "CUSTOM",
                topView: <div>{`Create event`}</div>,
                content: (
                  <CreateEventForm
                    communitySlug={sam.communitySlug}
                    onSuccessSubmit={() => {
                      hpState.dashboardState.closeRightNav();
                      hpState.dashboardState.showBottomToast({
                        msg: "Event created",
                        reload: true,
                      });
                    }}
                  />
                ),
              });
            }}
            newApptButton={{
              title: "Create event",
              onClick: () => {
                hpState.dashboardState.openRightNav({
                  _tag: "CUSTOM",
                  topView: <div>Create event</div>,
                  content: (
                    <CreateEventForm
                      communitySlug={sam.communitySlug}
                      onSuccessSubmit={() => {
                        hpState.dashboardState.closeRightNav();
                        hpState.dashboardState.showBottomToast({
                          msg: "Event created",
                          reload: true,
                        });
                      }}
                    />
                  ),
                });
              },
            }}
          />
          {/* <GoLiveNowButton /> */}
        </div>
      )
    )
  );
};

const GoLiveNowButton: React.FC<{}> = () => {
  const nav = useNavigate();
  const sam = useCommunitySam();
  const firebaseJs = useFirebaseJs();
  return (
    <div className="flex p-0 lg:p-8 lg:border-4 rounded-lg lg:shadow-md">
      <button
        className="w-full lg:w-[200px] lg:ml-4 bg-vid-purple text-center text-white p-buttons lg:p-3 lg:mb-4 rounded-xl"
        onClick={() => {
          // nav(
          //   UROUTES.GROUP_SESSIONS.GROUP_SESSION_ID.ENTRY.buildPath({
          //     groupSessionId: er.right.groupSessionId,
          //   })
          // );
        }}
      >
        Go live now
      </button>
    </div>
  );
};

class CreateEventFormMgr {
  startTime$ = new Rx.BehaviorSubject<O.Option<Date>>(O.none);
  durationInMinutes$ = new Rx.BehaviorSubject<O.Option<number>>(O.none);
  name$ = new Rx.BehaviorSubject<string>("");
  description$ = new Rx.BehaviorSubject<string>("");
  recurringStatus$ = new Rx.BehaviorSubject<RecurrenceFrequencyChoice>("none");
  rdSubmitRes$ = new Rx.BehaviorSubject<RD.RemoteData<DefaultError, any>>(
    RD.initial
  );
  sendInviteToAll$ = new Rx.BehaviorSubject<boolean>(false);

  constructor(
    readonly communitySlug: string,
    readonly onSuccessSubmit: () => void
  ) {}

  getFullForm = () => {
    const startTime = O.toNullable(this.startTime$.value);
    const durationInMinutes = O.toNullable(this.durationInMinutes$.value);
    const name = this.name$.value;
    const description = this.description$.value;
    const recurringStatus = this.recurringStatus$.value;

    return {
      startTime,
      durationInMinutes,
      name,
      description,
      recurringStatus,
      sendInviteToAll: this.sendInviteToAll$.value,
    };
  };

  submit = async () => {
    console.log(
      this.startTime$.value,
      this.durationInMinutes$.value,
      this.name$.value
    );
    const {
      startTime,
      durationInMinutes,
      name,
      description,
      recurringStatus,
      sendInviteToAll,
    } = this.getFullForm();

    if (startTime === null) {
      alert("Start time is required");
      return;
    }

    if (durationInMinutes === null) {
      alert("Duration is required");
      return;
    }

    this.rdSubmitRes$.next(RD.pending);
    const submitRes = await BE.fetchEndpointTE((Api) =>
      Api.hp.community.scheduleEvent.mutate({
        communitySlug: this.communitySlug,
        title: name,
        description,
        recurrenceFrequencyChoice: recurringStatus,
        startDate: startTime,
        durationSeconds: durationInMinutes * 60,
        sendInviteToAll,
      })
    )();

    this.rdSubmitRes$.next(RD.fromEither(submitRes));

    if (E.isRight(submitRes)) {
      this.onSuccessSubmit();
    }
  };
}

type OpenDropdownId = "user" | "payment" | "duration" | "reminder";

const CreateEventForm: React.FC<{
  communitySlug: string;
  onSuccessSubmit: () => void;
}> = ({ communitySlug, onSuccessSubmit }) => {
  const mgr = useMemo(
    () => new CreateEventFormMgr(communitySlug, onSuccessSubmit),
    []
  );
  const rdSubmitRes = useObservableEagerState(mgr.rdSubmitRes$);

  const [openDropdownId, setOpenDropdownId] =
    React.useState<OpenDropdownId | null>(null);

  return (
    <div className="flex-1 flex flex-col justify-between p-4">
      <div className="flex flex-col gap-8">
        <TextInput$ label="Name" value$={mgr.name$} />
        <TextAreaInput$ label="Description" value$={mgr.description$} />
        <CalendarMenuInput
          isOpen={openDropdownId === "duration"}
          onToggle={(isOpen) => setOpenDropdownId(isOpen ? "duration" : null)}
          title="Start time"
          onSelection={(dt) => {
            mgr.startTime$.next(O.some(dt));
          }}
        />
        <DurationPicker durationInMinutes$={mgr.durationInMinutes$} />
        <RecurringInput$
          recurringStatus$={mgr.recurringStatus$}
          onSelect={(rs) => {
            mgr.recurringStatus$.next(rs);
          }}
        />
        <SendInviteToAllButton
          enabled$={mgr.sendInviteToAll$}
          onChange={(enabled) => {
            mgr.sendInviteToAll$.next(enabled);
          }}
        />
      </div>
      <PrimaryButton
        title="Submit"
        onClick={() => mgr.submit()}
        isLoading={RD.isPending(rdSubmitRes)}
      />
      {RD.isFailure(rdSubmitRes) && (
        <div className="text-red-500 flex justify-center">
          {rdSubmitRes.error.error.message}
        </div>
      )}
    </div>
  );
};

const SendInviteToAllButton: React.FC<{
  enabled$: Rx.BehaviorSubject<boolean>;
  onChange: (enabled: boolean) => void;
}> = ({ enabled$, onChange }) => {
  const enabled = useObservableEagerState(enabled$);
  return (
    <div className="flex flex-col gap-4">
      <label className="font-semibold text-vid-purple">
        Send invite to all community members?
      </label>
      <Switch
        checked={enabled}
        onChange={onChange}
        className={`${
          enabled ? "bg-blue-600" : "bg-gray-200"
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span className="sr-only">Send invite to all community members?</span>
        <span
          className={`${
            enabled ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </div>
  );
};

const RecurringInput$: React.FC<{
  recurringStatus$: Rx.BehaviorSubject<RecurrenceFrequencyChoice>;
  onSelect: (rs: RecurrenceFrequencyChoice) => void;
}> = ({ recurringStatus$, onSelect }) => {
  const recurringStatus = useObservableEagerState(recurringStatus$);
  return (
    <Menu as="div" className="text-input">
      <Menu.Button className="flex gap-4">
        <label className="defaultLabel">Recurring (optional)</label>
        <div>{recurringStatus}</div>
      </Menu.Button>
      <Menu.Items>
        {ALL_RECURRING_STATUSES.map((rs) => (
          <Menu.Item>
            <div
              className="cursor-pointer"
              onClick={() => {
                onSelect(rs);
              }}
            >
              {rs}
            </div>
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

const ALL_RECURRING_STATUSES: RecurrenceFrequencyChoice[] = [
  "none",
  "daily",
  "weekly",
];
