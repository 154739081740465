import { type Call } from "@stream-io/video-react-sdk";
import { Equal, Match, Option } from "effect";
import { FirebaseJsMgr } from "frontend-shared/src/firebase";
import {
  FirestoreGroupSessionStateMgmt,
  FirestoreSessionStateMgmt,
  type RemoteSessionStateSyncMgr,
} from "frontend-shared/src/firestore-live-session.mgmt";
import { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import { type UApiOutput } from "frontend-shared/src/trpc-cli";
import { createContextAndHook, useOnce } from "frontend-shared/src/util";
import { useObservableState } from "observable-hooks";
import { useMemo } from "react";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { RD } from "shared/base-prelude";
import type { GroupSessionInfo } from "shared/schemas/group-session.schemas";
import { GroupSessionStateModule } from "shared/session-state/session-state.types";
import type { GroupSessionInviteUsersInfo } from "shared/types/session/session.types";
import { ApiMgr } from "../../../../api.mgr";
import type {
  BaseStageViewStateMgr,
  BaseStageViewStatMode,
  GroupSessionSpecialContent,
  GroupSessionSpecificStageViewState,
  StageContentViewMode,
} from "../../../../types/video-state.types";
import type { AbstractAudioPlayer } from "../../../media-player.statemgr";
import { BaseStateMgr } from "../../base.statemgr";
import { CallStateMgr } from "../call.statemgr";

export type KnownLayout = { _tag: "SPEAKER" } | { _tag: "PAGINATED_GRID" };
type GroupSessionState = GroupSessionStateModule.State;

// type GetGroupSessionResp = UApiOutput["u"]["groupSession"]["getGroupSession"];
type RegisterGroupSessionPingResp =
  UApiOutput["u"]["groupSession"]["registerGroupSessionPing"];

type GroupSessionStageViewState = BaseStageViewStatMode<
  GroupSessionSpecificStageViewState,
  GroupSessionSpecialContent
>;

class GroupSessionStageViewStateMgr
  implements
    BaseStageViewStateMgr<
      GroupSessionSpecificStageViewState,
      GroupSessionSpecialContent
    >
{
  stageViewState$: Rx.Observable<GroupSessionStageViewState>;
  localStateContentViewMode$ = new Rx.BehaviorSubject<
    Option.Option<StageContentViewMode>
  >(Option.none());

  setContentViewMode(viewMode: StageContentViewMode) {
    return Match.value(this.myRole).pipe(
      Match.when("HOST", () => {
        return this.remoteSyncMgr.updateRemoteState((curState) => {
          return new GroupSessionStateModule.State({
            ...curState,
            hostContentViewMode: viewMode,
          });
        });
      }),
      Match.when("PARTICIPANT", () => {
        return this.remoteSyncMgr.updateRemoteState((curState) => {
          return new GroupSessionStateModule.State({
            ...curState,
            participantsContentViewMode: viewMode,
          });
        });
      }),
      Match.exhaustive
    );
  }

  closeTopPreview() {
    this.remoteSyncMgr.runUpdateHostContentViewMode(null);
  }

  maximizeTopPreview() {
    this.remoteSyncMgr.runUpdateHostContentViewMode("FULL_SCREEN");
  }

  minimizeTopPreview() {
    this.remoteSyncMgr.runUpdateHostContentViewMode("TOP_PREVIEW");
  }

  constructor(
    remoteParticipantIds$: Rx.Observable<string[]>,
    readonly remoteSyncMgr: RemoteSessionStateSyncMgr<
      GroupSessionStateModule.FirebaseEncodedState,
      GroupSessionStateModule.State
    >,
    readonly myRole: "HOST" | "PARTICIPANT"
  ) {
    this.stageViewState$ = Rx.combineLatest([
      remoteParticipantIds$,
      remoteSyncMgr.decodedRemoteState$,
    ]).pipe(
      RxO.map(([remoteParticipantIds, remoteState]) => {
        const numRemoteParticipants = remoteParticipantIds.length;
        return {
          numRemoteParticipants,
          remoteState,
        };
      }),
      RxO.distinctUntilChanged((a, b) => {
        const sameParticipants =
          a.numRemoteParticipants === b.numRemoteParticipants;
        const sameRemoteState = Equal.equals(a.remoteState, b.remoteState);
        return sameParticipants && sameRemoteState;
      }),
      RxO.map(({ numRemoteParticipants, remoteState }) => {
        return this.determineStageViewState({
          numRemoteParticipants,
          remoteState,
        });
      }),
      RxO.startWith({
        _tag: "UNKNOWN",
      } as GroupSessionStageViewState)
    );
  }

  determineStageViewState(p: {
    numRemoteParticipants: number;
    remoteState: GroupSessionState;
  }): GroupSessionStageViewState {
    const { numRemoteParticipants, remoteState } = p;
    const mbContent = Option.fromNullable(remoteState.content?.current);
    const hostContentViewMode = Option.fromNullable(
      remoteState.hostContentViewMode
    );
    if (Option.isSome(hostContentViewMode) && Option.isSome(mbContent)) {
      return {
        _tag: "CONTENT_VIEW",
        viewMode: hostContentViewMode.value,
        content: mbContent.value,
      };
    }
    if (numRemoteParticipants === 0) {
      return {
        _tag: "PARTICIPANTS_VIEW",
        viewMode: {
          _tag: "SESSION_SPECIFIC",
          viewMode: {
            _tag: "GALLERY",
          },
        },
      };
    } else if (numRemoteParticipants === 1) {
      return {
        _tag: "PARTICIPANTS_VIEW",
        viewMode: {
          _tag: "SESSION_SPECIFIC",
          viewMode: {
            _tag: "SELF_SMALL_OTHER_USER_LARGE",
          },
        },
      };
    } else {
      return {
        _tag: "PARTICIPANTS_VIEW",
        viewMode: {
          _tag: "SESSION_SPECIFIC",
          viewMode: {
            _tag: "GALLERY",
          },
        },
      };
    }
  }
}

export class GroupSessionRemoteStateMgr extends BaseStateMgr {
  firestoreMgr: FirestoreSessionStateMgmt<
    GroupSessionStateModule.FirebaseEncodedState,
    GroupSessionStateModule.State
  >;

  constructor(
    readonly groupSession: GroupSessionInfo,
    firebaseJsMgr: FirebaseJsMgr,
    apiMgr: ApiMgr
  ) {
    super({ apiMgr });

    this.firestoreMgr = FirestoreGroupSessionStateMgmt(
      firebaseJsMgr,
      groupSession.id
    );
  }
}

export const [GroupSessionStateMgrContext, useGroupSessionStateMgr] =
  createContextAndHook<GroupSessionRemoteStateMgr>();

export function useSetupGroupSessionRoomCallStateMgr(p: {
  groupSessionId: string;
  channelMgr: Call;
  firebaseJsMgr: FirebaseJsMgr;
  apiMgr: ApiMgr;
  audioPlayer: AbstractAudioPlayer;
}): Option.Option<{
  gsRoomCallStateMgr: GroupSessionRoomAndCallStateMgr;
  sessionState: GroupSessionStateModule.FirebaseEncodedState;
}> {
  const groupSessionStateMgr = useGroupSessionStateMgr();

  const remoteParticipantIds$ = useOnce(() =>
    p.channelMgr.state.remoteParticipants$.pipe(
      RxO.map((participants) => participants.map((p) => p.userId))
    )
  );

  const gsRoomCallStateMgr = useMemo(
    () =>
      new GroupSessionRoomAndCallStateMgr(
        groupSessionStateMgr,
        p.channelMgr.id,
        remoteParticipantIds$,
        p.apiMgr,
        p.audioPlayer
      ),
    [p.groupSessionId, p.audioPlayer]
  );

  const sessionState: GroupSessionStateModule.FirebaseEncodedState | null =
    useObservableState(
      groupSessionStateMgr.firestoreMgr.remoteStateSyncMgr.remoteState$,
      null
    );

  return Option.fromNullable(sessionState).pipe(
    Option.map((sessionState) => ({
      gsRoomCallStateMgr,
      sessionState,
    }))
  );
}

export class GroupSessionRoomAndCallStateMgr extends CallStateMgr {
  rdInviteInfo$ = new Rx.BehaviorSubject<
    RD.RemoteData<unknown, GroupSessionInviteUsersInfo>
  >(RD.initial);

  selectedLayout$ = new Rx.BehaviorSubject<KnownLayout>({
    _tag: "PAGINATED_GRID",
  });

  currentLayout$: Rx.Observable<KnownLayout>;

  mainRoomMgr: MainRoomStateMgr<
    RegisterGroupSessionPingResp,
    GroupSessionSpecificStageViewState,
    GroupSessionSpecialContent,
    GroupSessionStateModule.FirebaseEncodedState,
    GroupSessionStateModule.State
  >;

  submitCallClosedResult$ = new Rx.BehaviorSubject<RD.RemoteData<unknown, any>>(
    RD.initial
  );

  constructor(
    readonly groupSessionStateMgr: GroupSessionRemoteStateMgr,
    readonly callId: string,
    readonly remoteParticipantIds$: Rx.Observable<string[]>,
    apiMgr: ApiMgr,
    deviceAudioPlayer: AbstractAudioPlayer
  ) {
    super(callId, apiMgr);

    const stageViewMgr = new GroupSessionStageViewStateMgr(
      this.remoteParticipantIds$,
      this.groupSessionStateMgr.firestoreMgr.remoteStateSyncMgr,
      "PARTICIPANT" // TODO: Get this from the server
    );
    this.mainRoomMgr = new MainRoomStateMgr<
      RegisterGroupSessionPingResp,
      GroupSessionSpecificStageViewState,
      GroupSessionSpecialContent,
      GroupSessionStateModule.FirebaseEncodedState,
      GroupSessionStateModule.State
    >({
      stageViewMgr,
      sendPingEff: this.BE.fetchSuccessOnlyEndpoint((Api) =>
        Api.u.groupSession.registerGroupSessionPing.mutate({
          groupSessionId: this.groupSessionStateMgr.groupSession.id,
        })
      ),
      fsSessionStateMgr: this.groupSessionStateMgr.firestoreMgr,
      audioPlayer: deviceAudioPlayer,
    });
    this.currentLayout$ = Rx.combineLatest([
      this.selectedLayout$,
      this.screenshareState$,
    ]).pipe(
      RxO.map(([layout, screenshareState]) => {
        if (screenshareState === null) {
          return layout;
        }
        if (screenshareState === "enabled") {
          return { _tag: "SPEAKER" } as KnownLayout;
        }
        return layout;
      })
    );

    this.fetchAndSetInviteInfo({ setPending: true });

    this.mainRoomMgr.pingMgr.sendPing();
  }

  cleanup = () => {
    this.mainRoomMgr.cleanup();
  };

  fetchAndSetInviteInfo(p: { setPending?: boolean }) {
    if (p.setPending) {
      this.rdInviteInfo$.next(RD.pending);
    }
    this.BE.fetchEndpointTE((Api) =>
      Api.u.groupSession.getInviteInfo.query({
        groupSessionId: this.groupSessionStateMgr.groupSession.id,
      })
    )().then((er) => {
      this.rdInviteInfo$.next(RD.fromEither(er));
    });
  }
}

export const [
  GroupSessionRoomCallStateMgrContext,
  useGroupSessionRoomCallStateMgr,
] = createContextAndHook<GroupSessionRoomAndCallStateMgr>();
