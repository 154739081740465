import type { FullCommunityEventInstanceData } from "shared/types/community-event.types";
import type { ApiMgr } from "../../../api.mgr";
import { BaseStateMgr } from "../base.statemgr";

export class CommunityEventsStateMgr extends BaseStateMgr {
  constructor(readonly communitySlug: string, readonly p: { apiMgr: ApiMgr }) {
    super({
      apiMgr: p.apiMgr,
    });
  }
}

export class CommunityEventInstanceStateMgr extends BaseStateMgr {
  constructor(
    readonly communitySlug: string,
    readonly eventInstance: FullCommunityEventInstanceData,
    readonly p: { apiMgr: ApiMgr }
  ) {
    super({
      apiMgr: p.apiMgr,
    });
  }

  hardDeleteEventInstance = () =>
    this.BE.fetchEndpointTE((Api) =>
      Api.hp.communityEventInstance.hardDeleteEventInstance.mutate({
        communitySlug: this.communitySlug,
        eventInstanceId: this.eventInstance.instanceId,
      })
    )();

  hardDeleteAllEventInstances = () =>
    this.BE.fetchEndpointTE((Api) =>
      Api.hp.communityEventInstance.hardDeleteAllEventInstances.mutate({
        communitySlug: this.communitySlug,
        eventInstanceId: this.eventInstance.instanceId,
      })
    )();
}
