import { Switch } from "@headlessui/react";
import { apiMgr } from "@webapp/backend";
import {
  SelectUserSection,
  SetDurationSection,
  SetEndingSessionReminderSection,
} from "@webapp/componentssession/session-form.components";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { RD, RxO } from "@webapp/prelude";
import { useFirebaseJs } from "frontend-shared/src/firebase";
import { StartASessionFormStateMgr } from "frontend-shared/src/mgrs/state-mgrs/start-a-session-form.statemgr";
import {
  useKeyOfBehaviorSubjectAsState,
  useKeyOfObservable,
  useOnce,
} from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  type SimplestUserWithProfilePhoto,
  type SimpleUserWithProfilePhoto,
} from "shared";
import { UROUTES } from "shared/routes/u.routes";

type OpenDropdownId = "user" | "payment" | "duration" | "reminder";

export const StartASessionForm: React.FC<{
  onClose: () => void;
  forClient:
    | { _tag: "EXISTING"; client: SimplestUserWithProfilePhoto }
    | { _tag: "SELECT"; myClients: SimplestUserWithProfilePhoto[] };
}> = ({ onClose, forClient }) => {
  const nav = useNavigate();
  const firebaseJs = useFirebaseJs();
  const stateMgr = useOnce(
    () =>
      new StartASessionFormStateMgr({
        apiMgr: apiMgr,
        forClient,
        firebaseMgr: firebaseJs,
        onSuccessSubmit: (sessionId) => {
          const navPath =
            UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.ENTRY.buildPath({
              privateSessionId: sessionId,
            });
          // const navPath =
          //   UROUTES.HP.MY.PRIVATE_SESSIONS.SESSION_ID.LIVE.WAITING_ROOM.buildPath(
          //     {
          //       sessionId,
          //     }
          //   );
          nav(navPath);
          onClose();
        },
      })
  );
  const durationInMinutes$ = useKeyOfObservable(
    stateMgr.formData$,
    "durationInMinutes"
  );
  const endingSessionReminder$ = useKeyOfObservable(
    stateMgr.formData$,
    "minutesBeforeEndReminder"
  );
  // const sendInviteEmail = useKeyOfBehaviorSubjectAsState(
  //   stateMgr.formData$,
  //   "sendInviteEmail"
  // );
  const audioOnly = useKeyOfBehaviorSubjectAsState(
    stateMgr.formData$,
    "audioOnly"
  );
  const rdSubmitResult = useObservableEagerState(stateMgr.submitResult$);
  const selectedUserId$ = useOnce(() =>
    stateMgr.selectedClient$.pipe(RxO.map((c) => c?.id ?? null))
  );

  const [openDropdownId, setOpenDropdownId] =
    React.useState<OpenDropdownId | null>(null);

  return (
    <div className="flex flex-col p-8">
      <div className="flex flex-col gap-12">
        {forClient._tag === "SELECT" && (
          <SelectUserSection
            label="Client"
            isOpen={openDropdownId === "user"}
            onToggle={(isOpen) => setOpenDropdownId(isOpen ? "user" : null)}
            selectedUserId$={selectedUserId$}
            users={forClient.myClients}
            onUserSelect={(client) => {
              stateMgr.selectedClient$.next(client);
            }}
          />
        )}
        <SetDurationSection
          isOpen={openDropdownId === "duration"}
          onToggle={(isOpen) => setOpenDropdownId(isOpen ? "duration" : null)}
          durationInMinutes$={durationInMinutes$}
          onChange={(v) => {
            stateMgr.setFormValue("durationInMinutes", v);
          }}
        />
        {/* <ClientsPageComponents.DurationRadioGroup
          sessionConfig$={sessionConfig$}
        /> */}
        {/* <ClientsPageComponents.SetTimeWarningRadioGroup
          sessionConfig$={sessionConfig$}
        /> */}
        <SetEndingSessionReminderSection
          isOpen={openDropdownId === "reminder"}
          onToggle={(isOpen) => setOpenDropdownId(isOpen ? "reminder" : null)}
          endingSessionReminder$={endingSessionReminder$}
          onChange={(v) => {
            stateMgr.setFormValue("minutesBeforeEndReminder", v);
          }}
        />
        {/* <div className="flex gap-8 mt-8">
          <h4 className="font-bold">Send Invite Email?</h4>
          <Switch
            checked={sendInviteEmail}
            onChange={(v) => {
              stateMgr.setFormValue("sendInviteEmail", v);
            }}
            className={`${
              sendInviteEmail ? "bg-vid-purple" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Send invite email</span>
            <span
              className={`${
                sendInviteEmail ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div> */}
        <div className="flex gap-8 mt-8">
          <h4 className="font-bold">Audio only?</h4>
          <Switch
            checked={audioOnly}
            onChange={(v) => {
              stateMgr.setFormValue("audioOnly", v);
            }}
            className={`${
              audioOnly ? "bg-vid-purple" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Audio only</span>
            <span
              className={`${
                audioOnly ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
      </div>
      <button
        className="bg-btn-purple p-4 rounded-xl text-white self-stretch mt-8"
        onClick={() => {
          stateMgr.submit();
        }}
      >
        {RD.isPending(rdSubmitResult) ? (
          <FullContainerLoadingSpinner />
        ) : (
          "Start session"
        )}
      </button>
      {RD.isFailure(rdSubmitResult) && (
        <div className="text-red-500 font-bold self-center mt-4">
          {rdSubmitResult.error}
        </div>
      )}
    </div>
  );
};
