import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { BE } from "@webapp/backend";
import { AvatarCircle } from "@webapp/componentsavatar.tc";
import {
  DashboardRightDrawerContainer,
  DashboardRightDrawerRemoteData,
} from "@webapp/componentsdashboard/right-nav.components";
import { FetchRemoteDataView } from "@webapp/utils/utils";
import { Match } from "effect";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { SessionInvoice, type SimplestUserWithProfilePhoto } from "shared";
import { TE } from "shared/base-prelude";
import { PrivateSessionAppointment } from "shared/schemas/session/private-session.schemas";
import { EventInstanceId } from "shared/types/calendar.types";
import { ImageSrc } from "shared/types/miscellaneous.types";
import {
  CreateCommunityPostForm,
  CreateCommunityPostFormTopView,
} from "./create-community-post.form.fc";
import { CreateCommunityForm } from "./create-community.form";
import { InvoiceDownloadForm } from "./invoice-download-form.fc";
import { HpSchedulePrivateSessionForm } from "./new-appointment-form.fc";
import { RegisterNewClientForm } from "./register-new-client.form";
import { StartASessionForm } from "./start-a-session-form.fc";
import {
  ViewAppointmentFormContent,
  ViewAppointmentFormTopView,
} from "./view-appointment.fc";
import {
  ViewCommunityEventContent,
  ViewCommunityEventInstanceContent,
} from "./view-community-event.fc";

export interface SimpleClient {
  id: string;
  name: string;
}

export type HpDashbaordRightNav =
  | {
      _tag: "START_CLIENT_SESSION";
      client: SimplestUserWithProfilePhoto;
    }
  | { _tag: "REGISTER_NEW_CLIENT" }
  | { _tag: "START_SESSION"; myClients: SimplestUserWithProfilePhoto[] }
  | {
      _tag: "NEW_APPOINTMENT";
      date?: Date;
      client: { id: string; name: string } | undefined;
      requestApptId: string | undefined;
    }
  | { _tag: "VIEW_APPOINTMENT"; appointmentId: EventInstanceId }
  | { _tag: "DOWNLOAD_INVOICE"; invoice: SessionInvoice }
  | { _tag: "CREATE_COMMUNITY_POST"; posterId: string; communitySlug: string }
  | {
      _tag: "VIEW_COMMUNITY_EVENT";
      communitySlug: string;
      eventTemplateId: string;
    }
  | {
      _tag: "VIEW_COMMUNITY_EVENT_INSTANCE";
      communitySlug: string;
      eventInstanceId: EventInstanceId;
    }
  | {
      _tag: "CREATE_COMMUNITY";
    }
  | { _tag: "CUSTOM"; topView: React.ReactNode; content: React.ReactNode };

export const DashboardRightDrawer: React.FC<{
  rightNav: HpDashbaordRightNav;
}> = ({ rightNav }) => {
  const hpState = useHpState();
  const closeRightNav = hpState.dashboardState.closeRightNav;
  return Match.value(rightNav).pipe(
    Match.tag("START_CLIENT_SESSION", (res) => (
      <DashboardRightDrawerContainer
        topView={
          <div className="flex flex-col gap-3 items-center">
            <AvatarCircle
              mbProfilePhoto={ImageSrc.fromMbUrl(res.client.profilePhoto)}
              size={50}
            />
            <h4>{res.client.name}</h4>
          </div>
        }
        content={
          <StartASessionForm
            onClose={() => {
              window.location.reload();
            }}
            forClient={{ _tag: "EXISTING", client: res.client }}
          />
        }
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("REGISTER_NEW_CLIENT", () => (
      <DashboardRightDrawerContainer
        topView={<div>Register a new client</div>}
        content={<RegisterNewClientForm />}
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("START_SESSION", ({ myClients }) => (
      <DashboardRightDrawerContainer
        topView={<div>Start a session</div>}
        content={
          <StartASessionForm
            onClose={() => {
              window.location.reload();
            }}
            forClient={{ _tag: "SELECT", myClients }}
          />
        }
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("NEW_APPOINTMENT", (res) => (
      <DashboardRightDrawerContainer
        topView={
          <div className="flex flex-col justify-center items-center">
            <img src="/pending.png" className="w-fit h-fit rounded-full" />
            <p className="text-2xl font-sans font-light mt-2">
              New appointment
            </p>
          </div>
        }
        content={
          <HpSchedulePrivateSessionForm
            initialDate={res.date}
            mbClient={res.client}
            requestApptId={res.requestApptId}
            onSuccessSave={() => {
              hpState.dashboardState.closeRightNav();
              hpState.dashboardState.showBottomToast({
                msg: "Appointment scheduled!",
                reload: true,
              });
            }}
          />
        }
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("VIEW_APPOINTMENT", (res) => {
      const fetchTE = pipe(
        BE.fetchEndpointTE((Api) =>
          Api.hp.calendar.getEventInstance.query({
            eventInstanceId: res.appointmentId,
          })
        ),
        TE.map((r) =>
          PrivateSessionAppointment.make(r, { disableValidation: true })
        )
      );
      return (
        <DashboardRightDrawerRemoteData
          fetchTE={fetchTE}
          topView={(r) => <ViewAppointmentFormTopView appointment={r} />}
          content={(r) => <ViewAppointmentFormContent initialAppointment={r} />}
          closeRightNav={closeRightNav}
        />
      );
    }),
    Match.tag("DOWNLOAD_INVOICE", (res) => (
      <DashboardRightDrawerContainer
        topView={<div>Download Invoice</div>}
        content={<InvoiceDownloadForm invoice={res.invoice} />}
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("CREATE_COMMUNITY", () => (
      <DashboardRightDrawerContainer
        topView={<div>Create a new community</div>}
        content={<CreateCommunityForm />}
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("CREATE_COMMUNITY_POST", (res) => (
      <DashboardRightDrawerContainer
        topView={<CreateCommunityPostFormTopView />}
        content={
          <CreateCommunityPostForm
            communitySlug={res.communitySlug}
            posterId={res.posterId}
          />
        }
        closeRightNav={closeRightNav}
      />
    )),
    Match.tag("VIEW_COMMUNITY_EVENT", (res) => {
      const fetchTE = BE.fetchEndpointTE((Api) =>
        Api.hp.communityEvent.getEventTemplateInfo.query(res)
      );
      return (
        <DashboardRightDrawerContainer
          topView={<div></div>}
          content={
            <FetchRemoteDataView
              fetchTE={fetchTE}
              loadedView={(template) => (
                <ViewCommunityEventContent
                  communitySlug={res.communitySlug}
                  eventTemplate={template}
                />
              )}
            />
          }
          closeRightNav={closeRightNav}
        />
      );
    }),
    Match.tag("VIEW_COMMUNITY_EVENT_INSTANCE", (res) => {
      const fetchTE = BE.fetchEndpointTE((Api) =>
        Api.hp.communityEventInstance.getInstanceInfo.query(res)
      );
      return (
        <FetchRemoteDataView
          fetchTE={fetchTE}
          loadedView={(v) => (
            <DashboardRightDrawerContainer
              topView={
                <div className="font-bold text-2xl">
                  <h4>{v.title}</h4>
                </div>
              }
              content={
                <ViewCommunityEventInstanceContent
                  communitySlug={res.communitySlug}
                  eventInstance={v}
                />
              }
              closeRightNav={closeRightNav}
            />
          )}
        />
      );
    }),
    Match.tag("CUSTOM", (res) => (
      <DashboardRightDrawerContainer
        topView={<></>}
        content={res.content}
        closeRightNav={closeRightNav}
      />
    )),
    Match.exhaustive
  );
};
