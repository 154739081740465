import { useRtcMgr } from "@pages/u/u.webstatemgr";
import {
  BackgroundFiltersProvider,
  StreamCall,
  StreamVideo,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { apiMgr } from "@webapp/backend";
import { SuccessOnlyViewO } from "@webapp/utils/utils";
import { Effect, Option } from "effect";
import {
  PrivateSessionCallStateMgrContext,
  usePrivateSessionCallStateMgr,
  usePrivateSessionStateMgr,
  useSetupPrivateSessionCallStateMgr,
} from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/remote-state-and-call.statemgrs";
import { useObservableEagerState } from "observable-hooks";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { UROUTES } from "shared/routes/u.routes";

export const PrivateSessionCallLayout: React.FC = () => {
  const { callId } = useTypedParams(
    UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.CALLS.CALL_ID
  );
  // const [{ startNew }] = useTypedSearchParams(
  //   UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.ENTRY
  // );
  const sessionMgr = usePrivateSessionStateMgr();

  useEffect(() => {
    console.log("CALLCLIENT.CALL MOUNT TOP!");
  }, []);

  const mbSetup = useSetupPrivateSessionCallStateMgr(
    sessionMgr,
    apiMgr,
    callId
  );

  const rtcMgr = useRtcMgr();

  useEffect(() => {
    Effect.runPromise(
      rtcMgr.lazyJoinChannel({
        channelType: "private_session",
        channelId: callId,
      })
    ).then((_) => {
      console.log("JOINED CALL! ", callId);
    });

    return () => {
      console.log("LEAVING CALL ON DISMOUNT! ", callId);
      Effect.runPromise(rtcMgr.handleDismountInCall).then((_) => {
        console.log("LEFT CALL! ", callId);
      });
    };
  }, []);

  const mbChannelMgr = useObservableEagerState(rtcMgr.mbCurrentChannelMgr$);

  return (
    <SuccessOnlyViewO
      mbV={Option.all([mbSetup, mbChannelMgr])}
      loadedView={([setup, channelMgr]) => (
        <PrivateSessionCallStateMgrContext.Provider
          value={setup.psCallStateMgr}
        >
          <StreamVideo client={rtcMgr.client}>
            <StreamCall call={channelMgr}>
              <BackgroundFiltersProvider>
                <Content />
              </BackgroundFiltersProvider>
            </StreamCall>
          </StreamVideo>
        </PrivateSessionCallStateMgrContext.Provider>
      )}
    />
  );
};

const Content: React.FC = () => {
  const psmgr = usePrivateSessionCallStateMgr();
  const { useScreenShareState } = useCallStateHooks();
  const { status: screenshareStatus } = useScreenShareState();

  useEffect(() => {
    psmgr.screenshareState$.next(screenshareStatus);
  }, [screenshareStatus]);

  return <Outlet />;
};
