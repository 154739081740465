import { route, string } from "react-router-typesafe-routes/dom";
import { UROUTES } from "./u.routes";

export * from "./hp/hp.routes";

export const ROUTES = route(
  "",
  {},
  {
    LOGIN: route("login", {
      searchParams: {
        prefilledEmail: string(),
        redirectTo: string(),
      },
    }),
    U: UROUTES,
  }
);

export const ROBOT_ROUTES = route(
  "robot",
  {},
  {
    SESSIONS: route(
      "sessions",
      {},
      {
        SESSION_ID: route(
          ":sessionId",
          {
            params: { sessionId: string().defined() },
          },
          {
            LIVE: route("live", {}),
          }
        ),
      }
    ),
  }
);

export const ANON_ROUTES = route(
  "anon",
  {},
  {
    CONFIRM_EMAIL_INVITE: route("confirm-email-invite", {
      searchParams: {
        email: string().defined(),
        redirectTo: string().defined(),
        action: string().defined(),
        source: string().defined(),
        clientId: string(),
      },
    }),
  }
);
