import * as S from "@effect/schema/Schema";
import { Effect } from "effect";
import { z } from "zod";
import type { UserWithProfilePhoto } from "../index";

export const WithProfilePhotoSchema = z.object({
  profilePhoto: z.string().nullable(),
});
export type WithProfilePhoto<V> = V & z.infer<typeof WithProfilePhotoSchema>;

export class SimpleUser extends S.Class<SimpleUser>("SimpleUser")({
  id: S.String,
  firstName: S.String,
  lastName: S.String,
  name: S.String,
  email: S.String,
}) {}

export class SimpleUserWithProfilePhoto extends SimpleUser.extend<SimpleUserWithProfilePhoto>(
  "SimpleUserWithProfilePhoto"
)({
  profilePhoto: S.NullOr(S.String),
}) {
  static fromUserWithPhoto = (
    user: UserWithProfilePhoto
  ): SimpleUserWithProfilePhoto =>
    new SimpleUserWithProfilePhoto({
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      name: user.name,
      email: user.email,
      profilePhoto: user.profilePhoto,
    });
}

export class BaseUserInputData extends S.Class<BaseUserInputData>(
  "BaseUserInputData"
)({
  firstName: S.String,
  lastName: S.String,
  email: S.optional(S.String),
  profilePhotoRemotePtr: S.NullOr(
    S.Struct({
      publicId: S.String,
      assetId: S.String,
    })
  ),
}) {
  static default = new BaseUserInputData({
    firstName: "",
    lastName: "",
    profilePhotoRemotePtr: null,
  });

  static validate = (data: BaseUserInputData) => {
    if (data.firstName.length === 0) {
      return Effect.fail("First name is required");
    }
    if (data.lastName.length === 0) {
      return Effect.fail("Last name is required");
    }
    return Effect.succeed(data);
  };
}
