import { pipe } from "fp-ts/function";
import { useObservableEagerState } from "observable-hooks";
import { useEffect, useMemo } from "react";
import { Bookend, SavedBookmark } from "shared/types/session/session.types";
import { RD, Rx, RxO } from "../../../../prelude";
import { BookendItem, SavedBookmarkItem } from "../../bookmark.components";

export const NoteToolsPanel: React.FC<{
  notes$: Rx.BehaviorSubject<string>;
  bookmarks: {
    rdBookmarks$: Rx.Observable<RD.RemoteData<any, SavedBookmark[]>>;
  };
  bookends: {
    rdRecordedBookends$: Rx.Observable<RD.RemoteData<any, Bookend[]>>;
    onBookendLabelChange: (p: { bookendId: string; newLabel: string }) => void;
  };
}> = ({ notes$, bookmarks, bookends }) => {
  return (
    <div className="flex flex-col gap-4">
      <NotesPanelSection notes$={notes$} />
      <BookmarksSection bookmarks={bookmarks} />
      <RecordedBookends bookends={bookends} />
    </div>
  );
};

const SectionContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="flex flex-col gap-4 border rounded-lg p-4">{children}</div>
  );
};

const NotesPanelSection: React.FC<{ notes$: Rx.BehaviorSubject<string> }> = ({
  notes$,
}) => {
  const notes = useObservableEagerState(notes$);
  return (
    <SectionContainer>
      <h1 className="text-2xl font-bold self-center">Notes</h1>
      <textarea
        className="flex-1 flex flex-col self-stretch border rounded-lg p-2"
        value={notes}
        onChange={(e) => {
          notes$.next(e.target.value);
        }}
      />
    </SectionContainer>
  );
};

const BookmarksSection: React.FC<{
  bookmarks: {
    rdBookmarks$: Rx.Observable<RD.RemoteData<any, SavedBookmark[]>>;
  };
}> = ({ bookmarks }) => {
  const rdBookmarks = useObservableEagerState(bookmarks.rdBookmarks$);
  return pipe(
    rdBookmarks,
    RD.fold(
      () => <div></div>,
      () => <div>...</div>,
      (e) => <div>{JSON.stringify(e)}</div>,
      (bookmarks) => (
        <SectionContainer>
          <h1 className="text-2xl font-bold self-center">Bookmarks</h1>
          <div className="flex flex-col gap-2 self-stretch">
            {bookmarks.map((bk) => (
              <SavedBookmarkItem key={bk.id} bookmark={bk} />
            ))}
          </div>
        </SectionContainer>
      )
    )
  );
};

const RecordedBookends: React.FC<{
  bookends: {
    rdRecordedBookends$: Rx.Observable<RD.RemoteData<any, Bookend[]>>;
    onBookendLabelChange: (p: { bookendId: string; newLabel: string }) => void;
  };
}> = ({ bookends }) => {
  const rdRecordedBookends = useObservableEagerState(
    bookends.rdRecordedBookends$
  );
  const labelChangeSubject = useMemo(
    () => new Rx.Subject<{ bookendId: string; newLabel: string }>(),
    []
  );

  useEffect(() => {
    const sub = labelChangeSubject
      .pipe(RxO.debounceTime(300))
      .subscribe((bl) => {
        bookends.onBookendLabelChange(bl);
      });

    return () => {
      sub.unsubscribe();
    };
  }, [labelChangeSubject]);

  return pipe(
    rdRecordedBookends,
    RD.fold(
      () => <div></div>,
      () => <div>...</div>,
      (e) => <div>{JSON.stringify(e)}</div>, // TODO: Convert this to skeleton loaders,
      (bes) => (
        <SectionContainer>
          <div className="flex flex-col gap-2 self-stretch">
            <h2>Recorded Bookends</h2>
            {bes.map((bk) => (
              <BookendItem
                key={bk.id}
                bookend={bk}
                onLabelChange={(bk, newLabel) => {
                  labelChangeSubject.next({ bookendId: bk.id, newLabel });
                }}
              />
            ))}
          </div>
        </SectionContainer>
      )
    )
  );
};
