import {
  InversePrimaryButton,
  PrimaryButton,
} from "@webapp/componentsprimitives/button";
import { LoadingSpinner } from "@webapp/loading";
import { Match } from "effect";
import { EmdrSyncStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/tools/shared/emdr.statemgr";
import { useObservableEagerState, useObservableState } from "observable-hooks";
import { useEffect } from "react";
import { IoPause, IoPlay, IoStop } from "react-icons/io5";
import { BaseSessionStateModule } from "shared/session-state/session-state.types";
import { EmdrState } from "shared/session-state/shared-session-content-state.types";

export const EmdrToolsPanel: React.FC<{
  emdrMgr: EmdrSyncStateMgr;
}> = ({ emdrMgr }) => {
  return (
    <div>
      <EmdrControl emdrMgr={emdrMgr} />
    </div>
  );
};

export const EmdrControl: React.FC<{
  emdrMgr: EmdrSyncStateMgr;
}> = ({ emdrMgr }) => {
  const emdrState: EmdrState | null = useObservableState(
    emdrMgr.emdrState$,
    null
  );
  const hideBouncingBall = useObservableEagerState(emdrMgr.hideBouncingBall$);
  const mediaButtonState = useObservableState(
    emdrMgr.mediaButtonState$,
    "PLAY"
  );

  useEffect(() => {
    emdrMgr.remoteStateMgr.runUpdateRemoteState((bss) => {
      const newBaseSessionState =
        BaseSessionStateModule.State.setDefaultInitialEmdrViewState(bss);
      return newBaseSessionState;
    });
  }, []);

  useEffect(() => {
    console.log("EMDR STATE! ", emdrState);
  }, [emdrState]);

  return (
    <div className="flex flex-col gap-4 rounded-lg p-4">
      {/* <ToolsSectionContainer title="Bouncing Ball" content={<></>} /> */}
      <ToolsSectionContainer
        title="Speed"
        content={
          <FrequencySlider
            min={1}
            max={10}
            value={emdrState?.ballFrequency ?? 5}
            onChange={(value) => {
              console.log("FREQUENCY! ", value);
              emdrMgr.setBouncingBallFrequency(value);
            }}
          />
        }
      />

      {/* {showBouncingBallState.state === "SHOW" && (
        <div>
          <button
            className="border rounded-lg bg-blue-100 text-gray-800 px-4 py-2"
            onClick={() => {
              {
                emdrMgr.hideBouncingBall(!hideBouncingBall);
              }
            }}
          >
            Hide ball from self view
          </button>
        </div>
      )} */}

      {Match.value(mediaButtonState).pipe(
        Match.when("PLAY", () => (
          <PrimaryButton
            title="Start"
            onClick={() => {
              emdrMgr.setStatePreparing();
            }}
          />
        )),
        Match.when("PAUSE", () => (
          <div className="flex gap-2">
            <InversePrimaryButton
              title="Pause"
              onClick={() => {
                emdrMgr.setStatePaused();
              }}
            />
            <PrimaryButton
              title="Stop"
              onClick={() => {
                emdrMgr.setStateOff();
              }}
            />
          </div>
        )),
        Match.when("PREPARING", () => (
          <PrimaryButton title="Preparing" isLoading={true} />
        )),
        Match.when("STOP", () => (
          <PrimaryButton
            title="Stop"
            onClick={() => {
              emdrMgr.setStateOff();
            }}
          />
        )),
        Match.when(null, () => <PrimaryButton title="Start" />),
        Match.exhaustive
      )}
    </div>
  );
};

interface ToolsSectionContainerProps {
  title: string;
  content: React.ReactNode;
}

const ToolsSectionContainer: React.FC<ToolsSectionContainerProps> = ({
  title,
  content,
}) => {
  return (
    <div className="flex flex-col items-start w-[312px] p-6 gap-4 border rounded-lg">
      <h2 className="w-full text-vid-black-900 font-sans text-base font-normal leading-[120%]">
        {title}
      </h2>
      {content}
    </div>
  );
};

interface FrequencySliderProps {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
}

const FrequencySlider: React.FC<FrequencySliderProps> = ({
  min,
  max,
  value,
  onChange,
}) => {
  return (
    <div className="relative w-full">
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        className="w-full appearance-none bg-vid-black-100 h-2 rounded-full focus:outline-none focus:ring-2 focus:ring-vid-purple slider-thumb"
        style={{
          background: `linear-gradient(to right, #690DFF 0%, #690DFF ${
            ((value - min) / (max - min)) * 100
          }%, #E5E7EB ${((value - min) / (max - min)) * 100}%, #E5E7EB 100%)`,
        }}
      />
    </div>
  );
};
