import type { InMemoryFile } from "../schemas/file.schemas";

export interface SimpleUserDTO {
  id: string;
  name: string;
  email: string;
  image: string | null;
}

export type ImageSrcData =
  | { _tag: "URL"; url: string }
  | { _tag: "InMemoryFile"; file: InMemoryFile };

export class ImageSrc {
  constructor(readonly data: ImageSrcData) {}

  static fromURL(url: string): ImageSrc {
    return new ImageSrc({ _tag: "URL", url });
  }

  static fromMbUrl(url: string | null): ImageSrc | null {
    return url ? ImageSrc.fromURL(url) : null;
  }

  static fromInMemoryFile(file: InMemoryFile): ImageSrc {
    return new ImageSrc({ _tag: "InMemoryFile", file });
  }

  toSrc(): string {
    return this.data._tag === "URL"
      ? this.data.url
      : this.data.file.base64String;
  }
}
