import type { InputDeviceStatus } from "@stream-io/video-react-sdk";
import { Option } from "effect";
import * as Rx from "rxjs";
import type { ApiMgr } from "../../../api.mgr";
import { createContextAndHook } from "../../../util";
import { BaseStateMgr } from "../base.statemgr";

// class RemoteParticipantSyncMgr {
//   private remoteParticipantIdsSync$ = new Rx.BehaviorSubject<string[]>([]);
//   remoteParticipantIds$ = this.remoteParticipantIdsSync$.pipe(
//     RxO.distinctUntilChanged()
//   );
//   syncNewRemoteParticipantsValue = (p: string[]) => {
//     this.remoteParticipantIdsSync$.next(p);
//   };
// }

export class CallStateMgr extends BaseStateMgr {
  screenshareState$ = new Rx.BehaviorSubject<InputDeviceStatus | null>(null);
  mbLeaveResult$ = new Rx.BehaviorSubject<Option.Option<any>>(Option.none());
  mbConnectAndJoinResult$ = new Rx.BehaviorSubject<Option.Option<any>>(
    Option.none()
  );

  constructor(readonly callId: string, apiMgr: ApiMgr) {
    super({ apiMgr });
  }
}

export const [CallStateMgrContext, useCallStateMgr] =
  createContextAndHook<CallStateMgr>();
