import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { useMe, useUMgr } from "@pages/u/u.webstatemgr";
import { apiMgr } from "@webapp/backend";
import { ProfileImageSelector } from "@webapp/componentsavatar.tc";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { Effect, Either, Option } from "effect";
import { pipe } from "fp-ts/function";
import { useAuthedFetchTE, type ApiMgr } from "frontend-shared/src/api.mgr";
import { StandardFormStateMgr } from "frontend-shared/src/mgrs/state-mgrs/base.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React, { useMemo } from "react";
import { HpProfile, type SimpleUserWithProfilePhoto } from "shared";
import { O, RD } from "shared/base-prelude";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { BaseUserInputData } from "shared/types/user.types";

export const HpSettingsEditProfileForm: React.FC = () => {
  const rdMyProfile = useAuthedFetchTE(
    (Api) => Api.hp.getMyProfile.query(),
    []
  );

  return pipe(
    rdMyProfile,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      (profile) => <EditProfileSection initialProfile={profile} />
    )
  );
};

const inputClassNames = `rounded-full border border-gray-400`;

class EditProfileFormMgr extends StandardFormStateMgr<
  BaseUserInputData,
  { profilePhotos: { thumbUrl: string; mediumUrl: string } | null },
  unknown
> {
  onSubmit = (formData: BaseUserInputData) => {
    return this.BE.fetchEndpointWithHandleError((Api) =>
      Api.u.me.setBaseProfile.mutate(formData)
    );
  };

  constructor(p: {
    initialProfile: SimpleUserWithProfilePhoto & HpProfile;
    me: SimpleUserWithProfilePhoto;
    apiMgr: ApiMgr;
  }) {
    super({
      apiMgr: p.apiMgr,
      mbInitialData: Option.some({
        firstName: p.initialProfile.firstName,
        lastName: p.initialProfile.lastName,
        email: p.initialProfile.email,
        profilePhotoRemotePtr: null,
      }),
      defaultData: BaseUserInputData.default,
    });
  }
}

const EditProfileSection: React.FC<{ initialProfile: HpProfile }> = ({
  initialProfile,
}) => {
  const uMgr = useUMgr();
  const hpState = useHpState();
  const me = useMe();
  const formMgr = useOnce(
    () =>
      new EditProfileFormMgr({
        initialProfile: { ...initialProfile, ...me },
        me,
        apiMgr: apiMgr,
      })
  );
  const firstName = formMgr.useFormValue("firstName");
  const lastName = formMgr.useFormValue("lastName");
  const email = formMgr.useFormValue("email");
  const rdSubmitResult = useObservableEagerState(formMgr.submitResult$);

  const currentMbProfilePhoto = useMemo(() => {
    if (rdSubmitResult.isLoading) {
      return null;
    }
    const mbSuccessProfPhoto = rdSubmitResult.asMbSuccess.pipe(
      Option.flatMap((p) => Option.fromNullable(p.profilePhotos?.mediumUrl))
    );
    if (Option.isSome(mbSuccessProfPhoto)) {
      return ImageSrc.fromURL(mbSuccessProfPhoto.value);
    }
    return ImageSrc.fromMbUrl(me.profilePhoto);
  }, [rdSubmitResult, me.profilePhoto]);

  if (rdSubmitResult.isLoading) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-12">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          Effect.runPromise(formMgr.submitEff).then((res) => {
            console.log("SUBMITTED");
            if (Either.isRight(res) && res.right.profilePhotos !== null) {
              Effect.runPromise(uMgr.refreshMe()).then((_) => {
                hpState.dashboardState.showBottomToast({
                  msg: "Profile updated",
                });
              });
            }
          });
        }}
        className="flex flex-col gap-4"
      >
        <ProfileImageSelector
          currentMbProfile={currentMbProfilePhoto}
          onImageSelectedAndTransformed={(res) => {
            console.log("res! ", res);
            formMgr.setFormValue("profilePhotoRemotePtr", {
              publicId: res.publicId,
              assetId: res.assetId,
            });
          }}
        />
        <div className="flex items-center gap-4">
          <input
            className={`${inputClassNames} px-4 py-2`}
            value={firstName}
            onChange={(e) => {
              formMgr.setFormValue("firstName", e.target.value);
            }}
          />
          <input
            className={`${inputClassNames} px-4 py-2`}
            value={lastName}
            onChange={(e) => {
              formMgr.setFormValue("lastName", e.target.value);
            }}
          />
        </div>
        <input
          className={`${inputClassNames} px-4 py-2`}
          value={email}
          onChange={(e) => {
            formMgr.setFormValue("email", e.target.value);
          }}
        />
        {RD.isPending(
          rdSubmitResult as unknown as RD.RemoteData<unknown, unknown>
        ) ? (
          <FullContainerLoadingSpinner />
        ) : (
          <button
            className="bg-vid-purple text-white rounded-lg p-4 self-start self-center"
            type="submit"
          >
            Save
          </button>
        )}
      </form>
    </div>
  );
};
