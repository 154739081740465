import { differenceInMinutes, format, setHours } from "date-fns";
import { useObservableState } from "observable-hooks";
import React, { useEffect, useRef } from "react";
import type {
  CalendarEventInstanceIsh,
  EventInstanceId,
} from "shared/types/calendar.types";
import { RD, Rx } from "../../prelude";

interface AppointmentProps {
  id: EventInstanceId;
  title: string;
  startTime: Date;
  endTime: Date;
  overlapCount: number;
  position: number;
}

const HOUR_HEIGHT = 240; // Height in pixels for an hour block

const AppointmentView: React.FC<AppointmentProps> = ({
  title,
  startTime,
  endTime,
  overlapCount,
  position,
}) => {
  const duration = differenceInMinutes(endTime, startTime);
  const appointmentHeight = (duration / 60) * HOUR_HEIGHT;
  const width = 100 / overlapCount;
  return (
    <div
      className="absolute bg-blue-200 rounded-md p-2 text-xs opacity-40"
      style={{
        top: `${
          (differenceInMinutes(startTime, setHours(startTime, 0)) / 60) *
          HOUR_HEIGHT
        }px`,
        height: `${appointmentHeight}px`,
        width: `${width}%`,
        left: `${position * width}%`,
      }}
    >
      <div className="font-bold">{title}</div>
      <div>
        {format(startTime, "p")} - {format(endTime, "p")}
      </div>
    </div>
  );
};

type DayViewCalendarProps<Appt extends CalendarEventInstanceIsh> = {
  // currentDay$: Rx.BehaviorSubject<Date>;
  appointmentsForCurrentDay$: Rx.Observable<RD.RemoteData<any, Appt[]>>;
};
export const DayViewCalendar = <Appt extends CalendarEventInstanceIsh>({
  appointmentsForCurrentDay$,
}: DayViewCalendarProps<Appt>) => {
  const appointments = useObservableState(
    appointmentsForCurrentDay$,
    RD.initial
  );

  const hours = Array.from({ length: 24 }, (_, index) =>
    setHours(new Date(), index)
  );

  const containerRef = useRef<HTMLDivElement>(null);

  // Step 2: Adjust scrollTop to start at 8 AM
  useEffect(() => {
    if (containerRef.current) {
      const startHour = 8; // The hour you want to start at
      const scrollTopValue = startHour * HOUR_HEIGHT;
      containerRef.current.scrollTop = scrollTopValue;
    }
  }, []);

  if (!RD.isSuccess(appointments)) {
    return null;
  }

  return (
    <div
      className="flex flex-col h-screen overflow-auto mt-4"
      ref={containerRef}
    >
      {/* <div className="text-lg font-semibold text-center py-4">
        {format(new Date(), "EEEE, MMMM d, yyyy")}
      </div> */}
      <div className="relative">
        {hours.map((hour, index) => (
          <div
            key={index}
            className="border-t border-gray-300 flex items-center pl-2"
            style={{
              height: `${HOUR_HEIGHT}px`,
            }}
          >
            {format(hour, "ha")}
          </div>
        ))}
        {appointments.value.map((appointment) => (
          <AppointmentView
            id={appointment.instanceId}
            key={appointment.instanceId}
            title={appointment.title}
            startTime={appointment.startTime}
            endTime={appointment.endTime}
            overlapCount={1}
            position={0}
          />
        ))}
      </div>
    </div>
  );
};
