import * as S from "@effect/schema/Schema";
import { differenceInMinutes } from "date-fns";
import { Effect, Match, Option } from "effect";
import { CallStateN } from "../schemas/call.schemas";
import { GroupSessionInfo } from "../schemas/group-session.schemas";
import { SessionCallStatus } from "../schemas/session.schemas";
import {
  AttendeeUser,
  AttendingStatus,
  EventInstanceId,
  EventTemplateId,
  type NearbyCalendarMonths,
  type TaggedCalendarEventInstance,
} from "./calendar.types";

const EnterGroupSession = S.TaggedStruct("EnterGroupSession", {
  groupSessionId: S.String,
  entryMethod: S.Union(S.Literal("JOIN"), S.Literal("START")),
});
type EnterGroupSession = S.Schema.Type<typeof EnterGroupSession>;

const NextUserCtaForCommunityEventOption = S.Union(
  S.TaggedStruct("RSVP", {}),
  EnterGroupSession
);
type NextUserCtaForCommunityEventOption = S.Schema.Type<
  typeof NextUserCtaForCommunityEventOption
>;

export class NextUserCtaForCommunityEvent extends S.TaggedClass<NextUserCtaForCommunityEvent>()(
  "NextUserCtaForCommunityEvent",
  {
    cta: NextUserCtaForCommunityEventOption,
  }
) {}

const PrimaryUserStatusForCommunityEventOption = S.Union(
  S.Literal("ATTENDING"),
  S.Literal("INTERESTED"),
  S.Literal("WAITING_FOR_HOST_TO_START")
);
type PrimaryUserStatusForCommunityEventOption = S.Schema.Type<
  typeof PrimaryUserStatusForCommunityEventOption
>;

export class PrimaryUserStatusForCommunityEvent extends S.TaggedClass<PrimaryUserStatusForCommunityEvent>()(
  "PrimaryUserStatusForCommunityEvent",
  {
    status: PrimaryUserStatusForCommunityEventOption,
  }
) {}

const CtaOrStatus = S.Union(
  NextUserCtaForCommunityEvent,
  PrimaryUserStatusForCommunityEvent
);
type CtaOrStatus = S.Schema.Type<typeof CtaOrStatus>;

export class PrimaryCtaOrStatusForCommunityEvent extends S.Class<PrimaryCtaOrStatusForCommunityEvent>(
  "PrimaryCtaOrStatusForCommunityEvent"
)({
  ctaOrStatus: CtaOrStatus,
}) {
  static asButtonTitle = (
    p: PrimaryCtaOrStatusForCommunityEvent
  ): string | null =>
    Match.value(p.ctaOrStatus).pipe(
      Match.tag("NextUserCtaForCommunityEvent", ({ cta }) => {
        return Match.value(cta).pipe(
          Match.tag("RSVP", () => "RSVP"),
          Match.tag("EnterGroupSession", ({ entryMethod }) => entryMethod),
          Match.exhaustive
        );
      }),
      Match.tag("PrimaryUserStatusForCommunityEvent", ({ status }) => {
        return Match.value(status).pipe(
          Match.when("ATTENDING", () => "Attending"),
          Match.when(
            "WAITING_FOR_HOST_TO_START",
            () => "Waiting for host to start"
          ),
          Match.orElse(
            () => status.toLowerCase().charAt(0).toUpperCase() + status.slice(1)
          )
        );
      }),
      Match.exhaustive
    );

  isNextUserCta = (): NextUserCtaForCommunityEvent | null => {
    return Match.value(this.ctaOrStatus).pipe(
      Match.tag("NextUserCtaForCommunityEvent", (cta) => cta),
      Match.orElse(() => null)
    );
  };

  static mkAsStatus = (
    status: PrimaryUserStatusForCommunityEventOption
  ): PrimaryCtaOrStatusForCommunityEvent => {
    return new PrimaryCtaOrStatusForCommunityEvent({
      ctaOrStatus: new PrimaryUserStatusForCommunityEvent({ status }),
    });
  };

  static mkAsNextUserCta = (
    cta: NextUserCtaForCommunityEventOption
  ): PrimaryCtaOrStatusForCommunityEvent => {
    return new PrimaryCtaOrStatusForCommunityEvent({
      ctaOrStatus: new NextUserCtaForCommunityEvent({ cta }),
    });
  };

  static isPrimaryUserStatus = (
    p: CtaOrStatus
  ): p is PrimaryUserStatusForCommunityEvent => {
    return p._tag === "PrimaryUserStatusForCommunityEvent";
  };

  static fromInstanceAndCallState(p: {
    instance: {
      myStatus: AttendingStatus | null;
      hostId: string;
      startsAt: Date;
    };
    forUserId: string;
    mbGroupSession: Option.Option<{
      state: SessionCallStatus.Status;
      info: GroupSessionInfo;
    }>;
  }): PrimaryCtaOrStatusForCommunityEvent | null {
    if (p.instance.myStatus === null) {
      return new PrimaryCtaOrStatusForCommunityEvent({
        ctaOrStatus: new NextUserCtaForCommunityEvent({
          cta: { _tag: "RSVP" },
        }),
      });
    }

    if (Option.isNone(p.mbGroupSession)) {
      return null;
    }

    if (p.instance.myStatus === "attending") {
      const isInStartableWindow =
        p.mbGroupSession.value.info.isInStartableWindow;
      const isUserHost = p.instance.hostId === p.forUserId;
      if (isInStartableWindow) {
        return PrimaryCtaOrStatusForCommunityEvent.mkAsNextUserCta({
          _tag: "EnterGroupSession",
          groupSessionId: p.mbGroupSession.value.info.id,
          entryMethod: isUserHost ? "START" : "JOIN",
        });
      }
    }

    return null;
  }
}

const FrequencyType = S.Union(S.Literal("daily"), S.Literal("weekly"));
type FrequencyType = S.Schema.Type<typeof FrequencyType>;
export class FullCommunityEventInstanceData extends S.Class<FullCommunityEventInstanceData>(
  "FullCommunityEventInstanceData"
)({
  instanceId: EventInstanceId,
  eventTemplateId: EventTemplateId,
  title: S.String,
  hostId: S.NullOr(S.String),
  description: S.NullOr(S.String),
  startTime: S.Date,
  endTime: S.Date,
  durationInMinutes: S.Number,
  recurrenceRule: S.NullOr(
    S.Struct({
      frequency: FrequencyType,
      interval: S.NullOr(S.Number),
    })
  ),
  attendees: S.Array(AttendeeUser),
  communityInfo: S.Struct({
    slug: S.String,
    name: S.String,
  }),
  myStatus: S.NullOr(AttendingStatus),
  shareableLink: S.String,
  primaryCtaOrStatus: S.NullOr(PrimaryCtaOrStatusForCommunityEvent),
  mbGroupSession: S.Option(
    S.Struct({
      info: GroupSessionInfo,
      state: SessionCallStatus.Status,
    })
  ),
  isLive: S.Boolean,
}) {
  static fromTaggedCalendarEventInstance = (
    taggedInstance: TaggedCalendarEventInstance,
    p: {
      forUserId: string;
      getGroupSessionEff: (p: {
        groupSessionId: string;
        forUser: { id: string };
      }) => Effect.Effect<{
        info: GroupSessionInfo;
        state: SessionCallStatus.Status;
      }>;
      withUsersProfilePhotos: <Userish>(p: {
        users: readonly Userish[];
        getUserId: (user: Userish) => string;
        getProfilePhotoSavedAt: (user: Userish) => Date | null;
      }) => Effect.Effect<(Userish & { profilePhoto: string | null })[]>;
      readonly mkShareableLink: Effect.Effect<string>;
    }
  ): Effect.Effect<FullCommunityEventInstanceData> =>
    Effect.gen(function* () {
      const instance = taggedInstance.unsafeAsCommunity();
      const myStatus =
        instance.attendees.find((aus) => aus.userId === p.forUserId)
          ?.attendingStatus ?? null;
      var mbGroupSession: Option.Option<{
        info: GroupSessionInfo;
        state: SessionCallStatus.Status;
      }> = Option.none();
      if (instance.communityInfo.groupSessionId) {
        mbGroupSession = yield* p
          .getGroupSessionEff({
            groupSessionId: instance.communityInfo.groupSessionId,
            forUser: { id: p.forUserId },
          })
          .pipe(Effect.map(Option.some));
      }
      const attendeesWithProfilePhotos = yield* p.withUsersProfilePhotos({
        users: instance.attendees,
        getUserId: (aus) => aus.userId,
        getProfilePhotoSavedAt: (aus) => aus.profilePhotoUploadedAt,
      });
      const isLive = mbGroupSession.pipe(
        Option.map((gs) => gs.state.isLive),
        Option.getOrElse(() => false)
      );
      const primaryCtaOrStatus =
        PrimaryCtaOrStatusForCommunityEvent.fromInstanceAndCallState({
          instance: {
            myStatus,
            hostId: instance.host.id,
            startsAt: instance.startTime,
          },
          forUserId: p.forUserId,
          mbGroupSession: mbGroupSession.pipe(
            Option.map((gs) => ({
              state: gs.state,
              info: gs.info,
            }))
          ),
        });

      const shareableLink = yield* p.mkShareableLink;
      return yield* Effect.succeed(
        FullCommunityEventInstanceData.make(
          {
            ...instance,
            attendees: attendeesWithProfilePhotos,
            shareableLink,
            hostId: instance.host.id,
            myStatus,
            communityInfo: {
              slug: instance.communityInfo.communitySlug,
              name: instance.communityInfo.communityName,
            },
            primaryCtaOrStatus,
            durationInMinutes: differenceInMinutes(
              instance.endTime,
              instance.startTime
            ),
            mbGroupSession: mbGroupSession,
            isLive,
          },
          { disableValidation: true }
        )
      );
    });
}

export interface CommunityEventCallInfo {
  callState: CallStateN.State;
}

export interface AllRelevantEvents {
  nearbyMonthEvents: NearbyCalendarMonths<FullCommunityEventInstanceData>;
  upcomingEvents: FullCommunityEventInstanceData[];
}
