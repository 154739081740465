import {
  DocumentSnapshot,
  deleteField,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { type HpDashboardNotifs } from "shared";
import { FirebaseJsMgr } from "../firebase";
import { createContextAndHook } from "../util";

export class PubSubNotificationsMgmt {
  static readonly HP_DASHBOARD = "hp-dashboard-notifications";

  constructor(readonly firebaseJs: FirebaseJsMgr) {}

  docForHpDashboard(p: { hpId: string }) {
    console.log("DOC FOR HP DASHBOARD! ", this.firebaseJs.firestore.toJSON());
    return doc(
      this.firebaseJs.firestore,
      PubSubNotificationsMgmt.HP_DASHBOARD,
      p.hpId
    );
  }

  addHpDashboardNotif(p: { hpId: string; notif: Partial<HpDashboardNotifs> }) {
    const hpDashboardDoc = this.docForHpDashboard({ hpId: p.hpId });
    return setDoc(hpDashboardDoc, p.notif, { merge: true });
  }

  notifyCpAcceptedHpRequest(p: { hpId: string; cpName: string }) {
    const data = JSON.stringify({
      accpetedRequestAt: new Date().toISOString(),
      cpName: p.cpName,
    });
    this.addHpDashboardNotif({
      hpId: p.hpId,
      notif: { cpAcceptedRequest: data },
    }).then((r) => {
      console.log("SET HP DASHBOARD NOTIF", r);
    });
  }

  async confirmHpReceivedNotif(p: {
    hpId: string;
    notif: keyof HpDashboardNotifs;
  }): Promise<void> {
    const hpDashboardDoc = this.docForHpDashboard({ hpId: p.hpId });
    getDoc(hpDashboardDoc).then((r) => {
      console.log("DOC! ", r.data());
    });
    return updateDoc(hpDashboardDoc, { [p.notif]: deleteField() });
  }

  subscribeToHpDashboardNotifications(p: {
    hpId: string;
    onSnapChange: (snap: DocumentSnapshot<HpDashboardNotifs>) => void;
  }) {
    const hpDashboardDoc = this.docForHpDashboard({ hpId: p.hpId });
    return onSnapshot(hpDashboardDoc, (snap) => {
      console.log("HP DASHBOARD NOTIF", snap);
      p.onSnapChange(snap);
    });
  }
}

export const [PubSubNotificationsMgmtContext, usePubSubNotificationsMgmt] =
  createContextAndHook<PubSubNotificationsMgmt>();
