import * as S from "@effect/schema/Schema";
import { Option } from "effect";

export interface InMemoryFile {
  base64String: string;
  fileMetadata: FileSchemas.FileMetadata;
}

export type FileLike = { size: number; type: string };

export namespace FileSchemas {
  export const ImageFileExt = S.Union(
    S.Literal("jpg"),
    S.Literal("jpeg"),
    S.Literal("png"),
    S.Literal("webp")
  );
  export type ImageFileExt = S.Schema.Type<typeof ImageFileExt>;

  export const VideoFileExt = S.Union(
    S.Literal("mp4"),
    S.Literal("mov"),
    S.Literal("avi")
  );
  export type VideoFileExt = S.Schema.Type<typeof VideoFileExt>;

  export const AudioFileExt = S.Union(S.Literal("mp3"));
  export type AudioFileExt = S.Schema.Type<typeof AudioFileExt>;

  export const AnyFileExt = S.Union(ImageFileExt, VideoFileExt, AudioFileExt);
  export type AnyFileExt = S.Schema.Type<typeof AnyFileExt>;

  const ImageMimeTypeLit = S.Union(
    S.Literal("image/jpeg"),
    S.Literal("image/jpg"),
    S.Literal("image/png"),
    S.Literal("image/webp")
  );
  export type ImageMimeTypeLit = S.Schema.Type<typeof ImageMimeTypeLit>;

  const VideoMimeTypeLit = S.Union(
    S.Literal("video/mp4"),
    S.Literal("video/quicktime"),
    S.Literal("video/avi")
  );
  export type VideoMimeTypeLit = S.Schema.Type<typeof VideoMimeTypeLit>;

  const AudioMimeTypeLit = S.Union(S.Literal("audio/mp3"));
  export type AudioMimeTypeLit = S.Schema.Type<typeof AudioMimeTypeLit>;

  export const MimeTypeLit = S.Union(
    ImageMimeTypeLit,
    VideoMimeTypeLit,
    AudioMimeTypeLit
  );
  export type MimeTypeLit = S.Schema.Type<typeof MimeTypeLit>;

  export class MimeType extends S.Class<MimeType>("MimeType")({
    mimeType: S.Union(MimeTypeLit, S.String),
  }) {
    static fromString(s: string): MimeType {
      const lit = S.decodeUnknownSync(MimeTypeLit)(s);
      return new MimeType({ mimeType: lit });
    }

    asExt(): AnyFileExt {
      switch (this.mimeType) {
        case "video/quicktime":
          return "mov";
        case "video/mp4":
          return "mp4";
        case "video/avi":
          return "avi";
        case "audio/mp3":
          return "mp3";
        case "image/jpeg":
        case "image/jpg":
          return "jpg";
        case "image/png":
          return "png";
        case "image/webp":
          return "webp";
        default:
          // Fallback to the original logic
          const split = this.mimeType.split("/");
          const ext = split[split.length - 1];
          return S.decodeUnknownSync(AnyFileExt)(ext);
      }
    }

    unsafeAsImageExt(): ImageFileExt {
      return S.decodeUnknownSync(ImageFileExt)(this.asExt());
    }
  }

  export const MediaType = S.Union(
    S.Literal("video"),
    S.Literal("image"),
    S.Literal("audio")
  );
  export type MediaType = typeof MediaType.Type;

  export class FileMetadata extends S.Class<FileMetadata>("FileMetadata")({
    mimeType: MimeTypeLit,
    sizeInBytes: S.Number,
  }) {
    static decodeOptionFromFile(file: FileLike): Option.Option<FileMetadata> {
      return S.decodeUnknownOption(MimeTypeLit)(file.type).pipe(
        Option.map(
          (mimeType) =>
            new FileMetadata({
              mimeType,
              sizeInBytes: file.size,
            })
        )
      );
    }

    static unsafeDecodeFromFile(file: FileLike): FileMetadata {
      const mbFileMetadata = FileMetadata.decodeOptionFromFile(file);
      if (Option.isNone(mbFileMetadata)) {
        throw new Error("Failed to decode file metadata");
      }
      return mbFileMetadata.value;
    }

    asExt(): AnyFileExt {
      return MimeType.fromString(this.mimeType).asExt();
    }

    unsafeAsImageExt(): ImageFileExt {
      return S.decodeUnknownSync(ImageFileExt)(this.asExt());
    }

    get isQuicktimeVideo(): boolean {
      return this.mimeType === "video/quicktime";
    }

    get mediaType(): MediaType {
      const [type] = this.mimeType.split("/");
      switch (type) {
        case "image":
        case "video":
        case "audio":
          return type;
        default:
          throw new Error(`Unsupported media type: ${type}`);
      }
    }
  }
}

export interface UploadFileToCloudinaryResponse {
  asset_id: string;
  public_id: string;
  secure_url: string;
}
