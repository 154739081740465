import { apiMgr } from "@webapp/backend";
import { DashboardRightDrawerContainer } from "@webapp/componentsdashboard/right-nav.components";
import { CalendarMenuInput } from "@webapp/componentsform/calendar.input";
import { PrimaryButton } from "@webapp/componentsprimitives/button";
import {
  SelectUserSection,
  SetDurationSection,
  SetPrimaryTherapyTypeSection,
} from "@webapp/componentssession/session-form.components";
import { RxO } from "@webapp/prelude";
import { FetchRemoteDataView } from "@webapp/utils/utils";
import { Match } from "effect";
import type { DefaultError } from "frontend-shared/src/api.mgr";
import { CpSchedulePrivateSessionFormStateMgr } from "frontend-shared/src/mgrs/state-mgrs/schedule-prive-session-form.statemgr";
import { useKeyOfObservable, useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect, useMemo } from "react";
import type { BaseNewPrivateSessionFormSubmit } from "shared";
import { RD, TE } from "shared/base-prelude";
import { RequestPrivateSessionApptForm } from "src/resources/request-private-session-apt/request-private-session-appt.form";
import { useCpState } from "./cpdashboard.state";

export type CpDashboardRightNav = {
  _tag: "NEW_APPOINTMENT";
};

export const DashboardRightDrawer: React.FC<{
  rightNav: CpDashboardRightNav;
}> = ({ rightNav }) => {
  const cpState = useCpState();
  return Match.value(rightNav).pipe(
    Match.tag("NEW_APPOINTMENT", () => (
      <DashboardRightDrawerContainer
        closeRightNav={() => {
          cpState.dashboardMgr.closeRightNav();
        }}
        topView={
          <div className="flex flex-col justify-center items-center p-8">
            <img src="/pending.png" className="w-fit h-fit rounded-full" />
            <p className="font-sans font-light mt-2 text-center">
              Request an appointment with your practitioner
            </p>
          </div>
        }
        content={
          <RequestPrivateSessionApptForm
            onSuccessSave={() => {
              cpState.dashboardMgr.closeRightNav();
              cpState.dashboardMgr.showBottomToast({
                msg: "We've notified your practitioner",
                reload: true,
                duration: { _tag: "SECONDS", seconds: 10 },
                confirmButton: {
                  label: "Got it",
                  onClick: () => {
                    cpState.dashboardMgr.hideBottomToast();
                  },
                },
              });
            }}
          />
        }
      />
    )),

    Match.exhaustive
  );
};

export const CpSchedulePrivateSessionForm: React.FC<{
  onSuccessSave: () => void;
  mbHp?: { id: string; name: string };
  requestApptId?: string;
  initialDate?: Date;
}> = ({ mbHp, onSuccessSave, initialDate }) => {
  return (
    <FetchRemoteDataView
      fetchTE={TE.right({} as Partial<BaseNewPrivateSessionFormSubmit>)}
      loadedView={(v: Partial<BaseNewPrivateSessionFormSubmit>) => (
        <CpCreateNewAppointmentFormPrefilled
          prefilledData={v}
          mbHp={mbHp}
          onSuccessSave={onSuccessSave}
          initialDate={initialDate}
        />
      )}
    />
  );
};

const ModelContext =
  React.createContext<CpSchedulePrivateSessionFormStateMgr | null>(null);

type OpenDropdownId =
  | "calendar"
  | "payment"
  | "user"
  | "therapyType"
  | "duration";

const CpCreateNewAppointmentFormPrefilled: React.FC<{
  prefilledData: Partial<BaseNewPrivateSessionFormSubmit>;
  onSuccessSave: () => void;
  mbHp?: { id: string; name: string };
  initialDate?: Date;
}> = ({ prefilledData, mbHp, onSuccessSave, initialDate }) => {
  const model = React.useMemo(
    () =>
      new CpSchedulePrivateSessionFormStateMgr({
        defaultPrefilledData: prefilledData,
        mbHp,
        apiMgr: apiMgr,
        initialDate,
      }),
    [prefilledData, mbHp, initialDate]
  );
  const startsAt = useObservableEagerState(model.formData$).startsAt;
  const [scheduleResult, setScheduleResult] = React.useState<
    RD.RemoteData<DefaultError, unknown>
  >(RD.initial);
  const hpList = useObservableEagerState(model.hpList$);
  const selectedHp$ = useKeyOfObservable(model.formData$, "hp");
  const selectedHpId$ = useMemo(
    () => selectedHp$.pipe(RxO.map((hp) => hp?.id ?? null)),
    [selectedHp$]
  );
  const therapyTypeSlug$ = useOnce(() =>
    model.formData$.pipe(RxO.map((f) => f.therapyTypeSlug))
  );
  const durationInMinutes$ = useOnce(() =>
    model.formData$.pipe(RxO.map((f) => f.durationInMinutes))
  );
  const priceCents$ = useKeyOfObservable(model.formData$, "priceCents");
  const [paymentMethod, setPaymentMethod] = React.useState("Auto-pay");

  const [openDropdownId, setOpenDropdownId] =
    React.useState<OpenDropdownId | null>(null);

  useEffect(() => {
    model.submitResult$.subscribe((rd) => {
      if (RD.isSuccess(rd)) {
        setScheduleResult(rd.value);
        onSuccessSave();
      }
    });
  }, []);

  return (
    <ModelContext.Provider value={model}>
      <div className="flex-1 flex flex-col justify-between px-8 py-4 overflow-y-scroll gap-4">
        <div className="flex-1 flex flex-col gap-4">
          <CalendarMenuInput
            isOpen={openDropdownId === "calendar"}
            onToggle={(isOpen) => setOpenDropdownId(isOpen ? "calendar" : null)}
            initialDate={startsAt ?? undefined}
            onSelection={(d) => model.setFormValue("startsAt", d)}
            title="Date & time"
          />
          <SelectUserSection
            label="Therapist/coach"
            isOpen={openDropdownId === "user"}
            onToggle={(isOpen) => setOpenDropdownId(isOpen ? "user" : null)}
            selectedUserId$={selectedHpId$}
            users={hpList}
            onUserSelect={(hp) => {
              model.setFormValue("hp", hp);
            }}
          />

          <SetPrimaryTherapyTypeSection
            isOpen={openDropdownId === "therapyType"}
            onToggle={(isOpen) =>
              setOpenDropdownId(isOpen ? "therapyType" : null)
            }
            therapyTypeSlug$={therapyTypeSlug$}
            onChange={(tt) => model.setFormValue("therapyTypeSlug", tt)}
          />

          <SetDurationSection
            isOpen={openDropdownId === "duration"}
            onToggle={(isOpen) => setOpenDropdownId(isOpen ? "duration" : null)}
            durationInMinutes$={durationInMinutes$}
            onChange={(d) => model.setFormValue("durationInMinutes", d)}
          />
        </div>
        <div className="mt-8 w-full">
          <PrimaryButton
            title="Save"
            onClick={() => {
              console.log("Save!");
              model.submit();
            }}
          />
          {RD.isFailure(scheduleResult) &&
            scheduleResult.error._tag === "ErrorFromServer" && (
              <div className="text-red-500">
                {scheduleResult.error.error.message}
              </div>
            )}
        </div>
      </div>
    </ModelContext.Provider>
  );
};
