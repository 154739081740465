import { Match } from "effect";
import { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { KNOWN_TOOL, TherapyToolMenuViewState } from "shared";

export const TherapyToolMenuButton: React.FC<{
  content: {
    title: string;
    description: string;
    iconName?: string;
  };
  onClick: () => void;
}> = ({ content, onClick }) => {
  const { title, description, iconName } = content;
  return (
    <div
      className="h-[70px] w-[312px] flex items-center rounded-[12px] px-4 border border-vid-black-200 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex-1 h-[40px] flex items-center gap-5">
        <div className="w-[40px] h-[40px] bg-gray-200 rounded-full flex items-center justify-center">
          {iconName && <img src={`/${iconName}.svg`} alt={title} />}
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div className="text-sm text-vid-black-800 ">{title}</div>
          <div className="text-xs text-vid-black-600">{description}</div>
        </div>
      </div>
    </div>
  );
};

export type KnownTools = {
  tool: KNOWN_TOOL;
  component: React.ReactNode;
}[];

export const TherapyToolMenuContainer: React.FC<{
  knownTools: KnownTools;
  initialViewState?: TherapyToolMenuViewState;
}> = ({ knownTools, initialViewState }) => {
  const [viewState, setViewState] = useState<TherapyToolMenuViewState>(
    initialViewState ?? { _tag: "MENU" }
  );

  return Match.value(viewState).pipe(
    Match.when({ _tag: "MENU" }, () => (
      <div className="self-stretch flex flex-col gap-4">
        {knownTools.map(({ tool }) => (
          <TherapyToolMenuButton
            key={tool}
            content={ButtonContentForKnownTool[tool]}
            onClick={() => {
              setViewState({ _tag: "TOOL", tool });
            }}
          />
        ))}
      </div>
    )),
    Match.when({ _tag: "TOOL" }, ({ tool }) => (
      <div className="self-stretch flex-1 flex flex-col gap-4">
        <button onClick={() => setViewState({ _tag: "MENU" })}>
          <IoArrowBack size={24} />
        </button>
        {knownTools.find((t) => t.tool === tool)?.component}
      </div>
    )),
    Match.exhaustive
  );
};

const ButtonContentForKnownTool: Record<
  KNOWN_TOOL,
  { title: string; description: string; iconName?: string }
> = {
  notes: {
    title: "Session Notes",
    description: "Bookmarks and recordings",
    iconName: "note",
  },
  emdr: {
    title: "Emdr",
    description: "Emdr",
    iconName: "emdr-balls-icon",
  },
  music: {
    title: "Music",
    description: "Music",
    iconName: "music",
  },
  screenshare: {
    title: "Screenshare",
    description: "Share your screen with the client",
    // iconName: "screen-share",
  },
  meditation: {
    title: "Meditation",
    description: "Meditation",
    iconName: "meditation-icon",
  },
  chat: {
    title: "Chat",
    description: "Chat with the client",
  },
  review: {
    title: "Review",
    description: "Review this session",
    // iconName: "review-icon",
  },
};
