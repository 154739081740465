import { useCall } from "@stream-io/video-react-sdk";
import { apiMgr } from "@webapp/backend";
import { WebkitAudioPlayer } from "@webapp/mgrs/web-audio-player";
import {
  CpPrivateSessionRoomCallStateMgr,
  CpPrivateSessionRoomCallStateMgrContext,
} from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/cp-room-call.statemgr";
import {
  usePrivateSessionCallStateMgr,
  usePrivateSessionStateMgr,
} from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/remote-state-and-call.statemgrs";
import { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";

export const CpPrivateSessionCallLayout: React.FC = () => {
  const psSessionStateMgr = usePrivateSessionStateMgr();
  const callStateMgr = usePrivateSessionCallStateMgr();
  const call = useCall()!;

  const cpRoomCallStateMgr = useMemo(
    () =>
      new CpPrivateSessionRoomCallStateMgr({
        apiMgr: apiMgr,
        sessionMgr: psSessionStateMgr,
        callStateMgr,
        remoteParticipants$: call.state.remoteParticipants$,
        audioPlayer: new WebkitAudioPlayer(() => {}),
      }),
    [callStateMgr, psSessionStateMgr]
  );

  useEffect(() => {
    console.log("RENDERING CP RTC LAYOUT! ", call.state.callingState);
    return () => {
      cpRoomCallStateMgr.cleanup();
    };
  }, [cpRoomCallStateMgr]);

  return (
    <CpPrivateSessionRoomCallStateMgrContext.Provider
      value={cpRoomCallStateMgr}
    >
      <Outlet />
    </CpPrivateSessionRoomCallStateMgrContext.Provider>
  );
};
