import { apiMgr, BE } from "@webapp/backend";
import { CalendarMenuInput } from "@webapp/components/form/calendar.input";
import { PrimaryButton } from "@webapp/components/primitives/button";
import { FormRadioCard } from "@webapp/components/primitives/radio-buttons";
import {
  SelectUserSection,
  SetDurationSection,
  SetPaymentSection,
  SetPrimaryTherapyTypeSection,
} from "@webapp/components/session/session-form.components";
import { RD, RxO } from "@webapp/prelude";
import { FetchRemoteDataView } from "@webapp/utils/utils";
import { Effect } from "effect";
import {
  type AuthedFetchError,
  type DefaultError,
} from "frontend-shared/src/api.mgr";
import { HpSchedulePrivateSessionFormStateMgr } from "frontend-shared/src/mgrs/state-mgrs/schedule-prive-session-form.statemgr";
import { useKeyOfObservable, useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React, { useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import type { BaseNewPrivateSessionFormSubmit } from "shared";
import { erte } from "shared/base-prelude";

const ModelContext =
  React.createContext<HpSchedulePrivateSessionFormStateMgr | null>(null);

function useModel() {
  return React.useContext(ModelContext)!;
}

const fetchDefaultAppointmentDataEff: Effect.Effect<
  Partial<BaseNewPrivateSessionFormSubmit>,
  AuthedFetchError
> = BE.fetchEndpointEff((Api) =>
  Api.hp.calendar.getDefaultMakeAppointmentData.query()
);
const fetchDefaultAppointmentDataTE = erte(fetchDefaultAppointmentDataEff);

export const HpSchedulePrivateSessionForm: React.FC<{
  onSuccessSave: () => void;
  mbClient: { id: string; name: string } | undefined;
  requestApptId?: string;
  initialDate?: Date;
}> = ({ mbClient, onSuccessSave, initialDate }) => {
  return (
    <FetchRemoteDataView
      fetchTE={fetchDefaultAppointmentDataTE}
      loadedView={(v) => (
        <HpCreateNewAppointmentFormPrefilled
          prefilledData={v}
          mbClient={mbClient}
          onSuccessSave={onSuccessSave}
          initialDate={initialDate}
        />
      )}
    />
  );
};

type OpenDropdownId =
  | "calendar"
  | "user"
  | "payment"
  | "therapyType"
  | "duration";

const HpCreateNewAppointmentFormPrefilled: React.FC<{
  prefilledData: Partial<BaseNewPrivateSessionFormSubmit>;
  onSuccessSave: () => void;
  mbClient: { id: string; name: string } | undefined;
  initialDate: Date | undefined;
}> = ({ prefilledData, mbClient, onSuccessSave, initialDate }) => {
  const model = React.useMemo(
    () =>
      new HpSchedulePrivateSessionFormStateMgr({
        defaultPrefilledData: prefilledData,
        mbClient,
        apiMgr: apiMgr,
        requestApptId: undefined,
        initialDate,
      }),
    [prefilledData, mbClient, initialDate]
  );
  const startsAt = useObservableEagerState(model.formData$).startsAt;
  const [scheduleResult, setScheduleResult] = React.useState<
    RD.RemoteData<DefaultError, unknown>
  >(RD.initial);
  const clientsList = useObservableEagerState(model.clientsList$);
  const selectedClientId$ = useOnce(() =>
    model.formData$.pipe(RxO.map((f) => f.client?.id ?? null))
  );
  const therapyTypeSlug$ = useOnce(() =>
    model.formData$.pipe(RxO.map((f) => f.therapyTypeSlug))
  );
  const durationInMinutes$ = useOnce(() =>
    model.formData$.pipe(RxO.map((f) => f.durationInMinutes))
  );
  const priceCents$ = useKeyOfObservable(model.formData$, "priceCents");
  const [paymentMethod, setPaymentMethod] = React.useState("Auto-pay");

  const [openDropdownId, setOpenDropdownId] =
    React.useState<OpenDropdownId | null>(null);

  useEffect(() => {
    model.submitResult$.subscribe((rd) => {
      if (RD.isSuccess(rd)) {
        setScheduleResult(rd.value);
        onSuccessSave();
      }
    });
  }, []);

  return (
    <ModelContext.Provider value={model}>
      <div className="flex-1 flex flex-col justify-between px-8 py-4 overflow-y-scroll gap-4">
        <div className="flex-1 flex flex-col gap-4">
          <CalendarMenuInput
            isOpen={openDropdownId === "calendar"}
            onToggle={(isOpen) => setOpenDropdownId(isOpen ? "calendar" : null)}
            initialDate={startsAt ?? undefined}
            onSelection={(d) => model.setFormValue("startsAt", d)}
            title="Date & time"
          />
          <SelectUserSection
            label="Client"
            isOpen={openDropdownId === "user"}
            onToggle={(isOpen) => setOpenDropdownId(isOpen ? "user" : null)}
            selectedUserId$={selectedClientId$}
            users={clientsList}
            onUserSelect={(client) => {
              model.setFormValue("client", client);
            }}
          />
          <SetPaymentSection
            priceCents$={priceCents$}
            setPriceCents={(v) => model.setFormValue("priceCents", v)}
            isOpen={openDropdownId === "payment"}
            onToggle={(isOpen) => setOpenDropdownId(isOpen ? "payment" : null)}
          />
          <FormRadioCard
            title="Payment"
            radioProps={{
              options: [
                {
                  name: "Auto-pay",
                  icon: <img src="/autopay.svg" height={15} width={15} />,
                },
                {
                  name: "Outside platform",
                  icon: (
                    <img src="/outside-platform.svg" height={15} width={15} />
                  ),
                },
              ],
              value: paymentMethod,
              onChange: (v) => {
                console.log("PAYMENT METHOD! ", v);
                setPaymentMethod(v);
              },
            }}
          />

          <SetPrimaryTherapyTypeSection
            isOpen={openDropdownId === "therapyType"}
            onToggle={(isOpen) =>
              setOpenDropdownId(isOpen ? "therapyType" : null)
            }
            therapyTypeSlug$={therapyTypeSlug$}
            onChange={(tt) => model.setFormValue("therapyTypeSlug", tt)}
          />

          <SetDurationSection
            isOpen={openDropdownId === "duration"}
            onToggle={(isOpen) => setOpenDropdownId(isOpen ? "duration" : null)}
            durationInMinutes$={durationInMinutes$}
            onChange={(d) => model.setFormValue("durationInMinutes", d)}
          />
        </div>
        <div className="mt-8 w-full">
          <PrimaryButton
            title="Save"
            isLoading={RD.isPending(scheduleResult)}
            onClick={() => {
              console.log("Save!");
              model.submit();
            }}
          />
          {RD.isFailure(scheduleResult) &&
            scheduleResult.error._tag === "ErrorFromServer" && (
              <div className="text-red-500">
                {scheduleResult.error.error.message}
              </div>
            )}
        </div>
      </div>
    </ModelContext.Provider>
  );
};
