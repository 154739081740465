import {
  CallingState,
  ParticipantView,
  StreamVideoParticipant,
  useCall,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { ControlPanelComponents } from "@webapp/components/live-session/control-panel.components";
import {
  CustomParticipantViewUI,
  CustomVideoPlaceholder,
  WaitingRoomContainer,
} from "@webapp/components/live-session/live-session.components";
import { CopyLinkButton } from "@webapp/components/miscellaneous";
import { AvatarCircles } from "@webapp/componentsavatar.tc";
import {
  CommonListRows,
  ListContainer,
} from "@webapp/componentslist.components";
import { FullContainerLoadingSpinner, LoadingSpinner } from "@webapp/loading";
import { useGroupSessionRoomCallStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/group-sessions/group-session.statemgrs";
import { useObservableEagerState } from "observable-hooks";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { O, RD } from "shared/base-prelude";
import { UROUTES } from "shared/routes/u.routes";

export const HpGroupSessionWaitingRoomPage: React.FC = () => {
  const call = useCall()!;
  const gsMgr = useGroupSessionRoomCallStateMgr();
  const { callId, groupSessionId } = useTypedParams(
    UROUTES.GROUP_SESSIONS.GROUP_SESSION_ID.CALLS.CALL_ID.WAITING_ROOM
  );
  const nav = useNavigate();

  return (
    <WaitingRoomContainer
      onBackClick={() => {
        call.endCall().then((_) => {
          const mbEvent = gsMgr.groupSessionStateMgr.groupSession.mbEvent;
          console.log("mbEvent", mbEvent);
          if (O.isSome(mbEvent)) {
            nav(
              UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.ALL.buildPath(
                {
                  community: mbEvent.value.community.slug,
                },
                {
                  showEventRightNav: mbEvent.value.eventTemplateId,
                }
              )
            );
          } else {
            nav(UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.path);
          }
        });
      }}
      leftSide={<LeftSide />}
      rightSide={<RightSide groupSessionId={groupSessionId} />}
      gotoSession={{
        title: "Enter",
        link: UROUTES.GROUP_SESSIONS.GROUP_SESSION_ID.CALLS.CALL_ID.MAIN_ROOM.buildPath(
          {
            groupSessionId,
            callId,
          }
        ),
      }}
    />
  );
};

const RightSide: React.FC<{ groupSessionId: string }> = () => {
  const gsMgr = useGroupSessionRoomCallStateMgr();
  const rdInviteInfo = useObservableEagerState(gsMgr.rdInviteInfo$);
  const [inviteByEmail, setInviteByEmail] = useState("");

  if (!RD.isSuccess(rdInviteInfo)) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-8">
      {rdInviteInfo.value.availableForInvite.length > 0 ? (
        <ListContainer
          title={"Invite Members"}
          rows={rdInviteInfo.value.availableForInvite.map((m) =>
            CommonListRows.UserWithActionRow({
              user: m,
              action: {
                title: "Invite",
                onClick: () => {},
                maxWidthPixels: 100,
              },
            })
          )}
        />
      ) : (
        <div>No members available for invite</div>
      )}
      {/* <PrimaryButton
        title="Invite all members to join"
        onClick={() => {
          BE.fetchEndpointTE((Api) =>
            Api.u.groupSession.inviteAllGroupMembersToJoin.mutate({
              groupSessionId,
            })
          )().then((er) => {
            if (E.isRight(er)) {
              alert("Invites sent");
            }
          });
        }}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log("inviteByEmail", inviteByEmail);
          BE.fetchEndpointTE((Api) =>
            Api.u.groupSession.inviteByEmailToJoin.mutate({
              groupSessionId,
              email: inviteByEmail,
            })
          )().then((er) => {
            if (E.isRight(er)) {
              alert("Invite sent");
            }
          });
        }}
      >
        <TextInput
          label="Invite by email"
          value={inviteByEmail}
          onChange={(email) => {
            setInviteByEmail(email);
          }}
        />
        <PrimaryButton title="Invite" type="submit" />
      </form> */}
    </div>
  );
};

const LeftSide = () => {
  const gsMgr = useGroupSessionRoomCallStateMgr();
  const { useCallCallingState, useLocalParticipant, useRemoteParticipants } =
    useCallStateHooks();

  const callingState = useCallCallingState();
  const localParticipant = useLocalParticipant();
  const remoteParticipants = useRemoteParticipants();
  const { useMicrophoneState, useCameraState } = useCallStateHooks();
  const { microphone, isMute: isAudioMute } = useMicrophoneState();
  const { camera, isMute: isVideoMute } = useCameraState();

  if (callingState !== CallingState.JOINED || localParticipant === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-4 w-full h-full">
      <div className="relative flex-1">
        <ParticipantView
          participant={localParticipant}
          ParticipantViewUI={CustomParticipantViewUI}
          VideoPlaceholder={CustomVideoPlaceholder}
        />
        <div className="absolute bottom-2 right-4 flex gap-2">
          <ControlPanelComponents.MuteAudioButton
            isMuted={isAudioMute}
            onClick={() => {
              microphone?.toggle();
            }}
          />
          <ControlPanelComponents.MuteVideoButton
            isMuted={isVideoMute}
            onClick={() => {
              camera?.toggle();
            }}
          />
        </div>
      </div>
      {remoteParticipants.length === 0 ? (
        <div className="flex flex-col gap-2 items-center self-center">
          <h4 className="text-[24px]">Invite others to join</h4>
          <CopyLinkButton
            link={gsMgr.groupSessionStateMgr.groupSession.inviteLink}
          />
        </div>
      ) : (
        <div className="flex gap-4 self-center items-center">
          <AvatarCircles sources={remoteParticipants.map((p) => p.image)} />
          <h5 className="text-[24px]">{`${remoteParticipants.length} ${
            remoteParticipants.length === 1 ? "member has" : "members have"
          } joined`}</h5>
        </div>
      )}
    </div>
  );
};

export const MyParticipantList = (props: {
  participants: StreamVideoParticipant[];
}) => {
  const { participants } = props;
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
      {participants.map((participant) => (
        <ParticipantView
          participant={participant}
          key={participant.sessionId}
          ParticipantViewUI={CustomParticipantViewUI}
          VideoPlaceholder={CustomVideoPlaceholder}
        />
      ))}
    </div>
  );
};
export const MyFloatingLocalParticipant = (props: {
  participant?: StreamVideoParticipant;
}) => {
  const { participant } = props;

  if (!participant) {
    return <></>;
  }

  return (
    <div className="absolute top-4 left-4 w-60 h-40 shadow-md rounded-lg">
      <ParticipantView
        participant={participant}
        ParticipantViewUI={CustomParticipantViewUI}
        VideoPlaceholder={CustomVideoPlaceholder}
      />
    </div>
  );
};
