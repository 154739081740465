import { Array, Console, Effect, Option, String } from "effect";
import * as Rx from "rxjs";
import type {
  KNOWN_CALL_TYPE,
  RtcCredentials,
} from "shared/schemas/call.schemas";
import * as RxO from "rxjs/operators";

export abstract class GenericRtcMgr<
  RtcEngine extends GenericRtcEngine<ChannelMgr>,
  ChannelMgr extends GenericRtcChannelMgr
> {
  readonly client: RtcEngine;

  readonly mbCurrentChannelMgr$: Rx.BehaviorSubject<Option.Option<ChannelMgr>> =
    new Rx.BehaviorSubject<Option.Option<ChannelMgr>>(Option.none());

  constructor(
    readonly creds: RtcCredentials,
    mkEngine: (creds: RtcCredentials) => RtcEngine
  ) {
    this.client = mkEngine(creds);
    this.mbCurrentChannelMgr$.subscribe((mbCall) => {
      console.log("MB CURRENT CHANNEL MGR! ", mbCall);
    });

    this.client.state.calls$
      .pipe(
        RxO.tap((calls) => {
          console.log("RAW CLIENT CALLS: ", calls);
        }),
        RxO.map((calls) => Array.head(calls)),
        RxO.distinctUntilChanged((a, b) => {
          console.log("COMPARING CALLS! ", a, b);
          const mbACid = a.pipe(Option.map((call) => call.cid));
          const mbBCid = b.pipe(Option.map((call) => call.cid));
          const isSame = Option.getEquivalence(String.Equivalence)(
            mbACid,
            mbBCid
          );
          console.log("IS SAME: ", isSame);
          return isSame;
        })
      )
      .subscribe((mbCall) => {
        const mbCurrCid = this.mbCurrentChannelMgr$.value.pipe(
          Option.map((call) => call.cid)
        );
        const mbCallCid = mbCall.pipe(Option.map((call) => call.cid));
        console.log("MB CURR CID: ", mbCurrCid, "MB CALL CID: ", mbCallCid);
        if (Option.getEquivalence(String.Equivalence)(mbCurrCid, mbCallCid)) {
          return;
        }
        console.log("SETTING MB GLOBAL CURRENT CHANNEL! ", mbCall);

        this.mbCurrentChannelMgr$.next(mbCall);
      });
  }

  lazyJoinChannel = (p: {
    channelType: KNOWN_CALL_TYPE;
    channelId: string;
  }): Effect.Effect<ChannelMgr> =>
    Effect.gen(this, function* () {
      return yield* this.disconnectThenJoin(p);
    });

  handleDismountInCall = Effect.gen(this, function* () {
    yield* this.leaveCurChannel();
    yield* Effect.promise(() => this.client.disconnectUser());
  });

  withAssumedCurrentChannel = <V>(
    f: (channelMgr: ChannelMgr) => Effect.Effect<V>
  ): Effect.Effect<V> => {
    if (Option.isNone(this.mbCurrentChannelMgr$.value)) {
      return Effect.dieMessage("No current channel");
    }
    return f(this.mbCurrentChannelMgr$.value.value);
  };

  leaveCurChannel = (): Effect.Effect<void> =>
    this.withAssumedCurrentChannel((call) =>
      Effect.promise(() => call.leave())
    ).pipe(Effect.catchAll((_) => Console.log("ERROR LEAVING CHANNEL! ", _)));

  disconnectThenJoin = (p: {
    channelType: KNOWN_CALL_TYPE;
    channelId: string;
  }) =>
    Effect.gen(this, function* () {
      const disconnectRes = yield* Effect.promise(() =>
        this.client.disconnectUser()
      ).pipe(
        Effect.catchAllDefect((e) =>
          Console.log("ERROR DISCONNECTING USER BEFORE JOINING CHANNEL! ", e)
        )
      );
      console.log("DISCONNECTED USER BEFORE JOIN! ", disconnectRes);

      yield* Effect.promise(() =>
        this.client.connectUser(this.creds.user, this.creds.token)
      );

      const channelMgr = this.client.call(p.channelType, p.channelId);

      const joinRes = yield* Effect.promise(() => channelMgr.join()).pipe(
        Effect.catchAllDefect((e) => Console.log("ERROR JOINING CHANNEL! ", e))
      );
      console.log("JOINED CHANNEL! ", joinRes);
      return channelMgr;
    });
}

export abstract class GenericRtcChannelMgr {
  abstract readonly cid: string;

  abstract readonly state: {
    remoteParticipants$: Rx.Observable<GenericRtcRemoteParticipant[]>;
  };

  abstract join(p?: { create?: boolean }): Promise<void>;

  abstract leave(): Promise<void>;
}

export abstract class GenericRtcEngine<
  ChannelMgr extends GenericRtcChannelMgr
> {
  abstract readonly state: {
    calls$: Rx.Observable<ChannelMgr[]>;
  };

  abstract disconnectUser(): Promise<void>;

  abstract connectUser(user: { id: string }, token: string): Promise<any>;

  abstract call(channelType: KNOWN_CALL_TYPE, channelId: string): ChannelMgr;
}

abstract class GenericRtcRemoteParticipant {
  abstract readonly audioStream?: any;
}
