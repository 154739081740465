import * as S from "@effect/schema/Schema";
import { apiMgr, BE } from "@webapp/backend";
import { CalendarMenuInput } from "@webapp/componentsform/calendar.input";
import { TextAreaInput } from "@webapp/componentsform/form-inputs.fc";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "@webapp/componentsprimitives/button";
import {
  SelectUserSection,
  SetDurationSection,
} from "@webapp/componentssession/session-form.components";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { Rx } from "@webapp/prelude";
import { format } from "date-fns";
import {
  FetchSuccessStateMgr,
  isLoadingState,
} from "frontend-shared/src/fetch";
import { BaseFormStateMgr } from "frontend-shared/src/mgrs/state-mgrs/base.statemgr";
import {
  useKeyOfBehaviorSubjectAsState,
  useKeyOfObservable,
  useOnce,
} from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useMemo } from "react";
import type { SimpleUserWithProfilePhoto } from "shared";
import { E, effectToTaskEither, O, RD, TE } from "shared/base-prelude";
import type { ApptRequestStatus } from "shared/db";

class RequestPrivateSessionApptInputFormData extends S.Class<RequestPrivateSessionApptInputFormData>(
  "RequestPrivateSessionApptInputFormData"
)({
  hpId: S.String,
  forDate: S.NullOr(S.Date),
  message: S.UndefinedOr(S.String),
}) {
  static asDefault = RequestPrivateSessionApptInputFormData.make({
    hpId: "",
    forDate: null,
    message: undefined,
  });
}

class RequestPrivateSessionApptFormStateMgr extends BaseFormStateMgr<
  RequestPrivateSessionApptInputFormData,
  { status: ApptRequestStatus },
  string
> {
  hpList$ = new Rx.BehaviorSubject<SimpleUserWithProfilePhoto[]>([]);
  constructor(formData: RequestPrivateSessionApptInputFormData) {
    super({
      apiMgr: apiMgr,
      mbInitialData: O.none,
      defaultData: formData,
      onSubmit: (formData) => {
        return effectToTaskEither(
          this.BE.fetchEndpointWithHandleError((Api) =>
            Api.cp.requestAnAppointment.mutate({
              hpId: formData.hpId,
              date: formData.forDate,
              message: formData.message,
            })
          )
        );
      },
    });

    this.fetchAndSetHpList();

    this.hpList$.subscribe((hpList) => {
      console.log("HP LIST iN SUB! ", hpList);
    });
  }

  fetchAndSetHpList() {
    this.BE.fetchEndpointTE((Api) => Api.cp.getMyTherapists.query())().then(
      (er) => {
        console.log("MY THERAPISTS! ", er);
        if (E.isRight(er)) {
          console.log("SETTING MY THERAPISTS! ", er.right);
          this.hpList$.next([...er.right]);
        }
      }
    );
  }

  static asInitial = () =>
    new RequestPrivateSessionApptFormStateMgr(
      RequestPrivateSessionApptInputFormData.asDefault
    );
}

export const RequestPrivateSessionApptForm: React.FC<{
  onSuccessSave: () => void;
}> = ({ onSuccessSave }) => {
  const formMgr = useOnce(() =>
    RequestPrivateSessionApptFormStateMgr.asInitial()
  );

  const hpList = useObservableEagerState(formMgr.hpList$);
  const hpId$ = useKeyOfObservable(formMgr.formData$, "hpId");
  const forDate = useKeyOfBehaviorSubjectAsState(formMgr.formData$, "forDate");
  const message = useKeyOfBehaviorSubjectAsState(formMgr.formData$, "message");
  const openDropdownId = useObservableEagerState(formMgr.openDropdownId$);
  const rdSubmitResult = useObservableEagerState(formMgr.submitResult$);

  if (RD.isPending(rdSubmitResult)) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-6 p-6">
      <SelectUserSection
        label="Therapist/Coach"
        placeholder="Search for therapist/coach"
        selectedUserId$={hpId$}
        users={hpList}
        isOpen={openDropdownId === "hpId"}
        onToggle={(isOpen) => formMgr.setOpenDropdownId(isOpen, "hpId")}
        onUserSelect={(hp) => {
          formMgr.setFormValue("hpId", hp.id);
        }}
      />

      <CalendarMenuInput
        isOpen={openDropdownId === "forDate"}
        onToggle={(isOpen) => formMgr.setOpenDropdownId(isOpen, "forDate")}
        initialDate={forDate ?? undefined}
        onSelection={(d) => formMgr.setFormValue("forDate", d)}
        title="Date & time"
      />

      <TextAreaInput
        label="Message"
        value={message ?? ""}
        onChange={(m) => formMgr.setFormValue("message", m)}
      />

      <div className="flex flex-col gap-2 self-center">
        {RD.isFailure(rdSubmitResult) && (
          <div className="text-red-500">{rdSubmitResult.error}</div>
        )}
      </div>

      <PrimaryButton
        title="Request"
        onClick={() => formMgr.submit(onSuccessSave)}
      />
    </div>
  );
};

class ViewAppointmentRequestFormData extends S.Class<ViewAppointmentRequestFormData>(
  "ViewAppointmentRequestFormData"
)({
  durationInMinutes: S.NullOr(S.Number),
}) {
  static asDefault = ViewAppointmentRequestFormData.make({
    durationInMinutes: null,
  });
}

class ViewAppointmentRequestFormStateMgr extends BaseFormStateMgr<
  ViewAppointmentRequestFormData,
  { status: ApptRequestStatus },
  string
> {
  fetchRequestApptMgr: FetchSuccessStateMgr<{
    forDate: Date;
    message: string;
    client: { name: string; email: string };
  }>;
  constructor(p: { requestId: string }) {
    super({
      apiMgr: apiMgr,
      mbInitialData: O.none,
      defaultData: ViewAppointmentRequestFormData.asDefault,
      onSubmit: () => TE.right({ status: "pending" }),
    });

    this.fetchRequestApptMgr = new FetchSuccessStateMgr(
      apiMgr,
      (Api) => Api.hp.calendar.getApptRequest.query({ id: p.requestId }),
      { autoFetchAndSetState: true }
    );
  }
}

export const ViewAppointmentRequestForm: React.FC<{
  requestId: string;
  onSuccess: (didAccept: boolean) => void;
}> = ({ requestId, onSuccess }) => {
  const formStateMgr = useMemo(
    () => new ViewAppointmentRequestFormStateMgr({ requestId }),
    [requestId]
  );

  const rdApptRequest = useObservableEagerState(
    formStateMgr.fetchRequestApptMgr.fetchState$
  );
  const rdSubmitResult = useObservableEagerState(formStateMgr.submitResult$);
  const durationInMinutes$ = useKeyOfObservable(
    formStateMgr.formData$,
    "durationInMinutes"
  );
  const durationInMinutes = useObservableEagerState(durationInMinutes$);
  const openDropdownId = useObservableEagerState(formStateMgr.openDropdownId$);

  if (isLoadingState(rdApptRequest)) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex gap-2 items-center">
        <h4 className="font-bold">Client:</h4>
        <div>{rdApptRequest.data.client.name}</div>
      </div>
      <div className="flex gap-2 items-center">
        <h4 className="font-bold">Date:</h4>
        <div>{format(rdApptRequest.data.forDate, "MMM d, yyyy h:mm a")}</div>
      </div>
      <SetDurationSection
        isOpen={openDropdownId === "durationInMinutes"}
        onToggle={(isOpen) =>
          formStateMgr.setOpenDropdownId(isOpen, "durationInMinutes")
        }
        durationInMinutes$={durationInMinutes$}
        onChange={(d) => formStateMgr.setFormValue("durationInMinutes", d)}
      />
      {RD.isFailure(rdSubmitResult) && (
        <div className="text-red-500 self-center">{rdSubmitResult.error}</div>
      )}
      <PrimaryButton
        title="Accept"
        onClick={() => {
          BE.runFetchEndpointWithHandleError(
            (Api) =>
              Api.hp.calendar.respondToApptRequest.mutate({
                id: requestId,
                doesAccept: true,
                durationInMinutes,
              }),
            {
              onSuccess: () => {
                onSuccess(true);
              },
              onError: (e) => {
                formStateMgr.submitResult$.next(RD.failure(e));
              },
            }
          );
        }}
      />
      <InversePrimaryButton
        title="Decline"
        onClick={() => {
          BE.runFetchEndpointWithHandleError(
            (Api) =>
              Api.hp.calendar.respondToApptRequest.mutate({
                id: requestId,
                doesAccept: false,
                durationInMinutes: null,
              }),
            {
              onSuccess: () => {
                onSuccess(false);
              },
              onError: (e) => {
                formStateMgr.submitResult$.next(RD.failure(e));
              },
            }
          );
        }}
      />
    </div>
  );
};
