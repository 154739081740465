import * as S from "@effect/schema/Schema";
import { Option } from "effect";

export const KNOWN_USER_CALL_ROLE = S.Union(
  S.Literal("user"),
  S.Literal("admin"),
  S.Literal("guest")
);

export type KNOWN_USER_CALL_ROLE = S.Schema.Type<typeof KNOWN_USER_CALL_ROLE>;

const CallMemberInfo = S.Struct({
  id: S.String,
  role: KNOWN_USER_CALL_ROLE,
  name: S.UndefinedOr(S.String),
  image: S.UndefinedOr(S.String),
});

export type CallMemberInfo = S.Schema.Type<typeof CallMemberInfo>;

export const CallCredentials = S.Struct({
  token: S.String,
  apiKey: S.String,
  me: S.Struct({ id: S.String, role: KNOWN_USER_CALL_ROLE }),
});

export type CallCredentials = S.Schema.Type<typeof CallCredentials>;

export const RtcCredentials = S.Struct({
  token: S.String,
  apiKey: S.String,
  user: S.Struct({ id: S.String }),
});

export type RtcCredentials = S.Schema.Type<typeof RtcCredentials>;

export const KnownCallType = S.Union(
  S.Literal("group_session"),
  S.Literal("private_session"),
  S.Literal("default")
);

export type KNOWN_CALL_TYPE = S.Schema.Type<typeof KnownCallType>;

export class CallParams extends S.Class<CallParams>("CallParams")({
  callId: S.String,
  callType: KnownCallType,
}) {
  static fromCid = (cid: string) => {
    return S.decodeUnknownSync(CallParamsFromCid)(cid);
  };
}

const CallParamsFromCid = S.transform(S.String, CallParams, {
  decode: (cid: string) => {
    const [callTypeStr, callId] = cid.split(":");
    const callType = S.decodeUnknownSync(KnownCallType)(callTypeStr);
    return new CallParams({ callId, callType });
  },
  encode: (callParams: CallParams) =>
    `${callParams.callType}:${callParams.callId}`,
});

class CallSessionInfo extends S.Class<CallSessionInfo>("CallSessionInfo")({
  participants: S.Array(
    S.Struct({
      user: S.Struct({
        id: S.String,
        name: S.UndefinedOr(S.String),
        image: S.UndefinedOr(S.String),
        role: S.String,
      }),
    })
  ),
}) {}

export class CallInfo extends S.Class<CallInfo>("CallInfo")({
  callId: S.String,
  callType: KnownCallType,
  members: S.Array(CallMemberInfo),
  createdAt: S.Date,
  endedAt: S.Option(S.Date),
  backstage: S.Boolean,
  isRecording: S.Boolean,
  session: S.Option(CallSessionInfo),
}) {
  get hasEnded(): boolean {
    return Option.isSome(this.endedAt);
  }

  asCallState = (): CallStateN.State => {
    return CallStateN.State.fromCallInfo(this);
  };

  isLive = (): boolean => {
    return this.asCallState().isLive();
  };
}

const CallWithCredentials = S.Struct({
  callInfo: CallInfo,
  callCredentials: CallCredentials,
});

export type CallWithCredentials = S.Schema.Type<typeof CallWithCredentials>;

export namespace CallStateN {
  export class InProgress extends S.Class<InProgress>("InProgress")({
    call: CallInfo,
    state: S.Union(S.Literal("LIVE"), S.Literal("BACKSTAGE")),
  }) {}

  export class NotInProgress extends S.Class<NotInProgress>("NotInProgress")({
    state: S.Union(S.Literal("ENDED"), S.Literal("UPCOMING")),
  }) {}

  const InProgressOrNot = S.Union(InProgress, NotInProgress);

  export class State extends S.Class<State>("CallState")({
    state: InProgressOrNot,
  }) {
    isLive = (): boolean => {
      return this.state instanceof InProgress && this.state.state === "LIVE";
    };

    static fromCallInfo = (info: CallInfo): State => {
      if (info.endedAt) {
        return new State({ state: new NotInProgress({ state: "ENDED" }) });
      }

      if (info.backstage) {
        return new State({
          state: new InProgress({ call: info, state: "BACKSTAGE" }),
        });
      }

      return new State({
        state: new InProgress({ call: info, state: "LIVE" }),
      });
    };

    static asUpcoming: State = new State({
      state: new NotInProgress({ state: "UPCOMING" }),
    });

    static asEnded: State = new State({
      state: new NotInProgress({ state: "ENDED" }),
    });
  }
}

// export interface CallClient<NativeClient> {
//   nativeClient: NativeClient;
//   connectUser(user: {
//     id: string;
//     name: string;
//   }): Promise<void | { created_at: string }>;
//   leave(): Promise<void>;
//   joinExisting(): Promise<void>;

//   remoteParticipants$: Rx.Observable<string[]>;
// }

export interface GenericChannelParticipantI {
  audioStream?: any;
  videoStream?: any;
}
