import { Match } from "effect";
import {
  MainRoomStateMgr,
  type OpenRightPanelViewState,
} from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import React from "react";
import { O } from "../../../../prelude";
import { SettingsRightNav } from "../../live-session.components";
import {
  KnownTools,
  TherapyToolMenuContainer,
} from "../../right-nav/therapy-tools/therapy-tools.components";

interface MainRoomRightNavProps {
  rightNav: O.Option<OpenRightPanelViewState>;
  mainRoomStateMgr: MainRoomStateMgr<any, any, any, any, any>;
  knownTools: KnownTools;
  settings: {
    shareableLink: string;
  };
}

export const MainRoomRightNav: React.FC<MainRoomRightNavProps> = ({
  rightNav,
  mainRoomStateMgr,
  knownTools,
  settings,
}) => {
  if (O.isNone(rightNav)) return null;

  return (
    <div className="flex-1 w-full md:w-[360px] bg-white rounded-[12px] flex flex-col overflow-y-auto">
      <div className="flex items-center px-4 py-6">
        <div className="w-8">
          {/* <button className="w-8 h-8 flex items-center justify-center">
              <img src="/back-arrow.svg" alt="Back" className="w-6 h-6" />
            </button> */}
        </div>
        <h4 className="flex-1 text-center text-[22px] font-sans font-normal leading-[120%] text-vid-black-900">
          {Match.value(rightNav.value).pipe(
            Match.when({ state: "THERAPY_TOOLS" }, () => "Practitioner Tools"),
            Match.when({ state: "SETTINGS" }, () => "Settings"),
            Match.when(
              { state: "NEXT_SCHEDULE_REMINDER" },
              () => "Next Schedule Reminder"
            ),
            Match.exhaustive
          )}
        </h4>
        <button
          className="w-9 h-9 flex items-center justify-center"
          onClick={() => {
            mainRoomStateMgr.closePanel();
          }}
        >
          <img src="/close.svg" alt="Close" />
        </button>
      </div>
      <div className="flex-1 flex flex-col min-h-0 overflow-y-auto">
        {Match.value(rightNav.value).pipe(
          Match.when({ state: "THERAPY_TOOLS" }, ({ initialViewState }) => {
            return (
              <div className="max-h-full flex-1 flex flex-col gap-4 py-4 px-4 rounded-lg overflow-y-scroll self-stretch">
                <TherapyToolMenuContainer
                  knownTools={knownTools}
                  initialViewState={initialViewState}
                />
              </div>
            );
          }),
          Match.when({ state: "SETTINGS" }, () => {
            return (
              <SettingsRightNav
                shareableLink={settings.shareableLink}
                setRemoteIsSharingAudioOnly={(isSharingAudioOnly) => {
                  mainRoomStateMgr.firestoreMgr.remoteStateSyncMgr.runUpdateRemoteState(
                    () => ({
                      isSharingAudioOnly,
                    })
                  );
                }}
              />
            );
          }),
          Match.when({ state: "NEXT_SCHEDULE_REMINDER" }, () => {
            return <div>Next Schedule Reminder</div>;
          }),
          Match.exhaustive
        )}
      </div>
    </div>
  );
};
