import type { ApiMgr } from "../../../../api.mgr";
import { BaseStateMgr } from "../../base.statemgr";

export class PrivateSessionReviewRdMgr extends BaseStateMgr {
  transcriptFS = this.mkFetchableState(
    this.BE.fetchEndpointTE((Api) =>
      Api.u.session.review.getMbTranscript.query({
        sessionId: this.p.sessionId,
      })
    )
  );
  chronSummaryFS = this.mkFetchableState(
    this.BE.fetchEndpointTE((Api) =>
      Api.u.session.review.getOrCreateChronologicalSummary.mutate({
        sessionId: this.p.sessionId,
        // forceRefresh: true,
      })
    )
  );
  notesAndBookmarksFS = this.mkFetchableState(
    this.BE.fetchEndpointTE((Api) =>
      Api.u.session.review.getNotesAndBookmarks.query({
        sessionId: this.p.sessionId,
      })
    )
  );
  genericFullSummaryFS = this.mkFetchableState(
    this.BE.fetchEndpointTE((Api) =>
      Api.u.session.review.getSessionBreakdownSummary.query({
        sessionId: this.p.sessionId,
      })
    )
  );

  fetchAndSetAll() {
    this.chronSummaryFS.fetchAndSet();
    this.notesAndBookmarksFS.fetchAndSet();
    this.transcriptFS.fetchAndSet();
  }

  constructor(readonly p: { sessionId: string }, readonly apiMgr: ApiMgr) {
    super({ apiMgr });
  }
}
