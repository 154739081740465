import { Effect, Option } from "effect";
import { useOnce, useRunSuccessEffectO$ } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import type { SimpleUserWithProfilePhoto } from "shared";
import type { RtcCredentials } from "shared/schemas/call.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { type ApiMgr } from "../../../api.mgr";
import type { FirebaseJsMgr } from "../../../firebase";
import { BaseStateMgr } from "../base.statemgr";
import { ChatStateMgr, type GenericChatCli } from "../chat.statemgr";
import {
  GenericRtcMgr,
  type GenericRtcChannelMgr,
  type GenericRtcEngine,
} from "../rtc.statemgr";

/*
    Common states/actions for all users (hp, cp, etc)
*/

export abstract class UserStateMgr<
  StreamChatCli extends GenericChatCli,
  RtcChannelMgr extends GenericRtcChannelMgr,
  RtcEngine extends GenericRtcEngine<RtcChannelMgr>,
  RtcMgr extends GenericRtcMgr<RtcEngine, RtcChannelMgr>
> extends BaseStateMgr {
  me$: Rx.BehaviorSubject<SimpleUserWithProfilePhoto>;

  chatStateMgr: ChatStateMgr<StreamChatCli>;
  rtcMgr: RtcMgr;

  profilePhoto$: Rx.Observable<Option.Option<ImageSrc>>;

  constructor(p: {
    me: SimpleUserWithProfilePhoto;
    apiMgr: ApiMgr;
    chatKeyAndToken: {
      apiKey: string;
      token: string;
    };
    rtcCreds: RtcCredentials;
    mkChatCli: (p: { apiKey: string }) => StreamChatCli;
    mkRtcMgr: (p: RtcCredentials) => RtcMgr;
  }) {
    super({ apiMgr: p.apiMgr });
    this.me$ = new Rx.BehaviorSubject(p.me);
    this.profilePhoto$ = this.me$.pipe(
      RxO.map((me) =>
        Option.fromNullable(me.profilePhoto).pipe(
          Option.map((url) => new ImageSrc({ _tag: "URL", url }))
        )
      )
    );

    this.chatStateMgr = new ChatStateMgr<StreamChatCli>({
      apiMgr: p.apiMgr,
      mySimpleProfile: p.me,
      chatKeyAndToken: p.chatKeyAndToken,
      mkChatCli: p.mkChatCli,
    });

    this.rtcMgr = p.mkRtcMgr(p.rtcCreds);
  }

  saveMyProfile = (p: {
    firstName: string;
    lastName: string;
    profilePhoto?: { publicId: string; assetId: string } | null;
    email?: string;
  }) =>
    Effect.gen(this, function* () {
      const saveResult = yield* this.BE.fetchSuccessOnlyEndpoint((Api) =>
        Api.u.me.setBaseProfile.mutate({
          ...p,
          profilePhotoRemotePtr: p.profilePhoto ?? null,
        })
      );

      console.log("saveResult", saveResult);

      return yield* this.refreshMe();
    });

  updateCachedMe = (partialMe: Partial<SimpleUserWithProfilePhoto>) => {
    this.me$.next({
      ...this.me$.value,
      ...partialMe,
    });
  };

  refreshMe = () =>
    Effect.gen(this, function* () {
      const { me } = yield* this.BE.fetchSuccessOnlyEndpoint((Api) =>
        Api.u.me.getMe.query()
      );
      this.me$.next(me);
      return me;
    });

  runRefreshMe = () => Effect.runPromise(this.refreshMe());
}

export function useSetupUStateMgr<
  StreamChatCli extends GenericChatCli,
  RtcChannelMgr extends GenericRtcChannelMgr,
  RtcEngine extends GenericRtcEngine<RtcChannelMgr>,
  RtcMgr extends GenericRtcMgr<RtcEngine, RtcChannelMgr>,
  PlatUStateMgr extends UserStateMgr<
    StreamChatCli,
    RtcChannelMgr,
    RtcEngine,
    RtcMgr
  >
>(
  apiMgr: ApiMgr,
  firebaseMgr: FirebaseJsMgr,
  me: SimpleUserWithProfilePhoto,
  mkChatCli: (p: { apiKey: string }) => StreamChatCli,
  mkPlatUStateMgr: (p: {
    me: SimpleUserWithProfilePhoto;
    apiMgr: ApiMgr;
    firebaseMgr: FirebaseJsMgr;
    chatKeyAndToken: { apiKey: string; token: string };
    rtcCreds: RtcCredentials;
    mkChatCli: (p: { apiKey: string }) => StreamChatCli;
  }) => PlatUStateMgr
): Option.Option<PlatUStateMgr> {
  const mbChatAndRtcCreds$ = useRunSuccessEffectO$(
    Effect.all([
      apiMgr.fetchSuccessOnlyEndpoint((Api) => Api.u.getUserChatToken.query()),
      apiMgr.fetchSuccessOnlyEndpoint((Api) =>
        Api.u.createRtcCredentials.mutate()
      ),
    ]),
    []
  );

  const mbUStateMgr$ = useOnce(() => {
    return mbChatAndRtcCreds$.pipe(
      RxO.distinctUntilChanged((a, b) => {
        return Option.isSome(a) && Option.isSome(b) && a.value === b.value;
      }),
      RxO.map((mbChatAndRtcCreds) => {
        return mbChatAndRtcCreds.pipe(
          Option.map(([chatCreds, rdRtcCredentials]) => {
            return mkPlatUStateMgr({
              me,
              apiMgr,
              firebaseMgr,
              chatKeyAndToken: {
                apiKey: chatCreds.apiKey,
                token: chatCreds.token,
              },
              rtcCreds: rdRtcCredentials,
              mkChatCli,
            });
          })
        );
      })
    );
  });

  const mbUStateMgr = useObservableEagerState(mbUStateMgr$);

  return mbUStateMgr;
}
