import type { StreamVideoParticipant } from "@stream-io/video-react-sdk";
import { Option } from "effect";
import type { ApiMgr } from "frontend-shared/src/api.mgr";
import type { AbstractAudioPlayer } from "frontend-shared/src/mgrs/media-player.statemgr";
import { BaseStateMgr } from "frontend-shared/src/mgrs/state-mgrs/base.statemgr";
import { MainRoomStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import type {
  PrivateSessionCallStateMgr,
  PrivateSessionStateMgr,
} from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/remote-state-and-call.statemgrs";
import { PrivateSessionReviewRdMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/review.statemgr";
import { PrivateSessionStageViewStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/stageview.statemgr";
import { NotesMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/tools/notes.statemgr";
import type { UApiOutput } from "frontend-shared/src/trpc-cli";
import { type PrivateSessionSpecificStageViewState } from "frontend-shared/src/types/video-state.types";
import * as Rx from "rxjs";
import type { PrivateSessionInfo } from "shared/schemas/session/private-session.schemas";
import { PrivateSessionStateModule } from "shared/session-state/session-state.types";
import { createContextAndHook } from "../../../../util";

export type HpPrivateSessionPingResult =
  UApiOutput["hp"]["sessions"]["liveSession"]["registerPing"];

type PrivateSessionSpecificContent = {};

export class HpPrivateSessionRoomCallStateMgr extends BaseStateMgr {
  sessionMgr: PrivateSessionStateMgr;
  callStateMgr: PrivateSessionCallStateMgr;
  session: PrivateSessionInfo;

  mainRoomStateMgr: MainRoomStateMgr<
    HpPrivateSessionPingResult,
    PrivateSessionSpecificStageViewState<StreamVideoParticipant>,
    PrivateSessionSpecificContent,
    PrivateSessionStateModule.FirebaseEncodedState,
    PrivateSessionStateModule.State
  >;

  notesMgr: NotesMgr;
  reviewMgr: PrivateSessionReviewRdMgr;

  constructor(
    readonly p: {
      sessionMgr: PrivateSessionStateMgr;
      callStateMgr: PrivateSessionCallStateMgr;
      callId: string;
      remoteParticipants$: Rx.Observable<StreamVideoParticipant[]>;
      apiMgr: ApiMgr;
      audioPlayer: AbstractAudioPlayer;
    }
  ) {
    super({ apiMgr: p.apiMgr });
    this.sessionMgr = p.sessionMgr;
    this.session = p.sessionMgr.privateSession;
    this.callStateMgr = p.callStateMgr;
    const stageViewMgr = new PrivateSessionStageViewStateMgr(
      p.remoteParticipants$,
      this.sessionMgr.firestoreMgr.remoteStateSyncMgr,
      "HOST"
    );
    this.mainRoomStateMgr = new MainRoomStateMgr<
      HpPrivateSessionPingResult,
      PrivateSessionSpecificStageViewState<StreamVideoParticipant>,
      PrivateSessionSpecificContent,
      PrivateSessionStateModule.FirebaseEncodedState,
      PrivateSessionStateModule.State
    >({
      stageViewMgr,
      sendPingEff: this.BE.fetchSuccessOnlyEndpoint((Api) =>
        Api.hp.sessions.session.liveSessionActions.registerPing.mutate({
          sessionId: this.session.id,
          callId: p.callId,
        })
      ),
      fsSessionStateMgr: this.sessionMgr.firestoreMgr,
      audioPlayer: p.audioPlayer,
    });
    this.notesMgr = new NotesMgr(
      this.sessionMgr.firestoreMgr.remoteStateSyncMgr,
      this.sessionMgr.privateSession,
      p.apiMgr
    );
    this.reviewMgr = new PrivateSessionReviewRdMgr(
      { sessionId: this.session.id },
      p.apiMgr
    );

    this.mainRoomStateMgr.pingMgr.sendPing();

    this.mainRoomStateMgr.pingMgr.pingResult$.subscribe((pr) => {
      this.showNextScheduleReminderIfNecessary(pr);
    });
  }

  cleanup() {
    this.mainRoomStateMgr.cleanup();
  }

  showNextScheduleReminderIfNecessary(pr: HpPrivateSessionPingResult) {
    const { mbTimeLeftInfo } = pr;

    if (Option.isNone(mbTimeLeftInfo)) {
      return;
    }

    const timeLeftSeconds = mbTimeLeftInfo.value.timeLeftSeconds;

    if (timeLeftSeconds < 60 * 5) {
      this.sessionMgr.firestoreMgr.remoteStateSyncMgr.runUpdateRemoteState(
        (sessionState) =>
          !sessionState.showedNextScheduleReminderAt
            ? {
                isShowingNextScheduleReminder: true,
                showedNextScheduleReminderAt: new Date().toISOString(),
              }
            : {}
      );
    }
  }
}

export const [
  HpPrivateSessionRoomCallStateMgrContext,
  useHpPrivateSessionRoomCallStateMgr,
] = createContextAndHook<HpPrivateSessionRoomCallStateMgr>();
