import * as S from "@effect/schema/Schema";
import type { CallTranscript } from "./transcript.schemas";

export const ChronologicalSummarySegmentSchema = S.Struct({
  startMinute: S.Number,
  endMinute: S.Number,
  title: S.String,
  summary: S.String,
  isTherapeutic: S.optional(S.Boolean),
});

export class ChronologicalSummary extends S.Class<ChronologicalSummary>(
  "ChronologicalSummary"
)({
  fullSummary: S.Array(ChronologicalSummarySegmentSchema),
}) {}

export class SavedChronologicalSummary extends ChronologicalSummary.extend<SavedChronologicalSummary>(
  "SavedChronologicalSummary"
)({
  fromTranscript: S.Struct({
    minStartTime: S.String,
    maxEndTime: S.String,
  }),
}) {
  asEncodedJsonValueForDb = (): string => {
    return JSON.stringify(this);
  };

  wasGeneratedFromTranscript = (transcript: CallTranscript): boolean => {
    const transcriptStartAndEnd = transcript.startAndEndDates();
    console.log("current transcriptStartAndEnd", transcriptStartAndEnd);
    const thisTranscriptStartAndEnd = this.fromTranscript;
    console.log("THIS TRANSCRIPT START AND END", thisTranscriptStartAndEnd);

    const matchingMinStartTime =
      transcriptStartAndEnd.minStartTime.toISOString() ===
      thisTranscriptStartAndEnd.minStartTime;
    const matchingMaxEndTime =
      transcriptStartAndEnd.maxEndTime.toISOString() ===
      thisTranscriptStartAndEnd.maxEndTime;

    return matchingMinStartTime && matchingMaxEndTime;
  };

  static fromString(data: string): SavedChronologicalSummary {
    const asJson = S.decodeSync(S.parseJson())(data);
    return S.decodeUnknownSync(SavedChronologicalSummary)(asJson);
  }
}
