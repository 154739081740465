import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import type { KnownMediaFile } from "shared";
import type { AbstractAudioPlayer } from "../../../../media-player.statemgr";
import {
  BaseSessionToolStateMgr,
  type BaseSessionToolStateMgrParams,
} from "./base-session-tool.statemgr";

export class AudioMediaStateMgr extends BaseSessionToolStateMgr {
  playingMediaFile$: Rx.Observable<KnownMediaFile | null>;

  sendPlayMediaFile(knownMediaFile: KnownMediaFile) {
    this.remoteStateMgr.runUpdateRemoteState((_) => ({
      playingMediaFile: knownMediaFile,
    }));
  }

  sendStopMediaFile() {
    this.remoteStateMgr.runUpdateRemoteState((_) => ({
      playingMediaFile: null,
    }));
  }

  playLocallyOnly(knownMediaFile: KnownMediaFile) {
    this.audioPlayer.setSourceAndPlay(knownMediaFile);
  }

  pauseLocal() {
    this.audioPlayer.pause();
  }

  constructor(
    readonly baseParams: BaseSessionToolStateMgrParams,
    readonly audioPlayer: AbstractAudioPlayer
  ) {
    super(baseParams);

    this.playingMediaFile$ = this.baseSessionState$.pipe(
      RxO.map((bs) => bs.playingMediaFile),
      RxO.distinctUntilChanged()
    );

    // this.audioPlayer.setOnDone(() => {
    //   console.log("AUDIO PLAYER ON DONE CALLED!");
    //   this.remoteStateMgr.runUpdateRemoteState((_) => ({
    //     playingMediaFile: null,
    //   }));
    // });

    this.playingMediaFile$.subscribe((knownFile) => {
      if (knownFile) {
        console.log("SETTING AUDIO PLAYER SOURCE! ", knownFile);
        this.audioPlayer.setSource(knownFile);
        setTimeout(() => {
          console.log("PLAYING AUDIO! ");
          this.audioPlayer.playAudioBuffer();
          // this.audioPlayer.play({
          //   autoStopAfterNSeconds:
          //     knownFile === "simple-singing-bowl.mp3" ? 5 : undefined,
          // });
        }, 1000);
      } else {
        this.audioPlayer.stop();
      }
    });
  }
}
