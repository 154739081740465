import { SuccessOnlyViewO } from "@webapp/utils/utils";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { UROUTES } from "shared/routes/u.routes";
import { BE } from "../../../../../backend";
import { PrivateChatFC } from "../../../../../components/chat/private-chat";
import { useCpState } from "../../cpdashboard.state";
import { AvatarCircle } from "@webapp/componentsavatar.tc";
import { ImageSrc } from "shared/types/miscellaneous.types";

export const CpHpDetailsPage = () => {
  const hpId = useTypedParams(UROUTES.CP.DASHBOARD.HPS.HP_ID.DETAILS).hpId;
  const cpState = useCpState();

  const mbPageInfo = BE.useSuccessFetchO(
    (Api) => Api.cp.getHpDetailsPageInfo.query({ hpId }),
    []
  );

  return (
    <SuccessOnlyViewO
      mbV={mbPageInfo}
      loadedView={(pageInfo) => (
        <div className="flex-1 flex flex-col gap-8 p-8">
          <AvatarCircle
            size={100}
            mbProfilePhoto={ImageSrc.fromMbUrl(pageInfo.profile.profilePhoto)}
          />
          <h4 className="text-2xl font-bold">{pageInfo.profile.name}</h4>
          <h5 className="text-lg text-gray-500">{pageInfo.profile.email}</h5>
          <PrivateChatFC
            chatStateMgr={cpState.uMgr.chatStateMgr}
            otherUserId={hpId}
            setupChatEff={() =>
              BE.fetchEndpointEff((Api) =>
                Api.cp.startChatWithTherapist.query({ hpId: hpId })
              )
            }
          />
        </div>
      )}
    />
  );
};
