import * as S from "@effect/schema/Schema";
import { DateTime, Effect, Match, Option } from "effect";
import { CallCredentials, CallInfo, CallStateN } from "./call.schemas";

/*
 **** SessionCallStatus ****
 */

export namespace SessionCallStatus {
  const InProgressState = S.Union(
    S.TaggedStruct("WaitingForHostToStart", {}),
    S.TaggedStruct("Running", {
      currentCall: S.Struct({
        callInfo: CallInfo,
        callCredentials: CallCredentials,
      }),
    })
  );
  class InProgress extends S.TaggedClass<InProgress>()("InProgress", {
    state: InProgressState,
  }) {}

  const NotStartedReason = S.Union(
    S.Literal("WAITING_FOR_HOST"),
    S.Literal("TOO_EARLY")
  );

  class NotStarted extends S.TaggedClass<NotStarted>()("NotStarted", {
    reason: NotStartedReason,
  }) {}

  class Ended extends S.TaggedClass<Ended>()("Ended", {
    endedAt: S.Date,
  }) {}

  export const PossibleState = S.Union(InProgress, NotStarted, Ended);
  export type PossibleState = S.Schema.Type<typeof PossibleState>;

  export class Status extends S.Class<Status>("SessionCallStatus")({
    status: PossibleState,
  }) {
    get isLive(): boolean {
      return this.mbCurrentCall().pipe(
        Option.map((callInfo) =>
          CallStateN.State.fromCallInfo(callInfo).isLive()
        ),
        Option.getOrElse(() => false)
      );
    }

    mbCurrentCall(): Option.Option<CallInfo> {
      return Match.value(this.status).pipe(
        Match.tag("InProgress", (ip) =>
          ip.state._tag === "Running"
            ? Option.some(ip.state.currentCall.callInfo)
            : Option.none()
        ),
        Match.orElse(() => Option.none())
      );
    }

    static from = (p: {
      sessionInfo: {
        isInStartableWindow: boolean;
        endedAt: Option.Option<Date>;
      };
      mbCurrentCall: Option.Option<{
        callInfo: CallInfo;
        callCredentials: CallCredentials;
      }>;
    }): Status => {
      return Option.match(p.mbCurrentCall, {
        onNone: () => {
          if (Option.isSome(p.sessionInfo.endedAt)) {
            return Status.make({
              status: Ended.make({ endedAt: p.sessionInfo.endedAt.value }),
            });
          }
          if (!p.sessionInfo.isInStartableWindow) {
            return Status.make({
              status: NotStarted.make({
                reason: "TOO_EARLY",
              }),
            });
          }

          return Status.make({
            status: NotStarted.make({ reason: "WAITING_FOR_HOST" }),
          });
        },
        onSome: (currentCall) => {
          return Status.make({
            status: InProgress.make({
              state: { _tag: "Running", currentCall },
            }),
          });
        },
      });
    };
  }
}

/*
 ***** SessionCallNextActionForUser ****
 */

export namespace SessionCallNextActionForUser {
  export const StayAndDisplayMessage = S.TaggedStruct("StayAndDisplayMessage", {
    message: S.String,
  });

  const StartOrJoin = S.Union(
    S.TaggedStruct("Start", {
      channelId: S.String,
    }),
    S.TaggedStruct("Join", {})
  );

  type StartOrJoin = S.Schema.Type<typeof StartOrJoin>;

  export const StartOrJoinCallAction = S.TaggedStruct("StartOrJoinCallAction", {
    startOrJoin: StartOrJoin,
  });

  export const EnterRoom = S.TaggedStruct("EnterRoom", {
    room: S.Union(S.Literal("waiting"), S.Literal("main")),
    call: S.Struct({ callInfo: CallInfo, callCredentials: CallCredentials }),
  });
  type EnterRoom = S.Schema.Type<typeof EnterRoom>;
  export const DoNothing = S.TaggedStruct("DoNothing", {});

  const PossibleAction = S.Union(
    StayAndDisplayMessage,
    EnterRoom,
    DoNothing,
    StartOrJoinCallAction
  );

  export class Action extends S.Class<Action>("SessionCallNextActionForUser")({
    action: PossibleAction,
  }) {
    get isStartAction(): boolean {
      return (
        this.action._tag === "StartOrJoinCallAction" &&
        this.action.startOrJoin._tag === "Start"
      );
    }

    get mbStartOrJoinAction(): StartOrJoin | null {
      return this.action._tag === "StartOrJoinCallAction"
        ? this.action.startOrJoin
        : null;
    }

    get mbEnterRoomAction(): EnterRoom | null {
      console.log("ACTION TAG! ", this.action._tag);
      return this.action._tag === "EnterRoom" ? this.action : null;
    }

    static from = (p: {
      state: SessionCallStatus.Status;
      channelId: string;
      isUserHost: boolean;
    }): Action => {
      console.log("GETTING ACTION FROM STATE! ", p.state, p.isUserHost);
      return SessionCallNextActionForUser.Action.make({
        action: SessionCallNextActionForUser.Action.actionOptionFrom({
          state: p.state,
          isUserHost: p.isUserHost,
          channelId: p.channelId,
        }),
      });
    };

    static actionOptionFrom = (p: {
      state: SessionCallStatus.Status;
      channelId: string;
      isUserHost: boolean;
    }): typeof PossibleAction.Type =>
      Match.value(p.state.status).pipe(
        Match.tag("NotStarted", ({ reason }) =>
          Match.value(reason).pipe(
            Match.when("WAITING_FOR_HOST", () => {
              if (p.isUserHost) {
                return StartOrJoinCallAction.make({
                  startOrJoin: {
                    _tag: "Start",
                    channelId: p.channelId,
                  },
                });
              } else {
                return DoNothing.make({});
              }
            }),
            Match.when("TOO_EARLY", () => {
              return DoNothing.make({});
            }),
            Match.exhaustive
          )
        ),
        Match.tag("InProgress", ({ state }) =>
          Match.value(state).pipe(
            Match.tag("WaitingForHostToStart", () => {
              if (p.isUserHost) {
                return StartOrJoinCallAction.make({
                  startOrJoin: {
                    _tag: "Start",
                    channelId: p.channelId,
                  },
                });
              } else {
                return StartOrJoinCallAction.make({
                  startOrJoin: { _tag: "Join" },
                });
              }
            }),
            Match.tag("Running", ({ currentCall }) => {
              return EnterRoom.make({ call: currentCall, room: "waiting" });
            }),
            Match.exhaustive
          )
        ),
        Match.tag("Ended", () => {
          return DoNothing.make({});
        }),
        Match.exhaustive
      );
  }
}

export class NearEndTimerInfo extends S.Class<NearEndTimerInfo>(
  "NearEndTimerInfo"
)({
  message: S.String,
}) {
  static mbNearEndTimerInfoFrom = (p: {
    timeLeftSeconds: number;
    mbConfiguredMinutesUntilEndReminder: Option.Option<number>;
    mbDisplayedTimeLeftNotifAt: Option.Option<Date>;
  }): Option.Option<NearEndTimerInfo> => {
    console.log("DETERMING NEAR END TIMER INFO! ", p);

    if (Option.isSome(p.mbDisplayedTimeLeftNotifAt)) {
      return Option.none();
    }

    if (Option.isNone(p.mbConfiguredMinutesUntilEndReminder)) {
      return Option.none();
    }

    const configuredMinutesUntilEndReminder =
      p.mbConfiguredMinutesUntilEndReminder.value;
    const { timeLeftSeconds } = p;
    const configuredSecondsUntilEndReminder =
      configuredMinutesUntilEndReminder * 60;

    const shouldShowNearEndTimer =
      timeLeftSeconds > configuredSecondsUntilEndReminder - 30 &&
      timeLeftSeconds < configuredSecondsUntilEndReminder + 30;

    if (!shouldShowNearEndTimer) {
      return Option.none();
    }

    const message = `${Math.floor(timeLeftSeconds / 60)} minutes left`;

    return Option.some(
      NearEndTimerInfo.make(
        {
          message,
        },
        { disableValidation: true }
      )
    );
  };
}

export class SessionTimeLeftInfo extends S.Class<SessionTimeLeftInfo>(
  "SessionTimeLeftInfo"
)({
  timeLeftSeconds: S.Number,
  mbNearEndTimerInfo: S.Option(NearEndTimerInfo),
}) {
  get shouldShowingNearEndTimer(): boolean {
    return Option.isSome(this.mbNearEndTimerInfo);
  }

  static mbFrom = (p: {
    startedAt: Date | null;
    estimatedDurationInMinutes: number | null;
    configuredMinutesUntilEndReminder: number | null;
    now: DateTime.DateTime;
  }): Option.Option<SessionTimeLeftInfo> => {
    console.log("GETTING SESSION TIME LEFT INFO! ", p);

    if (p.startedAt === null) {
      return Option.none();
    }

    return Option.some(
      SessionTimeLeftInfo.from({
        startedAt: DateTime.unsafeMake(p.startedAt),
        estimatedDurationInMinutes: 60,
        mbConfiguredMinutesUntilEndReminder: Option.fromNullable(
          p.configuredMinutesUntilEndReminder
        ),
        mbDisplayedTimeLeftNotifAt: Option.none(),
        now: p.now,
      })
    );
  };

  static from = (p: {
    startedAt: DateTime.Utc;
    estimatedDurationInMinutes: number;
    mbConfiguredMinutesUntilEndReminder: Option.Option<number>;
    mbDisplayedTimeLeftNotifAt: Option.Option<Date>;
    now: DateTime.DateTime;
  }): SessionTimeLeftInfo => {
    const {
      startedAt,
      estimatedDurationInMinutes,
      now,
      mbDisplayedTimeLeftNotifAt,
      mbConfiguredMinutesUntilEndReminder,
    } = p;
    const endTime = DateTime.add(startedAt, {
      minutes: estimatedDurationInMinutes,
    });
    const timeLeftSeconds = DateTime.distance(now, endTime) / 1000;

    const mbNearEndTimerInfo = NearEndTimerInfo.mbNearEndTimerInfoFrom({
      timeLeftSeconds,
      mbConfiguredMinutesUntilEndReminder,
      mbDisplayedTimeLeftNotifAt,
    });

    const secondsElapsed = DateTime.distance(startedAt, now) / 1000;

    return SessionTimeLeftInfo.make(
      {
        timeLeftSeconds: timeLeftSeconds,
        mbNearEndTimerInfo,
        secondsElapsed,
      },
      { disableValidation: true }
    );
  };
}

export class SessionTimeInfo extends S.Class<SessionTimeInfo>(
  "SessionTimeInfo"
)({
  mbTimeLeftInfo: S.Option(SessionTimeLeftInfo),
  secondsElapsed: S.Number,
}) {
  get isLikelyNearEndBasedOnTime() {
    if (Option.isNone(this.mbTimeLeftInfo)) {
      return false; // TODO, make a guess the session is 1 hour???
    }
    const timeLeftInfo = this.mbTimeLeftInfo.value;

    if (timeLeftInfo.timeLeftSeconds < 60 * 5) {
      return true;
    }

    return false;
  }

  static from = (p: {
    mbTimeLeftInfo: Option.Option<SessionTimeLeftInfo>;
    sessionLike: { startedAt: Option.Option<Date> };
  }): Effect.Effect<SessionTimeInfo> =>
    Effect.gen(this, function* () {
      const { mbTimeLeftInfo, sessionLike } = p;
      const secondsElapsed =
        yield* SessionTimeInfo.unsafeGetSecondsElapsedFromSession({
          sessionLike,
        });
      return SessionTimeInfo.make(
        {
          mbTimeLeftInfo,
          secondsElapsed,
        },
        { disableValidation: true }
      );
    });

  static unsafeGetSecondsElapsedFromSession = (p: {
    sessionLike: { startedAt: Option.Option<Date> };
  }): Effect.Effect<number> => {
    return Effect.gen(this, function* () {
      const now = yield* DateTime.now;
      const startedAt = p.sessionLike.startedAt;
      if (Option.isNone(startedAt)) {
        return yield* Effect.dieMessage(
          "No started at time when getting seconds elapsed"
        );
      }
      const startedAtDateTime = DateTime.unsafeMake(startedAt.value);
      const secondsElapsed = DateTime.distance(startedAtDateTime, now) / 1000;
      return secondsElapsed;
    });
  };
}

const SpecialClockInfo = S.Struct({
  secondsLeft: S.Number,
  description: S.Union(S.Literal("meditation"), S.Literal("warning")),
  message: S.NullOr(S.String),
});
export type SpecialClockInfo = S.Schema.Type<typeof SpecialClockInfo>;

const RegularClockDisplay = S.Union(
  S.TaggedStruct("REGULAR", { minutesLeft: S.NullOr(S.Number) }),
  S.TaggedStruct("ENDING_SOON_REMINDER", { message: S.String })
);

export class StageClockDisplay extends S.Class<StageClockDisplay>(
  "StageClockDisplay"
)({
  mbSpecialClock: S.Option(SpecialClockInfo),
  regularClock: RegularClockDisplay,
}) {
  static default = StageClockDisplay.make({
    mbSpecialClock: Option.none(),
    regularClock: { _tag: "REGULAR", minutesLeft: null },
  });

  static from = (p: {
    mbSpecialClock: Option.Option<SpecialClockInfo>;
    mbTimeLeftInfo: Option.Option<SessionTimeLeftInfo>;
  }): StageClockDisplay => {
    if (Option.isNone(p.mbTimeLeftInfo)) {
      return StageClockDisplay.make(
        {
          mbSpecialClock: p.mbSpecialClock,
          regularClock: { _tag: "REGULAR", minutesLeft: null },
        },
        { disableValidation: true }
      );
    }
    const timeLeftInfo = p.mbTimeLeftInfo.value;
    if (Option.isSome(timeLeftInfo.mbNearEndTimerInfo)) {
      return StageClockDisplay.make(
        {
          mbSpecialClock: p.mbSpecialClock,
          regularClock: {
            _tag: "ENDING_SOON_REMINDER",
            message: timeLeftInfo.mbNearEndTimerInfo.value.message,
          },
        },
        { disableValidation: true }
      );
    } else {
      return StageClockDisplay.make(
        {
          mbSpecialClock: p.mbSpecialClock,
          regularClock: {
            _tag: "REGULAR",
            minutesLeft: timeLeftInfo.timeLeftSeconds / 60,
          },
        },
        { disableValidation: true }
      );
    }
  };
}
