import * as S from "@effect/schema/Schema";
import type { Selectable } from "kysely";
import { z } from "zod";
import { RD } from "./base-prelude.js";
import type { Users } from "./db.js";
import { AppEnvSchema, type AppEnv } from "./types/app-env.types";

export function parseAppEnv(input: string): AppEnv {
  const parsed = AppEnvSchema.safeParse(input);
  if (parsed.success) {
    return parsed.data;
  } else {
    throw new Error("Invalid AppEnv value");
  }
}

export interface SessionInvoice {
  invoice_id: string;
  session_id: string;
  created_date: Date;
  from: {
    id: string;
    name: string;
    profilePhotoDownloadUrl: string | null;
  };
  client: {
    id: string;
    name: string;
    profilePhotoSavedAt: Date | null;
    profilePhotoDownloadUrl: string | null;
  };
  total: string;
  status: string;
  sessionLengthSeconds: number;
}

export interface SimplestUser {
  id: string;
  name: string;
  email: string;
}

export type SimpleUser = SimplestUser & {
  firstName: string;
  lastName: string;
};

export type SimplestUserWithProfilePhoto = SimplestUser & {
  profilePhoto: string | null;
};

export type SimpleUserWithProfilePhoto = SimpleUser & {
  profilePhoto: string | null;
};

export type UserWithProfilePhoto = Selectable<Users> & {
  profilePhoto: string | null;
};

export type BasicSessionReview = {
  session: {
    id: string;
    endedAt: Date;
    invoice: SessionInvoice;
    videoUrl: string | null;
    audioUrl: string | null;
  };
};

export type SessionDownloadUrls = {
  audioDownloadUrl: string | null;
  videoDownloadUrl: string | null;
  transcriptDownloadUrl: RD.RemoteData<any, string>;
};

export type StripeExpressBankAccount = {
  charges_enabled: boolean;
};

export interface HpProfile {
  bio: string;
  modalities: { name: string; slug: string }[];
}

export const NewHpSessionBookmarkSchema = z.object({
  label: z.string().optional(),
  color: z.string().optional(),
  bookmarkedAt: z.string().optional(),
});

export type NewHpSessionBookmark = z.infer<typeof NewHpSessionBookmarkSchema>;

export const BaseNewPrivateSessionFormSubmitSchema = z.object({
  requestApptId: z.string().optional(),
  sessionType: z.string().optional(),
  priceCents: z.number().nullable(),
  startsAt: z.date().nullable(),
  durationInMinutes: z.number().nullable(),
  therapyTypeSlug: z.string().nullable(),
  paymentMethod: z
    .union([z.literal("vidalify"), z.literal("outside")])
    .optional(),
});

export const NewCpPrivateSessionFormSubmitT =
  BaseNewPrivateSessionFormSubmitSchema.extend({
    hp: z.object({ id: z.string(), name: z.string() }).nullable(),
  });

export const NewHpPrivateSessionFormSubmitSchema =
  BaseNewPrivateSessionFormSubmitSchema.extend({
    client: z.object({ id: z.string(), name: z.string() }).nullable(),
  });

export type BaseNewPrivateSessionFormSubmit = z.infer<
  typeof BaseNewPrivateSessionFormSubmitSchema
>;

export type NewCpPrivateSessionFormSubmit = z.infer<
  typeof NewCpPrivateSessionFormSubmitT
>;

export type NewHpPrivateSessionFormSubmit = z.infer<
  typeof NewHpPrivateSessionFormSubmitSchema
>;

const NewPrivateSessionFormSubmitSchema =
  BaseNewPrivateSessionFormSubmitSchema.extend(
    NewCpPrivateSessionFormSubmitT.shape
  ).extend(NewHpPrivateSessionFormSubmitSchema.shape);

export type NewPrivateSessionFormSubmit = z.infer<
  typeof NewPrivateSessionFormSubmitSchema
>;

export const ModalityT = z.object({
  name: z.string(),
  slug: z.string(),
});

export type Modality = z.infer<typeof ModalityT>;

export interface SessionShortSummary {
  sessionInfo: {
    id: string;
    endedAt: Date;
  };
  review: { myNotes: string | null; autoSummary: string[] | null };
}

export type UserSessionRole = "HP" | "CP";

export function uidForSessionRole(role: UserSessionRole) {
  return role === "HP" ? 1 : 2;
}

export function sessionRoleForUid(uid: number) {
  return uid === 1 ? "HP" : "CP";
}

export const KnownToolSchema = z.enum([
  "notes",
  "screenshare",
  "emdr",
  "music",
  "chat",
  "meditation",
  "review",
]);

export type KNOWN_TOOL = z.infer<typeof KnownToolSchema>;

export type TherapyToolMenuViewState =
  | { _tag: "MENU" }
  | { _tag: "TOOL"; tool: KNOWN_TOOL };

export const SessionReminderIntervalSchema = z.union([
  z.literal("15min"),
  z.literal("24hr"),
]);
export type SessionReminderInterval = z.infer<
  typeof SessionReminderIntervalSchema
>;

export const HpDashboardNotifsSchema = z.object({
  cpAcceptedRequest: z.string().optional(),
});

export type HpDashboardNotifs = z.infer<typeof HpDashboardNotifsSchema>;

export const KnownMediaFileSchema = S.Union(
  S.Literal("meditation.mp3"),
  S.Literal("simple-singing-bowl.mp3")
);
export type KnownMediaFile = typeof KnownMediaFileSchema.Type;
