import { useUMgr } from "@pages/u/u.webstatemgr";
import { pipe } from "fp-ts/function";
import { useAuthedFetchTE } from "frontend-shared/src/api.mgr";
import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { RD } from "shared/base-prelude";
import {
  FullContainerLoadingSpinner,
  FullScreenLoadingSpinner,
} from "../../loading";
import { CpState, CpStateContext } from "./dashboard/cpdashboard.state";

export const CpLayout: React.FC = () => {
  const userMgr = useUMgr();
  const rdMyProfile = useAuthedFetchTE(
    (Api) => Api.cp.getMyProfile.query(),
    []
  );

  const rdCpState = useMemo(
    () =>
      pipe(
        rdMyProfile,
        RD.map((myProfileResp) => {
          return new CpState(userMgr, {
            myProfile: {
              id: myProfileResp.id,
              firstName: myProfileResp.first_name,
              lastName: myProfileResp.last_name,
              name: myProfileResp.name,
              email: myProfileResp.email,
              profilePhoto: myProfileResp.profilePhoto,
              worksWithClients: myProfileResp.works_with_clients,
            },
          });
        })
      ),
    [rdMyProfile]
  );

  return pipe(
    rdCpState,
    RD.fold(
      () => <></>,
      () => <FullScreenLoadingSpinner />,
      (e) => <div>{JSON.stringify(e)}</div>,
      (cpState) => (
        <CpStateContext.Provider value={cpState}>
          <div className="w-screen h-screen flex flex-col overflow-hidden">
            {/* <nav className="flex px-4 py-1 border">
        <button
          onClick={() => {
            signOut(firebaseAuth).then(() => {
              window.location.href = "/";
            });
          }}
        >
          Sign out
        </button>
      </nav> */}
            <div className="flex-1 flex flex-col overflow-hidden">
              <Outlet />
            </div>
          </div>
        </CpStateContext.Provider>
      )
    )
  );
};
