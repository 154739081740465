import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { BE } from "@webapp/backend";
import { AvatarCircle } from "@webapp/componentsavatar.tc";
import { FormDisclosureContainer } from "@webapp/componentsdisclosures";
import { CalendarMenuInput } from "@webapp/componentsform/calendar.input";
import { ThreeDotsMenu } from "@webapp/componentsmenus/three-dots.menu";
import { FormRadioCard } from "@webapp/componentsprimitives/radio-buttons";
import {
  SessionFormComponents,
  SetDurationSection,
} from "@webapp/componentssession/session-form.components";
import { RD, Rx, RxO } from "@webapp/prelude";
import { safeParseInt } from "@webapp/utils/utils";
import { Effect } from "effect";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { useNavigate } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";
import { PrivateSessionAppointment } from "shared/schemas/session/private-session.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";

export class ViewAppointmentStateMgr {
  appointment$: Rx.BehaviorSubject<PrivateSessionAppointment>;
  rdDeleteRes$ = new Rx.BehaviorSubject<RD.RemoteData<any, any>>(RD.initial);

  constructor(p: { appointment: PrivateSessionAppointment }) {
    this.appointment$ = new Rx.BehaviorSubject(p.appointment);
  }

  changeAppointmentDate(date: Date) {
    const updatedAppointment = this.appointment$.value.withUpdatedStartTime({
      startTime: date,
    });
    this.appointment$.next(updatedAppointment);
  }

  setDuration(duration: number | null) {
    const updatedAppointment =
      this.appointment$.value.withUpdatedDurationInMinutes({
        durationInMinutes: duration ?? 0,
      });
    console.log("UPDATED APPOINTMENT AFTER SET DURATION! ", updatedAppointment);
    this.appointment$.next(updatedAppointment);
  }

  setPriceCents(priceDollars: number | null) {
    console.log("SETTING PRICE CENTS! ", priceDollars);
    const priceCents = priceDollars ? priceDollars * 100 : null;
    const updatedAppointment = this.appointment$.value.withUpdatedPriceCents({
      priceCents,
    });
    console.log(
      "UPDATED APPOINTMENT AFTER SET PRICE CENTS! ",
      updatedAppointment
    );
    this.appointment$.next(updatedAppointment);
  }

  removeFromCal() {
    this.rdDeleteRes$.next(RD.pending);
    return Effect.runPromise(
      BE.fetchSuccessOnlyEndpoint((Api) =>
        Api.hp.calendar.removeAppointmentFromCalendar.mutate({
          eventInstanceId: this.appointment$.value.instance.instanceId,
        })
      ).pipe(
        Effect.tap((er) => {
          this.rdDeleteRes$.next(RD.success(er));
        })
      )
    );
  }
}

type DropdownId = "calendar" | "user" | "payment" | "therapyType" | "duration";

export const ViewAppointmentFormContent: React.FC<{
  initialAppointment: PrivateSessionAppointment;
}> = ({ initialAppointment }) => {
  const hpState = useHpState();
  const stateMgr = useOnce(
    () => new ViewAppointmentStateMgr({ appointment: initialAppointment })
  );
  const nav = useNavigate();
  const [paymentMethod, setPaymentMethod] = React.useState("Auto-pay");

  const [openDropdownId, setOpenDropdownId] = React.useState<DropdownId | null>(
    null
  );

  const appointment = useObservableEagerState(stateMgr.appointment$);

  const priceCentsStr = appointment.priceCents
    ? `$${appointment.priceCents * 100}`
    : "";

  console.log("PRICE CENTS STR! ", appointment.priceCents, priceCentsStr);

  return (
    <div className="flex flex-col gap-2">
      <div className="self-end relative h-12">
        <div className="absolute bottom-2 right-4 z-30">
          <ThreeDotsMenu
            size={30}
            menuItems={[
              {
                label: "Remove from cal",
                onClick: () => {
                  stateMgr.removeFromCal().then((r) => {
                    console.log("RD DELETE RES! ", r);
                    hpState.dashboardState.showBottomToast({
                      msg: "Deleted event",
                      reload: true,
                      closeRightNav: true,
                    });
                  });
                },
              },
            ]}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2 px-4 py-1">
        <CalendarMenuInput
          title="Date & time"
          isOpen={openDropdownId === "calendar"}
          onToggle={(isOpen) => setOpenDropdownId(isOpen ? "calendar" : null)}
          initialDate={appointment.instance.startTime}
          onSelection={(dt) => {
            stateMgr.changeAppointmentDate(dt);
          }}
        />
        <SetDurationSection
          isOpen={openDropdownId === "duration"}
          onToggle={(isOpen) => setOpenDropdownId(isOpen ? "duration" : null)}
          durationInMinutes$={stateMgr.appointment$.pipe(
            RxO.map((appt) => appt.durationInMinutes)
          )}
          onChange={(v) => {
            stateMgr.setDuration(v);
          }}
        />
        <FormDisclosureContainer
          isOpen={openDropdownId === "payment"}
          onToggle={(isOpen) => setOpenDropdownId(isOpen ? "payment" : null)}
          selection={appointment.instance.privateSessionInfo.priceCents}
          buttonView={{
            _tag: "DEFAULT",
            label: "Price",
            selectedView: (priceCents) => ({
              _tag: "TEXT",
              text: priceCents ? `$${priceCents / 100}` : "",
            }),
          }}
          dropdownView={() => (
            <div className="flex w-full">
              <input
                type="text"
                className="flex-1 text-input"
                value={
                  appointment.priceCents
                    ? `$${appointment.priceCents / 100}`
                    : ""
                }
                onChange={(e) => {
                  const priceDollarsStr = e.target.value.replace("$", "");
                  const priceDollars = safeParseInt(priceDollarsStr) ?? null;
                  console.log("PRICE DOLLARS! ", priceDollars);
                  stateMgr.setPriceCents(priceDollars);
                }}
              />
            </div>
          )}
        />
        <FormRadioCard
          title="Payment"
          // optionsMenu={<div></div>}
          radioProps={{
            options: [
              {
                name: "Auto-pay",
                icon: <></>,
              },
              {
                name: "Outside platform",
                icon: <></>,
              },
            ],
            value: paymentMethod,
            onChange: (v) => {
              console.log("PAYMENT METHOD! ", v);
              setPaymentMethod(v);
            },
          }}
        />
        <SessionFormComponents.StartSessionButton
          clientUserId={appointment.client.id}
          sessionConfig={{
            durationInMinutes: appointment.durationInMinutes,
            minutesBeforeEndReminder: null,
            sendInviteEmail: false,
          }}
          onSuccess={({ sessionId }) => {
            console.log("SESSION STARTED! ", sessionId);
            const navPath =
              UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.ENTRY.buildPath({
                privateSessionId: sessionId,
              });
            nav(navPath);
          }}
        />
      </div>
    </div>
  );
};

export const ViewAppointmentFormTopView: React.FC<{
  appointment: PrivateSessionAppointment;
}> = ({ appointment }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-2 p-4">
      <AvatarCircle
        mbProfilePhoto={ImageSrc.fromMbUrl(appointment.client.name)}
        size={60}
      />
      <h1 className="font-sans font-light text-xl">
        {appointment.client.name}
      </h1>
    </div>
  );
};
