import * as S from "@effect/schema/Schema";

export module MeditationStateModule {
  export const KnownMeditationVisualSchema = S.Union(
    S.Literal("Ocean"),
    S.Literal("Flame"),
    S.Literal("Stream"),
    S.Literal("None")
  );
  export type KnownMeditationVisual = typeof KnownMeditationVisualSchema.Type;

  const MeditationEndingSoundSchema = S.Union(
    S.Literal("SINGING_BOWL"),
    S.Literal("NONE")
  );

  export const PlayStateSchema = S.Union(
    S.Literal("READY"),
    S.Literal("PLAYING"),
    S.Literal("PAUSED"),
    S.Literal("STOPPED"),
    S.Literal("COMPLETE")
  );

  export type PlayState = S.Schema.Type<typeof PlayStateSchema>;

  export class State extends S.Class<State>("State")({
    playState: PlayStateSchema,
    timerMinutesSetting: S.NullOr(S.Number),
    countdownTimerSeconds: S.NullOr(S.Number),
    visual: KnownMeditationVisualSchema,
    endingSound: MeditationEndingSoundSchema,
  }) {}

  export const JsonStateSchema = S.parseJson(State);
  export type MeditationState = S.Schema.Type<typeof JsonStateSchema>;
  export type EncodedMeditationState = S.Schema.Encoded<typeof JsonStateSchema>;

  export const defaultMeditationState = new State({
    playState: "READY",
    timerMinutesSetting: null,
    countdownTimerSeconds: null,
    visual: "None",
    endingSound: "SINGING_BOWL",
  });
}

export type MeditationState = MeditationStateModule.State; // For convenience
export type KnownMeditationVisual = MeditationStateModule.KnownMeditationVisual;

export module EmdrStateModule {
  export class OffState extends S.TaggedClass<OffState>()("OFF", {}) {}
  export class PreparingState extends S.TaggedClass<PreparingState>()(
    "PREPARING",
    {
      showBouncingBallAt: S.String,
    }
  ) {}
  export class PlayingState extends S.TaggedClass<PlayingState>()(
    "PLAYING",
    {}
  ) {}
  export class PausedState extends S.TaggedClass<PausedState>()("PAUSED", {}) {}
  export class CompleteState extends S.TaggedClass<CompleteState>()(
    "COMPLETE",
    {}
  ) {}
  const EmdrMode = S.Union(
    OffState,
    PreparingState,
    PlayingState,
    PausedState,
    CompleteState
  );

  export class State extends S.Class<State>("State")({
    mode: EmdrMode,
    ballFrequency: S.Number,
  }) {}
  export const JsonStateSchema = S.parseJson(State);
  export type EmdrState = S.Schema.Type<typeof JsonStateSchema>;
  export type EncodedEmdrState = S.Schema.Encoded<typeof JsonStateSchema>;

  export const defaultEmdrState: EmdrState = State.make({
    mode: new OffState(),
    ballFrequency: 1,
  });
}

export type EmdrState = EmdrStateModule.State; // For convenience

export module ContentViewStateModule {
  export const ContentStageViewMode = S.Union(
    S.Literal("TOP_PREVIEW"),
    S.Literal("FULL_SCREEN")
  );

  export type ContentStageViewMode = S.Schema.Type<typeof ContentStageViewMode>;

  export module StateContentDisplay {
    export class EmdrDisplay extends S.TaggedClass<EmdrDisplay>()("EMDR", {
      state: EmdrStateModule.State,
    }) {}
    export class MeditationDisplay extends S.TaggedClass<MeditationDisplay>()(
      "MEDITATION",
      {
        state: MeditationStateModule.State,
      }
    ) {}
    export class ReviewDisplay extends S.TaggedClass<ReviewDisplay>()(
      "REVIEW",
      {}
    ) {}
    export class SessionSpecificDisplay extends S.TaggedClass<SessionSpecificDisplay>()(
      "SESSION_SPECIFIC",
      {
        tool: S.String,
      }
    ) {}
    export const CurrentDisplayContent = S.Union(
      EmdrDisplay,
      MeditationDisplay,
      ReviewDisplay,
      SessionSpecificDisplay
    );

    export type CurrentDisplayContent = S.Schema.Type<
      typeof CurrentDisplayContent
    >;

    export class State extends S.Class<State>("StateContentDisplay")({
      current: S.NullOr(CurrentDisplayContent),
    }) {
      static turnOff(): State {
        return new State({
          current: null,
        });
      }

      static asEmdrDisplay(state: EmdrState): State {
        return new State({
          current: new EmdrDisplay({
            state,
          }),
        });
      }

      static asMeditationDisplay(
        state:
          | {
              _tag: "NEW";
              state: MeditationState;
            }
          | { _tag: "DEFAULT" }
      ): State {
        return new State({
          current: new MeditationDisplay({
            state:
              state._tag === "NEW"
                ? state.state
                : MeditationStateModule.defaultMeditationState,
          }),
        });
      }
    }

    export const JsonStateSchema = S.parseJson(State);

    export const defaultState: State = {
      current: null,
    };
  }

  export class ContentStageState extends S.Class<ContentStageState>(
    "ContentStageState"
  )({
    viewMode: ContentStageViewMode,
    content: StateContentDisplay.State,
  }) {}

  export const ContentStageStateJsonSchema = S.parseJson(ContentStageState);

  export type ContentStageStateFbEncoded = S.Schema.Encoded<
    typeof ContentStageStateJsonSchema
  >;
}
