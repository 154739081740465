import { Disclosure } from "@headlessui/react";
import upArrowSrc from "@public/up-arrow.svg";
import {
  ParticipantView,
  useBackgroundFilters,
  useCallStateHooks,
  type Call,
} from "@stream-io/video-react-sdk";
import { BE } from "@webapp/backend";
import { AvatarCircle } from "@webapp/components/avatar.tc";
import { ControlPanelComponents } from "@webapp/components/live-session/control-panel.components";
import {
  CustomParticipantViewUI,
  CustomVideoPlaceholder,
  WaitingRoomContainer,
} from "@webapp/components/live-session/live-session.components";
import {
  MenuItem,
  ThreeDotsMenu,
} from "@webapp/components/menus/three-dots.menu";
import { CopyLinkButton } from "@webapp/componentsmiscellaneous";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { RD } from "@webapp/prelude";
import { format } from "date-fns";
import { Effect } from "effect";
import { pipe } from "fp-ts/function";
import { useTaskEither } from "frontend-shared/src/api.mgr";
import { useHpPrivateSessionRoomCallStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/hp-room-call.statemgr";
import { usePrivateSessionStateMgr } from "frontend-shared/src/mgrs/state-mgrs/sessions/private-sessions/remote-state-and-call.statemgrs";
import { useKeyOfObservableAsState } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { SessionShortSummary } from "shared";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";

export const HpPrivateSessionWaitingRoomPage: React.FC = () => {
  const { callId } = useTypedParams(
    UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.CALLS.CALL_ID.HP.MAIN_ROOM
  );
  const sessionMgr = usePrivateSessionStateMgr();
  const hpCallStateMgr = useHpPrivateSessionRoomCallStateMgr();

  const rdSubmitRegBackClick = useObservableEagerState(
    hpCallStateMgr.callStateMgr.waitingRoomBackClickTracker.rdValue$
  );

  if (RD.isPending(rdSubmitRegBackClick)) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <WaitingRoomContainer
      onBackClick={() => {
        Effect.runPromise(
          hpCallStateMgr.callStateMgr.waitingRoomBackClickTracker.fetchAndSetEff(
            true
          )
        ).then(({ nextRoute }) => {
          window.location.href = nextRoute;
        });
      }}
      leftSide={<LeftSide />}
      rightSide={
        <PastSessionReviewSection
          clientProfilePhoto={sessionMgr.privateSession.client.profilePhoto}
        />
      }
      gotoSession={{
        title: "Enter",
        link: UROUTES.PRIVATE_SESSIONS.PRIVATE_SESSION_ID.RTV.CALLS.CALL_ID.HP.MAIN_ROOM.buildPath(
          {
            privateSessionId: sessionMgr.privateSession.id,
            callId,
          }
        ),
      }}
    />
  );
};

const PastSessionReviewSection: React.FC<{
  clientProfilePhoto: string | null;
}> = ({ clientProfilePhoto }) => {
  const sessionMgr = usePrivateSessionStateMgr();
  const rdPastSessionReviews = useTaskEither(
    BE.fetchEndpointTE((Api) =>
      Api.hp.sessions.getRecentSessionShortReviews.query({
        cpUserId: sessionMgr.privateSession.client.id,
      })
    )
  );
  return (
    <div className="hidden md:flex flex-col border border-vid-black-200 self-stretch h-[600px] rounded-lg ">
      <div className="px-4 py-2 font-semibold text-sm bg-vid-black-100 rounded-t-lg">
        Past session notes
      </div>
      {pipe(
        rdPastSessionReviews,
        RD.fold3(
          () => <FullContainerLoadingSpinner />,
          (e) => <div>{JSON.stringify(e)}</div>,
          (reviews) => {
            if (reviews.length > 0) {
              return reviews.map((r) => (
                <PastSessionReviewDisclosureItem
                  shortSummary={r}
                  clientProfilePhotoUrl={clientProfilePhoto}
                />
              ));
            } else {
              return (
                <div className="flex-1 flex justify-center items-center">
                  No past sessions
                </div>
              );
            }
          }
        )
      )}
    </div>
  );
};

const PastSessionReviewDisclosureItem: React.FC<{
  shortSummary: SessionShortSummary;
  clientProfilePhotoUrl: string | null;
}> = ({ shortSummary, clientProfilePhotoUrl }) => {
  const { sessionInfo, review } = shortSummary;
  return (
    <Disclosure key={sessionInfo.id}>
      <div className="flex flex-col">
        <Disclosure.Button className="py-4 px-4 border flex">
          <div className="flex-1 self-stretch flex gap-4 items-center">
            <AvatarCircle
              mbProfilePhoto={
                clientProfilePhotoUrl
                  ? ImageSrc.fromURL(clientProfilePhotoUrl)
                  : null
              }
              size={40}
            />
            <div>
              <div className="rounded-full w-[170px] py-2 bg-vid-black-100 text-vid-black-900 text-center">
                {format(sessionInfo.endedAt, "MMM dd, yyyy")}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center px-8 py-4">
            <div className="w-8 h-8 flex justify-center items-center rounded-full border border-vid-black-200">
              <img src={upArrowSrc} className="w-4 h-4" />
            </div>
          </div>
        </Disclosure.Button>
        <Disclosure.Panel className="text-gray-500">
          {review.autoSummary && (
            <div className="overflow-y-auto py-2 px-4  flex flex-col gap-2">
              {review.autoSummary.map((s) => {
                return <p>{`* ${s}`}</p>;
              })}
            </div>
          )}
        </Disclosure.Panel>
      </div>
    </Disclosure>
  );
};

const LeftSide: React.FC = () => {
  const sessionMgr = usePrivateSessionStateMgr();
  const { useRemoteParticipants } = useCallStateHooks();
  const remoteParticipants = useRemoteParticipants();
  return (
    <div className="flex flex-col items-center gap-8">
      <VideoSection />
      <div className="flex flex-col gap-2 items-center self-center">
        <h4 className="text-[24px]">Invite others to join</h4>
        <CopyLinkButton link={sessionMgr.privateSession.inviteLink} />
      </div>
      {remoteParticipants.length > 0 && (
        <div>{`${remoteParticipants[0].name} is waiting`}</div>
      )}
    </div>
  );
};

const VideoSection: React.FC<{}> = ({}) => {
  const sessionMgr = usePrivateSessionStateMgr();
  const { useLocalParticipant, useMicrophoneState, useCameraState } =
    useCallStateHooks();
  const { microphone, isMute: isAudioMute } = useMicrophoneState();
  const { camera, isMute: isVideoMute } = useCameraState();
  const me = useLocalParticipant();

  const { disableBackgroundFilter, applyBackgroundBlurFilter } =
    useBackgroundFilters();

  const isBackgroundBlurred = useKeyOfObservableAsState(
    sessionMgr.firestoreMgr.remoteStateSyncMgr.decodedRemoteState$,
    "hpIsScreenBlurred",
    true
  );

  const handleToggleBackgroundBlur = () => {
    if (isBackgroundBlurred) {
      disableBackgroundFilter();
      sessionMgr.firestoreMgr.remoteStateSyncMgr.runUpdateRemoteState(() => ({
        hpIsScreenBlurred: false,
      }));
    } else {
      applyBackgroundBlurFilter("high");
      sessionMgr.firestoreMgr.remoteStateSyncMgr.runUpdateRemoteState(() => ({
        hpIsScreenBlurred: true,
      }));
    }
  };

  const menuItems: MenuItem[] = [
    {
      label: isBackgroundBlurred
        ? "Disable Background Blur"
        : "Enable Background Blur",
      onClick: handleToggleBackgroundBlur,
    },
    // Add more menu items here as needed
  ];

  if (!me) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="self-stretch flex-1 flex justify-end pr-0 md:pr-24">
      <div className="basis-[500px] grow-0 shrink min-w-0 h-[500px] relative rounded-xl overflow-hidden">
        <ParticipantView
          participant={me}
          ParticipantViewUI={CustomParticipantViewUI}
          VideoPlaceholder={CustomVideoPlaceholder}
        />
        <div className="absolute top-4 right-4 w-16 h-16 z-20">
          <ThreeDotsMenu menuItems={menuItems} />
        </div>
        <div className="absolute bottom-4 right-28 w-16 h-16 z-20">
          <div className="flex gap-8 items-center">
            <ControlPanelComponents.MuteAudioButton
              onClick={() => {
                microphone.toggle();
              }}
              isMuted={isAudioMute}
            />
            {!sessionMgr.privateSession.audioOnly && (
              <ControlPanelComponents.MuteVideoButton
                onClick={() => {
                  camera.toggle();
                }}
                isMuted={isVideoMute}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

async function leaveAndDeleteSession(p: { sessionId: string; call: Call }) {
  const sessionId = p.sessionId;
  const leaveResult = await p.call.endCall();
  const deleteResult = await BE.fetchEndpointTE((Api) =>
    Api.hp.sessions.session.deleteSession.mutate({
      sessionId,
    })
  )();

  console.log("LEAVE RESULT: ", leaveResult);
  console.log("DELETE RESULT: ", deleteResult);

  return deleteResult;
}
