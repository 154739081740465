import { Effect } from "effect";
import type {
  InMemoryFile,
  UploadFileToCloudinaryResponse,
} from "shared/schemas/file.schemas";

export class FileUtils {
  static fileAsBase64 = (file: File): Effect.Effect<string> =>
    Effect.async<string>((resume) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resume(Effect.succeed(reader.result));
        } else {
          resume(Effect.die(new Error("FileReader result is not a string")));
        }
      };
      reader.onerror = () => {
        resume(Effect.die(new Error("Failed to read file")));
      };
      reader.readAsDataURL(file);
    });

  static blobAsBase64 = (blob: Blob): Effect.Effect<string> =>
    Effect.async<string>((resume) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resume(Effect.succeed(reader.result));
        } else {
          resume(Effect.die(new Error("FileReader result is not a string")));
        }
      };
      reader.onerror = () => {
        resume(Effect.die(new Error("Failed to read blob")));
      };
      reader.readAsDataURL(blob);
    });
}

export class FileUploadUtils {
  static uploadFileToCloudinary = (p: {
    secureUploadUrl: string;
    file: InMemoryFile;
  }): Effect.Effect<UploadFileToCloudinaryResponse> =>
    Effect.gen(this, function* () {
      const formData = new FormData();
      formData.append("file", p.file.base64String);

      const uploadResponse = yield* Effect.promise(() =>
        fetch(p.secureUploadUrl, {
          method: "POST",
          body: formData,
        })
      );

      if (!uploadResponse.ok) {
        const errorText = yield* Effect.promise(() => uploadResponse.text());
        throw new Error(`Cloudinary upload failed: ${errorText}`);
      }

      const result: UploadFileToCloudinaryResponse = yield* Effect.promise(() =>
        uploadResponse.json()
      );
      console.log("Cloudinary upload result:", result);

      return result;
    });
}

export class FileDownloadUtils {
  static downloadFile = (p: {
    presignedDownloadUrl: string;
  }): Effect.Effect<Blob> =>
    Effect.gen(this, function* () {
      const response = yield* Effect.promise(() =>
        fetch(p.presignedDownloadUrl)
      );
      const blob = yield* Effect.promise(() => response.blob());
      return blob;
    });
}
