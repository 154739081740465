import * as S from "@effect/schema/Schema";
import { KnownMediaFileSchema } from "shared/index";
import {
  ContentViewStateModule,
  EmdrStateModule,
} from "./shared-session-content-state.types";

export module BaseSessionStateModule {
  export class State extends S.Class<State>("BaseSessionState")({
    playingMediaFile: S.NullOr(KnownMediaFileSchema),
    isSharingAudioOnly: S.NullOr(S.Boolean),
    hostContentViewMode: S.NullOr(ContentViewStateModule.ContentStageViewMode),
    participantsContentViewMode: S.NullOr(
      ContentViewStateModule.ContentStageViewMode
    ),
    content: S.NullOr(
      ContentViewStateModule.StateContentDisplay.JsonStateSchema
    ),
  }) {
    static setDefaultInitialEmdrViewState(curState: State) {
      return new State({
        ...curState,
        hostContentViewMode: "TOP_PREVIEW",
        participantsContentViewMode: "TOP_PREVIEW",
        content: ContentViewStateModule.StateContentDisplay.State.asEmdrDisplay(
          EmdrStateModule.defaultEmdrState
        ),
      });
    }
  }
  export type FirebaseEncodedState = S.Schema.Encoded<typeof State>;

  export const defaultState: State = {
    playingMediaFile: null,
    isSharingAudioOnly: null,
    hostContentViewMode: null,
    participantsContentViewMode: null,
    content: null,
  };
}

export module PrivateSessionStateModule {
  export class SessionStateSpecific extends S.Class<SessionStateSpecific>(
    "SessionStateSpecific"
  )({
    hpIsScreenBlurred: S.Boolean,
    cpIsScreenBlurred: S.Boolean,
    cpApprovedToJoinAt: S.NullOr(S.String),
    isShowingNextScheduleReminder: S.Boolean,
    showedNextScheduleReminderAt: S.NullOr(S.String),
    isRecordingSectionId: S.NullOr(S.String),
  }) {}
  export type FirebaseEncodedSessionStateSpecific = S.Schema.Encoded<
    typeof SessionStateSpecific
  >;

  const defaultSessionStateSpecific = new SessionStateSpecific({
    hpIsScreenBlurred: false,
    cpIsScreenBlurred: false,
    cpApprovedToJoinAt: null,
    isShowingNextScheduleReminder: false,
    showedNextScheduleReminderAt: null,
    isRecordingSectionId: null,
  });

  export class State extends S.Class<State>("StateSchema")({
    ...BaseSessionStateModule.State.fields,
    ...SessionStateSpecific.fields,
  }) {}

  export type FirebaseEncodedState = S.Schema.Encoded<typeof State>;

  export const defaultState: State = {
    ...BaseSessionStateModule.defaultState,
    ...defaultSessionStateSpecific,
  };
}

export type PrivateSessionState = PrivateSessionStateModule.State; // For convenience

export namespace GroupSessionStateModule {
  export class SessionStateSpecific extends S.Class<SessionStateSpecific>(
    "SessionStateSpecific"
  )({}) {}
  export type FirebaseEncodedSessionStateSpecific = S.Schema.Encoded<
    typeof SessionStateSpecific
  >;

  const defaultSessionStateSpecific = {};

  export class State extends S.Class<State>("StateSchema")({
    ...BaseSessionStateModule.State.fields,
    ...SessionStateSpecific.fields,
  }) {}

  export type FirebaseEncodedState = S.Schema.Encoded<typeof State>;

  export const defaultState: State = {
    ...BaseSessionStateModule.defaultState,
    ...defaultSessionStateSpecific,
  };
}

export type GroupSessionState = GroupSessionStateModule.State; // For convenience
