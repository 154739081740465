import { Effect, Match, Option } from "effect";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import * as N from "fp-ts/number";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import {
  ContentViewStateModule,
  MeditationStateModule,
  type MeditationState,
} from "shared/session-state/shared-session-content-state.types";
import { isNonNull } from "shared/util";
import { distinctUntilChangedEquals } from "../../../../util";
import type { AbstractAudioPlayer } from "../../../media-player.statemgr";
import { AudioMediaStateMgr } from "./shared/audio-media.statemgr";
import {
  BaseSessionToolStateMgr,
  type BaseSessionToolStateMgrParams,
} from "./shared/base-session-tool.statemgr";

type PlayState = MeditationState["playState"];

export class MeditationRemoteStateMgr extends BaseSessionToolStateMgr {
  remoteMeditationState$: Rx.Observable<MeditationState>;

  localCountdownTimerSeconds$ = new Rx.BehaviorSubject<O.Option<number>>(
    O.none
  );
  countdownTimerInterval: Timer | null = null;

  showClock$: Rx.Observable<Option.Option<number>>;

  audioMediaStateMgr: AudioMediaStateMgr;

  setInitialState() {
    this.updateRemoteMeditationStateP(
      MeditationStateModule.defaultMeditationState
    ).then();
  }

  timerMinutesToSeconds(timerMinutes: number | null) {
    return pipe(
      timerMinutes,
      O.fromNullable,
      O.map((m) => m * 60)
    );
  }

  setRemoteTimerMinutes(timerMinutes: number | null): void {
    const timerSeconds = this.timerMinutesToSeconds(timerMinutes);

    console.log("SETTING REMOTE TIMER! ", timerMinutes);

    this.runUpdateRemoteMeditationState({
      timerMinutesSetting: timerMinutes,
      countdownTimerSeconds: O.toNullable(timerSeconds),
      playState: "READY",
    });
  }

  setVisual(visual: MeditationStateModule.KnownMeditationVisual) {
    this.runUpdateRemoteMeditationState({ visual });
  }

  updateRemoteMeditationStateP(partial: Partial<MeditationState>) {
    const eff = this.remoteStateMgr
      .updateBaseContentState(({ current }) => {
        console.log("CURRENT! ", current);
        if (current?._tag !== "MEDITATION") {
          return ContentViewStateModule.StateContentDisplay.State.asMeditationDisplay(
            { _tag: "DEFAULT" }
          );
        }

        const curMeditationState = current.state;
        const baseMeditationState =
          curMeditationState ?? MeditationStateModule.defaultMeditationState;
        const newMeditationState = new MeditationStateModule.State({
          ...baseMeditationState,
          ...partial,
        });

        console.log("NEW MEDITATION STATE! ", newMeditationState);

        return ContentViewStateModule.StateContentDisplay.State.asMeditationDisplay(
          { _tag: "NEW", state: newMeditationState }
        );
      })
      .pipe(
        Effect.flatMap((_) =>
          Effect.sync(() =>
            this.remoteStateMgr.runUpdateHostContentViewMode("TOP_PREVIEW")
          )
        )
      );

    return Effect.runPromise(eff);
  }

  runUpdateRemoteMeditationState(partial: Partial<MeditationState>) {
    this.updateRemoteMeditationStateP(partial).then();
  }

  setRemotePlayState(playState: PlayState) {
    this.updateRemoteMeditationStateP({ playState }).then();
  }

  endAndClear() {
    this.clearLocalCountdownTimer();
    this.remoteStateMgr.clearAllContentState();
  }

  constructor(
    readonly baseParams: BaseSessionToolStateMgrParams,
    readonly audioPlayer: AbstractAudioPlayer
  ) {
    super(baseParams);
    this.audioMediaStateMgr = new AudioMediaStateMgr(baseParams, audioPlayer);
    this.remoteMeditationState$ = this.remoteStateMgr.decodedRemoteState$.pipe(
      RxO.map((rs) => rs.content?.current),
      RxO.filter(isNonNull),
      RxO.filter((c) => c._tag === "MEDITATION"),
      RxO.map((c) => c.state),
      distinctUntilChangedEquals()
    );

    this.remoteMeditationState$
      .pipe(
        RxO.map((rs) => ({
          playState: rs.playState,
          timerMinutesSetting: rs.timerMinutesSetting,
        })),
        RxO.distinctUntilChanged((a, b) => {
          return (
            a.playState === b.playState &&
            a.timerMinutesSetting === b.timerMinutesSetting
          );
        })
      )
      .subscribe(({ playState, timerMinutesSetting }) => {
        this.clearCountdownTimerInterval();
        this.handleNewPlayState({ playState, timerMinutesSetting });
      });

    this.showClock$ = Rx.combineLatest([
      this.localCountdownTimerSeconds$,
      this.remoteMeditationState$,
    ]).pipe(
      RxO.map(([cts, rs]) => {
        const showStates: PlayState[] = [
          "PLAYING",
          "PAUSED",
          "READY",
          //"COMPLETE"
        ];
        if (showStates.includes(rs.playState) && O.isSome(cts)) {
          return Option.some(cts.value);
        } else {
          return Option.none();
        }
      }),
      RxO.distinctUntilChanged((a, b) => O.getEq(N.Eq).equals(a, b))
    );
  }

  private startPlayingTimer(timerMinutesSetting: number | null) {
    this.clearLocalCountdownTimer();
    this.countdownTimerInterval = setInterval(() => {
      const curSeconds = this.localCountdownTimerSeconds$.value;
      if (O.isSome(curSeconds)) {
        if (curSeconds.value <= 0) {
          this.updateRemoteMeditationStateP({ playState: "COMPLETE" }).then(
            (_) => {
              this.clearCountdownTimerInterval();
            }
          );
        } else {
          // console.log("DECREMENTING! ", curSeconds.value);
          this.localCountdownTimerSeconds$.next(O.some(curSeconds.value - 1));
        }
      } else {
        if (timerMinutesSetting) {
          this.localCountdownTimerSeconds$.next(
            O.some(timerMinutesSetting * 60)
          );
        }
      }
    }, 1000);
  }

  private handleNewPlayState(p: {
    playState: PlayState;
    timerMinutesSetting: number | null;
  }) {
    const { playState, timerMinutesSetting } = p;
    return Match.value(playState).pipe(
      Match.when("PLAYING", () => {
        console.log("PLAYING COUNTDOWN LOGIC! ", timerMinutesSetting);
        this.startPlayingTimer(timerMinutesSetting);
      }),
      Match.when("STOPPED", () => {
        console.log("STOPPED! ");
        this.clearLocalCountdownTimer();
        this.audioMediaStateMgr.pauseLocal();
      }),
      Match.when("COMPLETE", () => {
        this.audioMediaStateMgr.playLocallyOnly("simple-singing-bowl.mp3");
        setTimeout(() => {
          this.setRemotePlayState("STOPPED");
        }, 8000);
      }),
      Match.orElse(() => {})
    );
  }

  clearLocalCountdownTimer() {
    if (O.isSome(this.localCountdownTimerSeconds$.value)) {
      this.localCountdownTimerSeconds$.next(O.none);
    }
    this.clearCountdownTimerInterval();
  }

  clearCountdownTimerInterval() {
    console.log(
      "CLEARING COUNTDOWN TIMER INTERVAL! ",
      this.countdownTimerInterval
    );
    if (this.countdownTimerInterval) {
      clearInterval(this.countdownTimerInterval);
    }
    this.countdownTimerInterval = null;
  }
}
