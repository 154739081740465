import { HpDashboardCalendarPage } from "@hppages/dashboard/calendar/calendar.page";
import { HpDashboardClientLayout } from "@hppages/dashboard/clients/[clientId]/[clientId].layout";
import { HpDashboardClientInfoPage } from "@hppages/dashboard/clients/[clientId]/client-info.page";
import { HpDashboardClientMessagesPage } from "@hppages/dashboard/clients/[clientId]/messages.page";
import { HpMyDashboardClientsPage } from "@hppages/dashboard/clients/clients.page";
import { CommunitiesLandingPage } from "@hppages/dashboard/community/communities/communities-landing.page";
import { CommunityTabLayout } from "@hppages/dashboard/community/community-tab.layout";
import {
  CommunityDiscussionFeed,
  CommunityLearningTab,
  CommunityMembersTab,
  HpDashboardCommunityPageLayout,
} from "@hppages/dashboard/community/community/community.layout";
import { CommunityEventPage } from "@hppages/dashboard/community/community/events/[eventId].page";
import {
  CommunityEventsTabAll,
  CommunityEventsTabLayout,
} from "@hppages/dashboard/community/community/events/community-events.tab";
import { GlobalMemberChatTab } from "@hppages/dashboard/community/global/members/[userId]/chat-tab";
import {
  GlobalMemberAboutTab,
  GlobalMemberPage,
} from "@hppages/dashboard/community/global/members/[userId]/global-member.page";
import { HpDashboardLayout } from "@hppages/dashboard/dashboard.layout";
import HpDashboardHomePage from "@hppages/dashboard/home.page";
import { HpMyDashboardInvoicesPage } from "@hppages/dashboard/invoices.page";
import { HpDashboardSettingsAccountPage } from "@hppages/dashboard/settings/account/account.page";
import { HpDashboardSettingsBillingPage } from "@hppages/dashboard/settings/billing/billing.page";
import { HpSettingsClientAndSchedulingPage } from "@hppages/dashboard/settings/clients-and-scheduling/clients-and-scheduling.page";
import { HpSettingsLayout } from "@hppages/dashboard/settings/settings.layout";
import { HpOnboardChooseProfileMethodPage } from "@hppages/onboard/choose-profile-method.page";
import { HpOnboardSetProfilePage } from "@hppages/onboard/set-profile.page";
import { OnboardLayout } from "@pages/onboard/onboard.layout";
import { type RouteObject } from "react-router-dom";
import { HPROUTES } from "shared/routes/hp/hp.routes";

const communityRoutes: RouteObject = {
  children: [
    {
      // path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID
      //   .relativePath,
      element: <GlobalMemberPage />,
      children: [
        {
          path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID
            .ABOUT.relativePath,
          element: <GlobalMemberAboutTab />,
        },
        {
          path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID
            .CHAT.relativePath,
          element: <GlobalMemberChatTab />,
        },
      ],
    },
    {
      // path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.relativePath,
      element: <CommunityTabLayout />,
      children: [
        {
          path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.relativePath,
          element: <CommunitiesLandingPage />,
        },
        {
          // path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY
          //   .relativePath,
          element: <HpDashboardCommunityPageLayout />,
          children: [
            {
              path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY
                .DISCUSSION.relativePath,
              element: <CommunityDiscussionFeed />,
            },
            {
              path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY
                .LEARNING.relativePath,
              element: <CommunityLearningTab />,
            },
            {
              children: [
                {
                  path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES
                    .COMMUNITY.MEMBERS.relativePath,
                  element: <CommunityMembersTab />,
                },
              ],
            },
            {
              element: <CommunityEventsTabLayout />,
              children: [
                {
                  path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES
                    .relativePath,
                },
                {
                  path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES
                    .COMMUNITY.EVENTS.ALL.relativePath,
                  element: <CommunityEventsTabAll />,
                },
                {
                  path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES
                    .COMMUNITY.EVENTS.EVENT_INSTANCE_ID.relativePath,
                  element: <CommunityEventPage />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const hpRoutes: RouteObject[] = [
  {
    element: <OnboardLayout />,
    children: [
      {
        path: HPROUTES.ONBOARD_CHOOSE_PROFILE_METHOD.relativePath,
        element: <HpOnboardChooseProfileMethodPage />,
      },
      {
        path: HPROUTES.ONBOARD_SET_PROFILE.relativePath,
        element: <HpOnboardSetProfilePage />,
      },
    ],
  },
  {
    element: <HpDashboardLayout />,
    children: [
      {
        path: HPROUTES.MY.DASHBOARD.CLIENTS.relativePath,
        element: <HpMyDashboardClientsPage />,
      },
      communityRoutes,
      {
        element: <HpDashboardClientLayout />,
        children: [
          {
            path: HPROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.INFO.relativePath,
            element: <HpDashboardClientInfoPage />,
          },
          {
            path: HPROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.CHAT.relativePath,
            element: <HpDashboardClientMessagesPage />,
          },
        ],
      },
      {
        path: HPROUTES.MY.DASHBOARD.HOME.relativePath,
        element: <HpDashboardHomePage />,
      },
      {
        path: HPROUTES.MY.DASHBOARD.INVOICES.relativePath,
        element: <HpMyDashboardInvoicesPage />,
      },
      {
        path: HPROUTES.MY.DASHBOARD.CALENDAR.relativePath,
        element: <HpDashboardCalendarPage />,
      },
      {
        // path: HPROUTES.MY.DASHBOARD.SETTINGS.relativePath,
        element: <HpSettingsLayout />,
        children: [
          {
            path: HPROUTES.MY.DASHBOARD.SETTINGS.ACCOUNT.relativePath,
            element: <HpDashboardSettingsAccountPage />,
          },
          {
            path: HPROUTES.MY.DASHBOARD.SETTINGS.CLIENT_AND_SCHEDULING
              .relativePath,
            element: <HpSettingsClientAndSchedulingPage />,
          },
          {
            path: HPROUTES.MY.DASHBOARD.SETTINGS.BILLING.relativePath,
            element: <HpDashboardSettingsBillingPage />,
          },
        ],
      },
    ],
  },
];
