import { Effect, Option } from "effect";
import type { ApiMgr } from "frontend-shared/src/api.mgr";
import type { FirebaseJsMgr } from "frontend-shared/src/firebase";
import {
  FirestorePrivateSessionStateMgmt,
  type FirestoreSessionStateMgmt,
} from "frontend-shared/src/firestore-live-session.mgmt";
import { BaseStateMgr } from "frontend-shared/src/mgrs/state-mgrs/base.statemgr";
import {
  createContextAndHook,
  FetchSuccessAtom,
  useOnce,
} from "frontend-shared/src/util";
import { useObservableState } from "observable-hooks";
import { useEffect, useMemo } from "react";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { RD } from "shared/base-prelude";
import type { CallWithCredentials } from "shared/schemas/call.schemas";
import type {
  PrivateSessionInfo,
  RegisterCloseCallClickNextAction,
  RegisterCloseCallClickResult,
} from "shared/schemas/session/private-session.schemas";
import type { PrivateSessionStateModule } from "shared/session-state/session-state.types";
import { CallStateMgr } from "../call.statemgr";

export class PrivateSessionStateMgr extends BaseStateMgr {
  firestoreMgr: FirestoreSessionStateMgmt<
    PrivateSessionStateModule.FirebaseEncodedState,
    PrivateSessionStateModule.State
  >;

  constructor(
    readonly privateSession: PrivateSessionInfo,
    firebaseJsMgr: FirebaseJsMgr,
    apiMgr: ApiMgr
  ) {
    super({ apiMgr });

    this.firestoreMgr = FirestorePrivateSessionStateMgmt(
      firebaseJsMgr,
      privateSession.id
    );
  }
}

export const [PrivateSessionStateMgrContext, usePrivateSessionStateMgr] =
  createContextAndHook<PrivateSessionStateMgr>();

export class PrivateSessionCallStateMgr extends CallStateMgr {
  submitCallClosedResult$ = new Rx.BehaviorSubject<
    RD.RemoteData<any, RegisterCloseCallClickResult>
  >(RD.initial);

  constructor(
    readonly privateSession: PrivateSessionInfo,
    readonly callId: string,
    apiMgr: ApiMgr
  ) {
    super(callId, apiMgr);
  }

  onCloseCallClick = (p: {
    onNextAction: (a: RegisterCloseCallClickNextAction) => void;
  }) => Effect.runPromise(this.onCloseCallClickEff(p));

  private onCloseCallClickEff = (p: {
    onNextAction: (a: RegisterCloseCallClickNextAction) => void;
  }) =>
    Effect.gen(this, function* () {
      yield* Effect.sync(() => {
        this.submitCallClosedResult$.next(RD.pending);
      });

      const { result } = yield* this.BE.fetchSuccessOnlyEndpoint((Api) =>
        Api.u.privateSession.registerCloseCall.mutate({
          sessionId: this.privateSession.id,
          callId: this.callId,
        })
      );

      return yield* Effect.sync(() => {
        p.onNextAction(result.nextAction);
      });
    });

  waitingRoomBackClickTracker = new FetchSuccessAtom({
    endpt: (Api) =>
      Api.u.privateSession.registerWaitingRoomBackClick.mutate({
        sessionId: this.privateSession.id,
        callId: this.callId,
      }),
    apiMgr: this.BE,
  });
}

export const [
  PrivateSessionCallStateMgrContext,
  usePrivateSessionCallStateMgr,
] = createContextAndHook<PrivateSessionCallStateMgr>();

export function usePluckPrivateSessionRemoteState$(
  remoteState$: Rx.Observable<PrivateSessionStateModule.State>,
  pluckKey: keyof PrivateSessionStateModule.State
) {
  const plucked$ = useOnce(() => remoteState$.pipe(RxO.pluck(pluckKey)));

  return plucked$;
}

export function usePluckPrivateSessionRemoteState<
  K extends keyof PrivateSessionStateModule.State
>(
  remoteState$: Rx.Observable<PrivateSessionStateModule.State>,
  pluckKey: K,
  defaultValue: PrivateSessionStateModule.State[K]
) {
  const plucked$ = usePluckPrivateSessionRemoteState$(remoteState$, pluckKey);
  const plucked = useObservableState(plucked$, defaultValue);

  return plucked;
}

export function useSetupPrivateSessionCallStateMgr(
  sessionStateMgr: PrivateSessionStateMgr,
  apiMgr: ApiMgr,
  callId: string
): Option.Option<{
  psCallStateMgr: PrivateSessionCallStateMgr;
}> {
  const psCallStateMgr = useMemo(() => {
    return new PrivateSessionCallStateMgr(
      sessionStateMgr.privateSession,
      callId,
      apiMgr
    );
  }, [sessionStateMgr.privateSession, callId, apiMgr]);

  return Option.some({ psCallStateMgr });
}
