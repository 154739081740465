import { useCallStateHooks } from "@stream-io/video-react-sdk";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import type { Rx } from "@webapp/prelude";
import { Match } from "effect";
import {
  BaseRegisterPingRes,
  MainRoomStateMgr,
} from "frontend-shared/src/mgrs/state-mgrs/sessions/main-room.statemgr";
import { useObservableEagerState, useObservableState } from "observable-hooks";
import { useEffect } from "react";
import { RD } from "shared/base-prelude";
import type { BaseSessionStateModule } from "shared/session-state/session-state.types";
import {
  ControlPanelComponents,
  ControlPanelContainer,
  DefaultCallControlButtons,
  SettingsIcon,
} from "../control-panel.components";
import { LiveSessionComponents } from "../live-session.components";
import { EMDRBall } from "../right-nav/therapy-tools/emdr/emdr-ball-vis";
import { MeditationPreview } from "../right-nav/therapy-tools/meditation.tools";
import { KnownTools } from "../right-nav/therapy-tools/therapy-tools.components";
import { MainRoomRightNav } from "./components/main-room-right-nav.fc";
import { StageTopNav } from "./components/stage-top-nav.fc";
import { useAssumedCurrentChannel } from "@pages/u/u.webstatemgr";

interface StageRenders<SessionSpecificStageViewState, SessionSpecificContent> {
  sessionSpecificStageView: (
    stageViewState: SessionSpecificStageViewState
  ) => React.ReactNode;
  sessionSpecificContentView: (
    content: SessionSpecificContent
  ) => React.ReactNode;
  bottomOfTopPreview: () => React.ReactNode;
  meOnlyVideoView: () => React.ReactNode;
}

export interface MainRoomContainerProps<
  RegisterPingRes extends BaseRegisterPingRes,
  SessionSpecificStageViewState,
  SessionSpecificContent,
  RemoteSessionState extends BaseSessionStateModule.FirebaseEncodedState,
  SessionState extends BaseSessionStateModule.State
> {
  mainRoomStateMgr: MainRoomStateMgr<
    RegisterPingRes,
    SessionSpecificStageViewState,
    SessionSpecificContent,
    RemoteSessionState,
    SessionState
  >;

  stageRenders: StageRenders<
    SessionSpecificStageViewState,
    SessionSpecificContent
  >;
  onCloseCallButtonClick: () => void;
  knownTools: KnownTools;
  settings: {
    shareableLink: string;
  };
  leftControls: React.ReactNode[];
  topBarLeft: {
    memberProfilePhotos: (string | null)[];
    totalMembers?: number;
  };
  rdClosedCallResult$: Rx.Observable<RD.RemoteData<any, any>>;
}

export const MainRoomContainer = <
  RegisterPingRes extends BaseRegisterPingRes,
  SessionSpecificStageViewState,
  SessionSpecificContent,
  RemoteSessionState extends BaseSessionStateModule.FirebaseEncodedState,
  SessionState extends BaseSessionStateModule.State
>({
  onCloseCallButtonClick,
  stageRenders,
  knownTools,
  leftControls,
  topBarLeft,
  settings,
  rdClosedCallResult$,
  mainRoomStateMgr,
}: MainRoomContainerProps<
  RegisterPingRes,
  SessionSpecificStageViewState,
  SessionSpecificContent,
  RemoteSessionState,
  SessionState
>) => {
  const { useMicrophoneState, useCameraState, useCallCallingState } =
    useCallStateHooks();
  const callState = useCallCallingState();
  const { isMute: isAudioMute } = useMicrophoneState();
  const { isMute: isVideoMute } = useCameraState();
  const rightNav = useObservableEagerState(mainRoomStateMgr.rightNav$);
  const rdCloseCallResult = useObservableEagerState(rdClosedCallResult$);

  const channelMgr = useAssumedCurrentChannel();

  useEffect(() => {
    console.log("CALL STATE! ", callState);
  }, [callState]);

  console.log("RENDERING MAIN ROOM CONTAINER! ");

  // const isViewingContentMaximized$ = useOnce(() =>
  //   mainRoomStateMgr.stageViewMgr.stageViewState$.pipe(
  //     RxO.map((v) => {
  //       if (v._tag === "CONTENT_VIEW" && v.viewMode._tag === "FULL_SCREEN") {
  //         return Option.some(v.content);
  //       } else {
  //         return Option.none();
  //       }
  //     })
  //   )
  // );
  // const mbViewingContentMaximized: Option.Option<
  //   SpecialContent<SessionSpecificContent>
  // > = useObservableState(isViewingContentMaximized$, Option.none());
  const isLeftNavToolsOpen = useObservableEagerState(
    mainRoomStateMgr.isLeftNavToolsOpen$
  );

  // if (Option.isSome(mbViewingContentMaximized)) {
  //   return (
  //     <div className="w-screen h-screen bg-vid-black-900 flex flex-col justify-center items-center relative overflow-hidden p-8">
  //       {Match.value(mbViewingContentMaximized.value).pipe(
  //         Match.tag("EMDR", ({ state }) => {
  //           return (
  //             <EMDRBall
  //               frequency={state.ballFrequency}
  //               isPlaying={state.mode.state === "PLAYING"}
  //               soundType="beep"
  //               soundDuration={0.05}
  //             />
  //           );
  //         }),
  //         Match.tag("MEDITATION", () => {
  //           return (
  //             <MeditationPreview
  //               meditationMgr={mainRoomStateMgr.tools.meditationMgr}
  //             />
  //           );
  //         }),
  //         Match.orElse(() => <></>)
  //       )}
  //     </div>
  //   );
  // }

  return (
    <div className="w-screen h-screen bg-vid-black-900 flex flex-col relative overflow-hidden">
      {RD.isPending(rdCloseCallResult) && (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-50  flex items-center justify-center">
          <FullContainerLoadingSpinner />
        </div>
      )}
      <div className="flex-1 flex gap-8 min-h-0 py-8 px-2 md:px-[36px] max-w-full">
        {isLeftNavToolsOpen && (
          <div className="basis-[60px] self-stretch grow-0 shrink-0 relative hidden md:flex">
            <div className="absolute inset-0 z-20 flex flex-col-reverse items-start gap-4 py-8">
              <LiveSessionComponents.BottomLeftButtons.ToggleNavButton
                arrowDirection="right"
                onClick={() => {
                  mainRoomStateMgr.toggleLeftToolsNav();
                }}
              />
              {leftControls.map((lc, idx) => (
                <div key={idx}>{lc}</div>
              ))}
            </div>
          </div>
        )}
        <div className="flex-1 min-h-0 flex flex-col gap-4">
          <StageArea
            topBarLeft={topBarLeft}
            mainRoomStateMgr={mainRoomStateMgr}
            stageRenders={stageRenders}
            isLeftNavToolsOpen={isLeftNavToolsOpen}
            onCloseTopPreview={() => {
              mainRoomStateMgr.stageViewMgr.closeTopPreview();
            }}
          />
        </div>
        <div className="absolute right-0 top-0 bottom-0 md:relative flex flex-col py-0">
          <MainRoomRightNav
            rightNav={rightNav}
            mainRoomStateMgr={mainRoomStateMgr}
            knownTools={knownTools}
            settings={settings}
          />
        </div>
      </div>
      <div className="basis-[100px] grow-0 shrink-0 bg-white flex flex-col justify-center">
        <ControlPanelContainer
          centralControls={
            <DefaultCallControlButtons
              audioButton={{
                isMuted: isAudioMute,
                onClick: () => {
                  channelMgr.microphone
                    .toggle()
                    .then((r) => {
                      console.log("RESULT OF MICROPHONE TOGGLE! ", r);
                    })
                    .catch((e) => {
                      console.log("ERROR TOGGLE MICROPHONE! ", e);
                    });
                },
              }}
              videoButton={{
                isMuted: isVideoMute,
                onClick: () => {
                  channelMgr.camera
                    .toggle()
                    .then((r) => {
                      console.log("RESULT OF CAMERA TOGGLE! ", r);
                    })
                    .catch((e) => {
                      console.log("ERROR TOGGLE CAMERA! ", e);
                    });
                },
              }}
              onEndCallButtonClick={() => {
                onCloseCallButtonClick();
              }}
            />
          }
          rightControls={
            <div className="flex gap-4 items-center">
              <ControlPanelComponents.RoundButtonContainer
                onClick={() => {
                  mainRoomStateMgr.openPanel({
                    state: "THERAPY_TOOLS",
                  });
                }}
                bgColor="white"
              >
                <img src="/magicpen.svg" />
              </ControlPanelComponents.RoundButtonContainer>
              <ControlPanelComponents.RoundButtonContainer
                onClick={() => {
                  mainRoomStateMgr.openPanel({
                    state: "SETTINGS",
                  });
                }}
                bgColor="white"
              >
                <SettingsIcon />
              </ControlPanelComponents.RoundButtonContainer>
            </div>
          }
        />
      </div>
    </div>
  );
};

const StageArea = <
  RegisterPingRes extends BaseRegisterPingRes,
  SessionSpecificStageViewState,
  SessionSpecificContent,
  RemoteSessionState extends BaseSessionStateModule.FirebaseEncodedState,
  SessionState extends BaseSessionStateModule.State
>({
  topBarLeft,
  mainRoomStateMgr,
  stageRenders,
  isLeftNavToolsOpen,
  onCloseTopPreview,
}: {
  topBarLeft: MainRoomContainerProps<
    RegisterPingRes,
    SessionSpecificStageViewState,
    SessionSpecificContent,
    RemoteSessionState,
    SessionState
  >["topBarLeft"];
  mainRoomStateMgr: MainRoomStateMgr<
    RegisterPingRes,
    SessionSpecificStageViewState,
    SessionSpecificContent,
    RemoteSessionState,
    SessionState
  >;
  stageRenders: StageRenders<
    SessionSpecificStageViewState,
    SessionSpecificContent
  >;
  isLeftNavToolsOpen: boolean;
  onCloseTopPreview: () => void;
}) => {
  const stageViewState = useObservableState(
    mainRoomStateMgr.stageViewMgr.stageViewState$,
    { _tag: "UNKNOWN" }
  );
  return (
    <div className="flex-1 min-h-0 flex flex-col gap-4">
      <StageTopNav
        topBarLeft={topBarLeft}
        mainRoomStateMgr={mainRoomStateMgr}
      />
      <div className="flex-1 flex flex-col  min-w-0 min-h-0 gap-4 max-w-full relative">
        {/* <h4 className="text-white text-sm font-bold">
          {JSON.stringify(stageViewState)}
        </h4> */}
        {Match.value(stageViewState).pipe(
          Match.tag("UNKNOWN", () => <></>),
          Match.tag("CONTENT_VIEW", ({ viewMode, content }) => {
            const contentView = Match.value(content).pipe(
              Match.tag("EMDR", ({ state }) => {
                return (
                  <div className="flex-1 flex flex-col justify-center">
                    <EMDRBall
                      frequency={state.ballFrequency}
                      isPlaying={state.mode._tag === "PLAYING"}
                      soundType="beep"
                      soundDuration={0.05}
                    />
                  </div>
                );
              }),
              Match.tag("SESSION_SPECIFIC", (r) =>
                stageRenders.sessionSpecificContentView(r.tool)
              ),
              Match.tag("MEDITATION", () => {
                return (
                  <MeditationPreview
                    meditationMgr={mainRoomStateMgr.tools.meditationMgr}
                  />
                );
              }),
              Match.orElse(() => <></>)
            );
            return Match.value(viewMode).pipe(
              Match.when("FULL_SCREEN", () => {
                return (
                  <div className="flex-1 w-full h-full relative">
                    <div className="absolute inset-0 w-full h-full rounded-[12px] overflow-hidden z-20 flex flex-col">
                      {contentView}
                    </div>
                    <div
                      className="absolute bottom-4 right-4 w-[40px] h-[40px] bg-vid-black-900 rounded-full flex items-center justify-center cursor-pointer z-40"
                      onClick={() => {
                        mainRoomStateMgr.stageViewMgr.minimizeTopPreview();
                      }}
                    >
                      <MaximizeSVG />
                    </div>
                    <div className="absolute bottom-20 left-20 w-[3px] h-[3px] z-10">
                      {stageRenders.bottomOfTopPreview()}
                    </div>
                  </div>
                );
              }),
              Match.when("TOP_PREVIEW", () => {
                return (
                  <div className="flex flex-col gap-4 flex-1">
                    <div className="flex-1 min-h-0 flex flex-col">
                      <TopPreviewContainer
                        content={contentView}
                        onClose={() => {
                          onCloseTopPreview();
                        }}
                        onMaximize={() => {
                          mainRoomStateMgr.stageViewMgr.maximizeTopPreview();
                        }}
                      />
                    </div>
                    <div className="flex-1 min-h-0 flex flex-col">
                      {stageRenders.bottomOfTopPreview()}
                    </div>
                  </div>
                );
              }),
              Match.exhaustive
            );
          }),
          Match.tag("PARTICIPANTS_VIEW", ({ viewMode }) => {
            return Match.value(viewMode).pipe(
              Match.tag("SELF_ONLY_VIDEO", () =>
                stageRenders.meOnlyVideoView()
              ),
              Match.tag("SESSION_SPECIFIC", ({ viewMode }) => {
                return stageRenders.sessionSpecificStageView(viewMode);
              }),
              Match.exhaustive
            );
          }),
          Match.exhaustive
        )}
        {!isLeftNavToolsOpen && (
          <div className="absolute bottom-4 left-4 ">
            <LiveSessionComponents.BottomLeftButtons.ToggleNavButton
              arrowDirection="left"
              onClick={() => {
                mainRoomStateMgr.toggleLeftToolsNav();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const TopPreviewContainer: React.FC<{
  content: React.ReactNode;
  onClose: () => void;
  onMaximize: () => void;
}> = ({ content, onClose, onMaximize }) => {
  return (
    <div className="flex-1 bg-white rounded-[12px] p-12 overflow-none relative">
      <div className="absolute inset-12 flex">{content}</div>
      <div
        className="absolute top-4 right-4 w-[40px] h-[40px] rounded-full flex items-center justify-center cursor-pointer rounded-full border"
        onClick={onClose}
      >
        X
      </div>
      <div
        className="absolute bottom-4 right-4 w-[40px] h-[40px] bg-vid-black-900 rounded-full flex items-center justify-center cursor-pointer"
        onClick={onMaximize}
        // onClick={onClose}
      >
        <MaximizeSVG />
      </div>
    </div>
  );
};

// const StageTopPreviewSection = <
//   RegisterPingRes extends BaseRegisterPingRes,
//   SessionSpecificStageViewState,
//   SessionSpecificContent,
//   ExtraRemoteState
// >({
//   mainRoomStateMgr,
//   renderCustomTopPreviewDisplay,
// }: {
//   mainRoomStateMgr: MainRoomStateMgr<
//     RegisterPingRes,
//     SessionSpecificStageViewState,
//     SessionSpecificContent,
//     ExtraRemoteState
//   >;
//   renderCustomTopPreviewDisplay: (
//     topPreview: CustomTopPreviewViewStates
//   ) => React.ReactNode;
// }) => {
//   const mbTopPreviewState = useObservableEagerState(
//     mainRoomStateMgr.topPreviewMgr.topPreviewContent$
//   );
//   const isMaximized = useObservableEagerState(
//     mainRoomStateMgr.topPreviewMgr.isTopPreviewMaximized$
//   );

//   if (O.isNone(mbTopPreviewState)) {
//     return <></>;
//   }

//   return (
//     <div
//       className={`${
//         isMaximized ? "flex-1" : "basis-[400px] grow-0 shrink-0"
//       }  bg-white rounded-[12px] p-4 overflow-y-auto relative`}
//     >
//       {!isMaximized && (
//         <div className="flex justify-end">
//           <button
//             onClick={() => {
//               mainRoomStateMgr.topPreviewMgr.closeTopPreview();
//             }}
//           >
//             X
//           </button>
//         </div>
//       )}
//       {Match.value(mbTopPreviewState.value).pipe(
//         Match.tag("Meditation", () => {
//           return <MeditationPreview mainRoomStateMgr={mainRoomStateMgr} />;
//         }),
//         Match.tag("Review", ({ sessionType }) => {
//           return (
//             <ReviewPageContent
//               sessionType={sessionType}
//               hideTabs={["Invoice"]}
//             />
//           );
//         }),
//         Match.tag("EMDR_BALL", ({ mode, ballFrequency }) => {
//           if (mode.state === "OFF") {
//             return <></>;
//           }
//           return (
//             <EMDRBall
//               frequency={ballFrequency}
//               isPlaying={mode.state === "PLAYING"}
//               soundType="beep"
//               soundDuration={0.05}
//             />
//           );
//         }),
//         Match.tag("Extra", ({ extraState }) => {
//           return renderCustomTopPreviewDisplay(extraState);
//         }),
//         Match.exhaustive
//       )}

//       <div
//         className="absolute bottom-4 right-4 w-[40px] h-[40px] bg-vid-black-900 rounded-full flex items-center justify-center cursor-pointer"
//         onClick={() => {
//           mainRoomStateMgr.topPreviewMgr.isTopPreviewMaximized$.next(
//             !isMaximized
//           );
//         }}
//       >
//         <MaximizeSVG />
//       </div>
//     </div>
//   );
// };

const MaximizeSVG: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7zM18 6L6 18"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M18 10V6h-4M6 14v4h4"
    ></path>
  </svg>
);
