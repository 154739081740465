import { BE } from "@webapp/backend";
import { FullCalendar } from "@webapp/componentscalendar/full-calendar.fc";
import { CalendarStateMgr } from "frontend-shared/src/mgrs/state-mgrs/calendar.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import type { PrivateSessionEventInstance } from "shared/types/calendar.types";
import { useCpState } from "./cpdashboard.state";

export const CpDashboardCalendarPage = () => {
  const cpState = useCpState();
  const stateMgr = useOnce(
    () =>
      new CalendarStateMgr<PrivateSessionEventInstance>({
        fetchApptsTE: BE.fetchEndpointTE((Api) =>
          Api.cp.getMyCalendarEvents.query()
        ),
      })
  );
  return (
    <FullCalendar<PrivateSessionEventInstance>
      stateMgr={stateMgr}
      fetchOnMount
      renderEventInMonth={(appt) => (
        <div
          className="bg-vid-black-100 px-2 py-2 flex gap-2 mb-1 rounded-2xl flex justify-center items-center cursor-pointer"
          onClick={() => {
            // hpState.dashboardState.openRightNav({
            //   _tag: "VIEW_APPOINTMENT",
            //   appointmentId: appt.id,
            // });
          }}
        >
          <div className="text-sm">{appt.privateSessionInfo.client.name}</div>
        </div>
      )}
      onDayClick={(day) => {
        console.log(day);
      }}
      newApptButton={{
        onClick: () => {
          cpState.dashboardMgr.openRightNav({
            _tag: "NEW_APPOINTMENT",
          });
          // hpState.dashboardState.openRightNav({
          //   _tag: "NEW_APPOINTMENT",
          // });
        },
        title: "New appointment",
      }}
    />
  );
};
