import * as S from "@effect/schema/Schema";
import { Effect, Match } from "effect";
import type { UnknownException } from "effect/Cause";
import {
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
  type DocumentData,
  type DocumentReference,
  type Firestore,
  type UpdateData,
} from "firebase/firestore";
import { FirebaseJsMgr } from "frontend-shared/src/firebase";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import {
  BaseSessionStateModule,
  GroupSessionStateModule,
  PrivateSessionStateModule,
} from "shared/session-state/session-state.types";
import { ContentViewStateModule } from "shared/session-state/shared-session-content-state.types";
import { isNonNull } from "shared/util";
import { getLastEmittedValue } from "./util";

type SessionType =
  | { _tag: "PRIVATE"; sessionId: string }
  | { _tag: "GROUP"; groupSessionId: string };

export class RemoteSessionStateSyncMgr<
  SerializedRemoteState extends BaseSessionStateModule.FirebaseEncodedState,
  DeserializedRemoteState extends BaseSessionStateModule.State
> {
  private syncedRemoteState$ =
    new Rx.BehaviorSubject<SerializedRemoteState | null>(null);

  remoteState$ = this.syncedRemoteState$.pipe(
    RxO.filter(isNonNull),
    RxO.distinctUntilChanged(
      (prev, cur) => JSON.stringify(prev) === JSON.stringify(cur)
    )
  );

  decodedRemoteState$: Rx.Observable<DeserializedRemoteState>;

  constructor(
    private readonly schema: S.Schema<
      DeserializedRemoteState,
      SerializedRemoteState
    >,
    private readonly sendUpdateRemoteState: (
      onNext: (state: SerializedRemoteState) => void
    ) => void,
    private readonly remoteStateUpdateEff: (
      newStatePart: Partial<SerializedRemoteState>
    ) => Effect.Effect<SerializedRemoteState, UnknownException>
  ) {
    this.decodedRemoteState$ = this.remoteState$.pipe(
      RxO.map((state) => S.decodeUnknownSync(this.schema)(state))
    );
    this.sendUpdateRemoteState((state) => {
      this.syncedRemoteState$.next(state);
    });
  }

  getCurrentRemoteState = (): Effect.Effect<DeserializedRemoteState, Error> =>
    getLastEmittedValue(this.decodedRemoteState$);

  runUpdateRemoteState = (
    updateFn: (
      curState: DeserializedRemoteState
    ) => Partial<DeserializedRemoteState>
  ): void => {
    Effect.runPromise(this.updateRemoteState(updateFn)).then();
  };

  updateRemoteState = (
    updateFn: (
      curState: DeserializedRemoteState
    ) => Partial<DeserializedRemoteState>
  ): Effect.Effect<
    | { _tag: "NOT_SYNCED" }
    | { _tag: "UPDATED"; newState: SerializedRemoteState },
    UnknownException | Error
  > =>
    Effect.gen(this, function* () {
      const curState = yield* this.getCurrentRemoteState();
      const newUpdatedState = updateFn(curState);
      const newState = { ...curState, ...newUpdatedState };
      const newRemoteState = S.encodeSync(this.schema)(newState);
      return yield* this.remoteStateUpdateEff(newRemoteState).pipe(
        Effect.map((newState) => ({ _tag: "UPDATED" as const, newState }))
      );
    });

  runUpdateHostContentViewMode = (
    viewMode: ContentViewStateModule.ContentStageViewMode | null
  ) => {
    this.runUpdateRemoteState((curState) => ({
      ...curState,
      hostContentViewMode: viewMode,
    }));
  };

  runUpdateParticipantsContentViewMode = (
    viewMode: ContentViewStateModule.ContentStageViewMode
  ) => {
    this.runUpdateRemoteState((curState) => ({
      ...curState,
      participantsContentViewMode: viewMode,
    }));
  };

  updateBaseContentState = (
    updateFn: (
      curContent: ContentViewStateModule.StateContentDisplay.State
    ) => Partial<ContentViewStateModule.StateContentDisplay.State>
  ): Effect.Effect<
    | { _tag: "NOT_SYNCED" }
    | { _tag: "UPDATED"; newState: SerializedRemoteState },
    UnknownException | Error
  > => {
    return this.updateRemoteState((curState) => ({
      ...curState,
      content: updateFn(
        curState.content ??
          ContentViewStateModule.StateContentDisplay.defaultState
      ),
    }));
  };

  runUpdateBaseContentState = (
    updateFn: (
      curContent: ContentViewStateModule.StateContentDisplay.State
    ) => Partial<ContentViewStateModule.StateContentDisplay.State>
  ) => {
    Effect.runPromise(this.updateBaseContentState(updateFn)).then();
  };

  clearAllContentState = () => {
    this.runUpdateRemoteState((curState) => ({
      ...curState,
      hostContentViewMode: null,
      content: null,
    }));
  };

  clearBaseContentState = () => {
    this.runUpdateBaseContentState((_) =>
      ContentViewStateModule.StateContentDisplay.State.make({ current: null })
    );
  };
}

export class FirestoreSessionStateMgmt<
  SerializedRemoteSessionState extends BaseSessionStateModule.FirebaseEncodedState,
  DeserializedSessionState extends BaseSessionStateModule.State
> {
  remoteStateSyncMgr: RemoteSessionStateSyncMgr<
    SerializedRemoteSessionState,
    DeserializedSessionState
  >;
  sessionDoc: DocumentReference<DocumentData, SerializedRemoteSessionState>;

  docForSessionType(
    sessionType: SessionType,
    firestore: Firestore
  ): DocumentReference<DocumentData, SerializedRemoteSessionState> {
    return Match.value(sessionType).pipe(
      Match.tag(
        "PRIVATE",
        ({ sessionId }) =>
          doc(firestore, "sessions", sessionId) as DocumentReference<
            DocumentData,
            SerializedRemoteSessionState
          >
      ),
      Match.tag(
        "GROUP",
        ({ groupSessionId }) =>
          doc(firestore, "group-sessions", groupSessionId) as DocumentReference<
            DocumentData,
            SerializedRemoteSessionState
          >
      ),
      Match.exhaustive
    );
  }

  constructor(
    mgr: FirebaseJsMgr,
    sessionType: SessionType,
    readonly initialSessionState: DeserializedSessionState,
    readonly schema: S.Schema<
      DeserializedSessionState,
      SerializedRemoteSessionState
    >,
    autoInitializeSessionState: boolean
  ) {
    this.sessionDoc = this.docForSessionType(sessionType, mgr.firestore);

    const subscribeToRemoteState = (
      onNext: (state: SerializedRemoteSessionState) => void
    ) => {
      onSnapshot(this.sessionDoc, (ssr) => {
        const snapshot = ssr.data();
        console.log("FIRESTORE SNAPSHOT! ", snapshot);
        if (snapshot) {
          const sessionState = S.decodeUnknownSync(this.schema)(snapshot);
          const remoteState = S.encodeSync(this.schema)(sessionState);
          onNext(remoteState);
        }
      });
    };

    this.remoteStateSyncMgr = new RemoteSessionStateSyncMgr<
      SerializedRemoteSessionState,
      DeserializedSessionState
    >(this.schema, subscribeToRemoteState, (newStatePart) =>
      this.sendUpdateSessionState(newStatePart)
    );

    /* TODO: Deprecated this */
    if (autoInitializeSessionState) {
      Effect.runPromise(this.lazyCreateSessionStateDoc).then();
    }
  }

  /* TODO: Deprecated this */
  lazyCreateSessionStateDoc = Effect.gen(this, function* () {
    const exists = yield* this.mbExistingDoc;
    if (exists) {
      return;
    }
    yield* this.createSessionStateDocEff();
  });

  /* TODO: Deprecated this */
  mbExistingDoc = Effect.gen(this, function* () {
    const ssr = yield* Effect.promise(() => getDoc(this.sessionDoc));
    return ssr.exists();
  });

  /* TODO: Deprecated this */
  createSessionStateDocEff = (): Effect.Effect<void> => {
    const defaultState = S.encodeSync(this.schema)(this.initialSessionState);
    console.log(
      "CREATING SESSION STATE DOC",
      this.initialSessionState,
      defaultState
    );
    return Effect.promise(() =>
      setDoc(this.sessionDoc, { ...defaultState, createdAt: new Date() })
    );
  };

  deleteSessionStateDoc() {
    return deleteDoc(this.sessionDoc);
  }

  private decodeDataFromFirestore(dd: DocumentData): DeserializedSessionState {
    return S.decodeUnknownSync(this.schema)(dd);
  }

  fetchRemoteSessionState = (): Effect.Effect<
    SerializedRemoteSessionState,
    UnknownException
  > =>
    Effect.gen(this, function* () {
      const ssr = yield* Effect.tryPromise(() => getDoc(this.sessionDoc));
      const state = this.decodeDataFromFirestore(ssr.data()!);
      const remoteState = S.encodeSync(this.schema)(state);
      return remoteState;
    });

  private sendUpdateSessionState = (
    update: Partial<SerializedRemoteSessionState>
  ): Effect.Effect<SerializedRemoteSessionState, UnknownException> =>
    Effect.gen(this, function* () {
      yield* Effect.tryPromise(() =>
        updateDoc(
          this.sessionDoc,
          update as UpdateData<SerializedRemoteSessionState>
        )
      );
      const curState = yield* this.fetchRemoteSessionState();
      return curState;
    });
}

export function FirestorePrivateSessionStateMgmt(
  mgr: FirebaseJsMgr,
  sessionId: string
) {
  return new FirestoreSessionStateMgmt(
    mgr,
    { _tag: "PRIVATE", sessionId },
    PrivateSessionStateModule.defaultState,
    PrivateSessionStateModule.State,
    true
  );
}

export function FirestoreGroupSessionStateMgmt(
  mgr: FirebaseJsMgr,
  groupSessionId: string
) {
  return new FirestoreSessionStateMgmt(
    mgr,
    { _tag: "GROUP", groupSessionId },
    GroupSessionStateModule.defaultState,
    GroupSessionStateModule.State,
    true
  );
}
