import { useHpState } from "@pages/u/hp/hp.webstatemgr";
import { BE } from "@webapp/backend";
import { FullContainerLoadingSpinner } from "@webapp/loading";
import { pipe } from "fp-ts/function";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { E, RD } from "shared/base-prelude";

export const RegisterNewClientForm: React.FC = () => {
  const { register, handleSubmit } = useForm<{ email: string }>();
  const dashboardMgr = useHpState().dashboardState;
  const [submitResult, setSubmitResult] = useState<
    RD.RemoteData<unknown, { msg: string }>
  >(RD.initial);

  return (
    <div className="w-[400px] py-16 px-8 flex flex-col mt-8 gap-8">
      <form
        className="flex flex-col gap-8"
        onSubmit={handleSubmit((data) => {
          console.log(data);
          setSubmitResult(RD.pending);
          BE.fetchEndpointTE((Api) =>
            Api.hp.requestClient.mutate({
              clientEmail: data.email,
              autoApprove: false,
            })
          )().then((er) => {
            console.log(er);
            setSubmitResult(
              pipe(
                RD.fromEither(er),
                RD.mapLeft((f) => f.error.message)
              )
            );

            if (E.isRight(er)) {
              dashboardMgr.closeRightNav();

              dashboardMgr.showBottomToast({
                msg: er.right.msg,
                duration: { _tag: "SECONDS", seconds: 10 },
                reload: true,
                // confirmButton: {
                //   label: "Got it",
                //   onClick: () => {
                //     dashboardMgr.hideBottomToast();
                //   },
                // },
              });
            }
          });
        })}
      >
        <h5>
          {`Enter your client's email address. We'll send them an email with a link to register and notify you when they approve.`}
        </h5>
        <input
          type="email"
          placeholder="email"
          className="text-input"
          autoCapitalize="off"
          autoCorrect="off"
          {...register("email", { required: true })}
        />
        <button disabled={RD.isPending(submitResult)} className="btn-light">
          {pipe(
            submitResult,
            RD.fold(
              () => <div>Add client</div>,
              () => <FullContainerLoadingSpinner />,
              (e: unknown) => <div>{JSON.stringify(e)}</div>,
              () => <div>Successfly added client</div>
            )
          )}
        </button>
      </form>
      {RD.isFailure(submitResult) && (
        <div className="text-red-500">{JSON.stringify(submitResult.error)}</div>
      )}
    </div>
  );
};
